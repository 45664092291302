import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Fee = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Fee - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Fee"
                />
                <meta
                    name="Keyword"
                    content="Canada Fee"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black text-4xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>FEE payment process</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The fee payment process for studying in Canada can vary depending on the institution and program you have chosen. Here are some general steps that you may need to follow:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Review tuition fees:</span> Check the tuition fees for your program and make sure you have a clear understanding of the costs involved.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for financial aid:</span> If you need financial assistance to pay for your studies, research the various financial aid options available, such as scholarships, bursaries, and student loans.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit your fees:</span> Once you have received your offer of admission, you will typically be required to submit a deposit to secure your spot in the program. This deposit may be credited towards your tuition fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pay the remaining fees:</span> The remaining tuition fees are usually due prior to the start of the academic term. You may be able to make this payment online, by mail, or in person.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Set up a payment plan:</span> Set up a payment plan: If you need to spread out your payments over a longer period of time, many institutions offer a payment plan option, which allows you to make smaller, regular payments instead of a large lump sum.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that fee payment processes and requirements can vary depending on the institution and program, so it's best to check with the institution directly for specific details and deadlines.</p>

                </div>
            </div>
        </div>
    )
}

export default Canada_Fee