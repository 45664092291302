import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_PR = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia PR - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia PR"
                />
                <meta
                    name="Keyword"
                    content="Australia PR"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Permanent Residency(PR)</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>PR stands for Permanent Residency, it is a status granted to individuals who are allowed to live and work in a country on a permanent basis. In Australia, obtaining PR is a multi-step process that requires meeting eligibility criteria, submitting a visa application, and being approved by the Department of Home Affairs. Individuals who hold PR in Australia are entitled to many of the same rights and privileges as citizens, including access to public healthcare and the ability to sponsor family members for migration. However, PR holders are not citizens, and do not have the right to vote or access certain government benefits. The process for obtaining PR in Australia can be complex and competitive, and is subject to change. It is advisable to seek professional guidance from a registered migration agent or a lawyer for the latest information and assistance.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Eligibility for PR</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students who have completed their studies in Australia may be eligible for Permanent Residency (PR) in Australia through several visa streams, subject to meeting specific eligibility criteria. Some of the most common paths to PR for international students include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Skilled Independent Visa (Subclass 189):</span> This visa is for highly skilled individuals who are not sponsored by an employer, state or territory, or family member. To be eligible, individuals must score enough points on the Points Test, have a positive skills assessment in an eligible occupation, and meet the English language requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Skilled Nominated Visa (Subclass 190):</span> This visa is for highly skilled individuals who are nominated by an Australian state or territory. To be eligible, individuals must score enough points on the Points Test, have a positive skills assessment in an eligible occupation, and meet the English language requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Graduate Temporary Visa (Subclass 485):</span> This visa is for international students who have completed a degree in Australia and are seeking to gain work experience in their field of study. To be eligible, individuals must have recently graduated from an eligible course of study and meet the other requirements of the visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Employer Nomination Scheme (Subclass 186):</span> This visa is for individuals who are sponsored by their employer for permanent work in Australia. To be eligible, individuals must be nominated by an employer for a permanent position and meet the other requirements of the visa.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: Eligibility criteria for PR visas are subject to change, and it is advisable to seek professional guidance from a registered migration agent or a lawyer for the latest information and assistance.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>How to Apply For PR</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To apply for Permanent Residency (PR) in Australia after holding a subclass 485 visa, the following steps can be followed:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meet the eligibility criteria:</span> International students must meet certain criteria to be eligible for PR, such as having a positive skills assessment in an occupation on the relevant skills list, meeting the English language requirements, and having worked for a specified period in their occupation.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose the right visa pathway:</span> There are several visa pathways to PR in Australia, including the Skilled Independent visa (subclass 189), the Skilled Nominated visa (subclass 190), and the Employer Nomination Scheme (subclass 186).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather and prepare documents:</span> Prepare all the required documentation, including English language test scores, work experience evidence, and a positive skills assessment report.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Lodge the visa application:</span> Complete and submit the online visa application form and pay the visa application fee.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for the processing:</span> The processing time for PR applications may vary, but it typically takes several months. Applicants will be informed of the outcome of their application via email.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend a visa interview:</span> Some applicants may be required to attend a visa interview. The purpose of the interview is to verify the information provided in the visa application and to clarify any issues.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive the visa grant:</span> If the visa application is approved, the applicant will receive the visa grant letter and can now start preparing for the move to Australia as a Permanent Resident.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Subclass 189, Subclass 190, and Subclass 186 are three different types of Permanent Residence (PR) visas for Australia.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Subclass 189 is the Skilled Independent visa, designed for skilled workers who have a job offer from an Australian employer or who have been nominated by a state or territory government.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Subclass 190 is the Skilled Nominated visa, designed for skilled workers who have been nominated by a state or territory government.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Subclass 186 is the Employer Nomination Scheme (ENS) visa, designed for skilled workers who have been nominated by their employer for a permanent position in Australia.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Each of these visas has different eligibility criteria and requirements, including age, skills, work experience, and language proficiency. They also vary in terms of the application process and timeline, so it is important to research each one carefully before deciding which one to apply for.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_PR