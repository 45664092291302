import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CountUp from 'react-countup'
import { FaGraduationCap } from 'react-icons/fa';
import { FaHouseUser } from 'react-icons/fa';
import { FaCopy } from 'react-icons/fa';
import { FaNewspaper } from 'react-icons/fa';
import { FaPlane } from 'react-icons/fa';
import { FaStickyNote } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Contactform from "../Contact/Contactform";
const LandingPage = () => {
  SwiperCore.use([Autoplay]);
  const history = useHistory();

  const [style, setStyle] = useState({ display: 'none' });
  const [styleII, setStyleII] = useState({ display: 'none' });
  const [styleIII, setStyleIII] = useState({ display: 'none' });
  const [styleIV, setStyleIV] = useState({ display: 'none' });
  const [styleV, setStyleV] = useState({ display: 'none' });
  const [styleVI, setStyleVI] = useState({ display: 'none' });

  const handleClickForFirst = () => {
    history.push('education-loan')
    window.scrollTo(0, 0);
  }

  const handleClickForSecond = () => {
    history.push('accommodation')
    window.scrollTo(0, 0);
  }

  const handleClickForThird = () => {
    history.push('letter-of-recommendation')
    window.scrollTo(0, 0);
  }

  const handleClickForFourth = () => {
    history.push('professional-resume')
    window.scrollTo(0, 0);
  }

  const handleClickForFifth = () => {
    history.push('flight-tickets-students-visa')
    window.scrollTo(0, 0);
  }

  const handleClickForSix = () => {
    history.push('statement-of-purpose')
    window.scrollTo(0, 0);
  }

  const handleClickForLandingPageImages = () => {
    history.push('contact')
    window.scrollTo(320, 320)
  }

  const Landing_Page_Logo = [
    { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-1.jpg' },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-2.png" },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-3.png" },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-4.png" },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-5.png" },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-6.png" },
    { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Landing+page+logo/LandingPage-logo-7.png" }
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Dream Makers</title>
        <meta
          name="description"
          content="Home"
        />
        <meta
          name="Keyword"
          content="Home"
        />
      </Helmet>
      <div>

        {/* Image Slider */}
        <div className='mx-auto md:mt-0 mt-2 mb-6'>
          <Swiper
            className=" "
            modules={[Autoplay, Pagination]} // for pagination add {,pagination} module
            pagination={{
              clickable: true,
            }}
            loop={true}
            slidesPerView={1}
            spaceBetween={1}

            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img
                src={'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/LPI-I.jpg'}
                alt=""
                className="mx-auto w-full md:h-full h-96 px-4 bg-cover md:bg-top bg-center"
              />
              <div className="fixed 2xl:top-64 2xl:mt-12 2xl:left-64 2xl:ml-32 lg:top-1 sm:top-5 sm:mt-20 md:mt-32 ml-16 lg:w-[42%] w-[70%] top-1 mt-20 ">
                <p className=" lg:text-5xl text-xl text-white font-bold text-left mb-2 mt-2">Make your dream of studying abroad a reality with Future Dream Makers !</p>
                <button onClick={handleClickForLandingPageImages} className="text-white px-4 py-2 bg-transparent border-4 border-red-500 rounded-full hover:bg-red-500 mb-2">APPLY NOW</button>
                <p className="lg:text-3xl text-xl text-white text-semibold">Get access to over 750 universities in the United States, United Kingdom, Australia, Canada, and Your Dream countries</p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img
                src={'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/LPI-II.jpg'}
                alt=""
                className="mx-auto w-full md:h-full h-96 px-4 bg-cover md:bg-top bg-center brightness-50"
              />
              <div className="fixed 2xl:top-64 2xl:mt-12 2xl:left-64 2xl:ml-32 sm:top-16 top-1 mt-28 ml-16 lg:w-[42%] w-[70%]">
                <p className=" md:text-5xl text-xl text-white font-bold text-left mb-2 mt-2">Education Consultancy and Training</p>
                <button onClick={handleClickForLandingPageImages} className="text-white px-4 py-2 bg-transparent border-4 border-red-500 rounded-full hover:bg-red-500 mb-2">APPLY NOW</button>
                <p className="md:text-3xl text-xl text-white text-semibold">IELTS, PTE, GMAT, SAT, TOEFL, CAT</p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img
                src={'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/LPI-III.jpg'}
                alt=""
                className="mx-auto w-full md:h-full h-96 px-4 bg-cover md:bg-top bg-center "
              />
              <div className="fixed 2xl:top-64 2xl:mt-12 2xl:left-64 2xl:ml-32 sm:top-16 top-1 mt-28 ml-16 lg:w-[42%] w-[70%]">
                <p className="md:text-5xl text-xl text-white font-bold text-left mb-2 mt-2">Study Abroad With Best University</p>
                <button onClick={handleClickForLandingPageImages} className="text-white px-4 py-2 bg-transparent border-4 border-red-500 rounded-full hover:bg-red-500 mb-2">APPLY NOW</button>
                <p className="md:text-3xl text-xl text-white text-semibold">Get UGC, DEB, AIU, WES and Govt. Approved Degrees</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* Service Section */}
        <div className='mx-auto w-full h-auto py-12 px-4 bg-LPS bg-cover md:bg-top bg-center'>
          <div className='text-center text-5xl font-bold text-white mb-6'>
            <p>Our Services</p>
          </div>
          <div className='grid md:grid-cols-3 grid-cols-1 gap-4 '>
            <div className='bg-white rounded-xl ' onMouseEnter={e => {
              setStyle({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyle({ display: 'none' })
              }}>
              <div><FaGraduationCap className='w-auto h-24 text-red-500 m-auto' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>EDUCATION LOAN</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>The Combination Of Applications, Admissions, Relocation, And Student Living Expenditures. We Can Assist You In Applying With Complete Peace Of Mind.</p>
              <div className='flex p-4' ><button style={style} onClick={handleClickForFirst} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black' >Learn More</button></div>
            </div>

            <div className='bg-white rounded-xl' onMouseEnter={e => {
              setStyleII({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyleII({ display: 'none' })
              }}>
              <div><FaHouseUser className='w-auto h-20 text-red-500 m-auto mt-3' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>ACCOMMODATION</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>Homely PGs, Hostels, And Flats Are Located Throughout Campus. From The Moment You Get Off The Plane, Stay In Touch With Your Loved Ones.</p>
              <div className='flex p-4'><button style={styleII} onClick={handleClickForSecond} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black'>Learn More</button></div>
            </div>

            <div className='bg-white rounded-xl' onMouseEnter={e => {
              setStyleIII({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyleIII({ display: 'none' })
              }}>
              <div><FaCopy className='w-auto h-20 text-red-500 m-auto mt-3' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>LETTER OF RECOMMENDATION</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>We Will Assist The Students With Letter Of Recommendation For Their Universities. Most Admission Committee Require 2-3 LORs To Gain A Clearer & Favorable Image</p>
              <div className='flex p-4'><button style={styleIII} onClick={handleClickForThird} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black'>Learn More</button></div>
            </div>

            <div className='bg-white rounded-xl' onMouseEnter={e => {
              setStyleIV({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyleIV({ display: 'none' })
              }}>
              <div><FaNewspaper className='w-auto h-20 text-red-500 m-auto mt-3' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>PROFESSIONAL RESUME</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>Our Experts Are Professionally Trained To Assist You In Writing And Editing Your Professional Resume In Order To Significantly Increase Your Chances Of Selection Abroad.</p>
              <div className='flex p-4'><button style={styleIV} onClick={handleClickForFourth} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black'>Learn More</button></div>
            </div>

            <div className='bg-white rounded-xl' onMouseEnter={e => {
              setStyleV({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyleV({ display: 'none' })
              }}>
              <div><FaPlane className='w-auto h-20 text-red-500 m-auto mt-3' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>FLIGHT TICKETS | VISA DOCUMENT</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>With Our Visa Counsellors Years Of Knowledge And Experience, We Will Ensure That Your Visa And Flight Tickets Are Granted And Purchased Quickly And Efficiently.</p>
              <div className='flex p-4'><button style={styleV} onClick={handleClickForFifth} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black'>Learn More</button></div>
            </div>

            <div className='bg-white rounded-xl' onMouseEnter={e => {
              setStyleVI({ display: 'block' });
            }}
              onMouseLeave={e => {
                setStyleVI({ display: 'none' })
              }}>
              <div><FaStickyNote className='w-auto h-20 text-red-500 m-auto mt-3' /></div>
              <p className='text-lg font-extrabold font-serif text-center'>STATEMENT OF PURPOSE (SOP)</p>
              <p className='p-4 text-base font-medium font-serif text-justify mb-2.5'>Our Experts Are Professionally Trained To Assist In Writing And Revising Powerful Profile Writing, Goal Setting, Academic Assessment And Solid Indian Connection</p>
              <div className='flex p-4'><button style={styleVI} onClick={handleClickForSix} className='bg-red-500 rounded-full p-4 font-medium drop-shadow-2xl text-center m-auto shadow-2xl shadow-black'>Learn More</button></div>
            </div>
          </div>
        </div>

        {/* FDM Partner University Swiper */}
        <p className="md:text-4xl text-3xl uppercase font-bold text-red-500 text-center mt-10 mb-10 underline  decoration-red-500 decoration-4 underline-offset-8">FDM Partner Universities</p>
        <div className='px-4 sm:px-12 md:px-8 lg:px-8 xl:px-8 2xl:px-36 m-auto mb-6'>
          <Swiper
            className=" "
            breakpoints={{
              300: {
                width: 350,
                slidesPerView: 1
              },
              350: {
                width: 350,
                slidesPerView: 2
              },
              400: {
                width: 400,
                slidesPerView: 2,
              },
              450: {
                width: 400,
                slidesPerView: 2,
              },
              500: {
                width: 500,
                slidesPerView: 2,
              },
              550: {
                width: 500,
                slidesPerView: 2,
              },
              600: {
                width: 600,
                slidesPerView: 2,
              },
              650: {
                width: 600,
                slidesPerView: 2,
              },
              700: {
                width: 600,
                slidesPerView: 2,
              },
              800: {
                width: 900,
                slidesPerView: 5,
              },
              1024: {
                width: 1024,
                slidesPerView: 5,
              }
            }}
            modules={[Autoplay]} // for pagination add {,pagination} module
            pagination={{
              clickable: true,
            }}
            loop={true}
            slidesPerView={5}
            spaceBetween={1}
            autoplay={{
              delay: 900,
              disableOnInteraction: false,
            }}
          >
            {Landing_Page_Logo.map((slideContent, index) => (
              <SwiperSlide>
                <img
                  src={slideContent.image}
                  alt=""
                  className="w-auto h-auto align-middle p-1 "
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Counter Section */}
        <p className="md:text-4xl text-3xl px-8 uppercase font-bold text-red-500 text-center mt-10 mb-10 underline  decoration-red-500 decoration-4 underline-offset-8">Why Choose FDM</p>
        <div className='mx-auto w-full md:h-96 h-auto py-12 px-4 bg-LPC bg-cover md:bg-top bg-center bg-[#abb8c3]'>
          <div className='grid md:grid-cols-3 md:gap-8 lg:gap-0 lg:grid-cols-5 grid-cols-1 place-content-stretch justify-items-center text-center md:mt-32 px-8'>

            <div className='text-white flex flex-col items-center md:mb-0 mb-6'>
              <div><p className='md:pr-0 pr-12 text-5xl font-bold'>
                <CountUp end={8} /> +
              </p> </div>
              <p className=' pl-8 text-lg font-semibold lg:pl-8'>
                Years of Experience
              </p>
            </div>

            <div className='text-white flex flex-col items-center md:mb-0 mb-6'>
              <div> <p className='md:pl-8 text-5xl font-bold'>
                <CountUp end={250} /> +
              </p> </div>
              <p className='md:pr-0 pr-8 text-lg font-semibold'>
                Universities
              </p>
            </div>

            <div className='text-white flex flex-col items-center md:mb-0 mb-6'>
              <div>  <p className=' text-5xl font-bold'>
                <CountUp end={500} /> +
              </p> </div>
              <p className='pr-12 text-lg font-semibold'>
                Programs
              </p>
            </div>

            <div className='text-white flex flex-col items-center md:mb-0 mb-6 md:ml-8 lg:ml-0'>
              <div> <p className='md:pl-0 pl-4 text-5xl font-bold'>
                <CountUp end={1000} /> +
              </p> </div>
              <p className='md:pl-0 pl-8 text-lg font-semibold lg:pl-0'>
                Students Counselled
              </p>
            </div>

            <div className='text-white flex flex-col items-center md:mb-0 mb-6 md:ml-4 lg:ml-0'>
              <div> <p className='text-5xl font-bold'>
                <CountUp end={750} /> +
              </p> </div>
              <p className='md:pl-8 pl-4 text-lg font-semibold'>
                Students Enrolled
              </p>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <Contactform />
      

      </div>
    </div>
  )
}

export default LandingPage
