import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_OHSC = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia OHSC - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia OHSC"
                />
                <meta
                    name="Keyword"
                    content="Australia OHSC"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>OHSC</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>OSHC stands for Overseas Student Health Cover, which is a health insurance policy that is required for international students in Australia to have. The policy is meant to cover the medical and hospital costs for international students in case of an injury or illness during their stay in Australia. This insurance is mandatory for student visa holders and must be valid for the entire duration of their study in Australia. OSHC is provided by various insurance providers in Australia, and the cost varies depending on the insurance provider and the length of the policy.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Process for OSHC</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of obtaining OSHC in Australia is straightforward and can usually be done online through the insurance provider's website. The following are the general steps to follow:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose an insurance provider:</span> There are several insurance providers offering OSHC in Australia, so you can compare plans, coverage, and prices and choose the best one for your needs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply online:</span> Most insurance providers offer an online application process, where you can complete the form and make payment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Payment of premium:</span> You will need to pay the premium for the OSHC policy, which can be done using a credit card, debit card, or bank transfer.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receiving the OSHC certificate:</span> Once you have completed the application and payment, you will receive the OSHC certificate either via email or mail. You must provide a copy of this certificate when applying for a student visa.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of OSHC varies depending on the insurance provider, the length of the policy, and the level of coverage. On average, the cost ranges from AUD 300 to AUD 500 per year, but it may be higher or lower depending on your circumstances.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: You can opt to include OSHC as part of your upfront payment to the university, or you can purchase it separately from the insurance provider.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_OHSC