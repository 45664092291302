import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Job = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Job - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Job"
                />
                <meta
                    name="Keyword"
                    content="Canada Job"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Part time job options</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Yes, part-time jobs are available in Canada. The types of part-time jobs available in Canada vary widely and may include positions in retail, hospitality, food service, customer service, administrative support, and many others. Some industries, such as healthcare and education, may offer part-time positions in specialized fields such as nursing, teaching, or support staff. The availability of part-time jobs can also vary depending on the region and the local job market. However, there are many job search websites and resources available in Canada that can help you find part-time job opportunities.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>From where student can findout part time job</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in Canada can find part-time jobs by using the following resources:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Campus job boards or career centers:</span> Many universities and colleges in Canada have job boards or career centers where students can find part-time job opportunities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Online job websites:</span> There are many online job websites in Canada that allow students to search for part-time jobs, such as Indeed, Monster, and Workopolis.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Networking:</span> Networking with other students, alumni, professors, and local professionals can help students find part-time job opportunities in Canada.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Part-time job fairs:</span> Many universities and colleges in Canada host part-time job fairs where students can meet with employers and learn about available job opportunities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Temporary staffing agencies:</span> Temporary staffing agencies can help students find part-time jobs in a variety of industries and professions.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>On-campus student jobs:</span> Many universities and colleges in Canada offer on-campus student jobs, such as research assistant positions or work-study programs.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important for students to research the eligibility requirements for working in Canada as an international student and to ensure they have the necessary work permit before starting a part-time job.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Website and app from where students can findout part time job</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in Canada can find part-time job opportunities through the following websites and apps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Indeed:</span> A popular job search website that allows users to search for part-time jobs by location and keyword.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Workopolis:</span> A Canadian job search website that has a section for part-time jobs and allows users to create job alerts for new opportunities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Monster:</span> A job search website with a large database of job opportunities, including part-time jobs, that can be filtered by location and keyword.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Kijiji:</span> A classifieds website and app that has a section for part-time jobs and allows users to search for opportunities by location.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>LinkedIn:</span> A professional networking platform that allows users to search for job opportunities, including part-time jobs, and connect with potential employers.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Glassdoor:</span> A job search website and app that provides information on company culture, salaries, and other aspects of a job, as well as job listings.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that not all part-time job opportunities in Canada are listed online, so students may also want to consider networking and talking to people in their communities to find out about available opportunities.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Which job is booming in Canada</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There is no one specific job that is booming in Canada for international students, as job trends can vary depending on the location and industry. However, the following industries and fields in Canada have seen strong growth in recent years and may offer good opportunities for international students:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Technology:</span> Canada has a thriving technology industry, particularly in cities like Toronto, Vancouver, and Montreal, and there is a growing demand for skilled workers in areas such as software development, artificial intelligence, and cybersecurity.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Healthcare:</span> The healthcare industry in Canada is rapidly expanding and there is a high demand for workers in areas such as nursing, medical technology, and physician services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Finance and Business:</span> Canada has a strong financial services sector and there is a growing demand for workers in areas such as accounting, finance, and business management.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Energy and Environment:</span> Canada is a major producer of energy, including oil and natural gas, and there is a growing demand for workers in areas such as renewable energy, environmental management, and sustainability</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Skilled Trades:</span> Canada has a growing demand for skilled trades workers, such as electricians, plumbers, and construction workers, as the country continues to develop its infrastructure and build new projects.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important for international students to research job market trends in their specific area of interest and to ensure they have the necessary qualifications and work permit before starting a job in Canada.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Full time job options</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several ways to find full-time job opportunities in Canada:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Online job search websites:</span> Websites like Indeed, Workopolis, and Monster have a large database of job postings in Canada, including full-time job opportunities. You can search for jobs by location, industry, and keyword to find positions that match your interests and skills.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Networking:</span> Networking is a key way to find job opportunities in Canada. Connect with professionals in your field, attend industry events, and join professional organizations to meet people and learn about available job openings.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Employee Referral Programs:</span> Many companies offer referral programs that reward current employees for referring friends and family members for job openings. If you know someone who works for a company you're interested in, ask if they know of any job openings.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Company websites:</span> Many companies have a careers or job search section on their websites, where they post current job openings. Check the websites of companies you're interested in and apply directly for any open positions.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Recruitment agencies:</span> Recruitment agencies can be a useful resource for finding job opportunities in Canada. They work with a variety of companies to fill job openings and can help you find a position that matches your skills and interests.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Government job portals:</span> The Government of Canada and individual provinces have job portals where you can search for job openings and learn about employment opportunities in various industries.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to be proactive and persistent in your job search. Prepare a strong resume and cover letter, and be prepared to apply to multiple job openings and follow up with potential employers.</p>

                </div>
            </div>
        </div>
    )
}

export default Canada_Job