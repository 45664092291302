import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Blackrock = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Blackrock - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Blackrock"
                />
                <meta
                    name="Keyword"
                    content="Ireland Blackrock"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Blackrock</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Blackrock is a suburb of Dublin, located on the south coast of Ireland, with a population of over 10,000 people. It is home to several universities and colleges, including Trinity College Dublin and University College Dublin. The average tuition fees for international students in Blackrock range from €10,000 to €20,000 per year. Some of the top courses for international students in Blackrock include finance, business, and marketing. The highest paying jobs in Blackrock are in the finance, technology, and marketing sectors, with roles such as financial analyst, software engineer, and marketing manager. The average wage for these jobs is between €45,000 and €60,000 per year. The cost of living in Blackrock is relatively higher compared to other cities in Ireland, with an average monthly rent of €900 and a monthly grocery bill of €300. Blackrock is known for its sandy beaches, beautiful parks, and scenic coastal walks. There are also several part-time and full-time job opportunities for international students in Blackrock, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Blackrock