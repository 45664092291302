import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const DS160 = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA DS160 - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA DS160"
                />
                <meta
                    name="Keyword"
                    content="USA DS160"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>what is DS160</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>DS-160 is an online visa application form used by the U.S. Department of State to collect information from individuals applying for nonimmigrant visas, including F-1 student visas and J-1 exchange visitor visas.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The DS-160 form is used by U.S. consular officials to determine an applicant's eligibility for a visa and to verify the information provided. The form collects information such as the applicant's name, birth date, passport information, education and work history, and the purpose of the trip to the United States.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Applicants for a U.S. visa must complete the DS-160 form online and submit it, along with the required fee and supporting documents, prior to scheduling an interview at a U.S. embassy or consulate. The DS-160 form is a critical component of the U.S. visa application process and must be completed accurately and truthfully.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In summary, the DS-160 is an online visa application form used by the U.S. Department of State to collect information from individuals applying for nonimmigrant visas, including F-1 student visas and J-1 exchange visitor visas. It is a critical component of the U.S. visa application process and must be completed accurately and truthfully.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Process For DS-160</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of completing the DS-160 visa application form is as follows:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather information:</span> Before starting the application, gather information about yourself, such as your name, address, work history, education history, and passport information.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Access the DS-160 online form:</span> Go to the U.S. Department of State's website and access the DS-160 form.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Complete the form:</span> Fill in all required fields with accurate and up-to-date information. You will be asked to provide information about your personal and travel details, including your name, birth date, passport information, and the purpose of your trip to the United States.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Upload a photo:</span> You will be required to upload a recent digital passport-style photo that meets the requirements specified by the U.S. Department of State.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit the form:</span> After you have completed the form and uploaded your photo, you must submit the form and pay the visa application fee.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Retain the confirmation page:</span> After submitting the form, you will receive a confirmation page with a unique barcode number. It is important to print and retain this page for your records, as you will need the barcode number when you attend your visa interview.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Schedule an interview:</span> After submitting the DS-160 form, you must schedule an interview at a U.S. embassy or consulate near you. You will need to bring the confirmation page and all required supporting documents to the interview.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In conclusion, the process of completing the DS-160 visa application form involves gathering information, accessing the form online, filling in required fields, uploading a photo, submitting the form, retaining the confirmation page, and scheduling an interview.</p>

                </div>
            </div>
        </div>
    )
}

export default DS160
