import { Switch, Route } from 'react-router-dom';
import './index.css'
import About from './static-module/Pages/About';
import Contact from './static-module/Contact/Contact';
import LandingPage from './static-module/Pages/LandingPage';
import USA from './static-module/Pages/Abroad/USA';
import Australia from './static-module/Pages/Abroad/Australia';
import Education_Loan from './static-module/Pages/Services/Education_Loan';
import Accomodation from './static-module/Pages/Services/Accomodation';
import Letter_Of_Recommendation from './static-module/Pages/Services/Letter_Of_Recommendation';
import Professional_Resume from './static-module/Pages/Services/Professional_Resume';
import Flights_Ticket_Student_Visa from './static-module/Pages/Services/Flights_Ticket_Student_Visa';
import Statement_of_purpose from './static-module/Pages/Services/Statement_of_purpose';
import Germany from './static-module/Pages/Abroad/Germany';
import New_Zealand from './static-module/Pages/Abroad/New_Zealand';
import United_Kingdom from './static-module/Pages/Abroad/United_Kingdom';
import Canada from './static-module/Pages/Abroad/Canada';
import Italy from './static-module/Pages/Abroad/Italy';
import BHM from './static-module/Pages/India/BHM';
import BBA from './static-module/Pages/India/BBA.jsx';
import BMS from './static-module/Pages/India/BMS';
import MBA from './static-module/Pages/India/MBA';
import PGDM from './static-module/Pages/India/PGDM';
import BCOM from './static-module/Pages/India/BCOM'
import JMC from './static-module/Pages/India/JMC'
import IELTS from './static-module/Pages/Program/IELTS';
import GMAT from './static-module/Pages/Program/GMAT';
import CMAT from './static-module/Pages/Program/CMAT';
import GRE from './static-module/Pages/Program/GRE';
import PTE from './static-module/Pages/Program/PTE';
import SAT from './static-module/Pages/Program/SAT';
import XAT from './static-module/Pages/Program/XAT';
import MPSC from './static-module/Pages/Program/MPSC';
import TOEFL from './static-module/Pages/Program/TOEFL';
import CAT from './static-module/Pages/Program/CAT';
import UPSC from './static-module/Pages/Program/UPSC';
import Career_Counselling from './static-module/Pages/Services/Career_Counselling';
import PR_Counselling from './static-module/Pages/Services/PR_Counselling';
import Currency_Exchange from './static-module/Pages/Services/Currency_Exchange';
import Visa_Counselling from './static-module/Pages/Services/Visa_Counselling';
import I20 from './static-module/Pages/ExtraContent/USA/I20'
import DS160 from './static-module/Pages/ExtraContent/USA/DS160'
import { USA_Accommodation } from './static-module/Pages/ExtraContent/USA/USA_Accommodation';
import USA_Funds from './static-module/Pages/ExtraContent/USA/USA_Funds';
import USA_Costing from './static-module/Pages/ExtraContent/USA/USA_Costing';
import UK_Cas from './static-module/Pages/ExtraContent/UK/UK_Cas';
import UK_Funds from './static-module/Pages/ExtraContent/UK/UK_Funds';
import UK_Accommodation from './static-module/Pages/ExtraContent/UK/UK_Accommodation';
import UK_Scholarship from './static-module/Pages/ExtraContent/UK/UK_Scholarship';
import Canada_University from './static-module/Pages/ExtraContent/Canada/Canada_University';
import Canada_Scholarship from './static-module/Pages/ExtraContent/Canada/Canada_Scholarship';
import Canada_Medical from './static-module/Pages/ExtraContent/Canada/Canada_Medical';
import Canada_Fee from './static-module/Pages/ExtraContent/Canada/Canada_Fee';
import Canada_Accommodation from './static-module/Pages/ExtraContent/Canada/Canada_Accommodation';
import Canada_PR from './static-module/Pages/ExtraContent/Canada/Canada_PR';
import USA_OPT from './static-module/Pages/ExtraContent/USA/USA_OPT';
import USA_Medical from './static-module/Pages/ExtraContent/USA/USA_Medical';
import Canada_DLI from './static-module/Pages/ExtraContent/Canada/Canada_DLI';
import Canada_Job from './static-module/Pages/ExtraContent/Canada/Canada_Job';
import UK_Jobs from './static-module/Pages/ExtraContent/UK/UK_Jobs';
import Blogs from './static-module/Pages/Blogs';
import UK_Medical from './static-module/Pages/ExtraContent/UK/UK_Medical';
import UK_VFS from './static-module/Pages/ExtraContent/UK/UK_VFS';
import UK_IHS from './static-module/Pages/ExtraContent/UK/UK_IHS.jsx';
import UK_Fee from './static-module/Pages/ExtraContent/UK/UK_Fee';
import Canada_GIC from './static-module/Pages/ExtraContent/Canada/Canada_GIC';
import Blog2 from './static-module/Pages/ExtraContent/Blogs/Blog2';
import Australia_Exams from './static-module/Pages/ExtraContent/Australia/Australia_Exams';
import Australia_GTE from './static-module/Pages/ExtraContent/Australia/Australia_GTE';
import Australia_OHSC from './static-module/Pages/ExtraContent/Australia/Australia_OHSC';
import Australia_Medical from './static-module/Pages/ExtraContent/Australia/Australia_Medical';
import Australia_PR from './static-module/Pages/ExtraContent/Australia/Australia_PR';
import Australia_COE from './static-module/Pages/ExtraContent/Australia/Australia_COE';
import Australia_Biometrics from './static-module/Pages/ExtraContent/Australia/Australia_Biometrics';
import Australia_Funds from './static-module/Pages/ExtraContent/Australia/Australia_Funds';
import Australia_Fee from './static-module/Pages/ExtraContent/Australia/Australia_Fee';
import Australia_Accommodation from './static-module/Pages/ExtraContent/Australia/Australia_Accommodation';
import Australia_Sydney from './static-module/Pages/ExtraContent/Australia/Australia_Sydney';
import Australia_Melbourne from './static-module/Pages/ExtraContent/Australia/Australia_Melbourne';
import Australia_Brisbane from './static-module/Pages/ExtraContent/Australia/Australia_Brisbane';
import Australia_Adelaide from './static-module/Pages/ExtraContent/Australia/Australia_Adelaide';
import Australia_Jobs from './static-module/Pages/ExtraContent/Australia/Australia_Jobs';
import Australia_Postworkvisa from './static-module/Pages/ExtraContent/Australia/Australia_Postworkvisa';
import Ireland from './static-module/Pages/Abroad/Ireland';
import Ireland_Blackrock from './static-module/Pages/ExtraContent/Ireland/Ireland_Blackrock';
import Ireland_Cork from './static-module/Pages/ExtraContent/Ireland/Ireland_Cork';
import Ireland_Drogheda from './static-module/Pages/ExtraContent/Ireland/Ireland_Drogheda';
import Ireland_Dublin from './static-module/Pages/ExtraContent/Ireland/Ireland_Dublin';
import Ireland_Dundalk from './static-module/Pages/ExtraContent/Ireland/Ireland_Dundalk';
import Ireland_Dunleary from './static-module/Pages/ExtraContent/Ireland/Ireland_Dunleary';
import Ireland_Galway from './static-module/Pages/ExtraContent/Ireland/Ireland_Galway';
import Ireland_Limerick from './static-module/Pages/ExtraContent/Ireland/Ireland_Limerick';
import Ireland_Swords from './static-module/Pages/ExtraContent/Ireland/Ireland_Swords';
import Ireland_Tralee from './static-module/Pages/ExtraContent/Ireland/Ireland_Tralee';
import Ireland_Waterford from './static-module/Pages/ExtraContent/Ireland/Ireland_Waterford';
import Ireland_Ennis from './static-module/Pages/ExtraContent/Ireland/Ireland_Ennis';
import Ireland_HealthInsurance from './static-module/Pages/ExtraContent/Ireland/Ireland_HealthInsurance';
import Ireland_Accommodation from './static-module/Pages/ExtraContent/Ireland/Ireland_Accommodation';
import Ireland_PSW from './static-module/Pages/ExtraContent/Ireland/Ireland_PSW';
import ErrorPage from './static-module/Pages/ErrorPage';
import Ireland_FinancialSupport from './static-module/Pages/ExtraContent/Ireland/Ireland_FinancialSupport';
import Ireland_Funds from './static-module/Pages/ExtraContent/Ireland/Ireland_Funds';
import Ireland_Travel from './static-module/Pages/ExtraContent/Ireland/Ireland_Travel';
import Ireland_AdmissionRequirement from './static-module/Pages/ExtraContent/Ireland/Ireland_AdmissionRequirement';
import Australia_G08 from './static-module/Pages/ExtraContent/Australia/Australia_G08';
import Canada_SOP from './static-module/Pages/ExtraContent/Canada/Canada_SOP'
import New_Zealand_VisaInterview from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_VisaInterview';
import New_Zealand_PCC from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_PCC';
import New_Zealand_MedicalAndTravel from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_MedicalAndTravel';
import New_Zealand_LOA from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_LOA';
import New_Zealand_EvaluateOffer from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_EvaluateOffer';
import New_Zealand_Funds from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Funds';
import New_Zealand_PS from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_PS';
import New_Zealand_LOR from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_LOR';
import New_Zealand_AdmissionRequirement from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_AdmissionRequirement';
import Biology_Courses from './static-module/Pages/Courses/Biology_Courses';
import Commerce_Courses from './static-module/Pages/Courses/Commerce_Courses';
import Engineering_Courses from './static-module/Pages/Courses/Engineering_Courses';
import New_Zealand_Auckland from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Auckland';
import New_Zealand_Northland from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Northland';
import New_Zealand_Waikato from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Waikato';
import New_Zealand_Nelson from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Nelson';
import New_Zealand_Otago from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Otago';
import New_Zealand_Southland from './static-module/Pages/ExtraContent/New_Zealand/New_Zealand_Southland';
import Canada_Alberta from './static-module/Pages/ExtraContent/Canada/Canada_Alberta';
import Canada_BritishColumbia from './static-module/Pages/ExtraContent/Canada/Canada_BritishColumbia';
import Canada_Manitoba from './static-module/Pages/ExtraContent/Canada/Canada_Manitoba';
import Canada_New_Brunswick from './static-module/Pages/ExtraContent/Canada/Canada_New_Brunswick';
import Canada_NewfoundAndLabrador from './static-module/Pages/ExtraContent/Canada/Canada_NewfoundAndLabrador';
import USA_Alabama from './static-module/Pages/ExtraContent/USA/USA_Alabama';
import USA_Alaska from './static-module/Pages/ExtraContent/USA/USA_Alaska';
import USA_Arizona from './static-module/Pages/ExtraContent/USA/USA_Arizona';
import USA_California from './static-module/Pages/ExtraContent/USA/USA_California';
import Italy_AdmissionDocuments from './static-module/Pages/ExtraContent/Italy/Italy_AdmissionDocuments';
import Italy_Arrival from './static-module/Pages/ExtraContent/Italy/Italy_Arrival';
import Italy_ItalianLanguageProfiency from './static-module/Pages/ExtraContent/Italy/Italy_ItalianLanguageProfiency';
import Italy_FianacialSupport from './static-module/Pages/ExtraContent/Italy/Italy_FianacialSupport';
import Italy_HealthInsurance from './static-module/Pages/ExtraContent/Italy/Italy_HealthInsurance';
import Italy_Milan from './static-module/Pages/ExtraContent/Italy/Italy_Milan';
import Italy_Naples from './static-module/Pages/ExtraContent/Italy/Italy_Naples';
import Italy_Palermo from './static-module/Pages/ExtraContent/Italy/Italy_Palermo';
import Italy_Rome from './static-module/Pages/ExtraContent/Italy/Italy_Rome';
import Italy_Turin from './static-module/Pages/ExtraContent/Italy/Italy_Turin';
import Arts_Courses from './static-module/Pages/Courses/Arts_Courses';
import UK_Aberdeen from './static-module/Pages/ExtraContent/UK/UK_Aberdeen';
import UK_Armagh from './static-module/Pages/ExtraContent/UK/UK_Armagh';
import UK_Belfast from './static-module/Pages/ExtraContent/UK/UK_Belfast';
import UK_Birmingham from './static-module/Pages/ExtraContent/UK/UK_Birmingham';
import UK_Bristol from './static-module/Pages/ExtraContent/UK/UK_Bristol';
import UK_Cardiff from './static-module/Pages/ExtraContent/UK/UK_Cardiff';
import UK_Edinburgh from './static-module/Pages/ExtraContent/UK/UK_Edinburgh';
import UK_England from './static-module/Pages/ExtraContent/UK/UK_England';
import UK_Glasgow from './static-module/Pages/ExtraContent/UK/UK_Glasgow';
import UK_London from './static-module/Pages/ExtraContent/UK/UK_London';
import UK_Londonderry from './static-module/Pages/ExtraContent/UK/UK_Londonderry';
import UK_Manchester from './static-module/Pages/ExtraContent/UK/UK_Manchester';
import UK_Newport from './static-module/Pages/ExtraContent/UK/UK_Newport';
import UK_Northern_Island from './static-module/Pages/ExtraContent/UK/UK_Northern_Island';
import UK_Scotland from './static-module/Pages/ExtraContent/UK/UK_Scotland';
import UK_Swansea from './static-module/Pages/ExtraContent/UK/UK_Swansea';
import UK_Wales from './static-module/Pages/ExtraContent/UK/UK_Wales';
import UK_Russell_Group from './static-module/Pages/ExtraContent/UK/UK_Russell_Group';
import Germany_Berlin from './static-module/Pages/ExtraContent/Germany/Germany_Berlin';
import Germany_Hamburg from './static-module/Pages/ExtraContent/Germany/Germany_Hamburg';
import Germany_Munich from './static-module/Pages/ExtraContent/Germany/Germany_Munich';
import USA_Fee from './static-module/Pages/ExtraContent/USA/USA_Fee'
import Australia_TopCourses from './static-module/Pages/ExtraContent/Australia/Australia_TopCourses';
import Italy_Scholarships from './static-module/Pages/ExtraContent/Italy/Italy_Scholarships';
import Italy_CostOfLiving from './static-module/Pages/ExtraContent/Italy/Italy_CostOfLiving';
import Germany_CostOfLiving from './static-module/Pages/ExtraContent/Germany/Germany_CostOfLiving';
function App() {
  return (
    <>
      <Switch>
        <Route exact={true} path="/" component={LandingPage} />
        <Route exact={true} path="/contact" component={Contact} />
        <Route exact={true} path="/about" component={About} />

        {/* BLOGS */}
        <Route exact={true} path="/blogs" component={Blogs} />
        <Route exact={true} path="/blogs/uk-course-difference" component={Blog2} />

        {/* COURSE PAGES */}
        <Route exact={true} path="/biology-courses" component={Biology_Courses} />
        <Route exact={true} path="/commerce-courses" component={Commerce_Courses} />
        <Route exact={true} path="/engineering-courses" component={Engineering_Courses} />
        <Route exact={true} path="/arts-courses" component={Arts_Courses} />

        {/* SERVICE PAGES */}
        <Route exact={true} path="/education-loan" component={Education_Loan} />
        <Route exact={true} path="/accommodation" component={Accomodation} />
        <Route exact={true} path="/letter-of-recommendation" component={Letter_Of_Recommendation} />
        <Route exact={true} path="/professional-resume" component={Professional_Resume} />
        <Route exact={true} path="/flight-tickets-students-visa" component={Flights_Ticket_Student_Visa} />
        <Route exact={true} path="/statement-of-purpose" component={Statement_of_purpose} />
        <Route exact={true} path="/career-counselling" component={Career_Counselling} />
        <Route exact={true} path="/pr-counselling" component={PR_Counselling} />
        <Route exact={true} path="/currency-exchange" component={Currency_Exchange} />
        <Route exact={true} path="/visa-counselling" component={Visa_Counselling} />

        {/* INDIA PAGES */}
        <Route exact={true} path="/bachelor-of-hotel-management-bhm" component={BHM} />
        <Route exact={true} path="/bachelor-of-business-administration-bba" component={BBA} />
        <Route exact={true} path="/bachelor-of-management-studies-bms" component={BMS} />
        <Route exact={true} path="/masters-in-business-administration-mba" component={MBA} />
        <Route exact={true} path="/post-graduate-diploma-in-management-pgdm" component={PGDM} />
        <Route exact={true} path="/bachelor-of-commerce-b-com" component={BCOM} />
        <Route exact={true} path="/journalism-and-mass-communication" component={JMC} />

        {/* PROGRAM PAGES */}
        <Route exact={true} path="/ielts" component={IELTS} />
        <Route exact={true} path="/gmat" component={GMAT} />
        <Route exact={true} path="/cmat" component={CMAT} />
        <Route exact={true} path="/gre" component={GRE} />
        <Route exact={true} path="/pte" component={PTE} />
        <Route exact={true} path="/sat" component={SAT} />
        <Route exact={true} path="/xat" component={XAT} />
        <Route exact={true} path="/mpsc" component={MPSC} />
        <Route exact={true} path="/toefl" component={TOEFL} />
        <Route exact={true} path="/cat" component={CAT} />
        <Route exact={true} path="/upsc" component={UPSC} />

        {/* USA ROUTES */}
        <Route exact={true} path="/united-state-of-america" component={USA} />
        <Route exact={true} path="/united-state-of-america/i-20" component={I20} />
        <Route exact={true} path="/united-state-of-america/ds-160" component={DS160} />
        <Route exact={true} path="/united-state-of-america/medical" component={USA_Medical} />
        <Route exact={true} path="/united-state-of-america/accommodation" component={USA_Accommodation} />
        <Route exact={true} path="/united-state-of-america/funds" component={USA_Funds} />
        <Route exact={true} path="/united-state-of-america/costing" component={USA_Costing} />
        <Route exact={true} path="/united-state-of-america/opt" component={USA_OPT} />
        <Route exact={true} path="/united-state-of-america/alabama" component={USA_Alabama} />
        <Route exact={true} path="/united-state-of-america/alaska" component={USA_Alaska} />
        <Route exact={true} path="/united-state-of-america/arizona" component={USA_Arizona} />
        <Route exact={true} path="/united-state-of-america/california" component={USA_California} />
        <Route exact={true} path="/united-state-of-america/fee" component={USA_Fee} />

        {/* UK ROUTES */}
        <Route exact={true} path="/united-kingdom" component={United_Kingdom} />
        <Route exact={true} path="/united-kingdom/cas" component={UK_Cas} />
        <Route exact={true} path="/united-kingdom/funds" component={UK_Funds} />
        <Route exact={true} path="/united-kingdom/accommodation" component={UK_Accommodation} />
        <Route exact={true} path="/united-kingdom/scholarship" component={UK_Scholarship} />
        <Route exact={true} path="/united-kingdom/jobs" component={UK_Jobs} />
        <Route exact={true} path="/united-kingdom/medical" component={UK_Medical} />
        <Route exact={true} path="/united-kingdom/vfs" component={UK_VFS} />
        <Route exact={true} path="/united-kingdom/ihs" component={UK_IHS} />
        <Route exact={true} path="/united-kingdom/fee" component={UK_Fee} />
        <Route exact={true} path="/united-kingdom/russell-group" component={UK_Russell_Group} />
        <Route exact={true} path="/united-kingdom/aberdeen" component={UK_Aberdeen} />
        <Route exact={true} path="/united-kingdom/armagh" component={UK_Armagh} />
        <Route exact={true} path="/united-kingdom/belfast" component={UK_Belfast} />
        <Route exact={true} path="/united-kingdom/birmingham" component={UK_Birmingham} />
        <Route exact={true} path="/united-kingdom/bristol" component={UK_Bristol} />
        <Route exact={true} path="/united-kingdom/cardiff" component={UK_Cardiff} />
        <Route exact={true} path="/united-kingdom/edinburgh" component={UK_Edinburgh} />
        <Route exact={true} path="/united-kingdom/england" component={UK_England} />
        <Route exact={true} path="/united-kingdom/glasgow" component={UK_Glasgow} />
        <Route exact={true} path="/united-kingdom/london" component={UK_London} />
        <Route exact={true} path="/united-kingdom/londonderry" component={UK_Londonderry} />
        <Route exact={true} path="/united-kingdom/machester" component={UK_Manchester} />
        <Route exact={true} path="/united-kingdom/newport" component={UK_Newport} />
        <Route exact={true} path="/united-kingdom/northern-island" component={UK_Northern_Island} />
        <Route exact={true} path="/united-kingdom/scotland" component={UK_Scotland} />
        <Route exact={true} path="/united-kingdom/swansea" component={UK_Swansea} />
        <Route exact={true} path="/united-kingdom/wales" component={UK_Wales} />

        {/* CANADA ROUTES */}
        <Route exact={true} path="/canada" component={Canada} />
        <Route exact={true} path="/canada/university" component={Canada_University} />
        <Route exact={true} path="/canada/scholarship" component={Canada_Scholarship} />
        <Route exact={true} path="/canada/medical" component={Canada_Medical} />
        <Route exact={true} path="/canada/fee" component={Canada_Fee} />
        <Route exact={true} path="/canada/accommodation" component={Canada_Accommodation} />
        <Route exact={true} path="/canada/permanent-resident" component={Canada_PR} />
        <Route exact={true} path="/canada/dli" component={Canada_DLI} />
        <Route exact={true} path="/canada/jobs" component={Canada_Job} />
        <Route exact={true} path="/canada/gic" component={Canada_GIC} />
        <Route exact={true} path="/canada/sop" component={Canada_SOP} />
        <Route exact={true} path="/canada/alberta" component={Canada_Alberta} />
        <Route exact={true} path="/canada/british-columbia" component={Canada_BritishColumbia} />
        <Route exact={true} path="/canada/manitoba" component={Canada_Manitoba} />
        <Route exact={true} path="/canada/new-brunswick" component={Canada_New_Brunswick} />
        <Route exact={true} path="/canada/newfoundland-and-labrador" component={Canada_NewfoundAndLabrador} />


        {/* NEW ZEALAND ROUTES */}
        <Route exact={true} path="/new-zealand" component={New_Zealand} />
        <Route exact={true} path="/new-zealand/visa-interview" component={New_Zealand_VisaInterview} />
        <Route exact={true} path="/new-zealand/police-clearance-certificates" component={New_Zealand_PCC} />
        <Route exact={true} path="/new-zealand/medical-travel-insurance" component={New_Zealand_MedicalAndTravel} />
        <Route exact={true} path="/new-zealand/letter-of-acceptance" component={New_Zealand_LOA} />
        <Route exact={true} path="/new-zealand/evaluate-the-offer" component={New_Zealand_EvaluateOffer} />
        <Route exact={true} path="/new-zealand/financial-support" component={New_Zealand_Funds} />
        <Route exact={true} path="/new-zealand/personal-statement" component={New_Zealand_PS} />
        <Route exact={true} path="/new-zealand/letter-of-recommendation" component={New_Zealand_LOR} />
        <Route exact={true} path="/new-zealand/admission-requirements" component={New_Zealand_AdmissionRequirement} />
        <Route exact={true} path="/new-zealand/auckland" component={New_Zealand_Auckland} />
        <Route exact={true} path="/new-zealand/northland" component={New_Zealand_Northland} />
        <Route exact={true} path="/new-zealand/waikato" component={New_Zealand_Waikato} />
        <Route exact={true} path="/new-zealand/nelson" component={New_Zealand_Nelson} />
        <Route exact={true} path="/new-zealand/otago" component={New_Zealand_Otago} />
        <Route exact={true} path="/new-zealand/southland" component={New_Zealand_Southland} />

        {/* GERMANY ROUTES */}
        <Route exact={true} path="/germany" component={Germany} />
        <Route exact={true} path="/germany/berlin" component={Germany_Berlin} />
        <Route exact={true} path="/germany/hamburg" component={Germany_Hamburg} />
        <Route exact={true} path="/germany/munich" component={Germany_Munich} />
        <Route exact={true} path="/germany/cost-of-living" component={Germany_CostOfLiving} />

        {/* ITALY ROUTES */}
        <Route exact={true} path="/italy" component={Italy} />
        <Route exact={true} path="/italy/admission-documents" component={Italy_AdmissionDocuments} />
        <Route exact={true} path="/italy/travel" component={Italy_Arrival} />
        <Route exact={true} path="/italy/italian-language-proficiency" component={Italy_ItalianLanguageProfiency} />
        <Route exact={true} path="/italy/financial-support" component={Italy_FianacialSupport} />
        <Route exact={true} path="/italy/health-insurance" component={Italy_HealthInsurance} />
        <Route exact={true} path="/italy/milan" component={Italy_Milan} />
        <Route exact={true} path="/italy/naples" component={Italy_Naples} />
        <Route exact={true} path="/italy/palermo" component={Italy_Palermo} />
        <Route exact={true} path="/italy/rome" component={Italy_Rome} />
        <Route exact={true} path="/italy/turin" component={Italy_Turin} />
        <Route exact={true} path="/italy/scholarships" component={Italy_Scholarships} />
        <Route exact={true} path="/italy/cost-of-living" component={Italy_CostOfLiving} />

        {/* AUSTRALIA ROUTES */}
        <Route exact={true} path="/australia" component={Australia} />
        <Route exact={true} path="/australia/exams-required" component={Australia_Exams} />
        <Route exact={true} path="/australia/gte" component={Australia_GTE} />
        <Route exact={true} path="/australia/ohsc" component={Australia_OHSC} />
        <Route exact={true} path="/australia/medical" component={Australia_Medical} />
        <Route exact={true} path="/australia/permanent-resident" component={Australia_PR} />
        <Route exact={true} path="/australia/coe" component={Australia_COE} />
        <Route exact={true} path="/australia/biometrics" component={Australia_Biometrics} />
        <Route exact={true} path="/australia/funds" component={Australia_Funds} />
        <Route exact={true} path="/australia/fee" component={Australia_Fee} />
        <Route exact={true} path="/australia/accommodation" component={Australia_Accommodation} />
        <Route exact={true} path="/australia/sydney" component={Australia_Sydney} />
        <Route exact={true} path="/australia/melbourne" component={Australia_Melbourne} />
        <Route exact={true} path="/australia/brisbane" component={Australia_Brisbane} />
        <Route exact={true} path="/australia/adelaide" component={Australia_Adelaide} />
        <Route exact={true} path="/australia/jobs" component={Australia_Jobs} />
        <Route exact={true} path="/australia/post-study-visa" component={Australia_Postworkvisa} />
        <Route exact={true} path="/australia/go8" component={Australia_G08} />
        <Route exact={true} path="/australia/top-courses" component={Australia_TopCourses} />

        {/* IRELAND ROUTES */}
        <Route exact={true} path="/ireland" component={Ireland} />
        <Route exact={true} path="/ireland/blackrock" component={Ireland_Blackrock} />
        <Route exact={true} path="/ireland/cork" component={Ireland_Cork} />
        <Route exact={true} path="/ireland/drogheda" component={Ireland_Drogheda} />
        <Route exact={true} path="/ireland/dublin" component={Ireland_Dublin} />
        <Route exact={true} path="/ireland/dundalk" component={Ireland_Dundalk} />
        <Route exact={true} path="/ireland/dunleary" component={Ireland_Dunleary} />
        <Route exact={true} path="/ireland/ennis" component={Ireland_Ennis} />
        <Route exact={true} path="/ireland/galway" component={Ireland_Galway} />
        <Route exact={true} path="/ireland/limerick" component={Ireland_Limerick} />
        <Route exact={true} path="/ireland/swords" component={Ireland_Swords} />
        <Route exact={true} path="/ireland/tralee" component={Ireland_Tralee} />
        <Route exact={true} path="/ireland/waterford" component={Ireland_Waterford} />
        <Route exact={true} path="/ireland/medical-insurance" component={Ireland_HealthInsurance} />
        <Route exact={true} path="/ireland/accommodation" component={Ireland_Accommodation} />
        <Route exact={true} path="/ireland/psw" component={Ireland_PSW} />
        <Route exact={true} path="/ireland/financial-support" component={Ireland_FinancialSupport} />
        <Route exact={true} path="/ireland/funds" component={Ireland_Funds} />
        <Route exact={true} path="/ireland/plan-your-travel" component={Ireland_Travel} />
        <Route exact={true} path="/ireland/admission-requirements" component={Ireland_AdmissionRequirement} />

        {/* ERROR PAGE */}
        <Route path="*" component={ErrorPage} />

      </Switch>

    </>
  );
}

export default App;
