import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Alaska = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Alaska - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Alaska"
                />
                <meta
                    name="Keyword"
                    content="USA Alaska"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Alaska</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Alaska is known for its wide-open spaces, abundant outdoor experiences, and Alaskan Native culture. Alaska is also one of the best places in the world in which to view the Northern Lights. From the mountains to the sea fjords, and Alaska's vast interior terrain, the 49th state is a photographer's dream. Population of Alaska 7.33 lakh and the GDP is 65,130.3 million  USD  dollars.We are famous for the Iditarod, gold mining, sourdough, the Alaska Railroad, aviation, Alaska Native heritage, homesteading, world-class fishing and seafood, outdoor adventures, fresh air, and a slower, more self-sufficient way of life.”</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Companies</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Alaska Business</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Arctic Solar Ventures - Alaska's Premium Solar Provider</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>America North Printers</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Sipping Streams Tea Company</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Royal Alaskan Movers</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Doyon, Limited</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Alaska:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Alaska Anchorage:</span> The University of Alaska Anchorage (UAA) is a public university in Anchorage, Alaska. UAA also administers four community campuses spread across Southcentral Alaska: Kenai Peninsula College, Kodiak College, Matanuska–Susitna College, and Prince William Sound College. Between the community campuses and the main Anchorage campus, roughly 15,000 undergraduate, graduate, and professional students are currently enrolled at UAA. It is Alaska's largest institution of higher learning and the largest university in the University of Alaska System. The university is classified among "Master's Colleges & Universities: Larger Programs" with an additional classification for Community Engagement.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Alaska Fairbanks:</span> The University of Alaska Fairbanks (UAF or Alaska) is a public land-grant research university in College, Alaska, a suburb of Fairbanks. It is the flagship campus of the University of Alaska system. UAF was established in 1917 and opened for classes in 1922. Originally named the Alaska Agricultural College and School of Mines, it became the University of Alaska in 1935. Fairbanks-based programs became the University of Alaska Fairbanks in 1975.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Alaska Pacific University:</span> Alaska Pacific University is a private university in Anchorage, Alaska. It was established as Alaska Methodist University in 1957. Although it was renamed to Alaska Pacific University in 1978, it is still affiliated with the United Methodist Church. The main campus is located adjacent to the University of Alaska Anchorage and the Alaska Native Medical Center.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Charter College:</span> Charter College is a network of private, independent institutions of higher education.Charter College offers programs in healthcare, business, veterinary science, information technology, and select trade careers. Charter operates campuses in five states and offers certificates, associate, and bachelor's degrees. Fully online programs are available for select programs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Iḷisaġvik College:</span> Iḷisaġvik College (Inupiaq: [iʎisɑʁvik]) is a public tribal land-grant community college in Utqiaġvik (formerly Barrow), Alaska. Operated by the North Slope Borough, a home rule government of the Iñupiat, it is the only tribally controlled college in Alaska, and it is the northernmost accredited community college in the United States. The college is located within the boundaries of the North Slope. It is an 89,000-square-mile (230,000 km2) region of Arctic tundra. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default USA_Alaska
