import React from 'react'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
const About = () => {
  const history = useHistory();

  const handleClickForAccommodation = () => {
    history.push('accommodation')
    window.scrollTo(0, 0);
  }
  const handleClickForSOP = () => {
    history.push('statement-of-purpose')
    window.scrollTo(0, 0);
  }
  const handleClickForAirTickets = () => {
    history.push('flight-tickets-students-visa')
    window.scrollTo(0, 0);
  }
  const handleClickForCareerCounselling = () => {
    history.push('career-counselling')
    window.scrollTo(0, 0);
  }
  const handleClickForPRCounselling = () => {
    history.push('pr-counselling')
    window.scrollTo(0, 0);
  }
  const handleClickForCurrencyExchange = () => {
    history.push('currency-exchange')
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Future Dream Makers</title>
        <meta
          name="description"
          content="About Us"
        />
        <meta
          name="Keyword"
          content="About Us"
        />
      </Helmet>

      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-AboutUs bg-cover md:bg-top bg-center' >
        {/* Content Section */}
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <p className="text-white font-heading font-semibold py-2 md:text-5xl text-xl mb-2">
            ABOUT US
          </p>
        </div>
      </div>

      <div className='flex justify-center md:flex-row mt-8'>
        <div className='md:text-justify'>
          <p className='2xl:px-64 px-8 uppercase text-black text-2xl font-extrabold mt-6 '>Welcome to </p>
          <p className='2xl:px-64 px-8 text-red-500 text-4xl font-bold py-1 font-serif'>Future Dream Makers</p>
          <p className='2xl:px-64 px-8 text-lg font-serif font-normal text-black mt-8 mb-6'>Established in 2020, Future Dream Makers is India’s most renowned
            Education Consultancy Firm. Our 3+ years of expertise has allowed us to
            carve out a unique position in the field of national and international
            admissions for engineering and management programmes. Our
            connections with 500+ colleges and universities across India and
            beyond are a source of great pleasure for the Future Dream Makers.</p>
          <p className='2xl:px-64 px-8 text-lg font-serif font-normal text-black mt-8 mb-6'>At Future Dream Makers, we believe in always giving our upmost
            services, which includes recruiting the best in the industry. Our advisors
            are professionals in international relations and studies. Our Cambridge
            certified IELTS Faculty has over 10 years of expertise and is one of the
            best in the country.</p>
          <p className='2xl:px-64 px-8 text-lg font-serif font-normal text-black mt-8 mb-6'>We strive on making dreams come true, with over 1000 successful international admissions and 10,000 exquisitely happy
            students and clients.  Our crew is a dedicated team who will make sure you are helped at every turn, from career counselling
            to overcoming financial obstacles. Our experts will guide you through the intimidating process of obtaining Education Loans
            and choosing the right courses at an affordable college or university.</p>
        </div>
      </div>

      <div className='flex justify-center py-4 gap-6 md:flex-row flex-col 2xl:px-64 px-8'>
        <div className=' border-4 border-black rounded-2xl hover:shadow-2xl shadow-black md:w-[125%]'>
          <div className=' w-[35%] py-1 m-auto mt-4 '>
            <img src='https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/bullseye.png' alt='Bullseye' className='hover:animate-bounce'></img>
          </div>
          <p className='text-red-500 text-2xl py-2 text-center font-serif font-semibold'>OUR MISSION</p>
          <p className='py-2 px-2 text-justify font-serif text-base font-medium '>To Provide Quality Solution At Global
            Destinations And Through Exceptional
            Client Service, Quality, And Commitment,
            We Provide The Most Effective Ethical
            Representation And Support Growth For
            The Students</p>
        </div>
        <div className='border-4 border-black rounded-2xl hover:shadow-2xl shadow-black  md:w-[125%]'>
          <div className='w-[35%] py-1 m-auto mt-4'>
            <img src='https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Mission.png' alt='Mission' className='hover:animate-bounce  '></img>
          </div>
          <p className='text-red-500 text-2xl py-2 text-center font-serif font-semibold'>OUR VISION</p>
          <p className='py-2 px-2 text-justify text-base font-serif font-medium '>Become Essential To Our Clients By
            Providing Differentiated Courses And
            Services To Help Them Achieve Their
            Career Counselling Aspirations.</p>
        </div>
        <div className='border-4 border-black rounded-2xl hover:shadow-2xl shadow-black  md:w-[125%]'>
          <div className='w-[35%] py-1 m-auto mt-4'>
            <img src='https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/bullseye.png' alt='Bullseye' className='hover:animate-bounce  '></img>
          </div>
          <p className='text-red-500 text-2xl py-2 text-center font-serif font-semibold'>OUR OBJECTIVE</p>
          <p className='py-2 px-2 text-justify font-serif text-base font-medium'>Assisting Students In The Development Of
            Interpersonal Skills Such As Time
            Management, Study And Work Perspective.
            Outlining Academic And Vocational Goals
            For Students. Assisting Students In Setting
            Realistic Goals.</p>
        </div>
      </div>

      <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-4 grid-cols-1 2xl:px-64 px-8 '>
        <button onClick={handleClickForCareerCounselling} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Career Counselling</button>
        <button className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>University Selection</button>
        <button onClick={handleClickForPRCounselling} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>PR Counselling</button>
        <button onClick={handleClickForAccommodation} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Accommodation</button>
        <button onClick={handleClickForCurrencyExchange} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110  '>Currency Exchange</button>
        <button onClick={handleClickForAirTickets} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Air Tickets</button>
        <button className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>VISA Counselling</button>
        <button onClick={handleClickForSOP} className='font-bold text-[#F9FAFB] font-serif text-lg bg-blue-900 text-center rounded-3xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Statement Of Purpose</button>
      </div>

      <div className='flex justify-around bg-pink-500 py-10 mb-4 md:flex-row flex-col md:gap-6 gap-6 px-4'>
        <p className='font-bold text-white text-justify md:text-3xl text-2xl md:px-0 px-6'>Want To Know More About The Colleges and Universities !</p>
        <button className='text-purple-500 bg-white rounded-full hover:bg-pink-400 md:px-5 py-5 md:w-[20%]' >
          <a href="mailto:info@futuredreammakers.com">MAIL US</a></button>
      </div>
      <div className='flex justify-center'>
        <div>
          <p className='text-black font-serif  font-bold uppercase text-center py-1 text-2xl mt-8 2xl:px-64 px-8'>TEAM MEMBERS</p>
          <p className='text-red-500 font-serif md:text-4xl text-3xl py-2 font-bold mb-5 2xl:px-64 px-8'>Our Creative Team</p>
        </div>
      </div>
      <div className='grid md:grid-cols-2 grid-cols-1 justify-items-center mb-8 2xl:px-64 px-4 gap-4'>
        <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/ayushi-FDM.jpeg" alt="ayushi-FDM" className=' h-96 border-4 border-black rounded-lg px-3 py-3 hover:shadow-2xl shadow-black'></img>
        <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/tamanna-FDM.jpeg" alt="tamanna-FDM" className=' h-96 border-4 border-black rounded-lg px-3 py-3 hover:shadow-2xl shadow-black'></img>
        <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/shivani-FDM.jpeg" alt="shivani-FDM" className='md:col-span-4  h-96 border-4 border-black rounded-lg px-3 py-3 hover:shadow-2xl shadow-black' ></img>
      </div>
    </div>
  )
}

export default About
