import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Dundalk = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Dundalk- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Dundalk"
                />
                <meta
                    name="Keyword"
                    content="Ireland Dundalk"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Dundalk</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Dún Dealgan is a town in the northeast of Ireland, with a population of over 40,000 people. It is home to several universities and colleges, including Institute of Technology, Sligo and Dublin Institute of Technology. The average tuition fees for international students in Dún Dealgan range from €10,000 to €15,000 per year. Some of the top courses for international students in Dún Dealgan include engineering, computer science, and health sciences. The highest paying jobs in Dún Dealgan are in the pharmaceutical, medical device, and technology sectors, with roles such as research scientist, software engineer, and sales manager. The average wage for these jobs is between €45,000 and €60,000 per year. The cost of living in Dún Dealgan is relatively lower compared to other cities in Ireland, with an average monthly rent of €500 and a monthly grocery bill of €200. There are also several part-time and full-time job opportunities for international students in Dún Dealgan, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Dundalk