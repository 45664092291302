import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_FinancialSupport = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Financial Support - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Financial Support"
                />
                <meta
                    name="Keyword"
                    content="Ireland Financial Support"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Financial support</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in Ireland may be eligible for financial support from a range of sources, including:</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Scholarships and bursaries offered by universities and colleges.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Financial assistance from the Irish government through the student grant system, which provides means-tested grants for eligible students.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Work study programs, which allow students to work part-time while studying to earn some extra income.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Part-time jobs and internships, which are available in a variety of industries and can provide valuable work experience and an income.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Educational loans, which are available from banks and other financial institutions.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to research the options available and to consider factors such as eligibility, application processes, and repayment terms before deciding which financial support is best for you.</p>



                </div>
            </div>
        </div>
    )
}

export default Ireland_FinancialSupport
