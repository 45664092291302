import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_Accommodation = () => {
    return (
        <div>
            <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Accommodation - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Accommodation"
                />
                <meta
                    name="Keyword"
                    content="Australia Accommodation"
                />
            </Helmet>
            </div>
            <div className='flex justify-center md:flex-row flex-col lg:mt-0 mt-16 mb-6 2xl:px-64'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Cost of Living And accommodation</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living and accommodation in Australia can vary depending on the city, with larger cities typically having a higher cost of living. On average, the cost of living in Australia is considered to be higher than in many other countries, with international students needing to budget around AUD 20,000 to AUD 30,000 (approximately USD 14,000 to USD 21,000) per year for living expenses.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Accommodation is one of the biggest expenses for international students in Australia, with prices ranging from around AUD 150 to AUD 600 (approximately USD 105 to USD 420) per week for different types of accommodation, such as homestay, student housing, and apartments.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are some of the most common types of accommodation options for international students in Australia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Homestay:</span> This involves living with an Australian family, which can provide students with a supportive and nurturing environment and an opportunity to learn about the Australian way of life.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Student housing:</span> This is dedicated accommodation for students and can range from shared apartments to single rooms in student residences.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Renting:</span> International students can rent apartments or share houses with other students.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Staying with friends or family:</span> If international students have friends or family members in Australia, they may choose to stay with them.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In terms of cost, student housing and homestay tend to be more expensive than renting, while staying with friends or family can be the most cost-effective option. It's important for international students to consider their budget, their personal preferences, and their academic requirements when choosing their accommodation in Australia.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Cost of living according to different cities </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living for international students in Australia can vary greatly depending on the city they are studying in. Here is a general idea of the cost of living in some of the largest cities in Australia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Sydney:</span> One of the most expensive cities in Australia, with a high cost of living, especially in terms of housing and food. On average, international students should budget around AUD 22,000 to AUD 30,000 (approximately USD 15,000 to USD 21,000) per year for living expenses in Sydney.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Melbourne:</span> Slightly cheaper than Sydney, with a more affordable cost of living compared to other major cities. International students should budget around AUD 20,000 to AUD 25,000 (approximately USD 14,000 to USD 17,500) per year for living expenses in Melbourne.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Brisbane:</span> A more affordable city compared to Sydney and Melbourne, with a lower cost of living, especially in terms of housing. International students should budget around AUD 18,000 to AUD 22,000 (approximately USD 12,500 to USD 15,000) per year for living expenses in Brisbane.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Perth:</span> Another more affordable city in Australia, with lower living costs compared to other major cities. International students should budget around AUD 18,000 to AUD 20,000 (approximately USD 12,500 to USD 14,000) per year for living expenses in Perth.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Adelaide:</span> A more affordable city in Australia, with a lower cost of living compared to other major cities. International students should budget around AUD 18,000 to AUD 20,000 (approximately USD 12,500 to USD 14,000) per year for living expenses in Adelaide.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these figures are just estimates, and the actual cost of living can vary greatly depending on personal circumstances, such as individual spending habits, lifestyle, and housing choices. International students should research the cost of living in the city they are considering carefully and plan their finances accordingly.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Accommodation