import React from 'react'

const Footer = () => {

    let SALinks = [
        { name: "United States of America", link: "/united-state-of-america" },
        { name: "Australia", link: "/australia" },
        { name: "Germany", link: "/germany" },
        { name: "New Zealand", link: "/new-zealand" },
        { name: "United Kingdom", link: "/united-kingdom" },
        { name: "Canada", link: "/canada" },
        { name: "Italy", link: "/italy" },
        { name: "Ireland", link: "/ireland" }
    ]

    let Services = [
        { name: "Education loan", link: "/education-loan" },
        { name: "Accommodation", link: "/accommodation" },
        { name: "Letter Of Recommendation", link: "/letter-of-recommendation" },
        { name: "Professional Resume", link: "/professional-resume" },
        { name: "Flight Tickets-Students Visa", link: "/flight-tickets-students-visa" },
        { name: "Statement of Purpose", link: "/statement-of-purpose" },
        { name: "Career Counselling", link: "/career-counselling" },
        { name: "Visa Counselling", link: "/visa-counselling" }

    ]


    return (
        <div>
            <div className='flex items-start font-medium justify-around text-white bg-[#374a5e] px-3 md:flex-row flex-col md:py-10 py-5 '>
                <div className='md:w-[30%] w-auto'>
                    <p className='md:px-4 px-2.5 md:text-center text-left font-semibold leading-7 font-poppins text-base '>Founded in 2020. Future Dream Makers enjoys high success rate in getting scholarship and visa approval
                        of admitted students in World Class QS High Ranked Universities . We're a team of Experts , passionate in
                        making your future bright.</p>
                    <p className='flex justify-center font-poppins m-4'>Find us on :</p>
                    <div className='flex items-center justify-center '>
                        <div>
                            {/* facebook */}
                            <svg
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                class="w-9 h-9 cursor-pointer leading-9"
                                onClick={() => window.open('https://m.facebook.com/people/Future-Dream-Makers/100082948226296/', '_blank')}
                            >
                                <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-92.4 233.5h-63.9c-50.1 0-59.8 23.8-59.8 58.8v77.1h119.6l-15.6 120.7h-104V912H539.2V602.2H434.9V481.4h104.3v-89c0-103.3 63.1-159.6 155.3-159.6 44.2 0 82.1 3.3 93.2 4.8v107.9z" />
                            </svg>
                        </div>
                        <div>
                            {/* instagram */}
                            <svg
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                class="w-9 h-9 cursor-pointer leading-9"
                                onClick={() => window.open('https://instagram.com/futuredream_makers?igshid=YmMyMTA2M2Y=', '_blank')}
                            >
                                <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 01-47.9 47.9z" />
                            </svg>
                        </div>
                        <div>
                            {/* linkedin */}
                            <svg
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                class="w-9 h-9 cursor-pointer leading-9"
                                onClick={() => window.open('https://www.linkedin.com/company/future-dream-makers/', '_blank')}
                            >
                                <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1168.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                            </svg>
                        </div>
                        <div>
                            {/* twitter */}
                            <svg
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                class="w-9 h-9 cursor-pointer leading-9"
                                onClick={() => window.open('https://instagram.com/futuredream_makers?igshid=YmMyMTA2M2Y=', '_blank')}
                            >
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 01-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 01-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 00229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='text-black font-bold md:py-0 py-8 '> <p className=' md:px-1.5 px-1.5 font-poppins text-xl font-extrabold leading-9'>STUDY ABROAD </p>
                    <ul className='text-white '>
                        {
                            SALinks.map((Link) => (
                                <li className='hover:text-red-500 p-1.5 cursor-pointer font-poppins text-base font-semibold '>
                                    <a href={Link.link}>{Link.name}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className='text-black font-bold '><p className=' md:px-1.5 px-1.5 font-poppins text-xl font-extrabold leading-9'>  SERVICES </p>
                    <ul className='text-white'>
                        {
                            Services.map((Link) => (
                                <li className='hover:text-red-500 p-1.5 cursor-pointer font-poppins text-base font-semibold'>
                                    <a href={Link.link}>{Link.name}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className=''>
                    <p className='text-black  md:px-1.5 px-1.5 md:mt-0 mt-8 font-poppins text-xl font-extrabold leading-9'>CORPORATE OFFICE</p>
                    <p className='p-1.5 font-poppins text-base font-semibold'>168-169, PU 4 Behind C21 Mall, Vijay Nagar, Indore (M.P.)</p>
                    <p className='p-1.5 font-poppins text-base font-semibold'>Contact- (+91) 87702-62128</p>
                    <p className='p-1.5 font-poppins text-base font-semibold'>Email- info@futuredreammakers.com</p>
                </div>

            </div>
            <div className='bg-[#ea5356] text-center px-5 py-10'>
                <p className='font-poppins text-base font-medium align-baseline'>Copyright © Future Dream Makers. All Rights Reserved.</p>

            </div>
        </div>
    )
}


export default Footer
