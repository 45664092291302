import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Russell_Group = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Russell Group - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Russell Group"
                />
                <meta
                    name="Keyword"
                    content="UK Russell Group"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Russell Group</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Russell Group is a self-selected association of 24 research-intensive universities in the United Kingdom that regularly ranks among the best universities in the world. It was formed in 1994 and is named after the Russell Building at the University of London, which was home to the British Academy. The group's members are recognised as major research institutions and have the highest average entry standards of any group of British universities.The group's mission is to maintain the UK's position as a world-leading center for research and education. Some of the most well-known universities in the Russell Group include the University of Oxford, the University of Cambridge, Imperial College London, and the University of Edinburgh.</p>
                    <div className='md:grid grid-cols-2'>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Birmingham</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Bristol</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Cambridge</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Cardiff University</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Durham University</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Edinburgh</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Exeter</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Glasgow</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Imperial College London</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>King's College London</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Leeds</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Liverpool</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>London School of Economics</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Manchester</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Newcastle University</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Nottingham</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Oxford</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Queen Mary, University of London</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Queen's University Belfast</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Sheffield</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Southampton</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University College London</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Warwick</p>
                        </div>
                        <div className='flex p-2 px-0 md:ml-0 ml-4'>
                            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of York</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UK_Russell_Group