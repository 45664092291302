import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const New_Zealand = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const NewZealand_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-1.jpg' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-2.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-3.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-4.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-5.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Newzealand+logo/New_Zealand_university_logo-6.png" }
    ];

    const Auckland = () => {
        history.push('new-zealand/auckland')
        window.scrollTo(0, 0)
    }
    const Northland = () => {
        history.push('new-zealand/northland')
        window.scrollTo(0, 0)
    }
    const Waikato = () => {
        history.push('new-zealand/waikato')
        window.scrollTo(0, 0)
    }
    const Nelson = () => {
        history.push('new-zealand/nelson')
        window.scrollTo(0, 0)
    }
    const Otago = () => {
        history.push('new-zealand/otago')
        window.scrollTo(0, 0)
    }
    const Southland = () => {
        history.push('new-zealand/southland')
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand"
                />
                <meta
                    name="Keyword"
                    content="New Zealand"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-NewZealand bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        NEW ZEALAND
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>New Zealand</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>New Zealand is a country located in the southwestern Pacific Ocean, consisting of two main islands, the North Island and the South Island, and numerous smaller islands. It is a constitutional monarchy with a parliamentary system of government and is a member of the Commonwealth of Nations. The official languages are English and Māori, and the currency is the New Zealand dollar. New Zealand is known for its stunning natural landscapes, including mountains, forests, beaches, and lakes, and is a popular destination for outdoor activities such as hiking, skiing, and water sports.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>New Zealand is a popular destination for international students due to its high-quality education system, safe and welcoming environment, and unique natural beauty. Studying in New Zealand provides international students with the opportunity to experience a new culture, learn English, and receive a globally recognized education.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>The country offers a range of study options, from primary and secondary schools to universities, polytechnics, and English language schools. New Zealand institutions offer a wide variety of academic programs, including arts, business, engineering, and science, at both undergraduate and postgraduate levels.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>The country is divided into 16 regions, which are primarily used for local government and statistical purposes. These regions are: Northland, Auckland, Waikato, Bay of Plenty, Gisborne, Hawke's Bay, Taranaki, Manawatu-Wanganui, Wellington, Tasman, Nelson, Marlborough, West Coast, Otago, and Southland. Each region has its own unique character, history, and natural attractions, making New Zealand a diverse and interesting place to visit and explore.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>About New Zealand</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Visit a welcome and open society: The people of New Zealand are among the most amazing and inviting people on the planet, who value new ideas and respect people from all walks of life.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Economic and political stability: Because of the cheap cost of living, New Zealand's economic and political situations are relatively stable, which is why immigration is accepted.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Enjoy the beautiful weather: winters are moderate, and the elevation is slightly overcast, while summers are hot and dry.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Learn the abilities you'll need to succeed in the world: New Zealand has always been renowned internationally for world-class standards, with five universities ranking among the top 400 in the world.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Register with Future Dream Makers and speak with a counsellor about your educational options.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Select a course based on your areas of interest.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Select a university that meets your choices, admissions requirements, and financial constraints.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>With our help, you can apply to your choice of university in New Zealand.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Consult our visa experts for assistance.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Obtain AIP (Approval in Principle) from the New Zealand High Commission by lodging a visa application.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Make monetary deposits</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal  font-serif'>FTS requires a deposit of NZD 15,000 in a New Zealand bank (Fund Transfer Scheme)</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif'>Prepare to depart for New Zealand by attending our pre-departure programme.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>New Zealand Admission Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The shortlisting process for international students applying to universities in New Zealand typically involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Researching universities and programs:</span> International students are advised to research and compare different universities and programs that align with their interests, career goals, and financial capabilities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Checking admission requirements:</span> Each university in New Zealand may have different <span className='text-red-500 '><a href='/new-zealand/admission-requirements'>admission requirements</a></span>, such as language proficiency, <span className='text-red-500 '><a href='/new-zealand/letter-of-recommendation'>letter of recommendation</a></span>, academic qualifications, <span className='text-red-500 '><a href='/new-zealand/personal-statement'>personal statement</a></span> and portfolio submissions. International students should carefully review these requirements before applying.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submitting a complete application:</span> Once international students have determined which universities and programs they would like to apply to, they should submit a complete application, including all required documentation and fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Waiting for a response:</span> After submitting their application, international students should wait for a response from the university. This process may take several weeks or even months, depending on the university and the time of year.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Evaluating the offer:</span> If an international student receives an offer from a university, they should <span className='text-red-500 '><a href='/new-zealand/evaluate-the-offer'>evaluate the offer</a></span> and make a decision on whether to accept or decline.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meeting the conditions of the offer:</span> If an international student decides to accept the offer, they should meet the conditions of the offer, such as submitting proof of language proficiency, final transcripts, and other required documents.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receiving an official letter of acceptance:</span> Once an international student meets the conditions of the offer, they will receive an official <span className='text-red-500 '><a href='/new-zealand/letter-of-acceptance'>letter of acceptance</a></span> from the university. This letter is required for visa and immigration purposes.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The above process may vary depending on the university and the type of program applied for, and international students are advised to research and seek advice from the university and immigration authorities before making any decisions.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>VISA PROCESS</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for applying for a student visa in New Zealand typically involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Acceptance into a New Zealand institution:</span> Students must first receive an offer of place from a New Zealand education provider, such as a university or college.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gather required documents:</span> Students will need to gather all required documents, including proof of identity, proof of <span className='text-red-500 '><a href='/new-zealand/financial-support'>financial support</a></span>, academic qualifications, English language proficiency, <span className='text-red-500 '><a href='/new-zealand/medical-travel-insurance'>medical and travel insurance</a></span> and <span className='text-red-500 '><a href='/new-zealand/police-clearance-certificates'>police clearance certificates</a></span> (if required).</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Complete the visa application form:</span> Students will need to complete the student visa application form and pay the visa application fee.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submit the application:</span> The completed application, along with all required supporting documents, must be submitted to the New Zealand immigration authorities.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for processing:</span> Processing times for student visas can vary, but typically take several weeks to several months.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Attend a visa interview:</span> Some students may be required to attend a <span className='text-red-500 '><a href='/new-zealand/visa-interview'>visa interview</a></span> in person or via video link.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Receive the visa decision:</span> Students will receive the decision on their visa application from the immigration authorities. If the visa is granted, they will receive a visa label or a visa certificate.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the requirements and processing times for student visas in New Zealand can change, and it is recommended to check with the immigration authorities for the most up-to-date information and requirements. Additionally, some institutions may have additional requirements for international students, so it is advisable to check with the institution directly for any additional information or requirements.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Types of student visa</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As of my knowledge cut-off in 2021, the main types of student visas available to Indian students applying to study in New Zealand are:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Student visa:</span> This is the standard visa for full-time international students who are enrolled in an approved education provider in New Zealand. The standard fee for this visa is NZD$365 (approximately INR 19,000).</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Fee-paying Student visa:</span> This visa is for students who are enrolled in fee-paying courses, such as English language courses, foundation studies, or courses that lead to a New Zealand qualification. The fee for this visa varies depending on the length of the course and the number of family members accompanying the student.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Partner of a Student visa:</span> This visa is for the partners or dependents of students who are studying in New Zealand on a student visa. The fee for this visa is NZD$365 (approximately INR 19,000) per person.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the visa fees listed above are subject to change and may not reflect the most up-to-date information. It is recommended to check with the immigration authorities for the most up-to-date information on student visa fees and requirements. Additionally, some institutions may have additional fees or requirements for international students, so it is advisable to check with the institution directly for any additional information or requirements.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6'>
                        <button onClick={Auckland} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>AUCKLAND</button>
                        <button onClick={Northland} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NORTHLAND</button>
                        <button onClick={Waikato} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>WAIKATO</button>
                        <button onClick={Nelson} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NELSON</button>
                        <button onClick={Southland} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>SOUTHLAND</button>
                        <button onClick={Otago} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>OTAGO</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {NewZealand_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default New_Zealand