import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Accomodation = () => {

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accomodation - Future Dream Makers</title>
        <meta
          name="description"
          content="Accomodation"
        />
        <meta
          name="Keyword"
          content="Accomodation"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Accommodation bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            Accommodation
          </p>
        </div>
      </div>

      <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
        <div className='md:text-justify  '>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Accommodation</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Future Dream Makers (FDM) provides hassle-free loan approvals, eliminating the need to visit multiple institutions for funding.</p>
          <p className=' font-semibold text-red-500 text-lg mb-6 mt-8  px-8' >Comfortable Flats And Hostels</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Our rooms are all up to date in terms of safety and hygiene, and they offer an excellent living environment.</p>
          <p className=' font-semibold text-red-500 text-lg mb-6 mt-8  px-8' >Reasonable Prices</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>We offer the greatest hotel deals at unbeatable prices.</p>
          <p className=' font-semibold text-red-500 text-lg mb-6 mt-8  px-8' >Our Services And Team Is Available At All Times Of The Day And Night.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>You can rely on our world-class customer service. 7 days a week, 24 hours a day. Our customer service is unrivalled.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Process For Accommodation</p>
          <div className='flex p-2 px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Research options:</span>Before you start looking for accommodation, research the different options available to you. This might include on-campus housing, off-campus housing, homestays, or student housing. Look into the pros and cons of each type of accommodation, and consider factors like cost, location, and amenities.</p></div>
          <div className='flex p-2 px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Contact the university:</span>If you plan to live in on-campus housing, contact the university's housing office to learn more about the application process, the availability of housing, and the cost. Most universities have their own websites and accommodation portals that you can use to book a room, and some universities have dedicated housing office which can help you with your booking.</p></div>
          <div className='flex p-2 px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Search online:</span>There are many websites that specialize in helping students find accommodation abroad. These sites often list a wide variety of options, including apartments, homestays, and hostels. You can compare prices, read reviews, and even book accommodation directly through the site.</p></div>
          <div className='flex p-2 px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Make a reservation:</span>Once you have found a suitable accommodation, make a reservation. You may have to pay a deposit or a fee to secure your booking.</p></div>
          <div className='flex p-2 px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for your arrival:</span>Once your accommodation is booked, start preparing for your arrival. Make sure you have all the necessary documents, like a passport and a visa, if required. You should also inform the accommodation provider of your arrival date and time, and get the contact details of the landlord or the accommodation office.</p></div>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to book your accommodation as early as possible to ensure that you have a place to stay when you arrive. It's also important to be aware of the different laws and regulations that may apply to renting accommodation in the country you're moving to.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>International Sim-Cards</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Get a SIM card to use as soon as you arrive at your destination. You can text, call, and surf the Internet with a student SIM without worrying about being cut off from your family and friends.</p>
          <p className=' font-semibold text-red-500 text-lg mb-4 mt-8  px-8' >Support To Your Travel Destination's Success</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Our SIM cards cover a wide range of international destinations, including the United States, Australia, Canada, Germany, New Zealand, and the United Kingdom.</p>
          <p className=' font-semibold text-red-500 text-lg mb-4 mt-8  px-8' >Affordable Travel Plans</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>We collaborate with the major mobile network carriers in those countries to provide you with the best calling, messaging, and Internet services possible.</p>
          <p className=' font-semibold text-red-500 text-lg mb-4 mt-8  px-8' >Door To Door Services</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Your SIM card will be delivered to your door and you will be able to activate it right away.</p>


        </div>
      </div>
    </div>
  )
}

export default Accomodation