import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const CAT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CAT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="CAT"
                />
                <meta
                    name="Keyword"
                    content="CAT"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-CAT bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        CAT
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is CAT ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>CAT is for Common Admission Test. It is a national-level MBA/PGDM entrance exam held for admission to 20 IIMs and 1000+ other CAT-accepting business schools. Every year, approximately 200,000 candidates apply for the CAT exam in order to gain admission to one of the 5000+ MBA/PGDM seats. According to previous year’s trends, the CAT cut off for top IIMs is above 95 percentile, while it ranges between 80 and 95 percentile for other top colleges.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Applications for CAT 2022 will be accepted beginning in the first week of August and will be accepted until the third week of September 2022. The application fee for general candidates is INR 2,200, while the fee for reserved candidates is INR 1,100. The CAT 2022 exam will be held online and will last two hours.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Candidates will be eligible to apply for the following types of programmes across all AICTE affiliated colleges-</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGDM – Post Graduate Diploma in Management</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>MBA – Masters of Business Administration</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST TYPE</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">CONTENT</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Computer-Based-Test</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">100 Questions</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 Hours or 120 Minutes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>CAT Eligibility Criteria</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates must have a bachelor’s degree in any discipline with a minimum of 50% (45% for SC/ST/PwD candidates) from any of India’s recognised universities.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The percentage of marks obtained by the candidate for the bachelor’s degree would be calculated in accordance with the practise of the university/institution from which the candidate obtained the degree.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>If the candidates are given grades/CGPA instead of marks, the conversion of grades/CGPA to percentage of marks will be based on the procedure certified by the university/institution where they received their bachelor’s degree.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates in their final year of a bachelor’s degree/equivalent qualification examination, as well as those who have completed degree requirements but are awaiting results, are eligible to apply.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>IIM Courses Through CAT</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The CAT exam is used to gain admission to various Post Graduate Programmes (PGP), Doctoral, and Executive programmes at the IIMs across the country. Some institutes even offer PhD-level Fellowship Programmes in Management (FPM). Courses available at IIMs are listed below:</p>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGP (Post Graduate Program in Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGP- FABM (Food and Agribusiness Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGPEM (PGP in Enterprise Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGPPM (PGP in Public Policy and Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>EPGP (Executive Post Graduate Program in Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGPSM (PGP in Sustainable Management)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGPWE (PGP in Management for Working Executive)</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGPBM (PGP in Business Management)</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>FPM (Fellow Program in Management)</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>CAT Exam Pattern</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>CAT is a computer-based examination (CBT). As a result, candidates must respond within 120 minutes (2 hours). It is divided into three sections and contains a total of 100 questions. The CAT 2021 exam pattern will be similar to the CAT official Mock test released by IIMs a month before the CAT exam.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The test comprises of three sections:</p>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Verbal Ability & Reading Comprehension</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Data Interpretation & Logical Reasoning</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Quantitative Ability</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6'>
                        <table className=" md:w-[90%] w-auto lg:w-[55%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SECTION	</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Verbal Ability & Reading Comprehension</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">34 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Data Interpretation & Logical Reasoning</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">32 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Quantitative Ability</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">34 Questions</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CAT