import React from 'react'
import { Helmet } from "react-helmet";
const UK_Wales = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Wales - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Wales"
                />
                <meta
                    name="Keyword"
                    content="UK Wales"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Wales</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Wales is one of the four countries that make up the United Kingdom. It is known for its stunning landscapes, rich cultural heritage, and historic cities. Some of the top cities in Wales include Cardiff, Swansea, and Newport. The population of these cities can range from hundreds of thousands to millions of residents.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Wales has a number of highly ranked universities, including Cardiff University, Swansea University, and Bangor University. The average tuition fees for international students in Wales can range from £10,000 to £15,000 per year, depending on the institution and program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living in Wales varies depending on the city, with Cardiff being one of the more expensive cities. Accommodation options in Wales include university dorms, private apartments, and shared houses. Living criteria also vary depending on the city and individual preferences.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Wales has a strong economy, with top companies in industries such as finance, technology, and energy. Some of the top companies in Wales include Admiral Group, Legal & General, and Tata Steel. Job opportunities in Wales vary by city and industry, with some popular job roles including software developer, financial analyst, and nurse. Part-time and full-time job opportunities are available, with many students taking on part-time work while studying. Some top courses in Wales include computer science, business, and law. The top job roles in demand can change depending on the job market, but some in-demand positions currently include data scientists, cybersecurity professionals, and digital marketing specialists.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Wales is also a popular tourist destination, known for its stunning landscapes, rich cultural heritage, and historic cities. Some of the top tourist attractions in Wales include Snowdonia National Park, Caernarfon Castle, St. David's Cathedral, and the Brecon Beacons National Park.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Wales
