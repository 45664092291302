import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const BMS = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bachelor of Management Studies - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Bachelor of Management Studies"
                />
                <meta
                    name="Keyword"
                    content="BMS"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-BMS bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                        Bachelor of Management Studies (BMS)
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BMS</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BMS in its entirety A bachelor’s degree in management studies takes three years to complete. The course is aimed to study analytical aspects of business management and gain in-depth knowledge in a variety of managerial and business-related areas such as human resource management, economics, marketing, and business analytics, among others.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of a Bachelor of Management (BMS) course varies from INR 20,000 and INR 15,00,00 LPA. Top BMS Colleges in India provide the education in both full-time and part-time formats. Candidates who desire to apply for the BMS Course must meet the minimum eligibility requirements, which include a graduation grade point average of at least 50%. BMS admission is based on merit or a competitive entrance exam. DU JAT, AIMA UGAT, and other BMS admission exams are the most common in India. After completing BMS course, candidates can look for job opportunities as a Marketing Manager, Business Developer.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Students Choose BMS Course ?</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>This course is open to anybody with an interest in business or management.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>After high school, students with good communication skills, teamwork spirit, leadership, or other requisites for being a manager can enrol in a Bachelor of Management Studies programme.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>This programme is also suitable for those who intend to pursue an MBA or comparable course after completing their undergraduate studies.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The training is also available to candidates who want to start their own firm.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why You Should Choose BMS Course ?</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Prior to real-world situations, doing a Bachelor of Management Studies course can help you build managerial abilities like communication, organisation, leading, reporting, and teamwork.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Bachelor of Management Studies can also help potential entrepreneurs advance their careers by providing insight into the corporate world. It can also serve as a springboard for someone who wishes to start their own business right after college.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Students are offered a variety of managerial level jobs once they complete the course, which might help them create business connections.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Types Of BMS Course ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Students can opt for different types of courses offered by Bachelor of Management Studies (BMS)</p>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Full-TIme BMS</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Full-time BMS is a three-year undergraduate programme offered by a variety of colleges and universities across the country. The course is aimed to train future managers and entrepreneurs with all of the necessary abilities to run a successful firm.</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Admission to the full-time BMS programme is dependent on admission exams like as the DUJAT, AIIMS UGAT, and others.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>St.Xaviers Mumbai, Shahid Sukhdev Singh College Delhi, Narsee Monjee Mumbai, and more major colleges provide the subject.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The course is designed for recent high school graduates who want to pursue a career in business.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Part-Time BMS</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Several institutions now offer part-time BMS programmes, in which students are not required to attend classes on a regular basis.</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Admission in a timely manner BMS is awarded on the basis of entrance exam results or on a merit basis based on 10+2 results.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The course is appropriate for working-class students or students who are unable to devote to the course full-time.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>One Year BMS</p>
                    <p className=' text-xl font-serif py-1 font-normal  px-8 mb-6'>Some organisations or schools provide one-year management courses tailored specifically for the working class or those who currently own and operate a business and wish to improve their knowledge and skills.</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Admissions are made on the basis of an entrance exam or on merit.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The working class is the best fit.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>ISB Hyderabad is one of the best business schools in the country.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-12 mt-8  px-8'>Online BMS</p>
                    <p className=' text-xl font-serif py-1 font-normal  px-8 '>BMS can be obtained from any Indian or international university, institution, or college. Candidates can take the course online from anywhere in the world using a variety of digital devices.</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Admission is usually provided on a first-come, first-served basis, but this may vary depending on the institution to which one is applying.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The training is best for persons who don’t have a lot of free time and are already working or running a business.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The online BMS course also provides the ability to get worldwide experience outside of the confines of one country.</p>
                    </div>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The course can also assist in the development of stronger global business networks.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BMS