import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Dublin = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Dublin- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Dublin"
                />
                <meta
                    name="Keyword"
                    content="Ireland Dublin"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Dublin</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Dublin is the capital and largest city of Ireland, with a population of over 1 million people. It is home to several top-ranked universities, including Trinity College Dublin, University College Dublin, and Dublin City University. The average tuition fees for international students in Dublin range from €10,000 to €30,000 per year, depending on the program. Some of the top courses for international students in Dublin include business, engineering, computer science, and humanities. The highest paying jobs in Dublin are in the technology, finance, and healthcare sectors, with roles such as software engineer, investment banker, and physician. The average wage for these jobs is between €50,000 and €100,000 per year. The cost of living in Dublin is relatively high, with an average monthly rent of €1,000 and a monthly grocery bill of €300. Dublin is also a popular tourist destination, with many historic sites, museums, and cultural events to explore. There are several part-time and full-time job opportunities for international students in Dublin, including roles in hospitality, retail, and customer service. Some of the top companies in Dublin include Google, Facebook, and Airbnb. International students who complete their studies in Dublin may be eligible to stay in the country for a period of time to work or look for work, under the Irish Third Level Graduate Scheme.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Dublin