import React from 'react'
import { Helmet } from "react-helmet";
const UK_Armagh = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Armagh - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Armagh"
                />
                <meta
                    name="Keyword"
                    content="UK Armagh"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Armagh</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Armagh is a city in Northern Ireland, known for its rich history, cultural heritage, and thriving arts scene. The population of Armagh is approximately 14,000. Armagh has a number of highly ranked universities, including the University of Ulster, St Mary's University College, and Armagh Institute of Further and Higher Education. The average tuition fees for international students in Armagh can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Armagh is relatively affordable, with accommodation being one of the more significant expenses. Armagh has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Armagh include Allstate, Citi, and Bombardier Aerospace. Job opportunities in Armagh are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Armagh
