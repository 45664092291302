import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Australia_Jobs = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Jobs - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Jobs"
                />
                <meta
                    name="Keyword"
                    content="Australia Jobs"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>PART TIME JOB OPPORTUNITIES</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in Australia are eligible to work up to 20 hours per week while their student visa is valid, and there are many part-time job opportunities available. Here are some of the top part-time job roles for international students in Australia along with the average hourly wage:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Retail Sales Assistant:</span> AUD 20 to 25 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Food and Beverage Server:</span> AUD 20 to 25 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Customer Service Representative:</span> AUD 25 to 30 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Warehouse Worker:</span> AUD 25 to 30 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Delivery Driver:</span> AUD 25 to 30 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Cleaner:</span> AUD 25 to 30 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Data Entry Clerk:</span> AUD 25 to 30 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Kitchen Hand:</span> AUD 20 to 25 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Tutor or Teaching Assistant:</span> AUD 30 to 40 per hour</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Sales Representative:</span> AUD 30 to 40 per hour</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these are general estimates and the actual wage will depend on several factors, including location, experience, and the specific job role. International students are encouraged to research the job market and wage information for their specific field and location carefully before seeking employment in Australia.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Top full time job Opportunities</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The job market in Australia is diverse, and there are many full-time job opportunities across various industries. Here are some of the top job roles and industries in Australia along with the average annual salary:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>IT Project Manager:</span> AUD 95,000 to 140,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Software Developer:</span> AUD 75,000 to 130,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Data Scientist:</span> AUD 95,000 to 150,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Human Resources Manager:</span> AUD 85,000 to 140,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Marketing Manager:</span> AUD 85,000 to 140,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Engineer (Mechanical, Civil, Electrical, etc.):</span> AUD 80,000 to 130,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Accountant:</span> AUD 65,000 to 115,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Nurse:</span> AUD 70,000 to 110,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Doctor:</span> AUD 150,000 to 250,000 per year</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Teacher:</span> AUD 70,000 to 120,000 per year</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these are general estimates and the actual salary will depend on several factors, including location, experience, and the specific job role. International students who are eligible to work in Australia are encouraged to research the job market and salary information for their specific field and location carefully before seeking full-time employment.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Highest Paying Location in Australia with Some of top industries</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The highest paying locations in Australia for Indian students will depend on the specific industry and job role. However, cities such as Sydney and Melbourne tend to have a high demand for skilled workers, which can result in higher salaries for certain industries and job roles.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>top companies in Australia</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Australia is home to a diverse range of companies across various industries, including finance, resources, technology, and more. Here is a list of some of the top companies in Australia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Commonwealth Bank of Australia (CBA)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Westpac Banking Corporation</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>BHP Group</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Rio Tinto Limited</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Woodside Petroleum Limited</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Australia and New Zealand Banking Group (ANZ)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Telstra Corporation Limited</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>National Australia Bank (NAB)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Caltex Australia Limited</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Worley Limited</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>This list is by no means exhaustive, and there are many other successful and growing companies operating in Australia across a variety of industries. The Australian economy is considered to be one of the strongest and most stable in the world, making it an attractive location for businesses and investors.</p>
                   
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Some of the top companies in Australia across these industries include:</p>
                    <p className='text-lg font-serif py-1 font-bold  px-8 mb-6'>Information Technology:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Commonwealth Bank of Australia</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Westpac Banking Corporation</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>National Australia Bank</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Telstra Corporation</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Optus</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-bold  px-8 mb-6'>Healthcare:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Ramsay Health Care</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Sonic Healthcare</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Healthscope</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-bold  px-8 mb-6'>Finance and Accounting:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Commonwealth Bank of Australia</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Westpac Banking Corporation</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>National Australia Bank</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-bold  px-8 mb-6'>Engineering:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Wood Group</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Worley</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Aurecon Group</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these are general estimates and the actual salary will depend on several factors, including location, experience, and the specific job role. International students are encouraged to research the job market and salary information for their specific field and location carefully before seeking employment in Australia.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are many resources available for Indian students looking for jobs in Australia. Some of the most popular websites to search for job opportunities are:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Seek <a className='text-blue-500' href='https://www.seek.com.au/' target="_blank">(www.seek.com.au)</a>:</span>a popular job search website that covers a wide range of industries and job types.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Indeed <a className='text-blue-500' href='https://au.indeed.com/' target="_blank">(www.indeed.com.au)</a>:</span>a global job search website that lists job openings from thousands of websites.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>LinkedIn <a className='text-blue-500' href='https://www.linkedin.com/hp' target="_blank">(www.linkedin.com)</a>:</span>a professional networking website that also lists job openings and allows you to connect with potential employers.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Australian Government's Jobactive <a className='text-blue-500' href='https://www.workforceaustralia.gov.au/' target="_blank">(www.jobactive.gov.au)</a>:</span>a job search website run by the Australian government that lists job opportunities in various industries.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Gumtree <a className='text-blue-500' href='https://www.gumtree.com.au/' target="_blank">(www.gumtree.com.au)</a>:</span>a classified ads website that includes job listings, especially for part-time and casual work.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>Jora <a className='text-blue-500' href='https://www.jora.com/' target="_blank">(www.jora.com)</a>:</span>a job search engine that aggregates job postings from various websites, including government and recruitment agency sites.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '><span className='text-lg font-bold font-serif'>GradAustralia <a className='text-blue-500' href='http://www.gradaustralia.com.au/' target="_blank">(www.gradaustralia.com.au)</a>:</span>a website specifically designed for students and graduates to find job opportunities.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Additionally, many universities in Australia have career centers that can provide guidance on job search and career development for international students. Networking through industry events and professional organizations can also be a valuable way to find job opportunities in Australia.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Jobs