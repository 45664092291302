import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Germany_Munich = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Germany Munich - Future Dream Makers</title>
        <meta
          name="description"
          content="Germany Munich"
        />
        <meta
          name="Keyword"
          content="Germany Munich"
        />
      </Helmet>
      <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
        <div className='md:text-justify  '>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Munich</p>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Population:</span> 1.5 million</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>GDP:</span> €77.5 billion</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Top Universities:</span> Ludwig Maximilian University, Technical University of Munich</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Average tuition fees:</span> €1,000-€1,500/month</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Cost of living:</span> €400-€800/month</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Accommodation:</span> €300-€800/month</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Top places to visit:</span> rich cultural history and gardens.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Part-time job opportunities:</span> available</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Full-time job opportunities:</span> abundant</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Top job roles:</span> software engineer, data analyst, project manager</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wages:</span> €50,000-€80,000/year</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Germany_Munich
