import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_GIC = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Canada GIC - Future Dream Makers</title>
        <meta
          name="description"
          content="Canada GIC"
        />
        <meta
          name="Keyword"
          content="Canada GIC"
        />
      </Helmet>
      <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
        <div className='md:text-justify  '>

          <p className=' uppercase font-semibold text-black text-4xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>GIC</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>GIC stands for Guaranteed Investment Certificate, which is a type of savings product offered in Canada by financial institutions. A GIC is a low-risk investment that offers a guaranteed return on your savings, usually with a fixed interest rate for a set period of time.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The importance of a GIC in Canada for international students lies in the fact that a GIC is often required as part of the financial proof when applying for a student visa. The Canadian government requires international students to demonstrate that they have sufficient financial resources to cover the cost of their tuition and living expenses while studying in Canada. A GIC is one way to meet this requirement by showing that you have a set amount of savings invested in a Canadian financial institution.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>By having a GIC, international students can demonstrate to visa officers that they have a stable source of funds to support their studies and living expenses in Canada. This can increase the chances of having their student visa application approved. It is important to note that the requirements for financial proof may vary depending on the visa officer processing your application and the program you are enrolling in, so it's best to check the latest information on the Immigration, Refugees and Citizenship Canada (IRCC) website.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process to open a Guaranteed Investment Certificate (GIC) in Canada typically involves the following steps:</p>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose a financial institution:</span> Research and compare different financial institutions that offer GICs, and choose one that meets your needs.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Open an account:</span> You will need to open an account with the financial institution where you want to invest in a GIC. This can typically be done online or in person.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Invest funds:</span> Deposit the funds you want to invest in the GIC into your account.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose the term and rate:</span> Select the term and interest rate that best fits your financial goals and needs. GIC terms can range from 30 days to 10 years, and interest rates can vary depending on the term and financial institution.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Confirm investment:</span> Confirm your investment and sign any necessary documents to complete the process.</p>
          </div>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The amount of money you need to invest in a GIC will depend on the term and interest rate you choose. As for the amount you will receive each month, it will depend on the interest rate and the amount you invested. Most GICs pay interest at the end of the term, but some may offer the option of receiving interest payments monthly or annually. It's important to read the terms and conditions of the GIC before making an investment to understand when and how you will receive your returns.</p>

        </div>
      </div>
    </div>
  )
}

export default Canada_GIC