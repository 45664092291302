import React from 'react'
import { BsDot } from 'react-icons/bs'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const BCOM = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bachelors of Commerce - Future Dream Makers</title>
                <meta
                    name="description"
                    content=" Bachelors of Commerce (BCOM)"
                />
                <meta
                    name="Keyword"
                    content="BCOM"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-BCOM bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                        Bachelors of Commerce (BCOM)
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' font-semibold uppercase text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>B.COM</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Bachelor of Commerce is the full version of BCom. The Bachelor of Commerce (BCom) is a three-year undergraduate curriculum. The BCom programme is aimed to teach students analytical skills, financial literacy, and business acumen, among other things, by emphasising theoretical topics.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>During the three-year BCom programme, students will acquire broad business principles while also studying key commerce disciplines like finance, economics, accounting, actuarial studies, and business management, among others. Few of the entrance exams conducted for enrolling in BCom courses are <span className='font-bold'>IPU CET, DUET, NPAT, BHU UET etc.</span></p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BCom courses demand a minimum of 50% in class 12 or an equivalent examination to be eligible. The BCom course is the most popular and in-demand among commerce students. The BCom Syllabus covers topics including taxation, economics, and accounting.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The B.Com course is available in 3 categories: </p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>B.Com General</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>B.Com Honours</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>B.Com LLB</p>
                    </div>

                    <p className=' font-semibold uppercase text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Students Choose B.COM ?</p>
                    <p className=' font-semibold text-red-500 text-2xl lg-6 mt-8  px-8' >Prepares Students For Entrepreneurship & Jobs</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>In contrast to other Bachelor degrees, a BCom degree educates graduates for both the corporate sector and entrepreneurship. The course equips students with commercial and financial information that they may apply in the workplace.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Vast Job Opportunities</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Bachelor of Commerce graduates can work in a wide range of industries, including banks and capital management, accounting firms, investment banking, and budget planning.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Salary Packages & Benefits:</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BCom graduates earn an average salary of INR 2.5 to 4 lakh per year, according to PayScale India. BCom graduates can expect to earn between INR 2.5 and 3 lakh per year as an entry-level income.</p>

                    <p className=' font-semibold uppercase text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Types Of B.COM Courses Students Can Choose</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Different types of courses of BCom courses are available, with 38 different specialisation programmes available across India. Students who have finished the 12th grade are qualified for any of these programmes and can apply to any of the connected universities throughout India. In India, there are now 6175 B.Com institutes.</p>

                    {/* table */}
                    <div className='flex justify-center mt-8 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TYPES OF COURSES</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">ADMISSION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">FEES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">B.COM Online </td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Merit Based</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Rs. 30,000</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">B.COM Distance Learning</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Merit /Entrance Based	</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Rs. 47,000</td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">B.COM Abroad</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Entrance Based	</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Rs. 12 Lakhs</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >B.COM Honours</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BCom Honours is a sort of BCom degree programme that focuses on a specific subject. The majority of students apply to major in accounting. BCom Honours is a three-year undergraduate degree programme in commerce that covers disciplines including accounting, taxation, economics, and financial management.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The only distinction between BCom Honours and Bachelor of Commerce is the nomenclature and course curriculum. Most students choose the BCom Honours degree because it allows them to study for certification tests such as the Chartered Accountancy (CA) and other certifications concurrently or later.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The possible specializations that are available in B.Com (Hons) are:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Accounting </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Taxation </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Business Management </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Banking and Finance </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Economics </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Financial Accounting </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Business Law </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Auditing </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Corporate Finance </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Investment Analysis </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Business Analytics </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>International Business </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Business Mathematics </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Human Resource Management </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Entrepreneurship </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Business Statistics </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Marketing Management</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >B.COM Online</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BCom Online is a three-year programme that teaches students about business communication, financial and corporate accounting, business law, and accounting.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Candidates who are unable to attend a traditional B.Com course or who cannot pay the standard BCom costs might consider BCom online.BCom online expenses typically range from INR 11,000 to INR 20,000.</p>
                </div>
            </div>
        </div>
    )
}

export default BCOM