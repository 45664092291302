import React from 'react'
import { Helmet } from "react-helmet";
const Education_Loan = () => {


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Education Loan - Future Dream Makers</title>
        <meta
          name="description"
          content="Education Loan"
        />
        <meta
          name="Keyword"
          content="Education Loan"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-EducationLoan bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            EDUCATION LOAN
          </p>
        </div>
      </div>

      <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
        <div className='md:text-justify'>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Education loan</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Future Dream Makers is a one-of-a-kind platform that offers the best financial decision for study abroad looking to carve out a career in academia. With education loans for abroad study, you can help your child achieve his or her academic goals. Our main purpose is to provide financial aid or education loan to international students at all levels, whether they are studying in the United States, the United Kingdom, Canada, Australia, New Zealand or any other place of their choice. When you apply for our education loans for abroad education, you can get up to 100% financing. Education loans for foreign education in amounts larger than Rs 20 lakhs are also available. Take advantage of a customer-friendly education loan for international education.</p>

          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>High Cost Abroad Education</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>An education obtained in a foreign country always comes at a high cost. And, at times, managing such an exponential cost becomes nearly difficult for the parents. The student is forced to establish a solid financial plan to support his studies and living expenditures for the specified period due to the high tuition charge, cost of living, and provision for health insurance, as well as other extraneous expenses. In general, a student has three options from which to choose.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Getting a scholarship that would cover all of your fees for the length of your school</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The entire educational programme is self-funded.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Obtaining a study-abroad loan from one of the certified study-abroad loan providers</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Because of the exponential expense, most students prefer to take out an overseas study loan from a loan provider. There are a few well-known and professional financial organisations that offer plans that cover the entire cost of studying abroad.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Prepare For Trip</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Based on the most recent travel warning given by their home country and the place they plan to visit, we inform and prepare them for their travels.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Pick-Up And Drop Facilities</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Our students are picked up from the airport and transported to the lodging of their choice in the foreign destination.</p>
        </div>
      </div>
    </div>
  )
}

export default Education_Loan