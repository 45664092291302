import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Manitoba = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Manitoba - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Manitoba"
                />
                <meta
                    name="Keyword"
                    content="Canada Manitoba"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Manitoba</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Manitoba is a Canadian province bordered by Ontario to the east and Saskatchewan to the west. Its landscape of lakes and rivers, mountains, forests and prairies stretches from northern Arctic tundra to Hudson Bay in the east and southern farmland. Much wilderness is protected in more than 80 provincial parks, where hiking, biking, canoeing, camping and fishing are all popular’ Canada's central province, Manitoba is primarily known for being home to the polar bear capital of the world. But there's a lot of other enticing attractions in the region, such as its many beautiful lakes, its world-class museums, and its unique festivals. WINNIPEG is the capital of Manitoba. Population of Manitoba is 13.7 lakhs (2019). GDP of Manitoba is C$71.019 billion. </p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Manitoba:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Manitoba:</span> Founded in 1877, University of Manitoba is a non-profit public higher-education institution located in the urban setting of the large city of Winnipeg (population range of 500,000-1,000,000 inhabitants), Manitoba. Officially recognized by the Department of Education and Training of Manitoba, University of Manitoba (U of M) is a large (university Rank enrolment range: 25,000-29,999 students) coeducational Canadian higher education institution. University of Manitoba (U of M) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees in several areas of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of Winnipeg:</span> Founded in 1871, The University of Winnipeg is a non-profit public higher-education institution located in the urban setting of the large city of Winnipeg (population range of 500,000-1,000,000 inhabitants), Manitoba. Officially recognized by the Department of Education and Training of Manitoba, The University of Winnipeg (UofW) is a medium-sized (university Rank enrolment range: 9,000-9,999 students) coeducational Canadian higher education institution. The University of Winnipeg (UofW) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 151 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Brandon University:</span> Founded in 1899, Brandon University is a non-profit public higher-education institution located in the urban setting of the small city of Brandon (population range of 50,000-249,999 inhabitants), Manitoba. Officially recognized by the Department of Education and Training of Manitoba, Brandon University (BU) is a small (university Rank enrolment range: 2,000-2,999 students) coeducational Canadian higher education institution. Brandon University (BU) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 123 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. International 0welcome to apply for enrolment. BU also provides several academic and non-academic facilities and services to students including a library, housing, sports facilities, financial aids and/or scholarships, study abroad and exchange programs, online courses and distance learning opportunities, as well as administrative services. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Canadian Mennonite University:</span> Established in 2000, Canadian Mennonite University is a non-profit private higher-education institution located in the urban setting of the large city of Winnipeg (population range of 500,000-1,000,000 inhabitants), Manitoba. Officially recognized by the Department of Education and Training of Manitoba, Canadian Mennonite University (CMU) is a very small (university Rank enrolment range: 1,000-1,999 students) coeducational Canadian higher education institution formally affiliated with the Christian-Mennonite religion. Canadian Mennonite University (CMU) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees in several areas of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Université de Saint-Boniface:</span> Established in 1818, Université de Saint-Boniface (University of Saint-Boniface) is a non-profit private higher-education institution located in the urban setting of the large city of Winnipeg (population range of 500,000-1,000,000 inhabitants), Manitoba. Officially recognized by the Department of Education and Training of Manitoba, Université de Saint-Boniface (USB) is a very small (university Rank enrolment range: 1,000-1,999 students) coeducational Canadian higher education institution. Université de Saint-Boniface (USB) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 204 years old Canadian higher-education institution has a selective admission policy based on entrance examinations and students' past academic record and grades. The admission rate range is 60-70% making this Canadian higher education organization a somewhat selective institution. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Canada_Manitoba
