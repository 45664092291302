import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const New_Zealand_Auckland = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Auckland - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Auckland"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Auckland"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>AUCKLAND</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Auckland is the largest city in New Zealand and a popular destination for international students. Here is some information about Auckland in terms of education, living, employment, and tourism:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Tuition fees in Auckland for international students can range from NZD 22,000 to NZD 38,000 per year, depending on the institution and the program.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>The cost of living in Auckland can be higher compared to other cities in New Zealand, but on average, international students can expect to spend around NZD 20,000 to 25,000 per year on living expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Accommodation options for international students in Auckland include homestays, student dormitories, flats, and apartments.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Popular courses for international students in Auckland include business, engineering, computer science, and tourism, among others.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>International students in Auckland are allowed to work part-time up to 20 hours per week during their studies and full-time during holidays.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>To find a job in Auckland, international students can start by searching for job openings online, networking with local professionals, and attending job fairs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>The Post-Study Work (PSW) visa allows international graduates from Auckland institutions to work in the country for up to three years after graduation.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Popular job profiles for international students in Auckland include sales and customer service, hospitality, and agriculture. Some of the top companies in Auckland include Fonterra, Air New Zealand, and the Warehouse Group.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Top tourist attractions in Auckland include the Sky Tower, Auckland Museum, and the Auckland Zoo, among others.</p>
                    </div>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities in Auckland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>The University of Auckland</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Auckland University of Technology (AUT)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Massey University</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default New_Zealand_Auckland
