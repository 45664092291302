import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Professional_Resume = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Professional Resume - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Professional Resume"
                />
                <meta
                    name="Keyword"
                    content="Professional Resume"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-ProfessionalResume bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        Professional Resume
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Professional Resume Writing</p>
                    <p className='text-lg font-normal font-serif py-1 px-8 mb-6'>Your CV is your golden ticket to securing an interview at your dream College or University, it must be given high consideration. All R esumes are not created equally, some are better than others. It’s critical that your resume shows your own successes and aspirations within the field you’re wanting to break into to bring it to the top of the pile. If you need assistance getting started, select the course that most closely resembles yours from the list below to see top-notch examples of what your resume should say.</p>
                    <p className='text-lg font-normal font-serif py-1 px-8 mb-6'>A professional resume is a document that highlights a person's education, work experience, skills, and achievements in a clear and concise manner. Its purpose is to present the person as a qualified candidate for a job or education opportunity and to demonstrate their suitability for the position or program. A professional resume should be well-organized, easy to read, and free of errors. It should also be tailored to the specific job or education program the person is applying for, showcasing their relevant skills and experience.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Importance of professional resume</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Application Requirements:</span> Many universities and educational institutions abroad require a resume as part of the application process, making it a crucial component of the admission process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Showcasing Qualifications:</span> A professional resume helps to showcase the applicant's academic qualifications, work experience, and other achievements, highlighting their strengths and making them stand out among other applicants.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Demonstrating Relevance:</span>  A well-crafted resume helps to demonstrate the relevance of the applicant's experience and skills to the desired field of study or career path, making them a more competitive candidate for admission.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Making a Positive Impression:</span> A professional resume can make a positive impression on the admission committee, demonstrating the applicant's attention to detail and commitment to the process.</p>
                    </div>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Tips for making professional resume</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Tailor the resume to the job or education program:</span> Customize the resume to the specific job or education program the person is applying for, highlighting the relevant skills and experience.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Use a clear and concise format:</span> Use a clear and simple format that is easy to read and follow.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Highlight achievements:</span> Focus on accomplishments and results, rather than just listing job duties.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Use keywords:</span> Use keywords relevant to the job or education program to increase the chances of the resume being noticed by employers or admission committees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Keep it concise:</span> Limit the resume to one or two pages, including only the most relevant information.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proofread and edit:</span> Carefully proofread and edit the resume to ensure it is error-free.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Seek feedback:</span> Ask a trusted friend, mentor, or career counselor to review the resume and provide constructive feedback.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Professional_Resume