import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const XAT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>XAT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="XAT"
                />
                <meta
                    name="Keyword"
                    content="XAT"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-XAT bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        XAT
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is XAT</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>XAT is an abbreviation for Xavier Aptitude Test. XAT is a national-level management entrance exam administered by XLRI, Jamshedpur to students seeking admission to MBA/PGDM programmes. For more than 70 years, XLRI has administered the XAT exam on behalf of XAMI. The XAT exam is one of the most popular among MBA candidates because it is accepted at over 700 colleges across the country, including XLRI, 95 XAT Associates, and the 12 XAMI member institutes.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>There are over 150 management institutes that accept XAT scores for admission. The XAT is a three-hour exam that serves as the first step in applying to XLRI and XAT Associate Institutes. Applicants who wish to apply to XAT Associate Institutes other than XLRI must do so separately.</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>PGDM – Post Graduate Diploma in Management</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>MBA – Masters of Business Administration</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Executive Diploma</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST TYPE</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">CONTENT </th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Online (Computer-Based-Test)</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">101 Questions</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">190 Minutes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>XAT Eligibility Criteria</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Any management aspirant who has completed a bachelor’s degree in any discipline from a recognised university or deemed university is eligible to take the test.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates who have graduated from a recognised or deemed university are eligible for XAT 2022.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates in the final year of their bachelor’s degree or those awaiting results are also eligible to apply. Candidates with a graduation average of 50% or less are eligible to take the XAT 2022.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates in their final year of graduation are also eligible to take the test, as long as they can submit their grades to the participating B-schools within the time frame specified.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Final-year graduation students taking the XAT must submit the aggregate percentage of the previous year’s degree course they are pursuing.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>XAT Exam Pattern</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>According to the XAT Exam Pattern 2023, the question paper will be divided into two sections with a total of 101 questions. It is critical to thoroughly review the XAT exam pattern in order to develop an effective preparation strategy.</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Verbal and Logical Ability</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Decision Making</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Quantitative Aptitude & Data Interpretation</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>General Knowledge</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Essay Writing</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className=" md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SECTION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">XAT Decision Making</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">21 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Verbal & Logical Ability</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">26 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Quantitative Aptitude & Data Interpretation</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">28 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">General Knowledge</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">25 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Essay Writing</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">1 Questions</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>XAT Marking Schemes</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>In the last two years, there have been no significant changes to the XAT marking scheme. It is important to remember the following points about the XAT 2023 Marking Scheme:</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Each MCQ is worth one point. 0.25 points are deducted for each incorrect response.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The deduction of 0.10 marks for each unattempted question after 8 unattended questions is a unique feature of the XAT exam marking pattern.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The Essay is only evaluated for candidates who have been shortlisted for the PI round.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 mb-6'>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The GK section has no negative marking. GK scores are not taken into account when calculating final XAT scores.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default XAT