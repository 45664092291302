import React from 'react'
import { Helmet } from "react-helmet";
const IELTS = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IELTS - Future Dream Makers</title>
        <meta
          name="description"
          content="IELTS"
        />
        <meta
          name="Keyword"
          content="IELTS"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-IELTS bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            IELTS
          </p>
        </div>
      </div>

      <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
        <div className='md:text-justify'>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>IELTS</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The International English Language Testing System (IELTS) is the most widely taken English language test for higher education and international migration. The prestigious IELTS exam was established in 1989 and is jointly managed by the official conducting authorities: the British Council and IDP IELTS. Non-native English language speakers can demonstrate their English language proficiency by taking the IELTS test in order to study, work, or migrate to English-speaking countries. Every year, over 3.5 million tests are administered.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why IELTS Is Important ?</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The International English Language Testing System (IELTS) is the most widely taken English language test for higher education and international migration. The prestigious IELTS exam was established in 1989 and is jointly managed by the official conducting authorities: the British Council and IDP IELTS. Non-native English language speakers can demonstrate their English language proficiency by taking the IELTS test in order to study, work, or migrate to English-speaking countries. Every year, over 3.5 million tests are administered.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The IELTS exam is required as a mandatory eligibility criterion by the majority of workplaces and the most prestigious universities/colleges. Government immigration agencies frequently require proof of English language skills as a prerequisite. IELTS is administered in over 1,600 locations across 140 countries. IELTS results are accepted as proof of language ability by over 10,000 organisations and institutions worldwide. Candidates can now choose between taking a paper-based IELTS exam and taking a online-delivered test. The format of both exams remains unchanged.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The IELTS Test is universally accepted in the United States, the United Kingdom, Europe, Canada, Australia, and New Zealand, as well as many other countries around the world.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Appearing for the IELTS test and obtaining an acceptable IELTS score is all but the first step in the Visa Application process. Because the IELTS Score adheres to the highest international language assessment standards, it is a reliable and valid indicator of your ability to communicate in English. IELTS scores are frequently non-negotiable and essential components of most institutions’ admission and recruitment processes.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl  underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>IELTS Test Format</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>IELTS is divided into two modules: Academic and General Training. The IELTS exam is divided into four sections: listening, reading, writing, and speaking. The Listening and Speaking tests are the same for both modules, but the Writing and Reading tests are distinctive. The IELTS Exam can now be taken on a online as well as on paper. There are no restrictions on the number of attempts. The  score is valid for next two years.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The test lasts for 2 hours 45 minutes in total. The exam’s Listening, Reading, and Writing modules will be administered on the same day with no breaks, whereas the Speaking module will be scheduled up to a week before or after the other tests. Your IELTS test centre will notify you of the date and time of your speaking test.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>IELTS Test Format</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Choose this module if you want to study at the undergraduate or postgraduate levels, or if you want to be a registered professional such as a doctor or a nurse.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>General IELTS Training</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Choose this module if you want to migrate to English-speaking countries such as the United States, the United Kingdom, Canada, Australia, or New Zealand, or if you want to train and/or study at a lower degree level.</p>
          <p className='  font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Test Format</p>
          {/* table */}
          <div className='flex justify-center mb-6 px-8'>
            <table className="md:w-[95%] w-auto lg:w-[90%] ">
              <thead>
                <tr>
                  <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST TYPE</th>
                  <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">CONTENT</th>
                  <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Listening</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">40 Questions</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">30 minutes + 10 minutes transfer time</td>
                </tr>
                <tr>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Reading</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">40 Questions</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">60 minutes</td>
                </tr>
                <tr>
                </tr>
                <tr>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Writing</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 tasks</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">60 minutes</td>
                </tr>
                <tr>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Speaking</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">3 parts</td>
                  <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">11–14 minutes.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IELTS