import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Galway = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Galway- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Galway"
                />
                <meta
                    name="Keyword"
                    content="Ireland Galway"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Galway</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Galway is a coastal city in the west of Ireland, with a population of over 75,000 people. It is home to several universities and colleges, including National University of Ireland, Galway and Galway-Mayo Institute of Technology. The average tuition fees for international students in Galway range from €10,000 to €20,000 per year. Some of the top courses for international students in Galway include arts, humanities, and social sciences. The highest paying jobs in Galway are in the finance, technology, and healthcare sectors, with roles such as financial analyst, software engineer, and physician. The average wage for these jobs is between €40,000 and €70,000 per year. The cost of living in Galway is relatively lower compared to other cities in Ireland, with an average monthly rent of €700 and a monthly grocery bill of €250. Galway is known for its vibrant arts scene, with several museums, galleries, and cultural events to explore. There are also several part-time and full-time job opportunities for international students in Galway, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Galway