import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Italy_AdmissionDocuments = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Admission Documents- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Admission Documents"
                />
                <meta
                    name="Keyword"
                    content="Italy Admission Documents"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Documents required for admission</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students who want to study in Italy will need to provide the following documents:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Passport:</span> A valid passport with at least three months of validity beyond the intended stay in Italy.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Student visa:</span> Depending on the student's country of origin and the length of stay in Italy, they may need to apply for a student visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proof of acceptance:</span> A letter of acceptance from the Italian university indicating that the student has been accepted into a course of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proof of financial support:</span> Students may need to provide proof of financial support, such as a scholarship, bank statement, or letter from a sponsor.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proof of Italian language proficiency:</span> Depending on the university and program, students may need to provide proof of their Italian language proficiency, such as a language test score.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Health insurance:</span> International students are required to have health insurance in Italy.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Criminal record certificate:</span> Some universities may require students to provide a certificate of good conduct or a police clearance certificate.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to check with the Italian university and embassy for specific requirements, as they may vary depending on the student's country of origin and program of study.</p>
                    </div>
                    </div>
    </div>
  )
}

export default Italy_AdmissionDocuments
