import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const JMC = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bachelor of Arts in Journalism and Mass Communication - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Bachelor of Arts in Journalism and Mass Communication"
                />
                <meta
                    name="Keyword"
                    content="JMC"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[32rem] h-96 py-12 px-4 bg-JMC bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                        Bachelor of Arts in Journalism and Mass Communication
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BA in Journalism and Mass Communication</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Bachelor of Arts in Journalism and Mass Communication is a three-year undergraduate programme that prepares students with the technical skills and social understanding needed to write about and convey information involving business, history, culture, budget issues, governmental matters, entertainment, and sports to the general public via mass media such as television, radio, and web-based social networks. The course gives a complete knowledge at both an academic and practical level, as journalism is founded on truth, autonomy, and transparency.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Communication Theories and Models, Public Relations, Design and Graphics, Digital Media, Reporting and Editing for Print Media, Radio Production, Data Journalism, Photo-Journalism, Event Management, and Media Law and Ethics are among the topics covered in this course.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Entrepreneurship, Business Communication, Foreign Language (French or German), Human Resource Development, Socio-cultural History of India, Organizational Behaviour, Fashion Communication, and Leadership Development and Team Building are among the courses available. with this curriculum.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Seminars, computer-based learning, guest lectures, workshops, Massive Open Online Courses (MOOCs), industry certification-training, and a variety of individual and group project works, such as live projects, incubation projects, community projects, and projects with NGOs, may all be part of the curriculum.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>This programme is open to those who have completed their 10+2 schooling or equivalent in any stream from a recognised board with a minimum of 50% in each subject. In different universities, the average course fee ranges from INR 2,000 to INR 5 lakh.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BA In Journalism And Mass Communication Eligibility Criteria</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The colleges that follow the BA Journalism and Mass Communication eligibility are listed below:</p>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>This programme is open to candidates who have completed their 10+2 schooling or equivalent in any stream with a minimum of 50% in each subject.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Institutions that need an entrance exam may consider both the entrance exam score and past academic records when making admission decisions. Each institution determines the examination’s cut-off mark.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Candidates from the SC/ST/OBC (non-creamy layer), differently abled, and other categories will receive a 5% mark relaxation.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Letters of reference might also be in the candidate’s favour.</p>
                    </div>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Choose BA In Journalism And Mass Communication</p>
                    <div className='flex   px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The curriculum provides students with the information and abilities needed to operate in a variety of media organisations and with various mass communication apparatus.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Problem-solving, interviewing, investigative, and reporting abilities, as well as an analytical approach to topics and trends, research aptitude, and networking skills, may be acquired by students.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Students will learn about the fundamentals of mass communication as well as recent rising media trends and challenges, as well as current technical trends and breakthroughs in journalism.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Graduates are exposed to a variety of print, electronic, and new-age media, as well as a variety of technologies used in mass communication, such as computer applications, software, and hardware used in print, radio, television, and the internet.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Students learn to conceptualise, create, design, and strategize high-quality media material for television, radio, cinema, and numerous digital channels such as social media.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Due to the remarkable rise of regional media and the proliferation of new media firms and formats, this degree programme assists in refining skills to acquire, interpret, transmit, and convey information to a big audience, one’s career is more revolutionised in non-metro cities and small towns.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6 mt-8'>Universities offer BA in Journalism and Mass Communication through :</p>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Merit-Based Admission</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Direct Admission</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 mb-6'>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Exam-Based Entrance</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JMC