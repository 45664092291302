import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const PTE = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PTE - Future Dream Makers</title>
                <meta
                    name="description"
                    content="PTE"
                />
                <meta
                    name="Keyword"
                    content="PTE"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-PTE bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        PTE
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is PTE ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PTE, or Pearson Test of English, is a computer-based English proficiency test administered by Pearson PLC Group for non-native speakers who wish to pursue higher education, future careers, or immigrate to primarily English-speaking countries. PTE is recognised by educational institutions and employers all over the world.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PTE is well-known for its unrivalled quality and objective methodical approach. It assesses a candidate’s overall proficiency in the English language through sections on Reading, Listening, Speaking, and Writing, all of which are completed in a single two-hour session.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PTE exams are accepted in many countries, including Australia, New Zealand, the United States, Canada, the United Kingdom, Ireland, and Singapore. PTE Academic is recognized by the governments of Australia and New Zealand for visa and immigration purposes.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>PTE Academic</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PTE Academic is recognised by governments, universities, and professional organisations all over the world. Those who want to study and/or work in another country should consider this option.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>In the event that a candidate is unable to travel to the exam centre, a version of PTE Academic can be taken from the safety and comfort of their own home. PTE Academic Online is now recognised by a wide range of institutions. PTE Academic Online is currently only available for studying applications.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span>PTE Academic United Kingdom VI </span> is best suited to those who want to work or live in the UK. Candidates should choose PTE Home for Family & Settlement visas in addition to UK citizenship. PTE Academic UKVI and PTE Home are approved Secured English Language Tests by the UK government (SELT).</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Choose PTE</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>There are numerous reasons to choose PTE, including the fact that it is designed to meet the needs of students all over the world and is accredited as ESOL (English for Speakers of Other Languages) International qualifications by OFQUAL (the Office of Qualifications and Examinations Regulation) in England.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>From its simple acceptance (the Pearson Test of English is accepted by over 3000 universities and colleges) to its easy booking and last-minute availability. The score is valid for a period of two years. The test taker can select from a variety of frequent dates based on their needs and thus book at the last minute. The results are ready in 48 hours, or two business days.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The new PTE exam adaptations, which go into effect on November 16, 2021, make taking the test even easier. The PTE exam has been shortened by an hour in its new format, with the exam lasting two hours instead of three. It assesses the candidate based on day-to-day activities rather than advanced English.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PTE, as the name implies, is an English test without the stress. Booking and taking the exam with PTE is a piece of cake. Their test centres administer the exam in smaller batches with frequent dates, allowing students to book their exams even at the last minute.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>PTE Exam Pattern</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The PTE exam is divided into three sections and consists of 20 different types of questions ranging from multiple choice to essay writing. Reviewing and practicing them prior to the exam will be critical to achieving high grades.</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Speaking & Writing </p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Reading</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Listening</p>
                    </div>

                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SECTION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Speaking & Writting</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">28-36 questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Reading</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">13-18 questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Listening</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">12-20 questions</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PTE