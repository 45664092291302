import React from 'react'
import { Helmet } from "react-helmet";
const UK_Cardiff = () => {
  return (
    <div>
     <Helmet>
                <meta charSet="utf-8" />
                <title>UK Cardiff - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Cardiff"
                />
                <meta
                    name="Keyword"
                    content="UK Cardiff"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Cardiff</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Cardiff is the capital city of Wales, known for its rich history, cultural heritage, and thriving arts scene. The population of Cardiff is approximately 361,000. Cardiff has a number of highly ranked universities, including Cardiff University, the University of South Wales, and Cardiff Metropolitan University. The average tuition fees for international students in Cardiff can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Cardiff is relatively affordable, with accommodation being one of the more significant expenses. Cardiff has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Cardiff include Admiral Group, HSBC, and GoCompare. Job opportunities in Cardiff are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div> 
    </div>
  )
}

export default UK_Cardiff
