import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs';
const Italy_Scholarships = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Scholarships - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Scholarships"
                />
                <meta
                    name="Keyword"
                    content="Italy Scholarships"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>scholarships in Italy for Indian students </p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Italian Government Scholarships for Indian Students:</span> This scholarship is offered by the Italian government for Indian students who wish to study in Italy. The scholarship covers tuition fees, accommodation, and other expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>DAAD Scholarships for Indian Students:</span> The German Academic Exchange Service (DAAD) offers scholarships for Indian students who want to study in Italy. The scholarship covers tuition fees, living expenses, and other related costs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Santander Scholarships for Indian Students:</span> Santander Bank offers scholarships for Indian students who want to study in Italy. The scholarship covers tuition fees, living expenses, and other related costs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Bilateral agreements between India and Italy:</span> Many universities in Italy have bilateral agreements with Indian universities. These agreements provide scholarships to Indian students who want to study in Italy.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Rotary International Scholarships for Indian Students:</span> Rotary International offers scholarships for Indian students who want to study in Italy. The scholarship covers tuition fees, living expenses, and other related costs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>ENI Scholarships for Indian Students:</span> ENI, a multinational oil and gas company, offers scholarships for Indian students who want to study in Italy. The scholarship covers tuition fees, living expenses, and other related costs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>AIFS Study Abroad Scholarships for Indian Students:</span> AIFS offers scholarships for Indian students who want to study in Italy. The scholarship covers tuition fees, living expenses, and other related costs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Italy_Scholarships
