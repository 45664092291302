import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Accommodation = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Accommodation - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Accommodation"
                />
                <meta
                    name="Keyword"
                    content="Canada Accommodation"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Accomodation option in Canada</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several options available for booking accommodation in Canada as a student:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>On-campus housing:</span> Many Canadian universities and colleges offer on-campus housing options for students, such as dormitories or apartments. This is a convenient option for students as it is close to the campus and offers easy access to campus facilities and services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Off-campus housing:</span> Students can also look for rental apartments, shared housing or homestay options in the local community. Websites like Kijiji, Craigslist, and Padmapper can be used to search for off-campus housing options.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Homestay:</span> This is a popular option for international students who want to live with a Canadian family and experience Canadian culture firsthand. Homestay providers offer a variety of options, including meals, private rooms, and shared spaces.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Residence:</span> There are also several private companies that offer student residence options, including shared apartments, individual apartments, and homestay options.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's recommended to book accommodation well in advance, especially during the peak student-arrival months of August and September. When choosing an accommodation option, consider factors like cost, location, and access to transportation, as well as your personal preferences and lifestyle.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Website and app which are helping students in accommodation.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several websites and apps that can help Indian students with accommodation in Canada:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Campus Living Centres:</span> This is a student housing provider that operates several on-campus and off-campus housing properties in Canada. They offer a range of accommodation options, including shared apartments and individual apartments.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Homestay:</span> Homestay is a popular option for international students in Canada, and there are several websites that can help you find a homestay, such as Homestay.com and Homestay Finder.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Kijiji:</span> Kijiji is a Canadian online classifieds and community website that can help you find off-campus housing options, such as apartments and shared housing.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Craigslist:</span> Craigslist is another online marketplace that can help you find off-campus housing options in Canada.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Padmapper:</span> Padmapper is a Canadian apartment search engine that allows you to search for apartments and rental properties across Canada.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>These websites and apps can be used to find and compare different accommodation options, and can help you make an informed decision about where to live while studying in Canada.</p>
                </div>
            </div>
        </div>
    )
}

export default Canada_Accommodation