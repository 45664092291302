import React from 'react'
import { Helmet } from "react-helmet";
const Australia_COE = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia COE - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia COE"
                />
                <meta
                    name="Keyword"
                    content="Australia COE"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>COA/COE</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Confirmation of Acceptance for Studies (COA) or Confirmation of Enrollment (COE) is usually issued by the Australian university after the student has fulfilled all the necessary admission requirements, including acceptance of offer, payment of fees and providing proof of English proficiency and health insurance, etc. The exact time frame for receiving the COA/COE may vary depending on the university and the student's individual circumstances, but typically it is issued within a few weeks of completing the admission process.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_COE