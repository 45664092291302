import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Medical = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Medical - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Medical"
                />
                <meta
                    name="Keyword"
                    content="Canada Medical"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Medical</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As an Indian student applying for a Canada student visa, you will typically need to undergo a medical examination. The medical examination will include a physical examination, laboratory testing of your blood and urine, and an assessment of any pre-existing medical conditions. The medical examination must be conducted by a designated medical practitioner (DMP) authorized by Immigration, Refugees and Citizenship Canada (IRCC).</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that not all student visa applicants are required to undergo a medical examination. The requirement depends on several factors, including the country of origin, length of stay, and type of program being enrolled in. You can check the latest information on the medical examination requirement on the IRCC website.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Medical process</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For Indian students applying for a Canada student visa, the medical examination process typically involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Book appointment:</span> Book a medical examination appointment with a designated medical practitioner (DMP) authorized by Immigration, Refugees and Citizenship Canada (IRCC).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Medical examination:</span> Attend the medical examination and provide a blood and urine sample for laboratory testing. The DMP will also conduct a physical examination and assess any medical conditions.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Results:</span> Wait for the results of the medical examination to be sent to IRCC.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submission:</span> If the medical examination results are positive, submit the medical certificate along with your student visa application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The medical examination is mandatory for some student visa applicants, depending on their country of origin, length of stay, and type of program they are enrolled in. It's best to check the IRCC website for the latest information on the medical examination requirement for your situation.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Health insurance</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Yes, if you are an international student in Canada, you are required to have health insurance coverage for the duration of your study permit. This insurance must meet the minimum coverage requirements set by the province or territory where you will be studying.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining health insurance for a Canadian student visa varies depending on the province or territory you are in. Generally, you can purchase health insurance from a private insurance company, or you can enroll in a government-sponsored health insurance plan if one is available in your province or territory. It is important to research and compare the different insurance options available to you to ensure that you choose a plan that meets your specific needs and budget. Additionally, be sure to obtain the necessary documents and proof of insurance coverage before submitting your study permit application to ensure a smooth process.</p>

                </div>
            </div>
        </div>
    )
}

export default Canada_Medical