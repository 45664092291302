import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const PGDM = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Post Graduate Diploma in Management - Future Dream Makers</title>
                <meta
                    name="description"
                    content="BBA || Bachelor of Business Adminstration"
                />
                <meta
                    name="Keyword"
                    content="PGDM"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-PGDM bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                        Post Graduate Diploma in Management <br>
                        </br>(PGDM)
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>PGDM</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>PGDM is a two-year diploma degree that focuses on delivering practical management expertise and is offered by AICTE-accredited B-Schools. If the college is certified by AIU, the PGDM full form is Postgraduate Diploma in Management, which is comparable to an MBA.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The PGDM programme lasts two years, and candidates must have completed a bachelor’s degree, preferably in management, to be eligible. This course, however, is open to graduates from any field. Entrance tests such as the MAT, CAT, XAT, and others are used to determine PGDM admission.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Because the PGDM Syllabus is market and industry focused, many working professionals and students select this management course. It keeps up with market developments and adjusts its curriculum accordingly.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Students Choose PGDM ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The PGDM Syllabus is meant to help students of many fields define their careers. For their overall growth, students with a Bachelor’s Degree in any discipline with the required percentage can enrol in the PGDM programme.</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className=' pr-8 pt-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>PGDM Is Open For Every Stream:</span> To enrol in MBA courses, students must have graduated from a recognised university with a bachelor’s degree in any field.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Integrated Course:</span> The PGDM is an interdisciplinary course that combines streams such as management, accounting, and economics to address industry needs. Rather than focusing on a single subject, the PGDM programme provides students with a broad range of expertise.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Training Future Managers: </span> The country’s demand for excellent managers is growing by the day. Organizations frequently spend a significant amount of money on training and development in order to fulfil industry requirements. The PGDM programme assists students in aligning their skills with the current needs of industries and markets.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Influence on Current Salary or Job Position:</span> The PGDM education aids aspirants in climbing the pyramid ladder. It provides salary increases, promotions, and CV enhancements, among other things.</p>
                    </div>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Types Of PGDM</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Resulting in an increase in the demand for PGDM courses, students now have a variety of alternatives to pick from among the various types of PGDM courses available. For example, a professional who wishes to study a PGDM degree to advance in his or her career can choose between a PGDM Distance course and a PGDM Online course, depending on his or her needs.</p>
                    {/* table */}
                    <div className='flex justify-center mt-8 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">Types OF PGDM</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">Eligibility</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">Admission</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Full-Time </td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Bachelor’s Degree with 50% marks For IIM 60% marks</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Entrance based</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Part-Time</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Bachelor's Degree	</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Merit Based</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Distance Learning</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Bachelor's Degree	</td>
                                    <td className="text-center text-white text-base font-medium font-serif bg-purple-500 px-3 py-3 border-2 border-white">Merit based</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Full Time MBA:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Working full-time The PGDM is a two-year professional diploma programme that teaches students about industry standards and norms in depth. This is the most common type of PGDM programme. Depending on the college, full-time PGDM fees range from INR 4-24 LPA.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Entrance Exams, either national or university-level, are used to determine</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGDM admissions. CAT, MAT, XAT, SNAP, NMAT, and GMAT are some of the most popular</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGDM entrance exams.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGDM Marketing, PGDM Finance, PGDM in HR, and other full-time PGDM specialisations are available.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Top institutions across the country offer a PGDM programme. Students can study PGDM at IIMs in addition to Top PGDM Colleges in India.</p>
                    </div>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Part Time PGDM</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Online PGDM courses are short-term courses offered by a variety of online platforms that can be extremely helpful in understanding a specific topic or subject. The internet platforms provide a variety of management themes based on current market trends, which can be valuable in advancing one’s professional career.</p>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The student will be well-versed in a variety of disciplines that are not covered in the curriculum.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>There isn’t any requirement for travel time.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The course is self-paced, so students can learn at their own pace.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>When compared to offline classes, the fees are lower, which benefits students financially.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Through trending topics, students can widen their horizons in the field of management and gain a greater understanding of what is going on in the world.</p>
                    </div>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Distance Learning</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A planned education that takes place away from the source of the teacher is referred to as PGDM distance education. The following are some of the benefits of pursuing a PGDM through distance education:</p>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The decreased cost of books is money saved, which may be put to better use elsewhere.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Allows a person to work and study simultaneously.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>While studying PGDM Distance Education, students have a wide range of college possibilities.</p>
                    </div>
                    <div className='flex    px-0 md:ml-0 ml-4 mb-6'>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>It allows a person to stay current on a subject without having to leave his work.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PGDM