import React from 'react'
import { Helmet } from "react-helmet";
const UK_London = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK London - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK London"
                />
                <meta
                    name="Keyword"
                    content="UK London"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>London</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>London is the capital and largest city of England, and one of the largest cities in the United Kingdom. With a population of over 8.9 million people, London is a diverse and cosmopolitan city, known for its rich history, cultural heritage, and world-class attractions.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>London has a number of highly ranked universities, including Imperial College London, University College London (UCL), and King's College London. The average tuition fees for international students in London can range from £15,000 to £25,000 per year, depending on the institution and program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living in London is relatively high compared to other cities in the UK, with the cost of accommodation being one of the most significant expenses. Accommodation options in London include university dorms, private apartments, and shared houses. Living criteria also vary depending on the location and individual preferences.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>London has a thriving economy, with some of the largest companies in the world based in the city. Some of the top companies in London include HSBC, BP, and Deloitte. Job opportunities in London are diverse, with popular job roles including investment banker, management consultant, and software developer. Part-time and full-time job opportunities are available, with many students taking on part-time work while studying. Some top courses in London include finance, law, and business. The top job roles in demand can change depending on the job market, but some in-demand positions currently include data scientists, cybersecurity professionals, and digital marketing specialists.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>London is also a popular tourist destination, known for its iconic landmarks, world-class museums, and rich cultural heritage. Some of the top tourist attractions in London include Buckingham Palace, the Tower of London, Big Ben and the Houses of Parliament, and the London Eye.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_London
