import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Canada = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const Canada_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-1.jpg' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-2.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-3.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-4.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-5.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Canada+logo/Canada_university-logo-6.png" }
    ];

    const Alberta = () => {
        history.push('canada/alberta')
        window.scrollTo(0, 0)
    }
    const BritishColumbia = () => {
        history.push('canada/british-columbia')
        window.scrollTo(0, 0)
    }
    const Manitoba = () => {
        history.push('canada/manitoba')
        window.scrollTo(0, 0)
    }
    const NewBrunswick = () => {
        history.push('canada/new-brunswick')
        window.scrollTo(0, 0)
    }
    const NewfoundlandAndLabrador = () => {
        history.push('canada/newfoundland-and-labrador')
        window.scrollTo(0, 0)
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada"
                />
                <meta
                    name="Keyword"
                    content="Canada"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Canada bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        CANADA
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Canada</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Students from all over the world choose Canada as one of their top educational destinations. However, before choosing on Canada, you should be aware of the many elements of the country.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Geographical Region</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Canada, the world’s second largest country by land area, is located in the northern section of North America. With approximately 9000 kilometres of land border with the United States, Canada has the world’s longest land border. Canada also has a massive coastline, as it is connected to three oceans: the Pacific, Atlantic, and Arctic. Aside from these physical elements, Canada offers a diverse topography of land with mountains, lakes, streams, and water falls, among other things.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Cultural Diversification</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Canada’s culture and languages are discussed. The official languages of Canada are English and French, with Chinese and Punjabi coming in third and fourth, respectively. Since its independence, Canada’s culture has been diverse and inclusive, representing approximately 250 ethnic origins. Because Canada is not linked with any religion, its residents practise a variety of religions.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Canada's Economy</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Economy Canada has an abundance of natural resources, and it produces more energy than the United States. Despite having a population of only 38 million people, Canada has vast resources. As a result, Canada is luring immigrants from all over the world by offering them a variety of benefits, including <span className='text-red-500 '><a href='canada/permanent-resident'>permanent status.</a></span></p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why To Study In Canada ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>With lots of <span className='text-red-500 '><a href='canada/jobs'>job </a></span> opportunities in canada , every person’s life is greatly influenced by their education as well as the <span className='text-red-500 '><a href='canada/scholarship'>scholarships </a></span> their universities provide. As a result, you must use caution while selecting a country for educational purposes. There are a variety of factors to consider, including.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8 '>Structure Of Education</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Because Canada’s educational system is flexible and dynamic, the majority of courses in the country combine theoretical knowledge with practical skills. In addition, Canada is one of the few countries that valued industrial experience as highly as academic qualifications. Aside from that, Canada offers a diverse choice of specialised and general courses that international students can select based on their future objectives.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Canada Admission Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The admission process for Indian students seeking to study in Canada typically involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Research and Choose a Program:</span> Choose a program at a Canadian university or college that aligns with your educational and career goals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meet the Requirements:</span> Make sure you meet the educational, language, and other requirements for the program.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply to the Institution:</span> Submit an application to the institution you have chosen, including all required documents such as transcripts, test scores, and language proficiency results</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain a Study Permit:</span> If accepted, you will need to apply for a study permit, which allows you to study in Canada for the duration of your program.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare for Arrival:</span> Once you have received your study permit, make arrangements for your travel and arrival in Canada, including securing a place to live i.e. <span className='text-red-500'><a href='/canada/accommodation'>accommodation</a></span></p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the admission process and requirements may vary depending on the institution and program, and it is advisable to check with the institution directly for specific details.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8 '>Process to find out better college and university in Canada</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Choosing the best university or college in Canada for your master's degree can be a challenging process, but with the right information and preparation, you can find the perfect fit for your academic and career goals. Here are some steps to help you choose the best institution for you:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Research your options:</span> Start by researching the universities and colleges in Canada that offer programs in your field of interest. Consider factors such as reputation, location, campus culture, and program offerings when making your list of potential institutions.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Review the admission requirements:</span> Make sure you meet the admission requirements for each institution you are considering. These may include language proficiency, academic transcripts, and test scores such as the GRE or GMAT.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Consider cost:</span> Consider the cost of tuition  <span className='text-red-500'><a href='/canada/fee'>(fee)</a></span> and living expenses , as well as any funding opportunities available, when comparing institutions.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Visit the campuses:</span> If possible, visit the institutions you are considering to get a better feel for the campus culture and student life.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Reach out to current students and alumni:</span> Connect with current students and alumni of each institution to get an inside perspective on what it's like to study and live there.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Make your final decision:</span> Based on your research and consideration of the factors above, make your final decision on the best institution for you.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To apply for admission to a <span className='text-red-500'><a href='canada/university'>Canadian university</a></span> or college, you will typically need to submit an online application, along with transcripts, test scores, and any other required documents. You may also be required to pay an application fee. Once you have been accepted, you will need to apply for a study permit to study in Canada.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Types Of Degrees</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Canadian educational model is designed to give students greater options in terms of courses and degree types based on their individual interests.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Under-Graduation Degree</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>An undergraduate degree serves as a stepping stone for a student’s future since it provides them with a specialised field to explore. Undergraduate courses are usually longer than those for other degrees. Students who have completed the 10+2 programme are entitled to apply for an undergraduate degree in their chosen field.</p>
                    <p className='  text-lg font-serif py-1 font-normal  px-8 '>A diploma, which is often a two-year programme, is considered an undergraduate programme. Similarly, an advanced diploma is a three-year undergraduate programme that can be pursued by students who have already earned a diploma in a suitable field. The bachelor’s degree is the longest programme, lasting four years, and students who have finished a diploma in a suitable course can apply for a bachelor’s degree.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of an undergraduate programme can range from 12,000 to 30,000 Canadian dollars, depending on the university and subject.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Masters Degree</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Individuals interested in learning more about a particular subject can enrol in a master’s programme in that discipline or in an inter-disciplinary subject. Master’s programmes are divided into two categories: thesis or research-based master’s programmes and course-based master’s programmes. Master’s degrees usually last one to two years.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of a master’s programme can range from 17,000 to 35,000 CAD, depending on the university and subject.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Post-Graduate Degree</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>These are shorter or equal-length programmes that reference masters but are more skill-oriented. They are intended to provide students with job-specific training so that they can obtain employment after completing the course. These courses take a hybrid approach, combining academic and practical knowledge.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>These courses range in price from 13,000 to 22,000 CAD, depending on the course.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Doctorate</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A doctoral degree is considered the pinnacle of a person’s academic achievements. Students who want to pursue research on a certain subject usually enrol in PhD programmes. Working professionals who want to advance in their careers seek out these courses to improve their resumes.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>These courses range in price from 7,000 to 18,000 CAD, depending on the course.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>VISA Process</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The name of the student visa for Indian students to study in Canada is the "Study Permit". With the visa application process ,visa <span className='text-red-500 '><a href='canada/sop'>SOP</a></span> is also required.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining a Canada student visa involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Acceptance into a designated learning institution in Canada:</span> Before applying for a study permit, you must have received a letter of acceptance from a designated learning institution <span className='text-red-500 '><a href='canada/dli'>(DLI)</a></span> in Canada.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proof of financial support:</span> You need to provide evidence that you have sufficient funds to cover tuition fees, living expenses, and return transportation for yourself and any dependents who will accompany you to Canada. <span className='text-red-500'><a href='canada/gic'>GIC</a></span> is also needed for successful visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Language proficiency:</span> Most Canadian universities require that international students demonstrate English or French language proficiency through a standardized test such as  <span className='text-red-500'><a href='/ielts'>IELTS</a></span> or <span className='text-red-500'><a href='/toefl'>TOEFL</a></span>.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit a study permit application:</span> You can apply for a study permit online or on paper through the Visa Application Centre (VAC) or a Canadian visa office in your home country.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Biometrics collection:</span> You may be required to provide biometric information as part of the application process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for a decision:</span> The processing time for a Canada student visa can vary, but typically takes several weeks to a few months.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend pre-arrival services:</span> If your study permit is approved, you may be able to participate in pre-arrival services offered by the government of Canada to help you prepare for your move to Canada.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>SDS</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>SDS (Student Direct Stream) is a category of study visa in Canada. It is designed to streamline the process for students from select countries who meet specific criteria.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of acceptance from a designated learning institution in Canada</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of funds to support oneself and accompanying family members for the duration of the study program</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A language test score that meets the minimum requirement (IELTS, TEF, or CELPIP)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A valid passport</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of payment of tuition fees for the first year of study</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of having a Guaranteed Investment Certificate (GIC) of CAD $10,000</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A clean criminal record</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SDS category visa is available to citizens of India, China, the Philippines, and Vietnam.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>NON-SDS</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Non-SDS (Non-Student Direct Stream) is a category of study visa in Canada for international students who do not meet the criteria for the SDS (Student Direct Stream) category.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of acceptance from a designated learning institution in Canada</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of funds to support oneself and accompanying family members for the duration of the study program</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A language test score that meets the minimum requirement (IELTS, TEF, or CELPIP)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A valid passport</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of payment of tuition fees for the first year of study</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A clean criminal record</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The processing time for a non-SDS study visa is usually longer than for an SDS visa, and additional documentation may be required</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6'>
                        <button onClick={Alberta} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ALBERTA</button>
                        <button onClick={BritishColumbia} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>BRITISH COLUMBIA</button>
                        <button onClick={Manitoba} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>MANITOBA</button>
                        <button onClick={NewBrunswick} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NEW BRUNSWICK</button>
                        <button onClick={NewfoundlandAndLabrador} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NEWFOUNDLAND AND LABRADOR</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {Canada_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Canada