import React from 'react'
import { Helmet } from "react-helmet";
const Italy_HealthInsurance = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Health Insuracne- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Health Insuracne"
                />
                <meta
                    name="Keyword"
                    content="Italy Health Insuracne"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Health insurance policy</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Health insurance is mandatory for all international students studying in Italy, regardless of their country of origin. The type of health insurance policy required will depend on the length of the student's stay and their country of origin.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For students from within the European Union (EU), a European Health Insurance Card (EHIC) is sufficient. The EHIC allows students to access public healthcare in Italy at reduced costs or for free.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For students from outside the EU, they will need to obtain a private health insurance policy. This policy should cover medical expenses and hospitalization in Italy. The coverage required may vary based on the specific university, but most universities require coverage of at least €30,000.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the health insurance policy must be valid for the entire duration of the student's stay in Italy. It is recommended that students check with their university for specific requirements and obtain the necessary insurance coverage before departing for Italy.</p>
                </div>
            </div>

        </div>
    )
}

export default Italy_HealthInsurance
