import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Commerce_Courses = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Commerce Courses - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Commerce Courses"
                />
                <meta
                    name="Keyword"
                    content="Commerce Courses"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Commerce bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        COMMERCE
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Bachelor’s Options for Commerce Students</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>B.Com</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>B.Com or Bachelor of Commerce is an undergraduate degree course which is pursued by students who want to make a career in the field of commerce and management. It is a three-year course and deals with the various aspects of commerce, including accounting, economics, finance, taxation, and business management. The course focuses on developing analytical, managerial, and problem-solving skills in students. After completing this course, students are eligible for various positions in the field of finance, banking, and other related fields. Students may also choose to specialize in a specific field within business, such as e-commerce, international business, or human resources management</p>
                   
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Master's Options for Commerce Students</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Commerce (M.Com)</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Commerce (M.Com) is a postgraduate degree in commerce and related subjects. The course is designed to provide students with advanced knowledge of commerce, accounting and financial management. Students are expected to gain an in-depth understanding of the field, and be able to apply their knowledge to a variety of business contexts. The course is typically two years in duration and can be taken either full-time or part-time.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>MBA (Finance)</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>An MBA in Finance is a graduate degree program that combines business and finance coursework, giving students the skills to take on leadership roles in the world of finance. Students learn the fundamentals of business such as accounting, finance, marketing, and operations, and then go on to specialize in finance topics such as financial analysis, investment banking, and risk management. MBA in Finance programs typically include coursework in financial management, corporate finance, capital markets, financial statement analysis, and financial modeling. In addition, students may take courses in portfolio management, international finance, and derivatives. </p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Arts in Economics</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Arts in Economics is a two-year program designed to provide students with a comprehensive understanding of economic principles and their application to real-world situations. The degree program offers courses in various aspects of economics, including microeconomics, macroeconomics, econometrics, public finance, international economics, and economic theory. Students in the program gain a thorough knowledge of economic principles, theories, and policies and how they affect the global economy. Additionally, students learn how to use economic models, tools, and techniques to analyze data and evaluate economic policies and trends. Graduates of the program are well-prepared to pursue a variety of careers in the field of economics, including policy analyst, financial analyst, economic consultant, or professor.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Science in Finance</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Science in Finance (MSF) is a graduate degree program designed to develop a student's knowledge and skills in the area of finance. This program focuses on the development of a student's analytical and managerial skills, which are essential for the analysis and management of financial institutions and markets. The program includes courses in financial markets, investments and corporate finance, financial statement analysis, and quantitative methods. It also includes courses in risk management, derivatives, and international finance. The program typically takes two years to complete and requires a capstone project or thesis.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>M.Phil in Commerce </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>M.Phil in Commerce is a postgraduate program that enables a student to specialize in a particular area of commerce. In this program, students gain advanced knowledge in accounting, finance, economics, management, and other related disciplines. It also offers a comprehensive understanding of business research methods, which can be utilized to further advance their research skills. This program is suitable for students who wish to pursue a higher-level academic degree and explore the field of commerce in greater depth.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>PG Diploma in Business Economics</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Post Graduate Diploma in Business Economics (PGDBE) is a specialized diploma program for students who wish to pursue a career in the field of business economics. The program is designed to provide students with the knowledge and skills needed to effectively analyze and interpret economic data and trends, and to apply their understanding of the principles of economics to the business world. Students who complete the program will gain an understanding of the economic environment and its implications on business decisions, as well as the ability to develop and implement successful strategies for managing economic risk. The program is usually two years in length.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of International Business</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of International Business (MIB) is a specialized degree program designed to equip students with the necessary knowledge and skills to become successful in the global business arena. This program is ideal for those who have an interest in pursuing a career in international business or those who want to gain a deeper understanding of international business operations. The curriculum focuses on the development of an international perspective, the management of international operations, and the principles of international marketing. It also covers topics such as international finance, international trade, and global business law. Graduates of this program are prepared for positions in international business, management, finance, and marketing. </p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Business Administration in International Business </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Business Administration in International Business (MBA-IB) is a program that focuses on the development of knowledge and skills related to the management of international business. The program is designed to prepare students for a career in the international business world, offering them a comprehensive understanding of the global economy and the management processes involved in conducting business internationally. The program focuses on topics such as international business strategy, global marketing and communications, international finance, and legal and ethical considerations. The MBA-IB program typically requires two years of full-time study and includes courses in economics, accounting, finance, marketing, and management. Students also learn about international trade, global financial markets, and the legal, political, and cultural issues that affect international business. During their studies, students may choose to specialize in areas such as international marketing, operations, and logistics. The MBA-IB program is designed to prepare graduates for a variety of roles in international business. Graduates may find employment in multinational corporations, government agencies, international financial institutions, and consulting firms. They may also pursue careers in international banking, international law, international trade, and international human resources. Graduates of the program are well-equipped to manage international business operations and to develop strategies for doing business in an increasingly globalized world.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Business Analytics </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Master of Business Analytics (MBA) is a professional degree designed to prepare graduates to be business analytics professionals, which involves the use of data and analytical methods to inform strategic decision-making. The MBA program typically consists of coursework in data mining, predictive analytics, data warehousing, and data visualization, as well as the development of the skills needed to effectively analyze, interpret, and communicate the insights gleaned from complex data sets. Students in an MBA program are also typically expected to gain a deep understanding of the business environment in which the data will be applied, as well as the ethical and legal implications of data-driven decisions.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Financial Analysis </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Financial Analysis (MFA) is a graduate-level degree program that focuses on the analysis of financial markets, instruments, and trends. Students learn how to evaluate companies, assess investments, and manage risk. The program provides a comprehensive understanding of the financial markets, from macroeconomics to microeconomics, from quantitative analysis to financial accounting. Students can learn to identify market opportunities and develop strategies to capitalize on them. The program also prepares individuals for positions in finance, banking, and financial services.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Professional Accounting </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Professional Accounting (MPA) is a postgraduate degree program designed to prepare students for professional careers in accounting. It provides a comprehensive education in accounting, auditing, taxation and management. The program includes training in financial statement analysis, accounting systems, auditing procedures, and business law. The program also includes courses in corporate finance, business economics, and financial management. Students who complete the program are well-prepared for careers as accountants, auditors, or financial analysts.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master of Taxation</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Master of Taxation (MTax) is an advanced professional degree in taxation. It is a specialized postgraduate degree program offered by many universities. It is designed to prepare students to become knowledgeable tax professionals who can help individuals, corporations, and other entities with their tax matters. The program typically includes a combination of coursework in taxation topics such as corporate taxation, international taxation, estate planning, and accounting and auditing. Students may also be required to complete a capstone project or research paper in order to demonstrate mastery of their chosen field of taxation.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Masters in Fin Tech</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>FinTech, or financial technology, is a term used to describe the use of technology to improve and automate financial services and processes. It typically involves the use of innovative technology to provide financial services to consumers and businesses. Examples of FinTech include mobile payments, online banking, and digital currencies. A Master's in FinTech can be a great way to gain the skills and knowledge needed to work in this rapidly expanding field. Master's in FinTech programs typically cover topics such as financial analysis and management, data science and analytics, financial regulation and compliance, and cybersecurity. Students also learn about blockchain, digital payments, and risk management. Upon completion of the program, graduates should be able to analyze, design, and develop financial services that are powered by technology.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Masters in Program Management </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Master's in Program Management is a specialized degree program designed to equip students with the knowledge and skills to effectively manage large, complex projects. The program curriculum focuses on developing project management skills, such as developing project plans, managing resources, monitoring progress and evaluating results. Coursework may also include topics such as project risk assessment, financial analysis, communications and team-building. Graduates of this program are qualified to take on leadership roles in the program management field.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Master in Management</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Master in Management (MIM) is a two-year postgraduate degree program covering a wide range of topics related to management and business. This program provides students with a comprehensive understanding of the various aspects of management and business, such as financial management, organizational behavior, strategy and planning, and marketing. It also provides students with the skills and knowledge necessary to effectively manage teams and organizations. By the end of the program, students will have gained the skills and knowledge necessary to become successful managers, entrepreneurs, and executives.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Masters in Luxury Brand Management</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Master's in Luxury Brand Management is a specialized field of study that focuses on the development and management of luxury brands. This program typically includes topics such as luxury marketing, branding, and positioning, product development, customer service, fashion design, and pricing strategies. Students will learn the necessary skills to create, manage, and enhance the value of luxury brands. The program is designed to equip graduates with the knowledge and skills necessary to create a successful luxury brand in the marketplace.</p>

                </div>
            </div>
        </div>
    )
}

export default Commerce_Courses
