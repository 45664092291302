import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const New_Zealand_EvaluateOffer = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Evaluate Offer - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Evaluate Offer"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Evaluate Offer"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Evaluating the offer</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Evaluating an offer from a university in New Zealand involves considering several factors, including:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Program suitability:</span> International students should consider whether the program aligns with their interests, career goals, and academic background.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Location:</span> International students should consider the location of the university, including its proximity to important amenities, public transportation, and cultural activities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Tuition and living expenses:</span> International students should consider the cost of tuition, living expenses, and any other costs associated with studying in New Zealand, and compare these costs to their financial resources.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Reputation and quality of education:</span> International students should research the reputation and quality of the university and program, and compare it to other universities and programs they are considering.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Career prospects:</span> International students should consider the career prospects and job opportunities that may be available to them upon graduation, and how these prospects align with their career goals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Student support services:</span> International students should consider the student support services offered by the university, including academic and personal support, health services, and opportunities for cultural and social engagement.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: These are common factors, and individual international students may have different priorities and considerations when evaluating an offer from a university in New Zealand. International students are advised to consider their personal circumstances and seek advice from the university and immigration authorities before making any decisions.</p>
                </div>
            </div>
        </div>
    )
}

export default New_Zealand_EvaluateOffer
