import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Italy_Arrival = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Arrival- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Arrival"
                />
                <meta
                    name="Keyword"
                    content="Italy Arrival"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Arrive in Italy</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Arriving in Italy can be an exciting experience for international students, but it can also be overwhelming. Here are some tips to help students make the transition smoothly:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare for customs and immigration:</span> Be sure to have all the necessary documents, including passport, visa, and proof of financial support, ready for presentation to customs and immigration officials upon arrival.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Get to your destination:</span> Depending on the arrival airport, students can take a taxi, train, or bus to reach their final destination. It is recommended to book transportation in advance, especially if arriving late at night.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Find accommodation:</span> It is recommended to have a place to stay arranged before arrival, such as a university residence hall or a rented apartment. If students have not pre-booked accommodation, they can search for temporary options such as hostels or hotels.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Set up bank account:</span> Students may need to open a bank account to manage their finances while in Italy. It is recommended to research and compare options before arriving in Italy.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Familiarize yourself with the local area:</span> Students should take some time to explore the local area, get to know their surroundings, and find important locations such as grocery stores, post office, and public transportation.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Enroll in the university:</span> If students have not already done so, they will need to enroll in their university, complete any necessary paperwork, and pay tuition and fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Get health insurance:</span> Students are required to have health insurance in Italy. If they have not already done so, they should research and compare options and purchase a policy as soon as possible after arrival.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Arriving in a new country can be overwhelming, but by following these steps, international students can make the transition smoothly and start their new life in Italy with confidence.</p>
                </div>
            </div>
        </div>
    )
}

export default Italy_Arrival
