import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Ireland_Accommodation = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Accommodation- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Accommodation"
                />
                <meta
                    name="Keyword"
                    content="Ireland Accommodation"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Book your accommodation</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several ways to book accommodation as an international student in Ireland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>On-Campus Accommodation:</span> Many universities and colleges in Ireland offer on-campus accommodation options for students. These options can range from traditional dormitory-style housing to apartments or studios. To book on-campus accommodation, students should contact the housing office at their university or college.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Off-Campus Accommodation:</span> Students can also find off-campus housing options, such as apartments, houses, or homestays. Websites such as Daft.ie and Rent.ie are popular places to search for off-campus housing in Ireland.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Private Accommodation:</span> Private accommodation providers offer a range of options, including apartments, bed and breakfasts, and homestays. Students can search for private accommodation options on websites such as Booking.com or Airbnb.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>When booking accommodation, international students should consider the location, cost, and type of accommodation that best suits their needs and budget. It is also a good idea to book accommodation in advance to ensure that they have a place to stay when they arrive in Ireland.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Accommodation