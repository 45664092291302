import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
export const USA_Accommodation = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Accommodation - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Accommodation"
                />
                <meta
                    name="Keyword"
                    content="USA Accommodation"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Accomodation</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Accommodation options for international students in the U.S. vary depending on the university and location. Here are some common options:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>On-Campus Housing:</span> Many universities in the U.S. offer on-campus housing options such as dormitories, apartments, or residence halls. These options provide a convenient and supportive living environment, often with amenities such as shared common spaces, dining halls, and laundry facilities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Off-Campus Housing:</span> Off-campus housing options include apartments, houses, and homestays. Off-campus housing may provide more independence and privacy, but it also involves more responsibilities, such as paying rent, utilities, and transportation costs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Homestays:</span> Homestays involve living with a local family and experiencing American culture firsthand. This can be a great way to practice your English and make new friends.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Short-term Accommodation:</span> Some students choose to stay in short-term accommodation such as hotels, motels, or hostels while they search for more permanent housing options.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to research your options and make arrangements well in advance of your arrival in the U.S. Some universities may have specific deadlines for submitting housing applications, so it is important to be aware of these and plan accordingly.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is also advisable to consider factors such as location, cost, and safety when making your accommodation decisions. Your university or local international student office can provide information and support in choosing and securing the best accommodation option for you.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>First time as international student what should I bring as a international student</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>If you are going to the U.S. as a first-time international student, there are several items that you may want to bring with you from India:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Travel Documents:</span> Ensure you have all necessary travel documents, including your passport, visa, I-20 form, and any other required immigration documents.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Clothing:</span> Pack appropriate clothing for the climate and weather in your destination. You may also want to bring some traditional or cultural clothing to wear for special events or ceremonies.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Electronics:</span> Consider bringing items such as a laptop, tablet, smartphone, and adaptors for charging your devices. You may also want to bring a travel adapter for use in the U.S.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Personal items:</span> Bring items such as toiletries, personal hygiene items, and any medications you may need.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Food:</span> If there are specific foods or snacks that you enjoy and are not readily available in the U.S., consider bringing a small supply.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Culture and traditions:</span> Consider bringing items that represent your culture and traditions, such as books, music, or artwork.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Financial information:</span> Bring a list of emergency contacts, bank information, and credit card information, as well as enough cash or travelers checks to cover your initial expenses.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is also a good idea to keep a copy of your passport and other important documents in a safe and secure place, such as a bank deposit box or with a trusted friend or family member. Your university or local international student office may provide additional information on what to bring with you to the U.S.</p>

                    {/* Food */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Food option for indian in USA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As an international student from India, you will have many food options available to you in the U.S. Here are some common options:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Indian Restaurants:</span> There are many Indian restaurants in the U.S. that serve traditional dishes such as chicken tikka masala, biryani, and samosas. You can also find regional specialties from different parts of India.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Ethnic Grocery Stores:</span> Many larger cities in the U.S. have ethnic grocery stores that sell ingredients and spices used in Indian cooking. You can buy ingredients to cook your own meals and create a taste of home.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Dining Halls:</span> If you are living on campus or in student housing, you may have access to a dining hall that serves a variety of meals, including vegetarian and vegan options.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meal Delivery Services:</span> There are several meal delivery services in the U.S. that offer healthy and convenient options, including vegetarian and vegan options.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Cooking at Home:</span> If you have access to a kitchen, you can cook your own meals using ingredients from local grocery stores. This can be a cost-effective and healthy option, and it allows you to create the flavors and dishes you are familiar with.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to remember that food options in the U.S. may be different from what you are used to, and it may take some time to find the options that work best for you. Your university or local international student office may be able to provide additional information and support on finding food options in your area.</p>

                    {/* Sim Card */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Sim for international student in usa</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>"SIM" likely refers to a mobile phone service in the U.S. for international students. Here is the general process for obtaining mobile phone service in the U.S.:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose a Service Provider:</span> : There are several major mobile phone service providers in the U.S., including AT&T, Verizon, T-Mobile, and Sprint. Consider factors such as coverage, price, and customer service when choosing a provider.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Purchase a Phone:</span> You can purchase a new or used phone from a mobile phone provider, retail store, or online. Some providers offer phone financing or leasing options.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Select a Plan:</span> Choose a mobile phone plan that best suits your needs, taking into consideration your budget, data and call requirements. You may need to provide a U.S. mailing address, credit card information, and a valid form of identification.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Activate Your SIM Card:</span> Once you have your phone and plan, you will need to activate your SIM card, which is a small card that fits into your phone and provides a unique identifier for your phone. The process of activation will depend on the provider and type of plan you have chosen.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Set Up Voicemail and Other Services:</span> : Most mobile phone plans include features such as voicemail and text messaging. You may need to set up these services and configure your phone settings.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Stay Within Your Plan:</span> Make sure to monitor your usage and stay within the limits of your plan to avoid additional charges.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to familiarize yourself with the terms and conditions of your mobile phone service agreement and to keep all necessary documentation and receipts in case of issues or disputes. Your mobile phone provider or local international student office may also provide additional information and support on obtaining mobile phone service in the U.S.</p>


                </div>
            </div>
        </div>
    )
}
