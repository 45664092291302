import React from 'react'
import { Helmet } from "react-helmet";
const Flights_Ticket_Student_Visa = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Flights Ticket Student Visa - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Flights Ticket Student Visa"
                />
                <meta
                    name="Keyword"
                    content="Flights Ticket Student Visa"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-FlightTicket bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        Flight Ticket | Student Visa
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Student VISA</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Anyone interested in studying abroad is well aware of the critical event of Student Visa processing. A foreign national who wishes to pursue higher education in a given country must first get a Study Permit/Student Visa from that country.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Future Dream Makers is a Visa Advisory firm with a long history. With over 18 years of experience, we work hard to ensure that our students and their families are completely satisfied. Future Dream Makers offers A to Z care, which means we’ll be there for you every step of the way. Our services also include assistance with putting together required documents such as a Statement of Purpose and Accommodation Assistance.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Visa Officer at the High Commission Embassy issues a Student Visa, which is an official document. A student visa is a temporary document that allows a foreign citizen to study in the country for the duration of their studies. Their stay comes to an end when they receive their diploma, after which they must return to their native country.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>After obtaining a Letter of Acceptance from the university/college to which they have been accepted, individuals can apply for the Student Permit Visa. In this case, the student’s official documents will be the university or college’s Acceptance Letter when applying for a Study Permit through the country’s citizenship and immigration department.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Air Tickets</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Choosing the appropriate airline and flight can be complicated and difficult. Disha Education Consultancy understands the problems of students and their parents. We not only give end-to-end comfort for children by booking flights from their home country to a foreign land or chosen country, but we also relieve parents’ fears by providing travel insurance for unforeseen events.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Assist In Booking Flights</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>We assist students in selecting airlines and purchasing tickets that are appropriate for their needs and budget.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Prepare For Trip</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Based on the most recent travel warning given by their home country and the place they plan to visit, we inform and prepare them for their travels.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Pick-Up And Drop Facilities</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Our students are picked up from the airport and transported to the lodging of their choice in the foreign destination.</p>
                </div>
            </div>
        </div>
    )
}

export default Flights_Ticket_Student_Visa