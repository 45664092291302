import React from 'react'
import { AiOutlineWhatsApp } from 'react-icons/ai'

const Whatsapp = () => {
  return (
    <div className=' fixed  bottom-0 right-0 m-5 bg-green-500 rounded-full z-20'>
      <a href='https://wa.me/+91 87702-62128' target="_blank"><AiOutlineWhatsApp size={60} /></a>
    </div>
  )
}

export default Whatsapp 
