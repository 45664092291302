import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Medical = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Medical - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Medical"
                />
                <meta
                    name="Keyword"
                    content="UK Medical"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>MEDICAL  </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As part of the UK Tier 4 visa application process for international students, Indian applicants may be required to complete a medical examination. The following steps outline the general medical process for Indian students applying for a Tier 4 visa in the UK:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Find an approved clinic:</span> The UK government maintains a list of approved clinics in India where Tier 4 visa applicants can complete their medical examinations. It is important to ensure that the clinic you choose is approved by the UK government.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Schedule an appointment:</span> Once you have found an approved clinic, you will need to schedule an appointment to complete your medical examination. You should schedule your appointment well in advance of your visa application deadline to ensure that you have enough time to complete the process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Complete the medical examination:</span> During your appointment, you will undergo a physical examination, including tests for tuberculosis (TB) and HIV. You may also be required to provide a blood sample and chest X-ray.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive your medical certificate:</span> After completing your medical examination, you will receive a medical certificate that confirms that you have met the medical requirements for a Tier 4 visa. You will need to provide this certificate as part of your visa application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that this is a general overview of the medical process for Indian students applying for a Tier 4 visa in the UK. It is advisable to check the UK government's Tier 4 visa guidance for the most up-to-date information on medical requirements and the process for Indian applicants.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Place where u can take your medical</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The UK government maintains a list of approved clinics in India where Tier 4 visa applicants can complete their medical examinations. Here is a non-exhaustive list of some of the approved clinics, along with their cities:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Dr. Lal PathLabs, Delhi</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>SRL Diagnostics, Mumbai</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Dr. A.G. Diagnostics, Bangalore</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Medall Healthcare, Chennai</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Apollo Diagnostics, Hyderabad</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Metropolis Healthcare, Pune</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Pathcare Laboratories, Kolkata</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Suburban Diagnostics, Ahmedabad</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>This list is subject to change, and it is advisable to check the UK government's Tier 4 visa guidance for the most up-to-date information on approved clinics in India. Additionally, you may also want to check the availability and booking options directly with the clinics before scheduling an appointment.</p>

                </div>
            </div>
        </div>
    )
}

export default UK_Medical