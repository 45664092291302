import React from 'react'
import { useHistory } from "react-router-dom";
import { BsCheckLg } from 'react-icons/bs'
const Ireland = () => {
    const history = useHistory();

    const Blackrock = () => {
        history.push('ireland/blackrock')
        window.scrollTo(0, 0);
    }
    const Cork = () => {
        history.push('ireland/cork')
        window.scrollTo(0, 0);
    }
    const Drogheda = () => {
        history.push('ireland/drogheda')
        window.scrollTo(0, 0);
    }
    const Dublin = () => {
        history.push('ireland/dublin')
        window.scrollTo(0, 0);
    }
    const Dundalk = () => {
        history.push('ireland/dundalk')
        window.scrollTo(0, 0);
    }
    const Dunleary = () => {
        history.push('ireland/dunleary')
        window.scrollTo(0, 0);
    }
    const Ennis = () => {
        history.push('ireland/ennis')
        window.scrollTo(0, 0);
    }
    const Galway = () => {
        history.push('ireland/galway')
        window.scrollTo(0, 0);
    }
    const Limerick = () => {
        history.push('ireland/limerick')
        window.scrollTo(0, 0);
    }
    const Swords = () => {
        history.push('ireland/swords')
        window.scrollTo(0, 0);
    }
    const Tralee = () => {
        history.push('ireland/tralee')
        window.scrollTo(0, 0);
    }
    const Waterford = () => {
        history.push('ireland/waterford')
        window.scrollTo(0, 0);
    }
    const handleClickForPersonalStatement = () => {
        history.push('ireland/admission-requirements')
        window.scrollTo(320, 320)
      }

    return (
        <div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Ireland </p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Ireland is a country located in Europe, known for its rich cultural heritage, beautiful landscapes, and friendly people. The current population of Ireland is 5,080,276. It has a strong economy and is home to several top-ranked universities, making it an attractive destination for international students. Ireland has a rich history, with prehistoric monuments, medieval castles, and Georgian architecture, as well as a vibrant cultural scene, with music, literature, and theater playing a central role in Irish society. Additionally, Ireland is renowned for its stunning natural beauty, with rolling green hills, rugged coastlines, and abundant wildlife. Whether you are interested in exploring its history, culture, or natural beauty, Ireland offers a unique and memorable experience for international students.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Ireland is a popular destination for international students due to its rich cultural heritage, high-quality education, and friendly people</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The country has many universities and colleges that offer a wide range of academic programs, including business, engineering, humanities, and science. Additionally, Ireland has a strong economy and a high standard of living, making it an attractive place to live and study. To study in Ireland, international students typically need to apply for a student visa and demonstrate sufficient financial resources to cover their tuition and living expenses. And after studies individual can go with <span className='text-red-500 '><a href='/ireland/psw'>"PSW"</a></span></p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Ireland Admission Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are the steps for international students to apply for higher education in Ireland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose a course and a university/college:</span> Research and compare universities/colleges in Ireland and their courses to find the best fit for you.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check the admission requirements:</span> Each university/college has different admission requirements, such as language proficiency, academic qualifications, and portfolio submissions. Make sure you fulfill these requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply online:</span> Most universities/colleges in Ireland have an online application system, where you can submit your personal and academic information, along with supporting documents such as transcripts, reference letters, <span onClick={handleClickForPersonalStatement} className='text-red-500 '>personal statement</span> , language test scores and <span className='text-red-500 '><a href='ireland/admission-requirements'>admission requirements</a></span>.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pay the application fee:</span> Most universities/colleges in Ireland require an application fee, which can vary depending on the institution and the course.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for the admission decision:</span> After submitting your application, you will receive a decision from the university/college on whether you have been accepted or not.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for a student visa:</span> If you have been accepted, you will need to apply for a student visa from the Irish embassy in your home country. You will need to provide supporting documents, such as proof of <span className='text-red-500 '><a href='ireland/financial-support'>financial support</a></span> , <span className='text-red-500 '><a href='ireland/medical-insurance'>medical insurance </a></span>, and evidence of English language proficiency.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Book your accommodation:</span> Once you have received your student visa, you can book your <span className='text-red-500 '><a href='/ireland/accommodation'>accommodation</a></span> in Ireland. You can choose between on-campus housing or private accommodation.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Plan your travel:</span> Finally, you can <span className='text-red-500 '><a href='ireland/plan-your-travel'>plan your travel</a></span> to Ireland and make arrangements for your arrival, such as booking your flights and arranging transportation from the airport to your accommodation.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>VISA Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A student visa is a type of visa that allows individuals to enter a country for the purpose of studying. In Ireland, the student visa is known as a "Student Visa" and is suitable for students who will be studying in Ireland for more than 6 months. This visa allows you to stay in Ireland for the duration of your course and also provides permission to work part-time during your studies.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To apply for a student visa in Ireland, you will typically need to provide evidence of your acceptance onto a full-time course of study at an Irish educational institution, proof of sufficient funds to support yourself during your studies, and a valid passport. You may also need to provide additional documents such as academic transcripts, English language proficiency test results, and a police clearance certificate, depending on your individual circumstances.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to check the specific requirements and guidelines of the Irish Naturalisation and Immigration Service before applying for a student visa, to ensure that you are eligible and have all the necessary documentation.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To apply for a student visa in Ireland, you need to follow these steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Determine your visa type:</span> Depending on the length of your course and the type of institution you will be attending, you may need to apply for a different type of visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather the required documents:</span> You will need to provide a range of documents to support your visa application, including proof of enrolment in your course, evidence of your financial means (<span className='text-red-500 '><a href='ireland/funds'>funds</a></span>), and proof of your English language proficiency.</p>
                    </div>

                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit the application:</span> You will need to complete the visa application form and submit it along with the required documents and the visa fee. This can usually be done online or in person at a visa application center.</p>
                    </div>

                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend a visa interview:</span> Depending on your circumstances, you may need to attend an interview to discuss your visa application.</p>
                    </div>

                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for a decision:</span> : Once you have submitted your visa application, you will need to wait for a decision. Processing times can vary, so it is important to apply well in advance of your intended travel date.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Here is a list of some of the documents you may need to provide when applying for a student visa in Ireland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Valid passport or travel document</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of enrolment in your course</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Evidence of financial means, such as bank statements</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of English language proficiency</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A recent passport-sized photograph</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Proof of accommodation in Ireland</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>A covering letter explaining your circumstances and the reason for your visa application</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Other documents as requested by the Irish Naturalisation and Immigration Service</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>In Ireland, there are two main types of student visas:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Short-Term Study Visa:</span> This visa is for students who will be studying in Ireland for less than 6 months. It is suitable for students who are taking a language course or a short course of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>PStudent Visa:</span> This visa is for students who will be studying in Ireland for more than 6 months. It allows you to stay in Ireland for the duration of your course and also provides permission to work part-time during your studies.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to choose the right type of visa based on your individual circumstances, as the requirements and conditions of each type of visa may vary. Before applying for a student visa, you should check the specific requirements and guidelines of the Irish Naturalisation and Immigration Service to ensure that you are eligible.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6 '>
                        <button onClick={Dublin} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Dublin</button>
                        <button onClick={Cork} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Cork</button>
                        <button onClick={Galway} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Galway</button>
                        <button onClick={Limerick} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Limerick</button>
                        <button onClick={Waterford} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Waterford</button>
                        <button onClick={Drogheda} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Drogheda</button>
                        <button onClick={Dundalk} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Dundalk</button>
                        <button onClick={Swords} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Swords</button>
                        <button onClick={Blackrock} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Blackrock</button>
                        <button onClick={Tralee} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Tralee</button>
                        <button onClick={Ennis} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Ennis</button>
                        <button onClick={Dunleary} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Dunleary</button>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Ireland