import React, { useState } from 'react'
import { AiOutlineCloseCircle, AiOutlineMenu } from 'react-icons/ai'
import "./CSS.css"
const Navbar = () => {
    const [nav, setNav] = useState(true)
    const [program, setProgram] = useState("none")
    const [studyIndia, setStudyIndia] = useState("none")
    const [studyAbroad, setStudyAbroad] = useState("none")
    const [section, setSection] = useState("none")
    const [course, setCourse] = useState("none")
    const handleNav = () => {
        setNav(!nav)
    }

    function handletoggleForProgramSection() {
        var x = document.getElementById("program");
        if (program === "none") {
            x.style.display = "block";
            setProgram("block")
        } else {
            x.style.display = "none";
            setProgram("none")
        }
    }

    function handletoggleForIndiaSection() {
        var x = document.getElementById("study_india");
        if (studyIndia === "none") {
            x.style.display = "block";
            setStudyIndia("block")
        } else {
            x.style.display = "none";
            setStudyIndia("none")

        }
    }

    function handletoggleForAbroadSection() {
        var x = document.getElementById("study_abroad");
        if (studyAbroad === "none") {
            x.style.display = "block";
            setStudyAbroad("block")
        } else {
            x.style.display = "none";
            setStudyAbroad("none")
        }
    }

    function handletoggleForSection() {
        var x = document.getElementById("section");
        if (section === "none") {
            x.style.display = "block";
            setSection("block")
        } else {
            x.style.display = "none";
            setSection("none")
        }
    }


    function handletoggleForCourses() {
        var x = document.getElementById("course");
        if (course === "none") {
            x.style.display = "block";
            setCourse("block")
        } else {
            x.style.display = "none";
            setCourse("none")
        }
    }


    return (
        <nav className='bg-white '>
            <div className='flex items-center font-medium justify-between'>
                <div className='py-8 px-5 2xl:ml-8 lg:block hidden'>
                    <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/FDM-Logo.png" alt="FDM logo" className='md:cursor-pointer md:h-20 h-12' onClick={() => window.open('http://futuredreammakers.com/', '_blank')}></img>
                </div>
                <ul className='hidden lg:flex items-center gap-5 xl:gap-6 px-2 mr-12 z-10 font-bold lg:text-[8px] xl:text-[12px] 2xl:text-[15px] align-middle text-black font-serif lg:mr-0 2xl:mr-12 '>
                    <li className='p-1'>
                        <a href='/'>
                            HOME
                        </a>
                    </li>
                    <li className='p-1 '><div class="dropdown inline-block relative  ">
                        <button class=" text-black py-1 rounded inline-flex items-center" >
                            <span class="mr-1 text-black font-bold uppercase ">COURSE</span>
                            <svg
                                viewBox="0 0 24 24"
                                fill="#FF0000"
                                height="1em"
                                width="1em"

                            >
                                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu hidden pt-1 box-content fixed top-35 right-60 left-10 pl-72 " >
                            <div className=' bg-white grid gap-2 md:grid-cols-4 grid-cols-1 mt-4 ' >
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-10" href="/commerce-courses">Commerce courses</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-10" href="/engineering-courses">Engineering courses</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-10" href="/arts-courses">Arts courses</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap mb-10" href="/biology-courses">Biology courses</a></li>
                            </div>
                        </ul>
                    </div>
                    </li>
                    <li className='p-1 '><div class="dropdown inline-block relative  ">
                        <button class=" text-black py-1 rounded inline-flex items-center" >
                            <span class="mr-1 text-black font-bold uppercase ">PROGRAM</span>
                            <svg
                                viewBox="0 0 24 24"
                                fill="#FF0000"
                                height="1em"
                                width="1em"

                            >
                                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu hidden pt-1 box-content fixed top-35 right-60 left-10 pl-72 " >
                            <div className=' bg-white grid gap-2 md:grid-cols-4 grid-cols-1 mt-4 ' >
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/ielts">IELTS</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/gmat">GMAT</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/cmat">CMAT</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/gre">GRE</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/pte">PTE</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/sat">SAT</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/xat">XAT</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/mpsc">MPSC</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/toefl">TOEFL</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/cat">CAT</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-12 " href="/upsc">UPSC</a></li>
                            </div>
                        </ul>
                    </div>

                    </li>
                    <li className='p-1 '><div class="dropdown inline-block relative  ">
                        <button class=" text-black py-1 rounded inline-flex items-center">
                            <span class="mr-1 text-black font-bold uppercase ">STUDY INDIA</span>
                            <svg
                                viewBox="0 0 24 24"
                                fill="#FF0000"
                                height="1em"
                                width="1em"
                            >
                                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu hidden pt-1 box-content fixed top-35 right-10 left-10 px-48 pl-72 ">
                            <div className=' bg-white grid gap-2 md:grid-cols-4 grid-cols-1 mt-4 '>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-business-administration-bba">Bachelor of Business Adminstration (BBA)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-management-studies-bms">Bachelor of Management Studies (BMS)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/masters-in-business-administration-mba">Masters of Business Administration (MBA)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/post-graduate-diploma-in-management-pgdm">Post Graduate Diploma in Management (PGDM)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-commerce-b-com">Bachelors of Commerce (BCOM)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-8 " href="/journalism-and-mass-communication">Bachelor of Arts in Journalism and Mass Communication</a></li>
                            </div>
                        </ul>
                    </div>
                    </li>
                    <li className='p-1 '><div class="dropdown inline-block relative  ">
                        <button class=" text-black py-1 rounded inline-flex items-center">
                            <span class="mr-1 text-black font-bold uppercase ">STUDY ABROAD</span>
                            <svg
                                viewBox="0 0 24 24"
                                fill="#FF0000"
                                height="1em"
                                width="1em"
                            >
                                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu hidden pt-1 box-content fixed right-10 left-10 px-48 pl-72 ">
                            <div className=' bg-white grid gap-2 md:grid-cols-4 grid-cols-1 mt-4 '>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/united-state-of-america">United State of America</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/australia">Australia</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/germany">Germany</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/new-zealand">New Zealand</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/united-kingdom">United Kingdom</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/canada">Canada</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-8 " href="/italy">Italy</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap mb-8 " href="/ireland">Ireland</a></li>
                            </div>
                        </ul>
                    </div>

                    </li>
                    <li className='p-1 '><div class="dropdown inline-block relative  ">
                        <button class=" text-black py-1 rounded inline-flex items-center">
                            <span class="mr-1 text-black font-bold uppercase ">SERVICES</span>
                            <svg
                                viewBox="0 0 24 24"
                                fill="#FF0000"
                                height="1em"
                                width="1em"
                            >
                                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu hidden pt-1 box-content fixed right-10 left-10 px-48 pl-72 ">
                            <div className=' bg-white grid gap-2 md:grid-cols-4 grid-cols-1 mt-4 '>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/education-loan">Education loan</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/accommodation">Accommodation</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/letter-of-recommendation">Letter of Recommendation</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/professional-resume">Professional Resume</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/flight-tickets-students-visa">Flight Ticket | Visa Document</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/statement-of-purpose">Statement of Purpose</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/career-counselling">Career Counselling</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/pr-counselling">PR Counselling</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/currency-exchange">Currency Exchange</a></li>
                                <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/visa-counselling">Visa Counselling</a></li>
                            </div>
                        </ul>
                    </div>
                    </li>
                    <li className='p-1'>
                        <a href='blogs'>
                            BLOGS
                        </a>
                    </li>
                    <li className='p-1'>
                        <a href='about'>
                            ABOUT US
                        </a>
                    </li>
                    <li className='p-1'>
                        <a href='contact'>
                            CONTACT US
                        </a>
                    </li>

                </ul>

                {/* Mobile devices */}
                <div className='fixed top-0 bg-white py-4 w-full z-10'>
                    <div className=' lg:hidden px-4 block  flex justify-between gap-10   '>
                        <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/FDM-Logo.png" alt="FDM logo" className='md:cursor-pointer md:h-20 h-12' onClick={() => window.open('http://futuredreammakers.com/', '_blank')}></img> <span onClick={handleNav}> {!nav ? <AiOutlineCloseCircle size={40} /> : <AiOutlineMenu size={30} />} </span>
                    </div>
                </div>

                <div id='toggle' className={!nav ? '  overflow-y-auto z-10 fixed left-0 top-0 w-[85%] md:w-[50%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 ' : 'fixed left-[-100%]  '} >
                    <img src="https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/FDM-Logo.png" alt="FDM logo" className='md:cursor-pointer h-20 px-0.5 py-2 m-6' onClick={() => window.open('http://futuredreammakers.com/', '_blank')}></img>
                    <ul className='p-4 uppercase font-bold text-black font-serif overflow-y-auto '>
                        <li className='p-2.5 border-b border-gray-600'>
                            <a href='/'>
                                HOME
                            </a>
                        </li>
                        <li className='p-1 border-b border-gray-600 '><div class="dropdown inline-block relative  ">
                            <button class=" text-black py-1 px-2 rounded inline-flex items-center" onClick={handletoggleForCourses} >
                                <span class="mr-1 text-black font-bold uppercase ">COURSE</span>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#FF0000"
                                    height="1em"
                                    width="1em"

                                >
                                    <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                                </svg>
                            </button>
                            <ul class="dropdown-menu hidden pt-1 box-content" id='course' >
                                <div className=' bg-white grid md:grid-cols-4 grid-cols-1' >
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/commerce-courses">Commerce courses</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/engineering-courses">Engineering courses</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/arts-courses">Arts courses</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/biology-courses">Biology courses</a></li>
                                </div>
                            </ul>
                        </div>
                        </li>
                        <li className='p-1 border-b border-gray-600 '><div class="dropdown inline-block relative  ">
                            <button class=" text-black py-1 px-2 rounded inline-flex items-center" onClick={handletoggleForProgramSection}>
                                <span class="mr-1 text-black font-bold uppercase " >PROGRAM</span>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#FF0000"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                                </svg>
                            </button>
                            <ul class=" dropdown-menu hidden pt-1 box-content">
                                <div className=' bg-white grid md:grid-cols-4 grid-cols-1' id='program'>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/ielts">IELTS</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/gmat">GMAT</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/cmat">CMAT</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/gre">GRE</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/pte">PTE</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/sat">SAT</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/xat">XAT</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/mpsc">MPSC</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/toefl">TOEFL</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/cat">CAT</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/upsc">UPSC</a></li>
                                </div>
                            </ul>
                        </div>

                        </li>
                        <li className='p-1 border-b border-gray-600'><div class="dropdown inline-block relative  ">
                            <button class=" text-black py-1 px-2 rounded inline-flex items-center" onClick={handletoggleForIndiaSection}>
                                <span class="mr-1 text-black font-bold uppercase ">STUDY INDIA</span>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#FF0000"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                                </svg>
                            </button>
                            <ul class="dropdown-menu hidden pt-1 box-content ">
                                <div className=' bg-white grid md:grid-cols-3 grid-cols-1 ' id='study_india'>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-business-administration-bba">Bachelor of Business Adminstration (BBA)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-management-studies-bms">Bachelor of Management Studies (BMS)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/masters-in-business-administration-mba">Masters of Business Administration (MBA)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/post-graduate-diploma-in-management-pgdm">Post Graduate Diploma in Management (PGDM)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-commerce-b-com">Bachelors of Commerce (BCOM)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/journalism-and-mass-communication">Bachelor of Arts in Journalism and Mass Communication</a></li>
                                </div>
                            </ul>
                        </div>
                        </li>
                        <li className='p-1 border-b border-gray-600 '><div class="dropdown inline-block relative  ">
                            <button class=" text-black py-1 px-2 rounded inline-flex items-center" onClick={handletoggleForAbroadSection}>
                                <span class="mr-1 text-black font-bold uppercase ">STUDY ABROAD</span>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#FF0000"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                                </svg>
                            </button>
                            <ul class="dropdown-menu hidden pt-1 box-content ">
                                <div className=' bg-white grid md:grid-cols-4 grid-cols-1' id='study_abroad'>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/united-state-of-america">United State of America</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/australia">Australia</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/germany">Germany</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/new-zealand">New Zealand</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/united-kingdom">United Kingdom</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/canada">Canada</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/italy">Italy</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/ireland">Ireland</a></li>
                                </div>
                            </ul>
                        </div>

                        </li>
                        <li className='p-1 border-b border-gray-600 '><div class="dropdown inline-block relative  ">
                            <button class=" text-black py-1 px-2 rounded inline-flex items-center" onClick={handletoggleForSection}>
                                <span class="mr-1 text-black font-bold uppercase ">SERVICES</span>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#FF0000"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
                                </svg>
                            </button>
                            <ul class="dropdown-menu hidden pt-1 box-content ">
                                <div className=' bg-white grid md:grid-cols-4 grid-cols-1' id='section'>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-t py-2 px-4 block whitespace-no-wrap" href="/education-loan">Education loan</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/accommodation">Accommodation</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/letter-of-recommendation">Letter of Recommendation</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/professional-resume">Professional Resume</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/flight-tickets-students-visa">Flight Ticket | Visa Document</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/statement-of-purpose">Statement of Purpose</a></li>
                                    <li class=""><a class=" bg-white text-black hover:text-red-500 rounded-b py-2 px-4 block whitespace-no-wrap" href="/career-counselling">Career Counselling</a></li>
                                </div>
                            </ul>
                        </div>
                        </li>
                        <li className='p-2.5 border-b border-gray-600'>
                            <a href='blogs'>
                                BLOGS
                            </a>
                        </li>
                        <li className='p-2.5 border-b border-gray-600'>
                            <a href='about'>
                                ABOUT US
                            </a>
                        </li>
                        <li className='p-2.5 border-b border-gray-600'>
                            <a href='contact'>
                                CONTACT US
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>
    )
}

export default Navbar
