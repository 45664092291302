import React from 'react'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
const Blogs = () => {
  const history = useHistory();
  const handleClickForBlog2 = () => {
    history.push('/blogs/uk-course-difference')
    window.scrollTo(0, 0);
  }

  const IELTS =() =>{
    history.push('/blogs/uk-course-difference')
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Future Dream Makers</title>
        <meta
          name="description"
          content="Blogs"
        />
        <meta
          name="Keyword"
          content="Blogs"
        />
      </Helmet>
      <div className='flex justify-center py-4 gap-6 md:flex-row flex-col 2xl:px-64 px-8 md:mt-0 mt-20'>

        <div className='border-4 border-black rounded-2xl hover:shadow-2xl shadow-black  md:w-[125%]'>
          <div className='w-[35%] py-1 m-auto mt-4'>
            <img src='https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/blog-1.jpg' alt='IELTS' className=''></img>
          </div>
          <p className='text-red-500 text-2xl py-2 text-left font-serif font-semibold px-2'>Ielts online test not acceptable by some of the UK universities</p>
          <p className='py-2 px-2 text-justify font-serif text-base font-medium '>IELTS Online is widely recognised by universities and other education providers. Before you book, please contact the organisation you’re applying to, to confirm that they accept the type of test you are choosing. IELTS Online is not accepted by immigration authorities.
            This is pertaining to updated FAQs by IELTS which now states that is it not acceptable for UKVI purposes.</p>
            <div className='w-[26%] m-auto'><button onClick={handleClickForBlog2} className=' hover:shadow-2xl shadow-black text-black font-serif bg-red-500 rounded-full md:px-2 py-1 mt-2 mb-2 '><a href='https://www.britishcouncil.it/en/exam/ielts/dates-fees-locations/ielts-online' target="_blank">Read More</a></button></div>
        </div>

        <div className='border-4 border-black rounded-2xl hover:shadow-2xl shadow-black  md:w-[125%]'>
          <div className='w-[35%] py-1 m-auto mt-4'>
            <img src='https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/blog-2.jpg' alt='UK' className=''></img>
          </div>
          <p className='text-red-500 text-2xl py-2 text-left font-serif font-semibold mt-6 px-2'>Difference between Premaster’s, Master’s, Pathway and Foundaton Courses in UK</p>
          <p className='py-2 px-2 text-justify font-serif text-base font-medium '>In the UK, there are several different types of courses that students can take prior to pursuing a master's degree. Above are the type of courses UK universities provide.</p>
            <div className='w-[26%] m-auto'><button onClick={IELTS} className=' hover:shadow-2xl shadow-black text-black font-serif bg-red-500 rounded-full md:px-2 py-1 mt-2 mb-2 '>Read More</button></div>
        </div>
      </div>
    </div>
  )
}

export default Blogs