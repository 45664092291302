import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Australia_Fee = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Fee - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Fee"
                />
                <meta
                    name="Keyword"
                    content="Australia Fee"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col lg:mt-0 mt-16 mb-6 2xl:px-64'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Tution Fees</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The tuition fees for international students in Australia can vary widely depending on the program of study, the university, and other factors. Here is a general guide to the minimum and maximum tuition fees for undergraduate and postgraduate programs for Indian students in Australia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Undergraduate (Bachelor's degree) Programs:</span> The minimum tuition fee for an undergraduate program in Australia is typically around AUD 20,000 to AUD 25,000 (approximately USD 14,000 to USD 17,500) per year, while the maximum fee can reach up to AUD 40,000 (approximately USD 28,000) or more per year.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Postgraduate (Master's degree) Programs:</span> The minimum tuition fee for a postgraduate program in Australia is typically around AUD 25,000 to AUD 30,000 (approximately USD 17,500 to USD 21,000) per year, while the maximum fee can reach up to AUD 60,000 (approximately USD 42,000) or more per year.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these are general estimates and the actual tuition fees for a program will depend on the specific university and program of study. Additionally, the cost of living in Australia, including accommodation, food, and other expenses, should also be taken into consideration when planning the budget for an international student. International students are encouraged to research the cost of tuition and living expenses for the specific program and university they are considering carefully.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Fee