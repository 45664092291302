import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Scholarship = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Scholarships - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Scholarships"
                />
                <meta
                    name="Keyword"
                    content="UK Scholarships"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Scholarship </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several scholarships available for Indian students who want to study in the United Kingdom. Some of the most popular scholarships include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Chevening Scholarships:</span> This scholarship program is funded by the UK government and offers awards to international students, including Indians, to study a one-year Master's degree in any subject at any UK university. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Commonwealth Scholarships:</span> This scholarship program is funded by the UK government and offers awards to international students, including Indians, to study a one-year Master's degree in a selected range of subjects at any UK university.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>British Council India Scholarships:</span> This scholarship program is funded by the British Council and offers awards to Indian students to study a one-year Master's degree in the United Kingdom.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>British High Commission Scholarships:</span> This scholarship program is funded by the British High Commission in India and offers awards to Indian students to study a one-year Master's degree in the United Kingdom.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University-specific scholarships:</span> Many UK universities also offer scholarships specifically for international students, including Indians. Some universities may have their own scholarship programs, while others may offer scholarships through external organizations or funding bodies.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that these are just a few examples of the scholarships available for Indian students in the United Kingdom. It is advisable to check the websites of the relevant organizations and universities for the most up-to-date information on scholarships, eligibility criteria, and application processes.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Universities scholarship</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Many UK universities offer scholarships for Indian students, either through their own internal scholarship programs or through external organizations and funding bodies. Here are a few examples:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Edinburgh:</span> The Edinburgh India Scholarship offers funding to Indian students who have applied to study a full-time Master's degree at the University of Edinburgh. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Cambridge:</span> The Cambridge Commonwealth Trust Scholarships offer funding to students from developing countries, including India, to study a Master's degree at the University of Cambridge</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University College London (UCL):</span> The UCL South Asian Scholarships offer funding to Indian students to study a Master's degree at UCL.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Imperial College London:</span> The Imperial India Masters Scholarships offer funding to Indian students to study a Master's degree at Imperial College London.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>King's College London:</span> The King's India Scholarships offer funding to Indian students to study a Master's degree at King's College London.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UK_Scholarship