import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_NewfoundAndLabrador = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Newfound and Labreador - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Newfound and Labreador"
                />
                <meta
                    name="Keyword"
                    content="Canada Newfound and Labreador"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Newfound and Labreador</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Newfoundland and Labrador form the most easterly province of Canada. On Newfoundland island, the Norse archaeological site L'Anse aux Meadows is the reputed settlement of Viking explorer Leif Erikson. Gros Morne National Park, on the Gulf of St Lawrence, has cliffs, waterfalls and glacial fjords. Southeastern capital city St. John’s is known for the 17th-century Signal Hill citadel, with a hillside walking trail. The population of new foundland and Labrador is 5.22 lakhs (2019).Gross domestic product (GDP): $33.2 billion. capital is St. John's.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Newfound and Labreador:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Memorial University of Newfoundland:</span> Founded in 1925, Memorial University of Newfoundland is a non-profit public higher-education institution located in the urban setting of the small city of St John’s (population range of 50,000-249,999 inhabitants), Newfoundland and Labrador. This institution also has branch campuses in the following locations: Corner Brook, Harlow. Officially recognized by the Department of Advanced Education, Skills and Labour of Newfoundland & Labrador, Memorial University of Newfoundland (MUN) is a large (university Rank enrolment range: 15,000-19,999 students) coeducational Canadian higher education institution. Memorial University of Newfoundland (MUN) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 97 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>College of the North Atlantic:</span> Founded in 1997, College of the North Atlantic is a non-profit public higher-education institution located in the medium town of Stephenville (population range of 2,500-9,999 inhabitants), Newfoundland and Labrador. This institution also has branch campuses in the following locations: Baie Verte, Bonavista, Burin, Carbonear, Clarenville, Corner Brook, Gander, Grand Falls-Windsor, Happy Valley-Goose Bay, Labrador West, Placentia, Port aux Basques, St. John's, Conception Bay South, St. Anthony, Doha (Qatar). Officially recognized by the Department of Advanced Education.</p>
                    </div>
                    </div>
                    </div>
    </div>
  )
}

export default Canada_NewfoundAndLabrador
