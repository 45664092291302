import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Australia_TopCourses = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Top Courses - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Top Courses"
                />
                <meta
                    name="Keyword"
                    content="Australia Top Courses"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Top Courses</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Australia is known for its high-quality education system and offers a wide range of courses for international students across various disciplines. Here are some of the top courses for international students in Australia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Engineering:</span> Engineering is a popular field of study for international students in Australia, with universities offering programs in areas such as civil engineering, mechanical engineering, electrical engineering, and more.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Business:</span> Business and management courses are also highly sought after by international students, including programs in accounting, finance, marketing, and more.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Information Technology:</span> The information technology sector is growing rapidly in Australia, and there are many programs available in areas such as computer science, software engineering, data analytics, and more.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Medicine and Healthcare:</span> Australia has a strong reputation for its healthcare system, and programs in medicine, nursing, and other healthcare-related fields are highly sought after by international students.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Environmental Science and Sustainability:</span> Australia is known for its commitment to environmental protection, and there are many programs available in areas such as environmental science, renewable energy, and sustainable development.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Humanities and Social Sciences:</span> Australia is home to many top universities that offer programs in areas such as history, sociology, psychology, and more.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Education:</span> Australia is also known for its strong education system, and there are many programs available for those interested in becoming teachers or working in the education sector.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>These are just a few examples of the many top courses available to international students in Australia. It's important to research and compare programs carefully, taking into consideration personal interests, academic background, and career goals, before making a decision.</p>
                    </div>
                    </div>
    </div>
  )
}

export default Australia_TopCourses
