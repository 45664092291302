import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const GRE = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>GRE - Future Dream Makers</title>
                <meta
                    name="description"
                    content="GRE"
                />
                <meta
                    name="Keyword"
                    content="GRE"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-GRE bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        GRE
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64 '>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is GRE ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Graduate Record Examination (GRE) is a graduate school entrance exam that impacts admissions decisions.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Aspiring graduate students who put off studying for tests should avoid cramming for the Graduate Record Exam, or GRE, because achieving an impressive score on this GRE Test is difficult without significant preparation, according to experts.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The GRE General Test is a standardised test designed and administered by the Educational Testing Service, also known as ETS, to assess overall academic readiness for graduate school. Some graduate programmes require applicants to take not only the general GRE, but also a GRE Subject Test, which evaluates technical knowledge in a specific discipline such as physics, psychology, or mathematics.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>GRE Subject Tests</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GRE subject tests are content-based exams that assess a student’s knowledge of a specific field of study, such as biology or psychology. Each is intended for students who have majored in or extensively studied the exam subject in question. A graduate school applicant may take a math subject test to demonstrate quantitative skills to graduate schools in fields where numerical competence is essential, such as computer science or economics. These are the six fields where a subject test is available:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Biology.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Chemistry.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Literature in English.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Mathematics.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Physics.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Psychology.</p>
                    </div>

                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>For each subject test, ETS provides free digital practice books. The following is a summary of the material that should be understood and studied prior to taking a GRE subject test.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Biology-</span>It focuses on three aspects of biology, each of which serves as the foundation for roughly one-third of the exam’s questions such as cellular and molecular biology, evolution and ecology, and organismal biology.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Chemistry-</span>This exam requires knowledge of all four major categories of chemistry as well as the relationships between them. Analytical chemistry, inorganic chemistry, organic chemistry, and physical chemistry are the four focus areas, with a greater emphasis on the latter two.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>English literature-</span>The exam requires you to demonstrate your literary analysis skills. To pass this test, you must also be able to recognise and accurately identify literary works, contextualise a piece of literature based on its historical or cultural context, and understand the history and theory of literary criticism.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Mathematics-</span>About half of this exam is dedicated to calculus, while the other quarter is dedicated to algebra and number theory. The remaining questions cover a variety of topics that are typically covered in an undergraduate math curriculum.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Physics-</span>Questions on optics and wave phenomena, thermodynamics and statistical mechanics, classical mechanics, electromagnetism, quantum mechanics, atomic physics, special relativity, and laboratory methods are common in this test. It also includes questions on specialised physics topics like nuclear and particle physics, but the specific topics covered vary depending on the exam.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Psychology-</span>This exam covers six areas of psychology: biological, cognitive, social, developmental, clinical, and topics like measurement and methodology.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>GRE Test Scores</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>After successfully completing the GRE general test, a candidate can expect to receive three scores: verbal reasoning, quantitative reasoning, and analytical writing. The verbal and quantitative scores range from 130 to 170 and are always whole numbers.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GRE subject test scores range from 200 to 990. On the biology and psychology subject tests, test takers receive not only an overall score but also several subscores ranging from 20 to 99, which reflect their knowledge of specific topics within the academic discipline.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Graduate school applicants, according to experts, should set their target GRE score based on the competitiveness of the programmes to which they are applying and the highest score they believe they can achieve. To have a positive impact on a prospective graduate student’s admissions chances at his or her target schools, a GRE score must be higher than the norm at those schools.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Cost To Take GRE Test</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The GRE general test costs $205 in most parts of the world, but there are five countries where the cost is higher: $213 in India, $226 in Nigeria, $230 in Australia, $231.30 in China, and $255 in Turkey.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GRE Subject Tests cost $150 in all locations.</p>
                </div>
            </div>
        </div>
    )
}

export default GRE