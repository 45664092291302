import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Arizona = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Arizona - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Arizona"
                />
                <meta
                    name="Keyword"
                    content="USA Arizona"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Arizona</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Each year, Arizona ranks as one of the top states in the United States to host international students. In fact, Arizona State University in Tempe, AZ, ranks as the 19th most populous universities in the country for international students, with an enrollment number of 4, 483 individuals in 2010 Arizona population  is 72.8 lakh .Arizona GDP was 337.52 billion us dollar.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Arizona:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of Arizona:</span> The University of Arizona (Arizona, U of A, UArizona, or UA) is a public land-grant research university in Tucson, Arizona. Founded in 1885 by the 13th Arizona Territorial Legislature, it was the first university in the Arizona Territory.The university is part of the Association of American Universities and the Universities Research Association. In the former, it is the only member from the state of Arizona</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Embry-Riddle Aeronautical University – Prescott:</span> Embry–Riddle Aeronautical University, Prescott is a residential campus of Embry-Riddle Aeronautical University in Prescott, Arizona. The university offers bachelor and master's in arts, sciences, aviation, business, engineering, and security & intelligence. The Prescott campus also offers a master's degree in Safety Science, Security & Intelligence, and Cyber Intelligence & Security.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Roseman University of Health Sciences:</span> Roseman University of Health Sciences is a private university focused on healthcare and located in Henderson, Nevada. It has a second campus in South Jordan, Utah. It was founded by Dr. Harry Rosenberg, enrolled its first class in January 2001, and was originally called the Nevada College of Pharmacy and the University of Southern Nevada</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default USA_Arizona
