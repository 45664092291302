import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Visa_Counselling = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Visa Counselling - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Visa Counselling"
                />
                <meta
                    name="Keyword"
                    content="Visa Counselling"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-VisaCounselling bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
           VISA COUNSELLING
          </p>
        </div>
      </div>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify'>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Visa Counselling</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Visa counseling is the process of providing guidance and assistance to individuals or groups who are seeking to obtain a visa. This can include providing information on the requirements and process for obtaining a visa, helping individuals complete the necessary application forms and documents, and offering advice on how to increase the chances of a successful visa application. Visa counselors may work for government agencies, embassies, or private companies and organizations that specialize in providing visa services. They can also be found in educational institutions, where students and staff may need guidance for visa applications. The goal of visa counseling is to help individuals navigate the complex and often confusing visa application process, and increase the chances of a successful outcome.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The visa counseling process can vary depending on the type of visa being applied for and the country where the application is being made. However, a typical process may include the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Initial assessment:</span> The visa counselor will meet with the individual or group to assess their needs and determine the type of visa that is most appropriate for their situation.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gathering documents:</span> The visa counselor will provide guidance on the documents that are required for the visa application, such as passport, proof of financial support, and evidence of ties to the home country.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Completing the application:</span> The visa counselor will help the individual or group complete the necessary application forms and provide advice on how to answer questions.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Interview preparation:</span> The visa counselor will prepare the individual or group for the visa interview, including providing guidance on what to expect and how to present themselves.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submission of application:</span> The visa counselor will then assist with submitting the visa application and any additional documents that are required.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Follow-up:</span> The visa counselor will follow-up on the status of the visa application, and provide guidance on what to do if the application is denied.</p></div>

                    {/* USA Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>USA visa type and category</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>B-2 Visa:</span> This is a tourist visa, which allows individuals to enter the U.S. for pleasure or tourism purposes.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>F-1 Visa:</span> This is a student visa, which allows individuals to enter the U.S. to attend an accredited college or university</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>H-1B Visa:</span> This is a work visa, which allows individuals to enter the U.S. to work in a specialty occupation, such as technology, engineering, or finance.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>L-1 Visa:</span> This is an intracompany transferee visa, which allows individuals to enter the U.S. to work for a related company in a different country.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>J-1 Visa:</span> This is an exchange visitor visa, which allows individuals to enter the U.S. for cultural exchange programs such as internships, research or training.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>O-1 Visa:</span> This is a visa for individuals with extraordinary ability in the sciences, arts, education, business, or athletics.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>R-1 Visa:</span> This is a religious worker visa, which allows individuals to enter the U.S. to work in a religious capacity.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>EB-1 Visa:</span> This is an employment-based, first preference visa, which allows individuals with extraordinary ability, outstanding professors and researchers, and certain multinational managers and executives to enter the U.S.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>EB-5 Visa:</span> This is an employment-based, fifth preference visa, which allows individuals to enter the U.S. through investment in a new commercial enterprise.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>USA visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The F-1 visa is the student visa for the United States. It allows international students to enter the U.S. to attend an accredited college or university. To be eligible for an F-1 visa, individuals must:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Be enrolled in a full-time program of study at an SEVP-approved school.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Have sufficient financial resources to pay for tuition, living expenses, and other related costs while studying in the U.S.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Prove that they have a residence abroad that they do not intend to abandon, and that they will return home after completing their studies.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Pass an interview and be able to demonstrate that they have a bona fide student status.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Meet all other immigration requirements as per the laws and regulations</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Once an individual has been granted an F-1 visa, they may enter the U.S. up to 30 days before the start of their program of study. They may also apply for an F-2 visa for dependents like spouse and children. The F-1 visa is usually valid for the duration of the student's program of study, plus 60 days after completion to allow the student time to prepare for departure or apply for a practical training program.</p>

                    {/* UK Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>UK visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The United Kingdom has a variety of different visa categories, each with its own specific purpose and requirements. Some of the most common types of visas issued by the UK include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 2 Work Visa:</span> This is a work visa, which allows individuals to enter the UK to work in a skilled occupation that is on the shortage occupation list or for which the individual has been offered a job by an employer with a valid sponsor license.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 4 Student Visa:</span> This is a student visa, which allows individuals to enter the UK to study at a recognized educational institution.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 5 Youth Mobility Visa:</span> This is a visa for young people from participating countries to live and work in the UK for up to 2 years.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 1 Entrepreneur Visa:</span> This is a visa for individuals who want to set up or run a business in the UK.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 1 Investor Visa:</span> This is a visa for high net worth individuals who want to invest at least £2 million in the UK.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Spouse Visa:</span> This is a visa for spouses or partners of UK citizens or settled persons to join them in the UK.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Ancestry Visa:</span> This is a visa for Commonwealth citizens who have a UK-born grandparent and can demonstrate they are able to work in the UK.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 2 Intra-Company Transfer Visa:</span> This is a visa for employees of multinational companies who are being transferred to a UK-based branch of the same company.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tier 5 Charity Worker Visa:</span> This is a visa for individuals who want to come to the UK to do unpaid voluntary work for a charity.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>EEA Family Permit:</span> This is a visa for family members of European Economic Area (EEA) citizens who are exercising their Treaty rights in the UK.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>UK visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining a Tier 4 student visa for the United Kingdom involves several steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Obtain an unconditional offer of a place on a course of study from a recognized educational institution in the UK. The institution must hold a Tier 4 sponsor license.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Complete the online Tier 4 visa application and pay the fee. You will need to provide personal information, passport details, and information about your course and financial means.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Gather all the required documents, including your passport, academic qualifications, and proof of funds to pay for your course and living expenses.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Book and attend a biometric appointment at a visa application center, where you will have your fingerprints and photograph taken.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Wait for a decision on your application. You will be notified of the decision via email.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>If your application is approved, you will receive a visa vignette (sticker) in your passport or a biometric residence permit (BRP) card. You will need to collect your BRP card from the UK within 10 days of your arrival</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>After you arrive in the UK, you must register with the University or college as a Tier 4 student.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>You must also comply with the conditions of your visa, including attending your course and not engaging in any unauthorized work.</p></div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Short term study visa in uk </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The United Kingdom offers a Short-term Study Visa for individuals who want to study in the UK for a short period of time, typically for a course lasting 6 months or less. The process for obtaining a Short-term Study Visa involves several steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Obtain an unconditional offer of a place on a short-term course of study from a recognized educational institution in the UK. The institution must hold a Tier 4 sponsor license.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Complete the online Short-term Study Visa application and pay the fee. You will need to provide personal information, passport details, and information about your course and financial means.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Gather all the required documents, including your passport, academic qualifications, and proof of funds to pay for your course and living expenses.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Book and attend a biometric appointment at a visa application center, where you will have your fingerprints and photograph taken.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Wait for a decision on your application. You will be notified of the decision via email.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>If your application is approved, you will receive a visa vignette (sticker) in your passport or a biometric residence permit (BRP) card. You will need to collect your BRP card from the UK within 10 days of your arrival.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>After you arrive in the UK, you must register with the University or college as a Short-term Study student.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>You must also comply with the conditions of your visa, including attending your course and not engaging in any unauthorized work.</p></div>

                    {/* Canada Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Canada visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Canada offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Temporary Resident Visa (TRV):</span> Allows individuals to visit Canada temporarily for leisure or business purposes. This can include options such as the Visitor Visa, the Super Visa for parents and grandparents, and the Electronic Travel Authorization (eTA) for visa-exempt countries.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Permit:</span> Allows individuals to work in Canada on a temporary basis. This can include options such as the open work permit, the employer-specific work permit, and the post-graduation work permit for international students.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Permit:</span> Allows individuals to study at a Canadian educational institution. This can include options such as the student visa, the co-op work permit, and the post-graduation work permit.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence:</span> Allows individuals to live and work in Canada permanently. This can include options such as the Express Entry Program, the Provincial Nominee Program, the Caregiver Program, and the Family Class sponsorship.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in Canada, such as participating in trade shows or making business deals. This can include options such as the Business Visitor Visa and the Entrepreneur Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tourist Visa:</span> Allows individuals to visit Canada for leisure or business purposes.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Student Visa:</span> Allows individuals to study at a Canadian educational institution.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Working Holiday Visa:</span> Allows individuals aged 18-35 from certain countries to live and work in Canada for up to 24 months.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Temporary Resident Permit:</span> Allows individuals who are inadmissible to Canada on certain grounds to enter or stay in the country temporarily.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Residence from Work:</span> Allows individuals who have been working in Canada for a certain period of time to apply for permanent residence.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Canada visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining a student visa for Canada typically involves several steps. Here is an overview of the general process:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Find a school:</span> Research and select a Canadian educational institution that meets your needs and has been designated by the Canadian government to accept international students.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Meet the institution's admission requirements, including any language proficiency requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Get a letter of acceptance:</span> Once you have been accepted to the institution, you will receive a letter of acceptance. This letter is required to apply for a student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study permit:</span> Submit an application for a study permit through the Government of Canada's online system or at a visa office. You will need to provide several documents, including your letter of acceptance, proof of financial support, and a valid passport.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your study permit is approved, make sure you have all the necessary documents, like valid passport, study permit, letter of acceptance and confirm your travel arrangements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Pay the fee:</span> Pay the required fee for the study permit.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your application. The processing time can vary depending on the location and the time of the year. You should be able to track your application status online.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your study permit is approved, make sure you have all the necessary documents, like valid passport, study permit, letter of acceptance and confirm your travel arrangements.</p></div>

                    {/* Australia Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Australia visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Australia offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tourist Visa:</span> Allows individuals to visit Australia temporarily for leisure or business purposes. This can include options such as the Visitor Visa (subclass 600) and the Electronic Travel Authority (ETA) Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Visa:</span> Allows individuals to work in Australia on a temporary or permanent basis. This can include options such as the Temporary Skill Shortage (TSS) Visa, the Employer Nomination Scheme (ENS) Visa, and the Regional Sponsored Migration Scheme (RSMS) Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Visa:</span> Allows individuals to study at an Australian educational institution. This can include options such as the Student Visa (subclass 500) and the Graduate Temporary (subclass 485) Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence Visa:</span> Allows individuals to live and work in Australia permanently. This can include options such as the Skilled Independent Visa (subclass 189), the Skilled Nominated Visa (subclass 190), and the Employer Sponsored Visa (subclass 186).</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Family Visa:</span> Allows individuals to sponsor their family members to come to Australia.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in Australia, such as participating in trade shows or making business deals.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Retirement Visa:</span> Allows individuals over 55 years old to retire in Australia.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Working Holiday Visa:</span> Allows individuals aged 18-30 from certain countries to live and work in Australia for up to 12 months.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Partnership Visa:</span> Allows partners of Australian citizens or residents to live in Australia.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Residence from Work Visa:</span> Allows individuals who have been living and working in Australia for a certain period of time to apply for residence.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Australia visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining a student visa for Australia typically involves several steps. Here is an overview of the general process:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Find a school:</span> Research and select an Australian educational institution that meets your needs and has been registered by the Australian government to accept international students.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Meet the institution's admission requirements, including any language proficiency requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Get a letter of offer:</span> Once you have been accepted to the institution, you will receive a letter of offer. This letter is required to apply for a student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study visa:</span> Submit an application for a study permit through the Government of Canada's online system or at a visa office. You will need to provide several documents, including your letter of acceptance, proof of financial support, and a valid passport.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Submit an application for a student visa through the Government of Australia's online system. You will need to provide several documents, including your letter of offer, proof of financial support, and a valid passport.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Pay the fee:</span> Pay the required fee for the student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Health Examination:</span> You may be required to complete a health examination and provide evidence of medical insurance.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your application. The processing time can vary depending on the location and the time of the year. You should be able to track your application status online.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your student visa is approved, make sure you have all the necessary documents, such as a valid passport and student visa, and confirm your travel arrangements.</p></div>

                    {/* New-Zealand Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>New Zealand visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>New Zealand offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Visitor Visa:</span> Allows individuals to visit New Zealand temporarily for leisure or business purposes. This can include options such as the Visitor Visa and the Electronic Travel Authority (ETA) Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Visa:</span> Allows individuals to work in New Zealand on a temporary or permanent basis. This can include options such as the Essential Skills Work Visa, the Skilled Migrant Category Visa, the Silver Fern Job Search Visa, and the Talent (Accredited Employer) Work Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Visa:</span> Allows individuals to study at a New Zealand educational institution. This can include options such as the Student Visa and the Post-Study Work Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence Visa:</span> Allows individuals to live and work in New Zealand permanently. This can include options such as the Skilled Migrant Category Visa, the Family of a New Zealand Resident Visa, and the Investor Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in New Zealand, such as participating in trade shows or making business deals. This can include options such as the Entrepreneur Work Visa and the Business Visitor Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Retirement Visa:</span> Allows individuals over 65 years old to live in New Zealand.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Working Holiday Visa:</span> Allows individuals aged 18-30 from certain countries to live and work in New Zealand for up to 12 months.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Partnership Visa:</span> Allows partners of New Zealand citizens or residents to live in New Zealand.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Residence from Work Visa:</span> Allows individuals who have been living and working in New Zealand for a certain period of time to apply for residence.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Parent Retirement Visa:</span> Allows parents of New Zealand citizens or residents to live in New Zealand.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>New Zealand visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for obtaining a student visa for New Zealand typically involves several steps. Here is an overview of the general process:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Find a school:</span> Research and select a New Zealand educational institution that meets your needs and has been registered by the New Zealand government to accept international students</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Meet the institution's admission requirements, including any language proficiency requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Get a letter of offer:</span> Once you have been accepted to the institution, you will receive a letter of offer. This letter is required to apply for a student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study visa:</span> Submit an application for a student visa through the Government of New Zealand's online system. You will need to provide several documents, including your letter of offer, proof of financial support, and a valid passport.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Submit an application for a student visa through the Government of Australia's online system. You will need to provide several documents, including your letter of offer, proof of financial support, and a valid passport.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Pay the fee:</span> Pay the required fee for the student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Health Examination:</span> You may be required to complete a health examination and provide evidence of medical insurance.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your application. The processing time can vary depending on the location and the time of the year. You should be able to track your application status online.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your student visa is approved, make sure you have all the necessary documents, such as a valid passport and student visa, and confirm your travel arrangements.</p></div>

                    {/* Italy Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>New Zealand visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Italy offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tourist Visa:</span> Allows individuals to visit Italy temporarily for leisure or business purposes. This can include options such as the short-stay "C" visa and the long-stay "D" visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Visa:</span> Allows individuals to work in Italy on a temporary or permanent basis. This can include options such as the Work Visa for Highly Qualified Workers, the Work Visa for Seasonal Workers, and the Self-Employment Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Visa:</span> Allows individuals to study at an Italian educational institution. This can include options such as the Study Visa, the Research Visa and the Language Course Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence Visa:</span> Allows individuals to live and work in Italy permanently. This can include options such as the Long-Term Residency Permit, the EU long-term Residence Permit and the Residence permit for family reasons.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in Italy, such as participating in trade shows or making business deals. This can include options such as the Business Visa and the Entrepreneur Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Family Reunion Visa:</span> Allows family members of Italian citizens or residents to join them in Italy.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Dependent/Partner Visa:</span> Allows the dependent or partner of a person who holds a valid Italian employment permit or student visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Humanitarian Visa:</span> Allows individuals who are not able to return to their home country due to war, persecution or a humanitarian crisis to live in Italy.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Retired Person Visa:</span> Allows retired persons to live in Italy.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Residence from Work Visa:</span> Allows individuals who have been working in Italy for a certain period of time to apply for residence.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Italy visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of applying for a student visa in Italy can vary depending on your country of origin and the type of program you are applying for. However, generally, the process involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Choose a program:</span> Research and choose a program at an Italian educational institution that meets your needs and is recognized by the Italian government.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Ensure that you meet the requirements for the program, including any language or academic requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gather the required documents:</span> Collect all the required documents, such as your passport, proof of financial support, and proof of acceptance into the program.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study visa:</span> Complete the online visa application form and pay the required fee.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Attend a visa appointment:</span> Attend a visa appointment at the Italian embassy or consulate in your home country. You may be required to provide biometric information and additional documents.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your visa application. The processing time can vary depending on the type of program and your country of origin.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> ⦁	Prepare for arrival: Once your visa is approved, prepare for your arrival in Italy, including making travel arrangements and arranging for accommodation.</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that student visa requirements and processing times can change, so you should check the Italian Ministry of Foreign Affairs and International Cooperation website for up-to-date information and contact the Italian embassy in your home country for more detailed information.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's also important to note that if you are a citizen of a country outside the EU, you will need to have a valid student visa to study in Italy.</p>

                    {/* Germany Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Germany visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Germany offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tourist Visa:</span> Allows individuals to visit Germany temporarily for leisure or business purposes. This can include options such as the short-stay "C" visa and the long-stay "D" visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Visa:</span> Allows individuals to work in Germany on a temporary or permanent basis. This can include options such as the Work Visa for Highly Qualified Workers, the Work Visa for Skilled Workers, and the Self-Employment Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Visa:</span> Allows individuals to study at a German educational institution. This can include options such as the Study Visa, the Language Course Visa, and the Research Visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence Visa:</span> Allows individuals to live and work in Germany permanently. This can include options such as the Permanent Residence Permit and the EU Blue Card.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in Germany, such as participating in trade shows or making business deals.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Family Reunion Visa:</span> Allows family members of German citizens or residents to join them in Germany.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Dependent/Partner Visa:</span> Allows the dependent or partner of a person who holds a valid German employment permit or student visa.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Humanitarian Visa:</span> Allows individuals who are not able to return to their home country due to war, persecution or a humanitarian crisis to live in Germany.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Retired Person Visa:</span> Allows retired persons to live in Germany.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Jobseeker Visa:</span> Allows individuals to look for a job in Germany for a limited period of time.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Germany visa Steps</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of applying for a student visa in Germany can vary depending on your country of origin and the type of program you are applying for. However, generally, the process involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Choose a program:</span> Research and choose a program at a German educational institution that meets your needs and is recognized by the German government.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Ensure that you meet the requirements for the program, including any language or academic requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gather the required documents:</span> Collect all the required documents, such as your passport, proof of financial support, and proof of acceptance into the program.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study visa:</span> Complete the online visa application form and pay the required fee.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Attend a visa appointment:</span> Attend a visa appointment at the German embassy or consulate in your home country. You may be required to provide biometric information and additional documents.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your visa application. The processing time can vary depending on the type of program and your country of origin.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your visa is approved, prepare for your arrival in Italy, including making travel arrangements and arranging for accommodation.</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that student visa requirements and processing times can change, so you should check the German Federal Foreign Office website for up-to-date information and contact the German embassy in your home country for more detailed information.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's also important to note that if you are a citizen of a country outside the EU, you will need to have a valid student visa to study in Germany.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Additionally, some universities in Germany require an additional residence permit application once you arrive in Germany. The residence permit must be obtained within 90 days of your arrival and needs to be renewed annually.</p>

                    {/* Ireland Section */}
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Ireland visa type and category</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Ireland offers a variety of visa options for individuals seeking to visit, live, work, or study in the country. Some of the most common types of visa include:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Tourist Visa:</span> Allows individuals to visit Ireland temporarily for leisure or business purposes. This can include options such as the short-stay "C" visa and the long-stay "D" visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Work Visa:</span> Allows individuals to work in Ireland on a temporary or permanent basis. This can include options such as the General Employment Permit, the Critical Skills Employment Permit, and the Intra-Company Transfer Permit.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Study Visa:</span> Allows individuals to study at an Irish educational institution. This can include options such as the Student Visa and the Third Level Graduate Scheme.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Permanent Residence Visa:</span> Allows individuals to live and work in Ireland permanently. This can include options such as the Long-Term Residency Permit, the Stamp 4 Residence Permit, and the Irish Citizenship.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Business Visa:</span> Allows individuals to conduct business in Ireland, such as participating in trade shows or making business deals. This can include options such as the Business Visa and the Start-Up Entrepreneur Program.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Family Reunion Visa:</span> Allows family members of Irish citizens or residents to join them in Ireland.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Dependent/Partner Visa:</span> Allows the dependent or partner of a person who holds a valid Irish employment permit or student visa.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Humanitarian Leave to Remain:</span> Allows individuals who are not able to return to their home country due to war, persecution or a humanitarian crisis to live in Ireland.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Retired Person Visa:</span> Allows retired persons to live in Ireland.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Residence from Work Visa:</span> Allows individuals who have been working in Ireland for a certain period of time to apply for residence.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Ireland student visa process</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of applying for a student visa in Ireland can vary depending on the type of program you are applying for and your country of origin. However, generally, the process involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Choose a program:</span> Research and choose a program at an Irish educational institution that meets your needs and is recognized by the Irish government.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the requirements:</span> Ensure that you meet the requirements for the program, including any language or academic requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gather the required documents:</span> Collect all the required documents, such as your passport, proof of financial support, and proof of acceptance into the program.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a study visa:</span> Complete the online visa application form and pay the required fee.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Attend a visa appointment:</span> Attend a visa appointment at the Irish embassy or consulate in your home country. You may be required to provide biometric information and additional documents.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your visa application. The processing time can vary depending on the type of program and your country of origin.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your visa is approved, prepare for your arrival in Ireland, including making travel arrangements and arranging for accommodation.</p></div>

                </div>
            </div>
        </div>
    )
}

export default Visa_Counselling