import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const TOEFL = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TOEFL - Future Dream Makers</title>
                <meta
                    name="description"
                    content="TOEFL"
                />
                <meta
                    name="Keyword"
                    content="TOEFL"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-TOEFL bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        TOEFL
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is TOEFL ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Millions of international students take the TOEFL test to demonstrate their English proficiency.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>More than one million international students attend American colleges and universities, but non-native English speakers are typically required to pass a language skills assessment before enrolling.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Many prospective students take the TOEFL, or Test of English as a Foreign Language. A high score may help them gain admission to their preferred colleges; a low score may necessitate additional language classes or limit options by reducing the number of schools that will admit those students.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A variation of the TOEFL test, which is accepted at over 11,000 institutions worldwide, has been administered since 1965 by Educational Testing Service, a private nonprofit organisation that conducts educational research with a focus on testing. The test was created by a collaboration of more than 30 public and private institutions to assess the English proficiency of incoming students who spoke English as a second language.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Thematically, the TOEFL iBT and TOEFL iBT Home Edition are the same. The latter, on the other hand, is taken at home. While many testing centres remained open during the pandemic, this new option allowed students to take the exam at their leisure while also ensuring test quality and security.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The TOEFL has four parts:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Reading</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Listening</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Speaking</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Writing</p>
                    </div>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Good TOEFL Score</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Each of the TOEFL’s four sections is worth 30 points. To assess English language skills, ETS employs both automated scoring methods and human graders.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Because each question has a single correct answer, the reading and listening sections are scored automatically, whereas the speaking and writing sections are reviewed by human raters.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>While a TOEFL test taker can achieve a maximum score of 120 on the iBT, individual schools determine what minimum score they will accept. Elite institutions expect high marks, frequently setting the bar at 100. Some colleges and universities accept students with significantly lower test scores.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>How Much A TOEFL Test Cost ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The TOEFL exam costs differ as per countries. For example, the ETS website lists costs in the United States at $225, but the price in Australia is $300. There is also a fee for those who sign up late.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>When Is The Best Time To Apply For TOEFL ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The TOEFL is administered more than 60 times per year at test centres around the world, as well as at home for those who prefer it. For the most up-to-date information, ETS recommends that registered users log in to their accounts.</p>
                </div>
            </div>
        </div>
    )
}

export default TOEFL