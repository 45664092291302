import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_Biometrics = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Biometrics - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Biometrics"
                />
                <meta
                    name="Keyword"
                    content="Australia Biometrics"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Biometrics</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Biometrics refers to the collection of fingerprints and facial images as part of the visa application process. The biometrics requirement applies to applicants for some visa subclasses, including the Subclass 500 (Student visa) in Australia.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are the steps to apply for biometrics for a Subclass 500 visa:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Complete the visa application form:</span> You will need to complete and submit the online visa application form, including all required supporting documents.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pay the visa application fee:</span> After submitting the visa application, you will need to pay the visa application fee.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive a request for biometrics:</span> If your visa application is eligible for biometrics, you will receive a request from the Department of Home Affairs to provide your biometrics.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Book an appointment:</span> Once you receive the request, you will need to book an appointment with an Australian Visa Application Centre (AVAC) or the Australian Biometrics Collection Service in your country.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Provide your biometrics:</span> During the appointment, you will need to provide your fingerprints and facial images.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the process and timeline for biometrics collection may vary depending on your location and the specific requirements of the Department of Home Affairs. You can check their website <a className='text-blue-500' href='https://www.homeaffairs.gov.au/' target="_blank">(www.homeaffairs.gov.au.)</a> for more information and updates on biometrics requirements for the Subclass 500 visa.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Biometrics