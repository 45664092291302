import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Alberta = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Alberta - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Alberta"
                />
                <meta
                    name="Keyword"
                    content="Canada Alberta"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Alberta</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Alberta is a province in Western Canada. Its landscape encompasses mountains, prairies, desert bad lands and vast coniferous forests. It has more than 600 lakes, and rich mineral deposits. In the west, the Canadian Rocky Mountain Parks have glaciers in the Columbia Icefields. The Waterton Glacier International Peace Park is a biosphere reserve that straddles the southern border with the USA. capital of Alberta is Edmonton and the area of Alberta 661,848 km².Population of Alberta is 43.7 lakhs (2019). The economy of Alberta is the sum of all economic activity in Alberta, Canada's fourth largest province by population. Alberta's GDP in 2018 was CDN$338.2 billion.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Alberta:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Alberta:</span> Founded in 1908, University of Alberta is a non-profit public higher-education institution located in the urban setting of the large city of Edmonton (population range of 500,000-1,000,000 inhabitants), Alberta. This institution also has a branch campus in Camrose. Officially recognized by the Ministry of Advanced Education of Alberta, University of Alberta (UA) is a very large (university Rank Enrolment range: 35,000-39,999 students) coeducational Canadian higher education institution. University of Alberta (UA) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees, doctorate degrees in several areas of study. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Calgary:</span> Founded in 1966, University of Calgary is a non-profit public higher-education institution located in the urban setting of the metropolis of Calgary (population range of 1,000,000-5,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, University of Calgary (University of Calgary) is a large (university Rank enrolment range: 25,000-29,999 students) coeducational Canadian higher education institution. University of Calgary (University of Calgary) offers courses and programs leading to officially recognized higher education degrees in several areas of study. See the university rank degree levels and areas of study matrix below for further details. This 56 years old Canadian higher-education institution has a selective admission policy based on entrance examinations and students' past academic record and grades. The admission rate range is 10-20% making this Canadian higher education organization a most selective institution. International students are welcome to apply for enrolment University of Calgary also provides several academic and non-academic facilities and services to students including a library, housing, sports facilities, financial aids and/or scholarships, study abroad and exchange programs, as well as administrative services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Lethbridge:</span> Established in 1967, University of Lethbridge is a non-profit public higher-education institution located in the urban setting of the small city of Lethbridge (population range of 50,000-249,999 inhabitants), Alberta. This institution also has branch campuses in the following locations: Calgary, Edmonton. Officially recognized by the Ministry of Advanced Education of Alberta, University of Lethbridge (UofL) is a medium-sized (university Rank enrolment range: 8,000-8,999 students) coeducational Canadian higher education institution. University of Lethbridge (UofL) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 55 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. International applicants are eligible to apply for enrolment. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Mount Royal University:</span> Established in 1910, Mount Royal University is a non-profit public higher-education institution located in the suburban setting of the metropolis of Calgary (population range of 1,000,000-5,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, Mount Royal University (MRU) is a large (university Rank enrolment range: 10,000-14,999 students) coeducational Canadian higher education institution. Mount Royal University (MRU) offers courses and programs leading to officially recognized higher education degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 112 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. International applicants are eligible to apply for enrolment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Ambrose University:</span> Established in 1910, Mount Royal University is a non-profit public higher-education institution located in the suburban setting of the metropolis of Calgary (population range of 1,000,000-5,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, Mount Royal University (MRU) is a large (university Rank enrolment range: 10,000-14,999 students) coeducational Canadian higher education institution. Mount Royal University (MRU) offers courses and programs leading to officially recognized higher education degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 112 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. International applicants are eligible to apply for enrolment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The King’s University:</span> Founded in 1979, The King’s University is a non-profit private higher-education institution located in the suburban setting of the large city of Edmonton (population range of 500,000-1,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, The King’s University (King's) is a very small (university Rank enrolment range: 500-999 students) coeducational Canadian higher education institution formally affiliated with the Christian-Reformed religion. The King’s University (King's) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 43 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. The admission rate range is 80-90% making this Canadian higher education organization a least selective institution. International students are welcome to apply for enrolment. King's also provides several academic and non-academic facilities and services to students including a library, sports facilities, study abroad and exchange programs, as well as administrative services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Concordia University of Edmonton:</span> Established in 1921, Concordia University of Edmonton is a non-profit private higher-education institution located in the urban setting of the large city of Edmonton (population range of 500,000-1,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, Concordia University of Edmonton (CUE) is a very small (university Rank enrolment range: 1,000-1,999 students) coeducational Canadian higher education institution. Concordia University of Edmonton (CUE) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 101 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. The admission rate range is 50-60% making this Canadian higher education organization a averagely selective institution. International applicants are eligible to apply for enrolment. CUE also provides several academic and non-academic facilities and services to students including a library, housing, sports facilities, financial aids and/or scholarships, study abroad and exchange programs, as well as administrative services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Southern Alberta Institute of Technology:</span> Founded in 1916, Southern Alberta Institute of Technology is a non-profit public higher-education institution located in the urban setting of the metropolis of Calgary (population range of 1,000,000-5,000,000 inhabitants), Alberta. Officially recognized by the Ministry of Advanced Education of Alberta, Southern Alberta Institute of Technology (SAIT) is a large (university Rank enrolment range: 10,000-14,999 students) coeducational Canadian higher education institution. Southern Alberta Institute of Technology (SAIT) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. This 106 years old Canadian higher-education institution has a selective admission policy based on students' past academic record and grades. International students are welcome to apply for enrolment. SAIT also provides several academic and non-academic facilities and services to students including a library, housing, sports facilities, financial aids and/or scholarships, study abroad and exchange programs, online courses and distance learning opportunities, as well as administrative services.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Northern Alberta Institute of Technology:</span> Founded in 1962, Northern Alberta Institute of Technology is a non-profit public higher-education institution located in the urban setting of the large city of Edmonton (population range of 500,000-1,000,000 inhabitants), Alberta. This institution also has a branch campus in Calgary. Officially recognized by the Ministry of Advanced Education of Alberta, Northern Alberta Institute of Technology (NAIT) is a very large (university Rank enrolment range: over-45,000 students) coeducational Canadian higher education institution. Northern Alberta Institute of Technology (NAIT) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. International students are welcome to apply for enrolment. NAIT also provides several academic and non-academic facilities and services to students including a library, sports facilities, financial aids and/or scholarships, online courses and distance learning opportunities, as well as administrative services.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Canada_Alberta
