import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const GMAT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>GMAT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="GMAT"
                />
                <meta
                    name="Keyword"
                    content="GMAT"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-GMAT bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        GMAT
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is GMAT ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Graduate Management Admission Test, or GMAT, is a required component of the business school admissions process. The GMAT is a standardised multiple-choice, computer-based, and computer-adaptive exam that is frequently required for admission to graduate business programmes (MBA) around the world.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GMAC created and administers the GMAT to provide business schools with standardised measures of applicants’ readiness for graduate-level academic work. Admissions committees at business schools consider your GMAT score, as well as your work experience, academic record, and supporting materials, to determine your readiness for the rigours of an MBA programme.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A high GMAT score will have an immediate and positive impact on your business school application.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>About GMAT</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Graduate Management Admission Test, or GMAT, is a required component of the business school admissions process. The GMAT is a standardised multiple-choice, computer-based, and computer-adaptive exam that is frequently required for admission to graduate business programmes (MBA) around the world.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GMAC created and administers the GMAT to provide business schools with standardised measures of applicants’ readiness for graduate-level academic work. Admissions committees at business schools consider your GMAT score, as well as your work experience, academic record, and supporting materials, to determine your readiness for the rigours of an MBA programme.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'> A high GMAT score will have an immediate and positive impact on your business school application.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>GMAT Format</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The GMAT assesses your knowledge of fundamental arithmetic, algebra, geometry, multi-source data analysis, and grammar. More importantly, it assesses your ability to critically think, analyse, and evaluate written material. The GMAT is primarily a test of your critical thinking abilities. The ability to reason through and analyse information is essential for achieving a high GMAT score.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The GMAT is divided into four sections, but you will use the same critical thinking and analysis skills throughout the exam as you will in your MBA coursework. The GMAT content is divided into four scored test sections, two of which are scored separately and two of which are scored separately but combined to generate your composite score:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Analytical Writing Assessment </p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Integrated Reasoning </p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Quantitative </p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Verbal </p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>GMAT test takers can order GMAT test sections in any order they want. Following the computer tutorial and just before the start of your test, you will select your section order at the test centre. You will be able to select one of three orders:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Analytical Writing Assessment (AWA), Integrated Reasoning (IR), Quantitative, Verbal</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Verbal, Quantitative, Integrated Reasoning, Analytical Writing Assessment</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Quantitative, Verbal, Integrated Reasoning, Analytical Writing Assessment</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The GMAT Analytical Writing Assessment section is scored separately on a scale of 0 to 6 in half-point increments. The Integrated Reasoning section is also graded separately on a scale of 1–8, with one-point increments. The scaled score for the Quantitative and Verbal sections is 0–60. They are then combined to produce a score on the 200–800 scale, with 10-point increments, which you are most likely familiar with.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The average Verbal score is 27, while the average Quantitative score is 39. Average score for  Analytical Writing is 4.4, while Integrated Reasoning is 4.2. Your combined Verbal and Quantitative scores are the most important for admission to MBA programmes. The GMAT algorithm is applied to your Verbal and Quantitative scores here, converting them to the familiar 200–800 scale, with a average score of 552. See the table below for more information on how the GMAT is scored.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>ANALYTICAL WRITING ASSESSMENT</p>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NUMBER OF QUESTIONS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TIME DURATION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SCORE RANGE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Single: Analysis of an Argument</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">30 Minutes</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">0 - 6</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Analytical Writing Assessment, also known as the “essay” section, assists business schools in analysing your writing abilities. It is scored independently, and your AWA score is not used to calculate your 200–800 point total. Essays are graded by both a human grader and a computer grading system, and the two scores are averaged to determine your overall grade. If the ratings differ significantly, your essay is read and scored by another human.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>For your writing task, you will be given a brief argument, similar to a paragraph from a Critical Reasoning question on the Verbal section. You are not asked to present your own point of view on the topic; rather, you are asked to critique the author’s argument, analysing the author’s evidence and reasoning.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Essay graders look for whether you can clearly identify and analyse parts of the argument, develop and organise your ideas thoughtfully and logically, and connect your statements with clear transitions when scoring this section.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>INTEGRATED REASONING</p>
                    {/* table */}
                    <div className='flex justify-center mb-6'>
                        <table className=" md:w-[90%] w-auto lg:w-[55%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NUMBER OF QUESTIONS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TIME DURATION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SCORE RANGE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Multi-Source Reasoning Questions, Graphics Interpretation Questions, Two-Part Analysis Questions, Table Analysis Questions ,12 Total Questions</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">30 Minutes</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">0 - 6</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GMAT