import React from "react";
import { Helmet } from "react-helmet";
import Contactform from "./Contactform";
const Contact = () => {

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Future Dream Makers</title>
        <meta name="description" content="Contact Us" />
        <meta name="Keyword" content="Contact Us" />
      </Helmet>
      <div>

     
      <div className="mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-ContactUs bg-cover md:bg-top bg-center">
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <p className="text-white font-heading font-semibold py-2 md:text-5xl text-xl mb-2 ">
            CONTACT US
          </p>
        </div>
      </div>

      <div className="md:text-justify">
        <p className="text-red-500 font-bold font-serif mt-4 text-center py-8 text-4xl ">
          GET IN TOUCH
        </p>
      </div>
      <Contactform/>
      <div className="grid md:grid-cols-2 grid-cols-1 lg:px-28 xl:px-64 px-8 gap-8 mb-6 ">
        {/* Form */}
        <div className="bg-[#033090] md:text-justify">
          <p className=" py-3 text-[#FBFBFB] font-bold text-xl px-4 mt-4 mb-1">
            About Future Dream Makers
          </p>
          <p className=" py-3 text-[#FBFBFB] px-4">
            From several years of expertise has allowed us to carve out a unique
            position in the field of national and international admissions for
            engineering management programmes and medical programmes
          </p>
        </div>
        <div className="bg-red-500 md:text-justify">
          <p className=" py-3 text-[#FBFBFB] font-bold text-xl px-4 mt-4 mb-1">
            Our Address
          </p>
          <p className=" py-3 text-[#FBFBFB] px-4">
            168-169, PU 4 Behind C21 Mall, Vijay Nagar, Indore (M.P)
          </p>
        </div>
      </div>

      {/* Google Map for FDM */}
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.3258704478394!2d75.89150751479552!3d22.75328478509008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303e3ba471bf9%3A0xbb44481c7daa3743!2sFuturedreammakers!5e0!3m2!1sen!2sin!4v1674884031964!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          className="2xl:px-64 mx-auto w-full md:h-[30rem] h-96 py-12 px-4"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> 
        </div>
      </div>
     
    </div>
  );
};

export default Contact;
