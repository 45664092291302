import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs';
const Italy_FianacialSupport = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Financial Support - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Financial Support"
                />
                <meta
                    name="Keyword"
                    content="Italy Financial Support"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Proof of financial support (e.g. scholarship, bank statement)</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Proof of financial support is one of the required documents for obtaining a student visa to study in Italy. This document is meant to demonstrate to the Italian authorities that the student has sufficient financial resources to support themselves during their studies in Italy.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The type of financial support accepted will vary based on the specific university, but common forms of financial support include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Scholarship:</span> A scholarship from an accredited institution that covers tuition fees and/or living expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Bank statement:</span> A recent bank statement showing enough funds to cover tuition fees and living expenses for the duration of the student's stay in Italy. The amount required will vary based on the specific university and the student's expenses, but it is typically estimated at €800 to €1,500 per month for living expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Sponsorship letter:</span> A letter from a sponsor, such as a parent or relative, who agrees to support the student financially during their studies in Italy.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the financial support document must be recent, typically within the past 3-6 months, and in the student's name or in the name of the sponsor. It is recommended that students check with their university for specific requirements and obtain the necessary financial support documentation before applying for a student visa.</p>
                </div>
            </div>
        </div>
    )
}

export default Italy_FianacialSupport
