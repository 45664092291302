import React from 'react'
import { Helmet } from "react-helmet";
const UK_Birmingham = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Birmingham - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Birmingham"
                />
                <meta
                    name="Keyword"
                    content="UK Birmingham"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Birmingham</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Birmingham is a city in central England, known for its rich history, cultural heritage, and thriving arts scene. The population of Birmingham is approximately 1.1 million. Birmingham has a number of highly ranked universities, including the University of Birmingham, Aston University, and Birmingham City University. The average tuition fees for international students in Birmingham can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Birmingham is relatively affordable, with accommodation being one of the more significant expenses. Birmingham has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Birmingham include HSBC, PwC, and KPMG. Job opportunities in Birmingham are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Birmingham
