import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const United_Kingdom = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const UK_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-1.jpg' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-2.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-3.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-4.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-5.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/UK+logo/UK_university_logo-6.png" }
    ];

    const Aberdeen = () => {
        history.push('united-kingdom/aberdeen')
        window.scrollTo(0, 0)
    }
    const Armagh = () => {
        history.push('united-kingdom/armagh')
        window.scrollTo(0, 0)
    }
    const Belfast = () => {
        history.push('united-kingdom/belfast')
        window.scrollTo(0, 0)
    }
    const Birmingham = () => {
        history.push('united-kingdom/birmingham')
        window.scrollTo(0, 0)
    }
    const Bristol = () => {
        history.push('united-kingdom/bristol')
        window.scrollTo(0, 0)
    }
    const Cardiff = () => {
        history.push('united-kingdom/cardiff')
        window.scrollTo(0, 0)
    }
    const Edinburgh = () => {
        history.push('united-kingdom/edinburgh')
        window.scrollTo(0, 0)
    }
    const England = () => {
        history.push('united-kingdom/england')
        window.scrollTo(0, 0)
    }
    const Glasgow = () => {
        history.push('united-kingdom/glasgow')
        window.scrollTo(0, 0)
    }
    const London = () => {
        history.push('united-kingdom/london')
        window.scrollTo(0, 0)
    }
    const Londonderry = () => {
        history.push('united-kingdom/londonderry')
        window.scrollTo(0, 0)
    }
    const Manchester = () => {
        history.push('united-kingdom/machester')
        window.scrollTo(0, 0)
    }
    const Newport = () => {
        history.push('united-kingdom/newport')
        window.scrollTo(0, 0)
    }
    const NorthernIsland = () => {
        history.push('united-kingdom/northern-island')
        window.scrollTo(0, 0)
    }
    const Scotland = () => {
        history.push('united-kingdom/scotland')
        window.scrollTo(0, 0)
    }
    const Swansea = () => {
        history.push('united-kingdom/swansea')
        window.scrollTo(0, 0)
    }
    const Wales = () => {
        history.push('united-kingdom/wales')
        window.scrollTo(0, 0)
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>United Kingdom - Future Dream Makers</title>
                <meta
                    name="description"
                    content="United Kingdom"
                />
                <meta
                    name="Keyword"
                    content="United Kingdom"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-UK bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        UK
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>United Kingdom</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The United Kingdom is one of the most popular study locations in the world. It has a long and illustrious history, and high-class institutions can be found in every corner of the country.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The United Kingdom, like other English-speaking countries, has a lot to offer international students. Education system compatibility allows you to learn in a method that aligns with your <span className='text-red-500'><a href='/united-kingdom/jobs'>job</a></span> goals.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>As the number of students has grown throughout the years, the study destination has continued to attract international students. Students from India, China, Hong Kong, France, the United States, Germany, Malaysia, the Republic of Ireland, Greece, and other countries are welcome in the United Kingdom.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>About UK</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>International Recognition: The United Kingdom is home to four of the top ten institutions in the world, as well as the world's second-highest number of Nobel Prize winners. 24 universities out of 500 universities by QS are of Uk operated by <span className='text-red-500'><a href='/united-kingdom/russell-group'>russell group</a></span>.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Affordable Education: Undergraduate degrees in the United Kingdom last three to four years, and master's programmes last one to two years, saving you money on both education and living expenses.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>UK boasts one of the best and highest-quality education systems in the world, with international recognition and outstanding programmes in a variety of academic areas.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Scholarships: International students can apply for a variety of <span className='text-red-500'><a href='united-kingdom/scholarship'>scholarships</a></span>, financial aid, grants, and bursaries offered by UK institutions.</p>
                    </div>
                    <div className='flex pr-2  px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Wide Range of Study Options: The United Kingdom provides a well-researched and wide range of courses in internationally ranked universities that prepare students for careers in business.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>VISA Process</p>
                    <p className='font-normal py-1 text-lg font-serif  px-8 mb-6 '>Here is a general outline of the student visa process for individuals from India who wish to study in the UK:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose a course and a university:</span> Select a course that is recognized by the UK government and find a university that offers the course.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check eligibility:</span> Ensure that you meet the eligibility criteria for a UK student visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Application and supporting documents:</span> Submit your visa application and provide the required supporting documents, including proof of your financial support, <span className='text-red-500'><a href='united-kingdom/medical'>medical</a></span> , <span className='text-red-500'><a href='united-kingdom/ihs'>IHS</a></span> , <span className='text-red-500'><a href='united-kingdom/vfs'>VFS</a></span> , English language proficiency, and a valid Certificate of Acceptance for Studies <span className='text-red-500 '><a href='united-kingdom/cas'>(CAS)</a></span> from the university.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Biometrics:</span> Provide biometric information such as fingerprints and a digital photograph as part of the visa application process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Payment of visa fee:</span> Pay the visa fee as specified on the UK government website.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Visa interview:</span> Receive a decision on your visa application, which can take several weeks to process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Decision:</span> Universities assess the student's academic transcripts, personal statement, reference letters, and English language proficiency scores to determine if they meet the minimum eligibility requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> If your visa is approved, you can prepare for your arrival in the UK, including arranging for travel and <span className='text-red-500'><a href='united-kingdom/accommodation'>accommodation</a></span> .</p>
                    </div>


                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>UK Admission Process</p>
                    <p className='font-normal py-1 text-lg font-serif  px-8 mb-6 '>The shortlisting process for international students applying to universities in the UK typically includes the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Review of application materials:</span> Universities assess the student's academic transcripts, personal statement, reference letters, and English language proficiency scores to determine if they meet the minimum eligibility requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Exam results evaluation:</span> The universities assess the student's scores in exams required for admission, such as <span className='text-red-500'><a href='/ielts'>IELTS</a></span> or <span className='text-red-500'><a href='/toefl'>TOEFL</a></span>.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Assessment of additional requirements:</span> Some universities may also consider additional factors, such as work experience or extracurricular activities.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Decision making:</span> Based on the above assessments, the universities make a decision on whether to offer the student a place in the program.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>University shortlisting</p>
                    <p className='font-normal py-1 text-lg font-serif  px-8 mb-6 '>A student can receive an offer letter from a top-ranked university in the UK by following these steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit a complete application:</span> Submit all required application materials, including academic transcripts, personal statement, reference letters, and English language proficiency scores to the university.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meet eligibility requirements:</span> Ensure that you meet the minimum eligibility requirements for the program you are applying to, such as minimum GPA, test scores, and language proficiency scores.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for the decision:</span> After submitting the application, wait for the university to make a decision. This process can take several weeks to several months, depending on the university and program.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive an offer:</span> If the university decides to offer you a place in the program, you will receive an offer letter. This letter will outline the conditions of your admission, such as the program details, tuition fees, and deadline for accepting the offer.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Accept or decline the offer:</span> If you accept the offer, you will need to follow the instructions in the offer letter to secure your place in the program, such as paying a deposit <span className='text-red-500'><a href='united-kingdom/fee'>(fee)</a></span> or submitting additional documents. If you decline the offer, you will need to inform the university of your decision.</p>
                    </div>
                    <p className='font-normal py-1 text-lg font-serif  px-8 mb-6 '>Note: The specific process and requirements for receiving an offer letter may vary between universities and programs.</p>
                    <p className='font-normal py-1 text-lg font-serif  px-8 mb-6 '>International students must apply for a Tier 4 (Student) visa to study in the UK. The process involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Acceptance into a course at a recognized UK institution</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Obtain a Confirmation of Acceptance for Studies<span className='text-red-500 '><a href='united-kingdom/cas'>(CAS)</a></span> from the institution</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Complete the online Tier 4 visa application form</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Pay the visa fee</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Provide biometric information</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Attend a visa application center for an interview (if required)</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Submit required supporting documents including</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Proof of financial support <span className='text-red-500 '><a href='united-kingdom/funds'>(funds)</a></span></p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Academic qualifications</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Passport</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Wait for a decision on the visa application</p>
                    </div>




                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-4 grid-cols-1 px-8 mb-6'>
                        <button onClick={England} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ENGLAND</button>
                        <button onClick={London} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>LONDON</button>
                        <button onClick={Manchester} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>MANCHESTER</button>
                        <button onClick={Birmingham} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>BIRMINGHAM</button>
                        <button onClick={Bristol} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>BRSITOL</button>
                        <button onClick={Scotland} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>SCOTLAND</button>
                        <button onClick={Edinburgh} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>EDINBURGH</button>
                        <button onClick={Glasgow} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>GLASGOW</button>
                        <button onClick={Aberdeen} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ABERDEEN</button>
                        <button onClick={Wales} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>WALES</button>
                        <button onClick={Cardiff} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>CARDIFF</button>
                        <button onClick={Swansea} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>SWANSEA</button>
                        <button onClick={Newport} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NEWPORT</button>
                        <button onClick={NorthernIsland} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NORTHERN IRELAND</button>
                        <button onClick={Belfast} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>BELFAST</button>
                        <button onClick={Londonderry} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>LONDONDERRY</button>
                        <button onClick={Armagh} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ARMAGH</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xlunderline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {UK_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default United_Kingdom