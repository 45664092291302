import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Funds = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Funds- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Funds"
                />
                <meta
                    name="Keyword"
                    content="Ireland Funds"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Funds to maintain before visa</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Irish Naturalisation and Immigration Service (INIS) requires international students to demonstrate that they have sufficient funds to cover their living expenses while studying in Ireland. The exact amount required varies, but typically students are expected to have access to funds equivalent to around €7,000 to €12,000 per year, depending on the location and lifestyle of the student. This may include funds for tuition fees, accommodation, travel, food, and other living expenses.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to have this funding in place and to be able to demonstrate it to the INIS when applying for a student visa. Acceptable forms of proof of funds may include bank statements, scholarship or sponsorship letters, or a letter from a parent or guardian confirming their support.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's also important to note that international students are not allowed to work more than 20 hours per week during term time and full-time during vacation periods.</p>
                
                </div>
            </div>
    </div>
  )
}

export default Ireland_Funds
