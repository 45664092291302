import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const I20 = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA I20 - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA I20"
                />
                <meta
                    name="Keyword"
                    content="USA I20"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>I-20</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>An I-20 is a document issued by a U.S. school that certifies that an international student has been accepted into a program of study and is eligible for an F-1 student visa. The I-20 form is used by U.S. immigration officials to determine the eligibility of an international student for an F-1 student visa in the United States.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students who wish to study in the United States must first be accepted into a program of study at a U.S. school and then obtain an I-20 form. They can then use the I-20 form to apply for an F-1 student visa, which will allow them to enter the United States and begin their studies.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>How to book interview for USA visa</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Determine the type of visa you need:</span> There are various types of visas available for international students, including F-1 student visas and J-1 exchange visitor visas. Determine the type of visa you need based on the purpose of your trip to the United States.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather required documents:</span> Prepare all required documents, including your passport, I-20 or DS-2019 form, financial documentation, and any other supporting documents required for your specific visa type.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Complete the visa application form (<span className='text-red-500 '><a href='ds-160'>DS-160</a></span>): </span> Submit the online visa application form and pay the required visa fee.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Schedule an interview:</span> After you have completed the visa application form, you can schedule an interview at the U.S. embassy or consulate nearest to your location.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend the interview:</span> Arrive at the U.S. embassy or consulate on the date and time of your scheduled interview. You may be required to bring original copies of your supporting documents, and you should be prepared to answer questions about your plans in the United States.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that visa interviews may be conducted in person or through a video conference, depending on the current situation and the policies of the U.S. embassy or consulate where you are applying. Additionally, processing times for visas can vary, so it is important to schedule your interview well in advance of your planned travel date.</p>

                    <p className=' font-semibold text-red-500 text-2xl lg-6 mt-8  px-8' >Tips to clear usa student visa interview</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are some tips to help you secure an F1 visa during your U.S. visa interview:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Be Prepared:</span> Make sure you have all the required documents and information ready. Review the specific requirements for an F1 visa and have all the necessary documentation organized and ready to present to the consular officer.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Dress Professionally:</span> Dress in business attire to show respect for the process and the consular officer.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Be on Time:</span> Arrive at the U.S. embassy or consulate on time for your appointment. Late arrival may result in a missed appointment and a delay in the processing of your visa application.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Provide Evidence of Student Status:</span> Provide evidence of your student status, such as an acceptance letter from your U.S. school, proof of financial support, and a plan for maintaining your status as a student in the U.S. .</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Be Honest:</span> Answer all questions truthfully and completely. Inconsistencies or false information may lead to visa denial or future ineligibility for a U.S. visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Explain Your Purpose:</span> Clearly explain your educational goals and how you plan to support yourself while you are studying in the U.S. .</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Show Ties to Home Country:</span> Demonstrate your ties to your home country, such as family, job, or other obligations, to show that you have a reason to return home after completing your studies.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Be Respectful:</span> Treat the consular officer with respect and courtesy throughout the interview process.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>By following these tips, you can increase your chances of securing an F1 visa during your U.S. visa interview. Good luck!</p>

                </div>
            </div>
        </div>
    )
}

export default I20
