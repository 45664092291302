import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Accommodation = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Accommodation - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Accommodation"
                />
                <meta
                    name="Keyword"
                    content="UK Accommodation"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Accommodation</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several accommodation options for international students in the UK. The following are some of the most common options, along with information on how to find and secure them:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University accommodation:</span> Many universities in the UK provide accommodation for their students. This can range from halls of residence to student apartments. To apply for university accommodation, you should contact the university's student services or accommodation office and follow their application process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Private rental:</span> You can also rent a room or an entire property from a private landlord. You can find private rental properties through online platforms such as Rightmove, Zoopla, and SpareRoom, or by contacting local estate agents. When renting a property, be sure to read the tenancy agreement carefully and understand your rights and responsibilities as a tenant.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Homestay:</span> A homestay is a type of accommodation where you live with a host family. This is a good option for international students who want to experience life in the UK and practice their English skills. To find a homestay, you can contact a homestay agency or search online platforms such as homestay.com.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Shared accommodation:</span> Sharing a house or apartment with other students is another option for international students in the UK. You can find shared accommodation through online platforms such as SpareRoom or by contacting local estate agents.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>When looking for accommodation, it is important to consider factors such as cost, location, and the terms of the tenancy agreement. It is also advisable to research the area where you will be staying and make sure that it is safe and accessible to the places you need to go, such as your university and local amenities.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Link to book accommodation in United kingdom</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are a few websites that offer accommodation options for international students in the UK:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Unite Students:</span><a className='text-blue-500' href='https://www.unitestudents.com/' target="_blank" rel="noopener noreferrer">https://www.unitestudents.com/</a></p>
                    </div>

                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>These websites offer a variety of accommodation options, including university halls of residence, private rental properties, homestays, and shared accommodation. You can compare prices, locations, and amenities, and book accommodation online through these platforms. Note that these websites may have different terms and conditions, so it's important to read and understand them before making a booking.</p>

                </div>
            </div>
        </div>
    )
}

export default UK_Accommodation