import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const New_Zealand_Funds = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Funds - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Funds"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Funds"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Financial resources and funds</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The amount of financial resources and funds required to maintain oneself in New Zealand will vary depending on individual circumstances such as lifestyle, location, and personal expenses. However, here are some general estimates:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Living expenses:</span> It is estimated that a single person will need around NZD 20,000 to 25,000 (approximately INR 9,80,000 to 12,25,000) per year for living expenses, including accommodation, food, transportation, and entertainment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Tuition fees:</span> If you are planning to study in New Zealand, the tuition fees can vary greatly depending on the level of study and the institution. On average, international students can expect to pay between NZD 20,000 to 40,000 (approximately INR 9,80,000 to 19,60,000) per year for tuition fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Health insurance:</span> All international students in New Zealand are required to have health insurance. The cost of health insurance can vary, but a basic policy can cost around NZD 600 to 1000 (approximately INR 29,400 to 49,000) per year.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that these are just estimates and your actual expenses may be higher or lower, depending on your personal circumstances. It is recommended to have access to additional funds to cover any unexpected expenses, such as medical emergencies or travel costs.</p>
                </div>
            </div>
        </div>
    )
}

export default New_Zealand_Funds
