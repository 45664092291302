import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
const USA = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const USA_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-1.png' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-2.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-3.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-4.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-5.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/USA+logo/USA-university-logo-6.jpg" }
    ];
    const Alabama = () => {
        history.push('united-state-of-america/alabama')
        window.scrollTo(0, 0)
    }
    const Alaska = () => {
        history.push('united-state-of-america/alaska')
        window.scrollTo(0, 0)
    }
    const Arizona = () => {
        history.push('united-state-of-america/arizona')
        window.scrollTo(0, 0)
    }
    const California = () => {
        history.push('united-state-of-america/california')
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>United State Of America - Future Dream Makers</title>
                <meta
                    name="description"
                    content="United State Of America"
                />
                <meta
                    name="Keyword"
                    content="United State Of America"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-USA bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        USA
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>USA</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>With over 1,000,000 international students opting to enhance their academic and life experience in the United States,
                            the United States has the world's largest international student population.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2 '> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>International students make up over 5% of all students enrolled in higher education in the United States, and the
                            number is growing. International education in the United States has gone a long way since the mid-1950s,
                            when international student enrollment was barely around 35,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2 '> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>In the fields of education and technology, the United States is a world leader.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2 '> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Its education is well-known all around the world. It features one of the best university systems in the world, with great
                            programmes in almost every discipline.</p>
                    </div>
                    <div className='flex p-2  px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className=' pr-8 ml-2 text-lg font-normal font-serif '>The United States is home to the majority of the world's top-ranked universities. These colleges and universities are
                            highly ranked in the globe due to their infrastructure, technological developments, and high-quality education.</p>
                    </div>
                    <p className='py-1 font-serif text-lg  px-8 '>Hundreds of thousands of intelligent, ambitious students from all over the world have flocked to the United States in the twentieth and twenty-first centuries. Academic brilliance, cutting-edge technology, extensive support services for overseas students, excellent funding opportunities, a large choice of educational alternatives, flexible curriculum, and hands-on training make the United States the chosen destination for Indian students.</p>
                    <p className='py-1 font-serif text-lg  px-8 '>In the academic year 2013-2014, 886,052 students from nearly every country on the planet attended higher education institutions in the United States. In the same time span, 102,673 Indian students enrolled.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Students Choose To Study In USA</p>
                    <p className=' py-1 text-lg font-serif font-normal  px-8 mb-6'>In terms of innovation and education, the United States leads the Western world. With a population of almost 326 million people, the United States is one of the world’s most culturally varied countries, with thousands of higher education institutions spread across its 50 states.</p>
                    <p className=' py-1 text-lg font-serif font-normal  px-8 mb-6'>Many of the universities in the United States are ranked first or second in a variety of rankings, indicating that a degree acquired at a US institution will be highly valued by companies globally. The United States has five institutions in the top ten of the QS World University Rankings in 2018, with Massachusetts Institute of Technology (MIT) topping the list. The United States is ranked 31st out of 100 countries</p>
                    <p className=' py-1 text-lg font-serif font-normal  px-8 mb-6'>If you are planning for overseas (USA) then this are the following step for you to perform
                        Start with College/University application after college application you will receive a conditional or unconditional offer letter. Then next process will be <span className='text-red-500'><a href='/united-state-of-america/fee'>fee payment</a></span> to university. After the fee payment SEVIS is mandatory between this process you should book your <span className='text-red-500 '><a href='/united-state-of-america/accommodation'>accommodation</a></span> in destination country after SEVIS you will receive your I20, I29 is a final document which will be provided by university to you after that you should start your Visa process. Steps of Visa Process is like tht in USA you have to fill your <span className='text-red-500 '><a href='/united-state-of-america/ds-160'>DS-160</a></span> form during your DS-160 you have to book your Visa Interview from the same website and it is very important to maintain your <span className='text-red-500 '><a href='/united-state-of-america/funds'>funds</a></span> and <span className='text-red-500 '><a href='/united-state-of-america/medical'>medical</a></span> before the Visa interview. After Visa Interview you will receive your stamped passport from the USA Embassy with 10-15 days usually. This days might vary according to rush and time if we talk about USA <span className='text-red-500 '><a href='/united-state-of-america/costing'>Budget</a></span> for Master’s courses is around 30Lakh-40Lakh depend on the course and university. Similarly if we talk about the budget for Bachelor's then for Public Universities it will cost around 17lakh-30lakh and if we talk about the Private Universities then it may cost around 30Lakh-50Lakh. After the Completion of your studies Bachelor's/Master's you have to do apply for <span className='text-red-500 '><a href='/united-state-of-america/opt'>OPT</a></span> and with this you can stay in USA for 12 months after completion of your studies.
                    </p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>USA Admission Process</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Research universities:</span> Identify schools that match your academic interests, desired location, and budget.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Check admission requirements:</span> Make sure you meet the admission requirements, including language proficiency (<span className='text-red-500 '><a href='/toefl'>TOEFL</a></span> , <span className='text-red-500 '><a href='/ielts'>IELTS</a></span> , etc.), transcripts, and test scores (<span className='text-red-500 '><a href='/gre'>GRE</a></span>/<span className='text-red-500 '><a href='/gmat'>GMAT</a></span> if required).</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submit an online application:</span> Fill out an online application and pay the application fee.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submit supporting documents:</span> Send in transcripts, letters of recommendation, statement of purpose, and any other required documents.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a response:</span> After submitting your application, wait for a response from the university. They may request additional information or an interview.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Accept or decline offer:</span> If you are admitted, you will receive an offer of admission. Decide if you will accept or decline the offer.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare for arrival:</span> Once your visa is approved, prepare for your arrival in Italy, including making travel arrangements and arranging for accommodation.</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note that the specific requirements and procedures can vary depending on the university, so be sure to follow the instructions provided by each school.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Conditional Offer</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A conditional offer letter from a university in the USA means that an applicant has been accepted into a program, but their offer is contingent upon meeting certain requirements, such as achieving a minimum grade point average (GPA) in their current studies, submitting official test scores (e.g. <span className='text-red-500 '><a href='/toefl'>TOEFL</a></span> , <span className='text-red-500 '><a href='/gre'>GRE</a></span> ), or completing additional coursework.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Un-Conditional Offer</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>An unconditional offer letter means that an applicant has been fully accepted into the program and does not have any outstanding requirements to fulfill. This type of offer letter is typically given to applicants who have already met all of the necessary requirements, such as submitting transcripts and test scores, and have demonstrated the necessary academic qualifications for the program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that both types of offer letters can be subject to change, and universities reserve the right to withdraw an offer if an applicant fails to meet the conditions specified in a conditional offer letter, or if they discover new information that affects the applicant's eligibility.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>WHAT IS SEVIS?</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>SEVIS (Student and Exchange Visitor Information System) is a computerized system used by the U.S. government to maintain information on international students and exchange visitors in the United States. It is administered by U.S. Immigration and Customs Enforcement (ICE) and is used to track and monitor the activities of international students and exchange visitors while they are in the United States.SEVIS will track you from the time you receive your <span className='text-red-500 '><a href='united-state-of-america/i-20'>I-20</a></span> until you complete your program.</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>International students and exchange visitors who are studying in the United States are required to pay a SEVIS fee and register with SEVIS after being admitted to a program. SEVIS helps to ensure that international students and exchange visitors comply with the terms of their visas, maintain their immigration status, and provide accurate and up-to-date information to the U.S. government.</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>SEVIS is an important tool for universities, international students, and the U.S. government, as it helps to ensure the security and integrity of the student and exchange visitor programs.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Purpose of sevis</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>The purpose of SEVIS (Student and Exchange Visitor Information System) is to track and monitor the activities of international students and exchange visitors while they are in the United States. It is a database maintained by the U.S. government that provides real-time information on the status of international students and exchange visitors, including their enrollment in school, compliance with visa requirements, and departure from the United States.</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>SEVIS helps to ensure that international students and exchange visitors comply with the terms of their visas and maintain their immigration status. It also provides the U.S. government with a centralized source of information about international students and exchange visitors, which helps to improve the security and integrity of the student and exchange visitor programs.</p>
                    <p className='py-1 font-normal text-lg font-serif  px-8 mb-6'>In summary, the purpose of SEVIS is to track and monitor international students and exchange visitors in the United States, to ensure compliance with visa requirements and maintain the security and integrity of the student and exchange visitor programs.</p>

                    <p className='uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>VISA Process</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The F-1 visa is the student visa for the United States. It allows international students to enter the U.S. to attend an accredited college or university. To be eligible for an F-1 visa, individuals must:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Be enrolled in a full-time program of study at an SEVP-approved school.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Have sufficient financial resources to pay for tuition, living expenses, and other related costs while studying in the U.S. i.e. <span className='text-red-500'><a href='united-state-of-america/funds'>funds.</a></span></p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Prove that they have a residence abroad that they do not intend to abandon, and that they will return home after completing their studies.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Pass an interview and be able to demonstrate that they have a bona fide student status.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Meet all other immigration requirements as per the laws and regulations</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Once an individual has been granted an F-1 visa, they may enter the U.S. up to 30 days before the start of their program of study. They may also apply for an F-2 visa for dependents like spouse and children. The F-1 visa is usually valid for the duration of the student's program of study, plus 60 days after completion to allow the student time to prepare for departure or apply for a practical training program.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8 px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6 z-1'>
                        <button onClick={Alabama} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ALABAMA</button>
                        <button onClick={Alaska} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ALASKA</button>
                        <button onClick={Arizona} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ARIZONA</button>
                        <button onClick={California} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>CALIFORNIA</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {USA_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default USA