import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const New_Zealand_Southland = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Southland - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Southland"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Southland"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Southland</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Southland is a region located in the lower South Island of New Zealand.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Tuition fees in Southland for international students can range from NZD 22,000 to NZD 30,000 per year, depending on the institution and the program.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>The cost of living in Southland is lower compared to other regions in New Zealand, with international students expecting to spend around NZD 15,000 to 20,000 per year on living expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Accommodation options for international students in Southland include homestays, student dormitories, flats, and apartments.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Popular courses for international students in Southland include engineering, tourism, and business.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>International students in Southland are allowed to work part-time up to 20 hours per week during their studies and full-time during holidays.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>To find a job in Southland, international students can start by searching for job openings online, networking with local professionals, and attending job fairs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>The Post-Study Work (PSW) visa allows international graduates from Southland institutions to work in the country for up to three years after graduation.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Popular job profiles for international students in Southland include tourism, agriculture, and retail. Some of the top companies in Southland include SIT, the Southland District Council, and Fonterra, among others.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Top tourist attractions in Southland include the Stewart Island, the Catlins, the Bluff Hill Lookout, and the Tuatapere Hump Ridge Track, among others.</p>
                    </div>
                    
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities in Southland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Southern Institute of Technology (SIT)</p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default New_Zealand_Southland
