import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Australia_Adelaide = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Adelaide - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Adelaide"
                />
                <meta
                    name="Keyword"
                    content="Australia Adelaide"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>universities /Colleges of Adelaide</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Adelaide, the capital city of South Australia, has a range of universities and colleges that offer a diverse range of courses. Some of the top universities/colleges in Adelaide include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of Adelaide</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Flinders University</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>University of South Australia</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Torrens University Australia</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>TAFE SA</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Carnegie Mellon University Australia Campus</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Australian Catholic University</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Le Cordon Bleu Australia</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>South Australian School of Art, University of South Australia</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>These institutions offer a range of undergraduate, postgraduate and research programs in various disciplines such as arts, science, engineering, medicine, law, business, education, and more.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Adelaide