import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Medical = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Medical - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Medical"
                />
                <meta
                    name="Keyword"
                    content="USA Medical"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Medical Process For USA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The medical process for international students in the U.S. typically involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Get a physical examination:</span> Before entering the U.S., international students must undergo a physical examination by a designated panel physician. This doctor must be authorized by the U.S. Department of State to perform medical exams for U.S. visa applicants.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Get required vaccinations:</span> Depending on your home country and the length of your stay in the U.S., you may be required to get certain vaccinations. Your designated panel physician can provide you with a list of required vaccinations.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Get a tuberculosis (TB) test:</span> If you come from a country where TB is common, you may be required to undergo a TB test. This test can be done either before or after you arrive in the U.S.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain a Form I-693:</span> After your physical examination and any required vaccinations or tests, the panel physician will issue you a Form I-693, which is a report of medical examination and vaccination record.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit Form I-693:</span> You must submit the completed Form I-693 with your visa application. In some cases, the U.S. embassy or consulate may also require you to submit additional medical information or test results.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the medical process for international students in the U.S. can vary depending on the specific requirements of the U.S. embassy or consulate processing your visa application. It is important to check with the U.S. embassy or consulate near you for specific requirements and to schedule your medical examination well in advance of your planned travel date.</p>

                </div>
            </div>
        </div>
    )
}

export default USA_Medical
