import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_New_Brunswick = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada New Brunswick - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada New Brunswick"
                />
                <meta
                    name="Keyword"
                    content="Canada New Brunswick"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>New Brunswick</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>New Brunswick has more than 60 lighthouses and is famous for its inland lighthouse system that dots its inland rivers. The Bay of Fundy is a pristine sanctuary for all kinds of rare, unusual wild creatures.the population of new Brunswick is 7.77 lakhs (2019). New Brunswick's gross domestic product (GDP) in 2022 reached $32.3b, with growth of 1.3% over the 5 years to 2022. Fredericton is the capital of New Brunswick in eastern Canada.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of New Brunswick:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of New Brunswick:</span> Established in 1785, University of New Brunswick is a non-profit public higher-education institution located in the urban setting of the small city of Fredericton (population range of 50,000-249,999 inhabitants), New Brunswick. This institution also has a branch campus in Saint John. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, University of New Brunswick (UNB) is a large (university Rank enrolment range: 10,000-14,999 students) coeducational Canadian higher education institution. University of New Brunswick (UNB) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Mount Allison University:</span> Founded in 1839, Mount Allison University is a non-profit public higher-education institution located in the rural setting of the medium town of Sackville (population range of 2,500-9,999 inhabitants), New Brunswick. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, Mount Allison University (MTA) is a small (university Rank enrolment range: 2,000-2,999 students) coeducational Canadian higher education institution. Mount Allison University (MTA) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Université de Moncton:</span> Established in 1864, Université de Moncton (University of Moncton) is a non-profit public higher-education institution located in the urban setting of the small city of Moncton (population range of 50,000-249,999 inhabitants), New Brunswick. This institution also has branch campuses in the following locations: Edmundston, Shippagan. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Yorkville University:</span> Founded in 2003, Yorkville University is a for-profit private higher-education institution located in the urban setting of the small city of Fredericton (population range of 50,000-249,999 inhabitants), New Brunswick. This institution also has branch campuses in the following locations: Toronto, Vancouver. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, Yorkville University is a small (university Rank enrolment range: 5,000-5,999 students) coeducational Canadian higher education institution.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>St. Thomas University:</span> Founded in 1910, St. Thomas University is a non-profit public higher-education institution located in the urban setting of the small city of Fredericton (population range of 50,000-249,999 inhabitants), New Brunswick. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, St. Thomas University (STU) is a small (university Rank enrolment range: 2,000-2,999 students) coeducational Canadian higher education institution formally affiliated with the Christian-Catholic religion.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Crandall University:</span> Established in 1949, Crandall University is a non-profit private higher-education institution located in the urban setting of the small city of Moncton (population range of 50,000-249,999 inhabitants), New Brunswick. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, Crandall University (CU) is a very small (university Rank enrolment range: 500-999 students) coeducational Canadian higher education institution formally affiliated with the Christian-Baptist religion.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Kingswood University:</span> Founded in 1945, Kingswood University is a non-profit private higher-education institution located in the rural setting of the medium town of Sussex (population range of 2,500-9,999 inhabitants), New Brunswick. Officially recognized by the Department of Post-Secondary Education, Training and Labour of New Brunswick, Kingswood University (KU) is a very small (university Rank enrolment range: 250-499 students) coeducational Canadian higher education institution formally affiliated with the Christian-Methodist religion. Kingswood University (KU) offers courses and programs leading to officially recognized higher education degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Canada_New_Brunswick
