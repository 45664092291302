import React from 'react'
import { Helmet } from "react-helmet";
const USA_Funds = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Funds - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Funds"
                />
                <meta
                    name="Keyword"
                    content="USA Funds"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Funds For USA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The amount of financial support required for an F1 visa will vary depending on the cost of tuition, room and board, and other expenses associated with attending a U.S. college or university. As a general guideline, U.S. immigration regulations require international students to demonstrate that they have enough funds to cover the first year of expenses and any additional years, if applicable.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Typically, you will need to show proof of sufficient financial support for tuition and living expenses, including room and board, transportation, and personal expenses. The U.S. school you are attending may provide an estimate of the cost of attendance, which you can use to help determine the amount of financial support you need.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In addition to your own funds, you may also be able to include funds from scholarships, grants, or other sources. It is important to consult with the U.S. school you are attending or an immigration lawyer for specific guidance on the amount of financial support required for your F1 visa.</p>

                </div>
            </div>
        </div>
    )
}

export default USA_Funds
