import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Cork = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Cork - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Cork"
                />
                <meta
                    name="Keyword"
                    content="Ireland Cork"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Cork</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Cork is the second-largest city in Ireland, with a population of over 125,000 people. It is home to several universities, including University College Cork and Cork Institute of Technology. The average tuition fees for international students in Cork range from €10,000 to €20,000 per year. Some of the top courses for international students in Cork include engineering, computer science, and health sciences. The highest paying jobs in Cork are in the pharmaceutical, medical device, and technology sectors, with roles such as software engineer, research scientist, and sales manager. The average wage for these jobs is between €50,000 and €70,000 per year. The cost of living in Cork is lower than in Dublin, with an average monthly rent of €800 and a monthly grocery bill of €250. Cork is known for its vibrant arts scene, with several museums, galleries, and cultural events to explore. There are also several part-time and full-time job opportunities for international students in Cork, including roles in hospitality, retail, and customer service. Some of the top companies in Cork include Pfizer, Johnson & Johnson, and Amazon.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Cork