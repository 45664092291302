import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_SOP = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Visa SOP - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Visa SOP"
                />
                <meta
                    name="Keyword"
                    content="Canada Visa SOP"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Visa SOP</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Statement of Purpose (SOP) is a written document that is required as part of the Canada student visa application process. It is used to explain your motivations, academic and professional background, and future goals to the visa officer processing your application.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To write a better SOP for your Canada student visa application, you should consider the following tips:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Be clear and concise:</span> Write in simple, easy-to-understand language and avoid using complex vocabulary.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Tailor your SOP to the program:</span> Make sure your SOP reflects the specific program you are applying for, including why you chose it, and how it aligns with your career goals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Highlight your achievements:</span> Emphasize any academic or professional achievements, such as awards, publications, or work experience.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Explain your future plans:</span> Discuss your future goals and how the program will help you achieve them.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Proofread:</span> Carefully proofread your SOP for grammar, spelling, and typographical errors.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Keep it relevant:</span> Avoid including irrelevant information or personal anecdotes that do not directly support your application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to remember that the SOP is an opportunity to showcase your strengths and demonstrate your motivation to study in Canada. By presenting a well-written and thoughtful SOP, you can increase your chances of having your student visa application approved.</p>
                </div>
            </div>
        </div>
    )
}

export default Canada_SOP
