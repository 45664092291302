import React from 'react'
import { Helmet } from "react-helmet";
const Germany_CostOfLiving = () => {
  return (
    <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Germany Costing - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Germany Costing"
                />
                <meta
                    name="Keyword"
                    content="Germany Costing"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>accomodation in germany with cost</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of accommodation in Germany can vary depending on the location, type of accommodation, and the time of year. On average, a budget hotel room can cost around 50-70 euros per night, while a more luxurious hotel can cost around 100-200 euros or more.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Shared apartments or hostels can be a more budget-friendly option, with prices starting at around 20-30 euros per night for a bed in a shared dorm room. A private room in a shared apartment can cost around 50-80 euros per night.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Long-term rentals such as apartments or houses can cost between 700-1500 euros per month, depending on the location and size of the property.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Keep in mind that these are just rough estimates and actual costs may vary.</p>

                    </div>
                    </div>
    </div>
  )
}

export default Germany_CostOfLiving
