import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Alabama = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Alabama - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Alabama"
                />
                <meta
                    name="Keyword"
                    content="USA Alabama"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Alabama</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Alabama is known for its Southern hospitality, its history of civil rights struggles, and as the birthplace of the Civil Rights Movement. It is also a large producer of two commodities in the United States and is a significant home to space discovery. Population of Alabama is 50.4LAKH  and GDP is  204.88 billion USD dollar.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of Alabama:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Auburn University:</span> Auburn University (AU or Auburn) is a public land-grant research university in Auburn, Alabama, US. With more than 24,600 undergraduate students and a total enrollment of more than 30,000 with 1,330 faculty members, Auburn is the second-largest university in Alabama. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of South Alabama:</span> When you go by the moniker USA and the campus beauty queen wins the Miss USA title year after year (the Pi Kappa Phi Miss USA pageant, that is) you're standing on hallowed ground. In this case it's the ground of the University of South Alabama, situated on the upper Gulf Coast. The school's crown jewel is its College of Medicine and other facilities, including USA Medical Center.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of Alabama:</span> The university offers programs of study in 13 academic divisions leading to bachelor's, master's, education specialist, and doctoral degrees. The only publicly supported law school in the state is at UA. Other academic programs unavailable elsewhere in Alabama include doctoral programs in anthropology, communication and information sciences, metallurgical engineering, music, Romance languages, and social work.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Samford University:</span> Samford University is an educational institution that offers undergraduate, graduate, postgraduate, and research courses in science, arts, music, business administration, nursing, law, juridical science, ministry, and pharmacy. The schools of the university include School of Business, School of Divinity, School of Education, School of Law, School of Nursing, School of performing Arts.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Athens State University:</span> Athens State University is a public upper-division university in Athens, Alabama. Its academics are housed in three colleges: Education, Arts and Sciences, and Business.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default USA_Alabama
