import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Cas = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>UK CAS - Future Dream Makers</title>
        <meta
          name="description"
          content="UK CAS"
        />
        <meta
          name="Keyword"
          content="UK CAS"
        />
      </Helmet>
      <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
        <div className='md:text-justify  '>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>CAS</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Confirmation of Acceptance for Studies (CAS) is a unique reference number issued by a UK educational institution to an international student. The CAS serves as evidence of the student's acceptance into a course of study, and is required to apply for a Tier 4 (Student) visa.</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of obtaining a CAS involves the following steps</p>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Acceptance into a course at a UK institution</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Contact the institution's international office or student services to request a CAS</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Provide the institution with the required information and documents, such as:</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Proof of academic qualifications</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Evidence of financial support</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>The institution will assess the student's eligibility for a CAS and, if approved, issue a CAS number</p>
          </div>
          <div className='flex p-2   px-0 md:ml-0 ml-4'>
            <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>The student uses the CAS number to apply for a Tier 4 (Student) visa</p>
          </div>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The process and requirements for obtaining a CAS may vary between institutions and can change. It's recommended to check the latest information directly with the institution.</p>

        </div>
      </div>
    </div>
  )
}

export default UK_Cas
