import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_University = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada University - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada University"
                />
                <meta
                    name="Keyword"
                    content="Canada University"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Top college and university of Canada</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>According to the latest QS World University Rankings, the top ranked universities in Canada are:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>University of Toronto</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>McGill University</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>University of British Columbia</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>University of Alberta</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Simon Fraser University</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that rankings can vary depending on the criteria used and the source of the ranking. It's best to consider multiple rankings and resources when evaluating universities and to also consider your own personal goals and priorities in choosing the best fit for you</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Some university gives conditional in Canada</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Yes, some Canadian universities may issue conditional offer letters, which indicate that an offer of admission is contingent upon the completion of certain requirements, such as meeting specific academic or language proficiency standards.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Conditional offers are common in programs such as graduate studies or professional programs, where the applicant may need to demonstrate that they meet a certain level of proficiency before being fully admitted to the program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The specific universities that offer conditional offer letters may vary, and it's best to check with the individual institutions you are considering to see if they offer this option. You can usually find this information on the university's website or by contacting the admission office</p>


                </div>
            </div>
        </div>
    )
}

export default Canada_University