import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Australia_Sydney = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Sydney - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Sydney"
                />
                <meta
                    name="Keyword"
                    content="Australia Sydney"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Universities/colleges of Sydney</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Sydney, the capital of New South Wales and one of the largest cities in Australia, Sydney is one of the most popular cities in Australia for international students and is known for its vibrant and multicultural atmosphere. Popular courses for Indian students in Sydney include engineering, business, and finance. It is also the home to several prestigious universities and colleges, including:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of Sydney:</span> Australia's first university and one of the top universities in the world.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of New South Wales (UNSW):</span> a world-renowned university for science, technology, engineering and mathematics.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Macquarie University:</span> a leading Australian university with a strong focus on innovative research.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Western Sydney University:</span> a growing and dynamic university that is dedicated to providing high-quality education to its students.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Australian Catholic University:</span> a national university that offers a range of undergraduate and graduate programs in arts, education, business, health sciences, and more.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Sydney Institute of Technology:</span> a well-established institute that provides hands-on training and practical learning experiences in various fields.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>These are just a few of the universities and colleges located in Sydney. There are also several other institutions of higher education, including technical colleges and private colleges that offer a wide range of courses and programs to international students.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Sydney