import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const New_Zealand_PS = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Personal Statement - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Personal Statement"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Personal Statement"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'> Personal statement or essay</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A personal statement or essay is a written document that provides an opportunity for an applicant to present themselves to a university, school, company, or other organization. It is a chance for the applicant to showcase their strengths, interests, goals, and motivations, and to explain why they would be a good fit for the opportunity they are applying for. The personal statement or essay should be well-written, concise, and clearly convey the applicant's message.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Some key elements that a personal statement or essay should include are:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Introduction:</span> A brief overview of who the applicant is and what they hope to achieve with their application.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Background:</span> A brief explanation of the applicant's educational and professional background, highlighting any relevant experience.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Goals:</span> A clear explanation of the applicant's goals and aspirations, and how the opportunity they are applying for will help them achieve these goals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Strengths:</span> A discussion of the applicant's strengths, skills, and qualities that make them a good fit for the opportunity.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Reasons for interest:</span> An explanation of why the applicant is interested in the specific opportunity, and how it aligns with their interests and goals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Future plans:</span> A discussion of the applicant's future plans and how the opportunity will help them achieve these plans.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important for the personal statement or essay to be well-written and free of errors, as it is often the first thing the admission committee or hiring manager will see. Additionally, it is advisable to tailor the personal statement or essay to the specific opportunity being applied for, as this will demonstrate that the applicant has done their research and is serious about the opportunity.</p>
                </div>
            </div>
        </div>
    )
}

export default New_Zealand_PS
