import React from 'react'
import { Helmet } from "react-helmet";
const UK_Edinburgh = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>UK Edinburgh - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Edinburgh"
                />
                <meta
                    name="Keyword"
                    content="UK Edinburgh"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Edinburgh</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Edinburgh is the capital city of Scotland, known for its rich history, world-class cultural attractions, and stunning architecture. The population of Edinburgh is approximately 513,000. Edinburgh has a number of highly ranked universities, including the University of Edinburgh, Heriot-Watt University, and the Royal Conservatoire of Scotland. The average tuition fees for international students in Edinburgh can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Edinburgh is relatively high, with accommodation being one of the more significant expenses. Edinburgh has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Edinburgh include Standard Life Aberdeen, Royal Bank of Scotland, and Amazon. Job opportunities in Edinburgh are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                    </div>
                    </div>
    </div>
  )
}

export default UK_Edinburgh
