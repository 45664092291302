import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Ireland_PSW = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland PSW- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland PSW"
                />
                <meta
                    name="Keyword"
                    content="Ireland PSW"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>PSW</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>PSW stands for "Post-Study Work". In the context of Ireland, PSW refers to the opportunity for international students to work in the country after completing their studies. The PSW scheme allows international students who have completed a level 7 or higher course at an Irish educational institution to work in the country for a period of time after graduation. The exact length of time that a student is eligible to work under the PSW scheme may vary depending on the level and duration of their course of study.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The PSW scheme is designed to provide international students with valuable work experience in Ireland and to help them transition into the workforce after completing their studies. It is a great opportunity for students to build their professional networks and to gain practical skills and experience in their field.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In order to be eligible for the PSW scheme, students must meet certain criteria, including completing a full-time course of study at an Irish educational institution, passing their course, and obtaining a valid work permit. It is important to check the specific requirements and guidelines of the Irish Naturalisation and Immigration Service before applying for PSW, to ensure that you are eligible.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Requirements and guidelines of the Irish Naturalisation and Immigration Service before applying for PSW.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The specific requirements and guidelines for applying for PSW in Ireland are determined by the Irish Naturalisation and Immigration Service (INIS). Some of the key requirements and guidelines are as follows:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Course of Study:</span> To be eligible for PSW, students must have completed a level 7 or higher course at an Irish educational institution.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Duration of Study:</span> The length of the PSW eligibility period is determined by the length of the course of study. For example, students who complete a level 7 course of at least two years’ duration may be eligible for a PSW period of 24 months.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Work Permit:</span> To work in Ireland under the PSW scheme, students must obtain a valid work permit. This can be done through the Irish Naturalisation and Immigration Service (INIS).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Good Standing:</span> To be eligible for PSW, students must be in good standing with their educational institution and must not have any record of serious disciplinary action.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Health Insurance:</span> Students must have valid health insurance coverage while working in Ireland under the PSW scheme.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Criminal Record:</span> Students must not have any criminal convictions that would make them ineligible to work in Ireland.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Language Ability:</span> Students must have a sufficient level of English language proficiency to work in Ireland.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the specific requirements and guidelines for PSW in Ireland may change over time, and it is recommended that students check with the Irish Naturalisation and Immigration Service for the most up-to-date information.</p>

                </div>
            </div>
        </div>
    )
}

export default Ireland_PSW
