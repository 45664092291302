import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_PR = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada PR - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada PR"
                />
                <meta
                    name="Keyword"
                    content="Canada PR"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Permanent residence</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Indian students who have completed a study program in Canada may be eligible to apply for permanent residency (PR) in Canada. The process for applying for PR involves several steps and can be complex, so it's recommended to consult with an immigration lawyer or an authorized representative for the most up-to-date information.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here is a general overview of the process for Indian students to apply for permanent residency in Canada after completing a study program.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain a Post-Graduation Work Permit (PGWP) if eligible:</span> Indian students who have completed a full-time program of study that lasted at least eight months may be eligible to apply for a PGWP, which allows them to work in Canada for up to three years after graduation. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gain Canadian work experience:</span> While holding a PGWP, students should work in Canada and gain Canadian work experience to increase their chances of being accepted for PR.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Determine eligibility for a PR program:</span> There are several PR programs in Canada, including the Federal Skilled Worker Program, the Federal Skilled Trades Program, and the Canadian Experience Class, among others. Indian students should determine which program they are eligible for based on their work experience, education, language skills, and other factors.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit an Expression of Interest (EOI):</span> Once a student has determined their eligibility for a PR program, they should submit an EOI to the Government of Canada, indicating their interest in immigrating to Canada as a permanent resident.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive an invitation to apply for PR:</span> If a student's EOI is selected, they will receive an invitation to apply for PR, which they must do within a specified timeframe.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit a complete PR application:</span> Once an invitation to apply for PR has been received, the student must submit a complete PR application, including all required documentation and fees, within the specified timeframe.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend a medical examination and interview:</span> If required, the student may need to attend a medical examination and an interview with a visa officer to determine their eligibility for PR.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive a decision on their PR application:</span> After completing all the steps in the PR process, the student will receive a decision on their PR application from the Government of Canada.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>This is a general overview of the PR process for Indian students in Canada, and the exact process may vary based on individual circumstances. It's important to consult with an immigration lawyer or an authorized representative for the most up-to-date information and guidance.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>PGWP</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in Canada who have completed their studies may be eligible for a Post-Graduation Work Permit (PGWP). The PGWP allows students to work in Canada for up to three years after graduation, helping them gain valuable Canadian work experience. To be eligible for a PGWP, the student must have:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Studied full-time in Canada and completed a program of study that is at least 8 months in duration</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Graduated from a designated learning institution</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Applied for the PGWP within 90 days of receiving written confirmation (such as a transcript or an official letter) from their educational institution indicating that they have met the requirements for completing their program of study</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Obtained a valid study permit before applying for the PGWP</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Demonstrated proficiency in English or French by passing a language test approved by Immigration, Refugees and Citizenship Canada (IRCC)</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that eligibility requirements for the PGWP can change, so students should check the latest information on the IRCC website.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>EOI</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Expression of Interest (EOI) is a system used by the Government of Canada to manage applications for permanent residency (PR) under certain immigration programs, such as the Federal Skilled Worker Program and the Canadian Experience Class.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Under the EOI system, individuals interested in immigrating to Canada as permanent residents submit an expression of interest to the government, indicating their qualifications and interest in immigrating to Canada. The government then uses a points-based system to assess and rank the EOIs, and invites the highest-scoring individuals to apply for PR.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The EOI system is designed to make the PR application process more efficient and to help ensure that the most qualified individuals are selected for PR. However, submitting an EOI does not guarantee that an individual will receive an invitation to apply for PR, as the number of invitations issued is determined by the government and may change over time.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that not all PR programs in Canada use the EOI system, and the eligibility criteria and application process for PR can change. It's recommended to consult with an immigration lawyer or an authorized representative for the most up-to-date information on the EOI system and the PR process in Canada.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Rights of pr holder</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Permanent Residents (PRs) in Canada have the following rights:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Live, work and study anywhere in Canada:</span> PRs have the freedom to live and work in any province or territory in Canada. They can also study at any educational institution in Canada.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Access to public health care:</span> PRs have access to publicly funded health care services through the Canada Health Act.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Protection under Canadian law:</span> PRs are protected under Canadian law and have access to the Canadian justice system.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Ability to sponsor family members:</span> PRs can sponsor their family members to come to Canada as permanent residents.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for citizenship:</span> After living in Canada as a PR for at least three years, PRs are eligible to apply for Canadian citizenship.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Social benefits:</span> PRs are eligible for certain social benefits such as Employment Insurance, Old Age Security, and the Canada Pension Plan.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that PRs are not citizens and do not have the same rights and privileges as citizens. For example, they cannot vote in federal or provincial elections, hold certain jobs that require high-level security clearance, or represent Canada abroad as a diplomatic agent.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Stay Back option</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The length of stay-back options for Indian students after a study permit in Canada depends on the length of the study program they completed.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For students who completed a program of study that lasted at least eight months, they are eligible to apply for a Post-Graduation Work Permit (PGWP), which allows them to work in Canada for up to three years after graduation.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For students who completed a program of study that lasted less than eight months, they are not eligible for a PGWP but may be able to apply for a work permit under a different category, such as an open work permit or an employer-specific work permit, depending on their individual circumstances.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the eligibility criteria and application process for work permits can change, and students should consult with an immigration lawyer or an authorized representative for the most up-to-date information.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Citizenship process of Canada</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>After completing your education as an international student in Canada, you may be eligible to apply for Canadian citizenship, depending on various factors such as your immigration status, length of residency in Canada, and other eligibility criteria. The general process for obtaining Canadian citizenship is as follows:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Meet the eligibility criteria:</span> You must be a permanent resident of Canada and have lived in Canada for at least three out of the last five years, be 18 years or older, have an adequate knowledge of English or French, and have a clean criminal record.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather necessary documents:</span> You will need to provide original documents that support your residency, identity, and immigration status, as well as any other documents required by the government.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Take the citizenship test:</span> You may be required to take a test on Canadian history, values, and rights and responsibilities of citizenship.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit the citizenship application:</span> You must submit a complete citizenship application, including the fee and all required documents, to Immigration, Refugees and Citizenship Canada (IRCC).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend a citizenship ceremony:</span> If your application is approved, you will be required to attend a citizenship ceremony and take the Oath of Citizenship.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the process and requirements for Canadian citizenship can change, so it is always best to check the most up-to-date information on the IRCC website. Additionally, it is recommended to seek the guidance of a qualified immigration professional if you have any questions or concerns about the process.</p>

                </div>
            </div>
        </div>
    )
}

export default Canada_PR