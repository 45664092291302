import React from 'react'
import { Helmet } from "react-helmet";
const UK_Manchester = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Manchester - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Manchester"
                />
                <meta
                    name="Keyword"
                    content="UK Manchester"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Manchester</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Manchester is a city in the northwest of England, known for its rich history, world-class cultural attractions, and vibrant music scene. The population of Manchester is approximately 545,500. Manchester has a number of highly ranked universities, including the University of Manchester, Manchester Metropolitan University, and the Royal Northern College of Music. The average tuition fees for international students in Manchester can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Manchester is relatively affordable, with accommodation being one of the more significant expenses. Manchester has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Manchester include Deloitte, KPMG, and PwC. Job opportunities in Manchester are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Manchester
