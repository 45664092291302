import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Jobs = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Jobs - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Jobs"
                />
                <meta
                    name="Keyword"
                    content="UK Jobs"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>part time job in UK</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To secure a part-time job in the UK as an international student, you can follow these steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check your eligibility to work in the UK:</span> International students with a Tier 4 visa may be able to work part-time during term-time and full-time during vacations, depending on the conditions of their visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Update your CV and cover letter:</span> Adapt your CV to the UK format and highlight your relevant skills and experience.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Search for job openings:</span> You can search for part-time job openings through various online job portals, such as Indeed, Reed, or Totaljobs. You can also check with your university's career center or job fairs, local businesses, and local newspapers.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for the jobs:</span> Submit your job applications along with your CV and cover letter, following the instructions provided in the job adverts.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare for Interviews:</span> Research the company, practice your answers to common interview questions, and dress appropriately for the interview.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Follow up:</span> After the interview, send a thank you email to the interviewer, and wait for their response. If you don't hear back within a week or two, it's okay to follow up and inquire about the status of your application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: Some employers may require a National Insurance number, proof of your right to work in the UK, and a valid visa before they can offer you a job. Also, some jobs may require additional certifications or training, so make sure to check the job requirements before applying.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Some part time jobs are in UK</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students in the UK can work part-time while they study, although there are some restrictions on the number of hours they can work. Here is a list of common part-time jobs available to international students in the UK:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Retail and Sales Associate</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Hospitality Worker (e.g. Waiter/Waitress)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Customer Service Representative(e.g. call center, help desk)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Data Entry Clerk</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Tutor or Teaching Assistant(e.g. language tutoring, private tuition)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Barista or Bartender</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Library Assistant</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Receptionist</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Delivery Driver</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Cleaner or Janitor</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: Availability of part-time jobs may vary based on your location and the current job market, and it's important to research job opportunities in your area. Additionally, some employers may require you to have a certain level of English language proficiency.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Full time job in UK</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To secure a full-time job in the UK after completing your education as an international student, you can follow these steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check your eligibility to work in the UK:</span> International students with a Tier 4 visa may be able to work full-time after completing their studies, depending on the conditions of their visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Update your CV and cover letter:</span> Adapt your CV to the UK format and highlight your relevant skills and experience.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Network and build connections:</span> Attend job fairs, events, and networking opportunities to meet potential employers and learn about job openings. Utilize LinkedIn and other social media platforms to expand your network.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Search for job openings:</span> You can search for full-time job openings through various online job portals, such as Indeed, Reed, or Totaljobs. You can also check with your university's career center, recruitment agencies, local businesses, and industry-specific job boards.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for the jobs:</span> Submit your job applications along with your CV and cover letter, following the instructions provided in the job adverts.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare for Interviews:</span> Research the company, practice your answers to common interview questions, and dress appropriately for the interview.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Follow up:</span> After the interview, send a thank you email to the interviewer, and wait for their response. If you don't hear back within a week or two, it's okay to follow up and inquire about the status of your application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: Some employers may require a National Insurance number, proof of your right to work in the UK, and a valid visa before they can offer you a job. Also, some jobs may require additional certifications or training, so make sure to check the job requirements before applying.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top employer in UK</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here is a list of some of the top employers in the UK for international students:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Accenture</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>KPMG</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>PwC</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Deloitte</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>EY</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>HSBC</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Barclays</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>J.P. Morgan</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Amazon</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Google</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Microsoft</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Coca-Cola</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Unilever</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Rolls-Royce</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Tesco</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here is a list of popular websites and apps for finding part-time jobs in the UK along with their links:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Indeed <a className='text-blue-500' href='https://www.indeed.co.uk'>(https://www.indeed.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Reed <a className='text-blue-500' href='https://www.reed.co.uk'>(https://www.reed.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Totaljobs <a className='text-blue-500' href='https://www.totaljobs.com'>(https://www.totaljobs.com)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Gumtree <a className='text-blue-500' href='https://www.gumtree.com'>(https://www.gumtree.com)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Adzuna <a className='text-blue-500' href='https://www.adzuna.co.uk'>(https://www.adzuna.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Monster <a className='text-blue-500' href='https://www.monster.co.uk'>(https://www.monster.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>StudentJob UK <a className='text-blue-500' href='https://www.studentjob.co.uk'>(https://www.studentjob.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Jobsite <a className='text-blue-500' href='https://www.jobsite.co.uk'>(https://www.jobsite.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Snagajob <a className='text-blue-500' href='https://www.snagajob.co.uk'>(https://www.snagajob.co.uk)</a></p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif  '>Nijobsearch <a className='text-blue-500' href='https://www.nijobsearch.co.uk'>(https://www.nijobsearch.co.uk)</a></p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UK_Jobs