import React from 'react'
import { Helmet } from "react-helmet";
const UK_Aberdeen = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Aberdeen - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Aberdeen"
                />
                <meta
                    name="Keyword"
                    content="UK Aberdeen"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Aberdeen</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Aberdeen is a city in northeast Scotland, known for its rich history, cultural heritage, and thriving arts scene. The population of Aberdeen is approximately 220,000. Aberdeen has a number of highly ranked universities, including the University of Aberdeen, Robert Gordon University, and Aberdeen College. The average tuition fees for international students in Aberdeen can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Aberdeen is relatively high, with accommodation being one of the more significant expenses. Aberdeen has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Aberdeen include Royal Bank of Scotland, Standard Life Aberdeen, and BP. Job opportunities in Aberdeen are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Aberdeen
