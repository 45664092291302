import React from 'react'
import { Helmet } from "react-helmet";
import {BsFillEmojiFrownFill} from "react-icons/bs"
const ErrorPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error Page - Future Dream Makers</title>
        <meta
          name="description"
          content="Error Page"
        />
        <meta
          name="Keyword"
          content="Error Page"
        />
      </Helmet>
      <div className='flex justify-center md:flex-row flex-col 2xl:px-64 lg:mt-0 mt-20 md:mt-28 md:ml-0 ml-4 mb-6'>
        <div className='md:text-justify border-4 border-red-500 grid grid-cols-1'>
          <BsFillEmojiFrownFill  className='text-yellow-500 text-9xl m-auto mt-2'/>
          <p className=' font-bold text-black md:text-4xl text-2xl font-serif  mb-6 mt-8  px-8'>Sorry</p>
          <p className=' md:text-4xl text-2xl text-black  font-serif py-1 font-bold  px-8 mb-6 '>Error 404</p>
          <p className=' md:text-4xl text-2xl font-serif py-1 font-bold text-black  px-8 mb-6 '>Page not found</p>
          <a class="inline-flex items-center px-4 mx-auto py-2 mb-2 text-white bg-red-500 border border-red-500 rounded-full hover:bg-red-500 focus:outline-none focus:ring">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-3 h-3 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span className="md:text-lg font-subheading text-sm">
                      <a href="/">Home</a>
                    </span>
                  </a>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
