import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const PR_Counselling = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PR Counselling - Future Dream Makers</title>
                <meta
                    name="description"
                    content="PR Counselling"
                />
                <meta
                    name="Keyword"
                    content="PR Counselling"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-PRCounselling bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
           PR COUNSELLING
          </p>
        </div>
      </div>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify'>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>PR Counselling</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Permanent resident (PR) counseling is a process of providing guidance and support to individuals who are interested in becoming permanent residents of a country. It typically involves a professional counselor or advisor who helps the individual navigate the process of applying for PR status and provides information about the requirements, eligibility criteria, and documentation needed for the application.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The counselor may also assist the individual in understanding the cultural and social differences between their home country and the country they are applying to become a permanent resident of. They may also help the individual prepare for any interviews or tests that may be required as part of the application process.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In some cases, the counselor may also provide guidance on finding employment or housing in the new country, as well as information on healthcare, education, and other services that are available to permanent residents.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Overall, the goal of PR counseling is to help the individual make a smooth transition to their new country of residence by providing them with the necessary information and support.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Process of PR counselling</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of permanent resident (PR) counseling may vary depending on the country or region you are applying from, but generally it involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Initial consultation:</span>Begin by researching universities that offer the program you're interested in. Look at the universities' websites, talk to current students or alumni, and gather information about the program, the campus, and the surrounding community.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Eligibility assessment:</span>Based on your research, narrow down your list of potential universities to a smaller group that best fits your needs and preferences.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Application assistance:</span>If possible, visit the campus of each university on your short list. This will give you a better sense of the university's atmosphere and culture, and help you decide if it's the right fit for you.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Post-application support:</span>Once you have identified the university you would like to apply, Prepare the required documents and submit the application to the university.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Final decision:</span>Universities will notify you of their decision. If you are accepted, you will typically have to make a decision about whether to attend or not.</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the process may differ based on the country or region you are applying from, and some countries have specific requirements, deadlines, or procedures to follow. Additionally, some counselors may charge a fee for their services, so it's important to inquire about this before beginning the process.</p>

                </div>
            </div>
        </div>
    )
}

export default PR_Counselling