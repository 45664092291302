import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const BHM = () => {
   return (
      <div>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Bachelor of Hotel Management - Future Dream Makers</title>
            <meta
               name="description"
               content="Bachelor of Hotel Management "
            />
            <meta
               name="Keyword"
               content="BHM"
            />
         </Helmet>
         <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-BHM bg-cover md:bg-top bg-center' >
            <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
               <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                  Bachelor of Hotel Management (BHM)
               </p>
            </div>
         </div>
         <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
            <div className='md:text-justify'>
               <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BHM</p>
               <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Bachelor of Hotel Management (BHM) programme is a three-year undergraduate degree programme in hospitality management. A few colleges and institutes, such as WelcomGroup Graduate School of Hotel Management, Army Institute of Hotel Management and Catering Technology, and others, also offer this course for four years.</p>
               <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To be eligible for this course, you must have a minimum of 50% in your 10+2 or equivalent, with English as a mandatory subject. The eligibility criteria and pass percentages differ from one institute to the next. Admission to this course is possible based on both entrance exams and merit. To be a good fit for this course, you must have excellent communication and interpersonal skills. The BHM course curriculum includes subjects such as housekeeping operations, front office operations, food and beverage services, and kitchen services.</p>

               <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BHM Eligibility Criteria</p>
               <div className='flex md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Your age should fall between 19 and 22 years old (General Category). SC and ST candidates are given a 2-3 year reprieve.</p>
               </div>
               <div className='flex md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>You must have obtained or secured at least a 50% grade point average in 10+2 from a recognised board or equivalent.</p>
               </div>
               <div className='flex md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>If you are applying for lateral entry admissions, you must have a minimum of a 50 percent grade point average in a 3-year diploma in hotel management or equivalent.</p>
               </div>
               <div className='flex md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>You must have finished your qualification with English as a required subject.</p>
               </div>
               <div className='flex md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>You should be able to communicate effectively.</p>
               </div>

               <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BHM Entrance Exam</p>
               <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BHM admissions are typically merit-based, but only a few colleges and institutes administer their own aptitude tests. UPSEE, AIHMCT WAT, IIHM ECHAT, and other entrance exams may be required of you. This course has three different modes of admission, which are discussed below.</p>
               <div className='flex p-2   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Merit-Based Admission</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Entrance-Based Admission</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Lateral-Based Admission</p>
               </div>
               <p className=' text-xl font-serif py-1 font-normal  px-8 mb-6' >The examination usually lasts three hours and consists of 200 questions divided into the five sections listed above. Because these entrance exams are followed by Group Discussion and Personal Interview rounds, one must also prepare thoroughly for those additional rounds. The entrance test covers the 5 sections below:</p>
               <div className='flex px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Numerical Ability</p>
               </div>
               <div className='flex px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Reading Comprehension</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>General Awareness</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Logical Reasoning</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Hospitality Sector Questions</p>
               </div>
               <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>BHM Distance Learning</p>
               <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6' >Distance learning is also an option for pursuing a Bachelor of Hotel Management degree. There are top and well-known institutes and universities that provide BHM via correspondence. People also wonder if BHM is available at IGNOU, but Indira Gandhi National Open University does not offer the BHM course.</p>
               <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6' >The advantages of pursuing a BHM through distance education are numerous, including:</p>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>This option allows you to learn the course study from reputable colleges and universities without leaving your hometown.</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 '>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The study material for distance learning is delivered via postal service, emails, pen drives, and online classes.</p>
               </div>
               <div className='flex   px-0 md:ml-0 ml-4 mb-6'>
                  <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The course fee is usually less than that of a regular college course.</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default BHM