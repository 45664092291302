import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_VFS = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK VFS - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK VFS"
                />
                <meta
                    name="Keyword"
                    content="UK VFS"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>VFS</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of booking a VFS appointment for a UK international student visa is as follows:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Go to the VFS Global website: <a className='text-blue-500' href='https://www.vfsglobal.com/UK/India/'>https://www.vfsglobal.com/UK/India/</a></p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Choose the type of visa you are applying for (student visa).</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Fill in the online visa application form</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Pay the visa fee</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Book an appointment with a VFS Global center</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Visit the VFS Global center on the scheduled appointment date with all required documents and biometric information</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Wait for a decision on your visa application</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The specific requirements and details for a UK student visa application may vary, so it is important to check the UK government's official visa website for the most up-to-date information.</p>

                </div>
            </div>
        </div>
    )
}

export default UK_VFS