import React from 'react'
import { Helmet } from "react-helmet";
import { BsDot } from 'react-icons/bs'
const Statement_of_purpose = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Statement Of Purpose - Future Dream Makers</title>
        <meta
          name="description"
          content="Statement Of Purpose"
        />
        <meta
          name="Keyword"
          content="Statement Of Purpose"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-SOP bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            Statement Of Purpose
          </p>
        </div>
      </div>

      <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
        <div className='md:text-justify'>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Statement Of Purpose (SOP)</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>It may not be your style to highlight your achievements in the greatest possible light, but it is ours. We at Future Dream Makers are quite proud of our ability to provide the best SOP writing services in India. Our team of professionals has been properly qualified to assist you in writing and editing your Statement of Purpose draughts in order to increase your chances of being selected abroad.</p>
          <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >What Is A Statement Of Purpose?</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SOP is a professionally documented essay that serves as a synthesis of all of your life’s high highlights. Because the Statement of Purpose can make or break your ambition, hiring professionals gives you the freedom to widen your academic horizons.</p>
          <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >How Will Team Future Dream Makers Will Assist With Your SOP</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>If you’re a student determined to go across the globe to attend a prestigious college or university, you’re probably well aware of the critical role SOP plays in making your ambition a reality.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Our clients’ overwhelming happiness with our work is what distinguishes Future Dream Makers. Each SOP is evaluated and addressed separately. We have the ability to provide personalised and unique essays in a timely manner. Our expert writers are among the best in the business. All of this, combined with our years of painstakingly broad experience, has allowed us to carve out a unique niche for ourselves.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Over the last 08 years, our staff has supplied over 3000+ satisfying SOPs, LORs, and Justification Letters with unwavering dedication and unquestionable work ethic. Our team of expert SOP writers draughts based on your comments, following the requirements set forth by the aforementioned university or employer. The draught will be forwarded to you for review and correction. There will be no additional fees associated with the corrections. The finalised paper will be given to you after additional communication and modifications.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Process of statement of purpose</p>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Researching the program and the institution to understand their requirements and expectations</p>
          </div>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Reflecting on your past experiences, accomplishments, and future goals</p>
          </div>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Brainstorming ideas and making an outline.</p>
          </div>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Writing multiple drafts, seeking feedback, and making revisions</p>
          </div>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Finalizing the statement, proofreading and editing</p>
          </div>
          <div className='flex md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Submitting the statement along with other application materials</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Statement_of_purpose