import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Limerick = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Limerick- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Limerick"
                />
                <meta
                    name="Keyword"
                    content="Ireland Limerick"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Limerick</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Limerick is a city in the mid-west of Ireland, with a population of over 100,000 people. It is home to several universities and colleges, including University of Limerick and Limerick Institute of Technology. The average tuition fees for international students in Limerick range from €10,000 to €20,000 per year. Some of the top courses for international students in Limerick include engineering, computer science, and health sciences. The highest paying jobs in Limerick are in the technology, pharmaceutical, and medical device sectors, with roles such as software engineer, research scientist, and sales manager. The average wage for these jobs is between €50,000 and €70,000 per year. The cost of living in Limerick is relatively lower compared to other cities in Ireland, with an average monthly rent of €700 and a monthly grocery bill of €250. Limerick is known for its rich history and cultural heritage, with several museums, galleries, and cultural events to explore. There are also several part-time and full-time job opportunities for international students in Limerick, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Limerick