import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const MPSC = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MPSC - Future Dream Makers</title>
                <meta
                    name="description"
                    content="MPSC"
                />
                <meta
                    name="Keyword"
                    content="MPSC"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-MPSC bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        MPSC
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is MPSC ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>MPSC is an abbreviation for Maharashtra Public Service Commission (MPSC). MPSC is a Maharashtra Government organisation in charge of conducting exams for recruitment to Maharashtra Government departments.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Maharashtra Public Service Commission (MPSC) is a body established by the Indian Constitution to select applicants for civil service jobs in the Indian state of Maharashtra based on merit and reservation rules.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>As previously stated, an MPSC exam is required if you want to work in a government department. It could be for Maharashtra government clerical jobs, Maharashtra government engineering jobs, Maharashtra income tax department jobs, Maharashtra government deputy collector jobs,  Govt. of Maharashtra jobs of police sub-inspector and generally for any post of Class I and Class II Officers Govt. of Maharashtra.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>MPSC Eligibility Criteria</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The MPSC Rajyaseva Prelims exam was conducted for 390 vacancy posts. Applicants who have appeared for the exam can check the difficulty level, subject-wise questions distribution, questions asked. expected cut-off and more.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The standard eligibility criteria for MPSC Rajyaseva Exam :</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Age Limit for MPSC</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Educational Qualification for MPSC</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Physical Measurements for MPSC</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Other Eligibility Criteria for MPSC</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The age limit for the MPSC Rajya Seva Examination.</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Minimum Age: 19 years</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-bold font-serif '>Maximum Age: 38 years</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>MPSC Exam Format</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Knowing the MPSC Rajyaseva Exam Pattern allows you to plan your preparation for the exam. The MPSC Rajyaseva Exam is divided into three stages, which are listed below.</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span>MPSC Prelims Exam-</span>2 papers- 400 marks</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span>MPSC Mains Exam-</span>6 papers- 800 marks</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span>MPSC Interview-</span>100 marks</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Each paper is worth 200 points and has a time limit of two hours.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Aspirants will lose one-quarter of their marks for each incorrect answer. Previously, aspirants received a 13-point deduction for each incorrect answer.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>All of the questions are written in both Marathi and English.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>The Prelims marks will only be used to shortlist candidates for the MPSC Mains exam.</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">PAPER</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">MARKS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">MEDIUM</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NATURE OF PAPER</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Paper-I (Compulsory)</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">200</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">100</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">English & Marathi</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Objective Type</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 Hours</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Paper-II (Compulsory)	</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">200</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">100</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">English & Marathi</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Objective Type</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 Hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MPSC