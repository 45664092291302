import React from 'react'
import { Helmet } from "react-helmet";
const UK_Belfast = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Belfast - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Belfast"
                />
                <meta
                    name="Keyword"
                    content="UK Belfast"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Belfast</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Belfast is the capital city of Northern Ireland and the largest city in the province. It has a population of approximately 333,000 people. Belfast is home to a number of highly ranked universities, including Queen's University Belfast, the University of Ulster, and St Mary's University College. The average tuition fees for international students in Belfast can range from £10,000 to £15,000 per year, depending on the institution and program. The cost of living in Belfast is relatively affordable, with accommodation being one of the more significant expenses. Belfast has a strong economy, with top companies in industries such as finance, technology, and retail. Some of the top companies in Belfast include Allstate, Citi, and Bombardier Aerospace. Job opportunities in Belfast are diverse, with popular job roles including software developer, financial analyst, and nurse.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Belfast
