import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Funds = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Funds - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Funds"
                />
                <meta
                    name="Keyword"
                    content="UK Funds"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Funds</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students applying for a Tier 4 (Student) visa in the UK must prove that they have sufficient funds to support themselves without relying on public funds during their studies. This can be done by providing evidence of funds in the form of:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Personal savings</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Scholarships or bursaries</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Sponsorship from family or friends</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Government sponsorship</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Bank statements or financial reports</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for proving funds involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Review the Tier 4 visa requirements for funds, which may vary based on individual circumstances and length of stay.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Gather the necessary financial documents, such as bank statements or sponsorships.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Ensure that the financial documents meet the requirements set by the UK visa authorities.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Submit the financial documents as part of the Tier 4 visa application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The requirements and process for proving funds may change, it's recommended to check the latest information on the UK government website.</p>


                </div>
            </div>
        </div>
    )
}

export default UK_Funds