import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Switch } from 'react-router-dom'
import Whatsapp from './static-module/FixedComponents/Whatsapp';
import TopScroll from './static-module/FixedComponents/TopScroll';
import Navbar from './static-module/FixedComponents/Navbar';
import Footer from './static-module/FixedComponents/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Navbar/>
    <TopScroll></TopScroll>
    <Whatsapp></Whatsapp>
    <Switch>
      <App />
    </Switch>
    <Footer/>
  </BrowserRouter>
);
