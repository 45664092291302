import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Italy_CostOfLiving = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy Costing- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy Costing"
                />
                <meta
                    name="Keyword"
                    content="Italy Costing"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Cost of living</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living in Italy can vary based on several factors, including the city, type of accommodation, and personal expenses. However, on average, students can expect to spend the following amounts per month:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Accommodation:</span> €300 to €800, depending on the type of accommodation (e.g. single room in a student residence, shared apartment, etc.).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Food:</span> €300 to €500, depending on personal eating habits and the type of food purchased (e.g. groceries, eating out, etc.).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Transportation:</span> €50 to €100, depending on the city and the frequency of use.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Miscellaneous expenses (e.g. books, clothing, entertainment, etc.):</span> €200 to €500.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these estimates are for general guidance only and that actual costs will vary based on individual circumstances. Students should plan for additional expenses such as tuition fees, health insurance, and travel costs, and budget accordingly.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's also recommended that students open a bank account in Italy and get a local SIM card for their phone upon arrival, as this can help to reduce expenses in the long run.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>local SIM card</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Getting a local SIM card in Italy is a convenient option for international students as it allows them to have a local phone number and access to mobile data while they are in the country. There are several options available for purchasing a SIM card in Italy, including the following:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Telecom providers:</span> Major telecom providers in Italy, such as TIM, Vodafone, and WindTre, offer a variety of plans for voice calls, text messages, and data. These plans can be purchased online or in-store, and students will need to provide proof of identity and address (e.g. passport, Italian tax code).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepaid SIM cards:</span> Prepaid SIM cards can be purchased at many convenience stores, electronics stores, and airports, and they do not require a contract. These cards can be loaded with credit, which can be used for voice calls, text messages, and data.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Virtual providers:</span> Virtual providers, such as Lycamobile and CoopVoce, offer competitive rates for voice calls, text messages, and data. These providers operate on the networks of major telecom providers and offer their services through prepaid SIM cards.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>When choosing a SIM card, it is important to compare plans and prices, and to consider factors such as network coverage, data allowances, and international calling options.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's recommended that students research their options and compare plans before purchasing a SIM card in Italy to ensure that they get the best value for their money.</p>
                </div>
            </div>
        </div>
    )
}

export default Italy_CostOfLiving
