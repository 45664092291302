import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Costing = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Budget - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Budget"
                />
                <meta
                    name="Keyword"
                    content="USA Budget"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Average costing for masters students in USA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The average cost of a master's degree in the USA for an Indian student can range from $30,000 to $80,000 per year. This includes tuition fees, living expenses, and other miscellaneous expenses. The actual cost will depend on the institution and location, as well as the specific program and lifestyle of the student.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Average costing for UG</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The average cost of an undergraduate degree in the USA for an Indian student can range from $20,000 to $60,000 per year. This includes tuition fees, living expenses, and other miscellaneous expenses. The actual cost will depend on the institution and location, as well as the student's specific program and lifestyle.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Average costing for MBA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The average cost of an MBA degree in the USA for an Indian student can range from $50,000 to $150,000 or more. This includes tuition fees, living expenses, and other miscellaneous expenses. The actual cost will depend on the institution and location, as well as the student's specific program and lifestyle.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Problems and solution for international students in currency exchange</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Indian students studying abroad in the USA as international students may face the following problems during currency exchange:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Exchange rate fluctuations:</span> The value of the Indian Rupee may fluctuate relative to the US Dollar, making it difficult to budget and plan expenses.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Hidden fees:</span> Some currency exchange services may charge hidden fees, leading to a higher cost for the student.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Limited options:</span> There may be limited options for exchanging currency in the USA, making it difficult for students to access Indian Rupees when needed.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Lack of familiar banking services:</span> Indian students may not be familiar with the banking services available in the USA, making it difficult to manage their finances.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Currency conversion errors:</span> Mistakes can occur during currency conversion, leading to incorrect amounts being credited or debited from a student's account.</p>
                    </div>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Solutions to these problems may include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Monitoring exchange rates:</span> Students can monitor exchange rates and exchange currency when rates are favorable.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Comparing exchange services:</span> Students can compare fees and exchange rates offered by different services to find the best option.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Using a trusted exchange service:</span> Students can use a trusted exchange service that offers competitive rates and low fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Opening a local bank account:</span> Students can open a local bank account in the USA to manage their finances more easily.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Seeking advice from financial experts:</span> Students can seek advice from financial experts, such as their bank or a currency exchange specialist, to help manage their finances while studying abroad.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>By taking these steps, international students can help minimize the problems they face during currency exchange and manage their finances more effectively while studying in the USA.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Some popular courses </p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are some popular master's degree programs in the US and their average salary range:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Business Administration (MBA):</span> This program focuses on business management and leadership. The average salary range is $70,000-$150,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Computer Science (MCS):</span> This program focuses on computer science and technology. The average salary range is $80,000-$140,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Education (MEd):</span> This program focuses on education and teaching. The average salary range is $50,000-$90,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Engineering (MEng):</span> This program focuses on engineering and technical design. The average salary range is $80,000-$140,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Public Health (MPH):</span> This program focuses on public health and healthcare management. The average salary range is $60,000-$120,000.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Data Science (MSDS):</span> This program focuses on data science and analytics. The average salary range is $90,000-$140,000.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the salary ranges mentioned are just estimates and can vary based on factors such as location, company, and experience level. Additionally, the job market and salaries for different master's programs can change over time and may be impacted by economic conditions.</p>


                </div>
            </div>
        </div>
    )
}

export default USA_Costing
