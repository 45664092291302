import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const SAT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SAT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="SAT"
                />
                <meta
                    name="Keyword"
                    content="SAT"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-SAT bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        SAT
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is SAT ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SAT is a college entrance exam that is used by the majority of colleges and universities to make admissions decisions. The College Board created and administers the SAT, a multiple-choice, pencil-and-paper exam.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The purpose of the SAT is to assess a high school student’s readiness for college and to provide colleges with a single point of comparison for all applicants. College admissions officers will look at your standardised test scores, high school GPA, classes you took in high school, letters of recommendation from teachers or mentors, extracurricular activities, admissions interviews, and personal essays. The importance of SAT scores in the college application process varies by school.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Overall, the higher your SAT/ACT score, the more options for attending and paying for college you will have.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Best Time To Take SAT</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SAT, ACT, or both are taken by the majority of high school students in the spring of their junior year or the fall of their senior year. It’s critical to allow enough time to retake the test if you need to improve your score before applying to college. Every year in August, October, November, December, March, May, and June, the SAT exam is administered across the country. View a list of all upcoming SAT test dates.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>SAT Exam Pattern</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>There are Two SAT sections:</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Mathematics</span></p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Evidence – Reading and Writing Based</span></p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SAT also includes an essay section that is optional. Scores for the SAT Essay are reported separately from overall test scores. Some colleges might require you to take the SAT Essay.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SAT lasts three hours. If you choose to take the SAT with Essay, you will have 3 hours and 50 minutes to complete the exam.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Each section of the SAT is scored on a scale of 200 to 800 points. The sum of your section scores equals your total SAT score. The maximum SAT score that can be obtained is 1600. You will receive a separate score if you take the Essay.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>When To Register For SAT</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The SAT registration deadlines are about five weeks before each test date. On the College Board’s website, you can register online. Under special circumstances, the College Board may require SAT registration by mail.</p>
                </div>
            </div>
        </div>
    )
}

export default SAT