import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Helmet } from "react-helmet";
const Australia = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const Australia_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-1.jpg' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-2.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-3.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-4.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-5.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Australia+logo/Australia_university-logo-6.jpg" }
    ];

    const Sydney = () => {
        history.push('australia/sydney')
        window.scrollTo(0, 0);
    }
    const Melbourne = () => {
        history.push('australia/melbourne')
        window.scrollTo(0, 0);
    }
    const Brisbane = () => {
        history.push('australia/brisbane')
        window.scrollTo(0, 0);
    }
    const Adelaide = () => {
        history.push('australia/adelaide')
        window.scrollTo(0, 0);
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia"
                />
                <meta
                    name="Keyword"
                    content="Australia"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Australia bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        Australia
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Australia</p>
                    <div className='flex p-2  px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Australia is a country and continent located in the Southern Hemisphere. It is the world's sixth-largest country by total area and has a population of approximately 25 million people. Australia is known for its diverse landscapes, including deserts, forests, beaches, and mountains, as well as its unique flora and fauna, such as kangaroos and koalas.</p>
                    </div>
                    <div className='flex p-2  px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Australia is known for its high-quality education system, with many universities ranked among the best in the world. Australia is also famous for it's <span className='text-red-500 '><a href='/australia/go8'>G08</a></span> universities.</p>
                    </div>
                    <div className='flex p-2  px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The country has a strong economy and is known for its high standard of living, excellent healthcare system, and good quality of life. The capital city of Australia is Canberra, and the largest city is Sydney. Other major cities include <span className='text-red-500 '><a href='/australia/melbourne'>Melbourne</a></span>, <span className='text-red-500 '><a href='/australia/brisbane'>Brisbane</a></span>, <span className='text-red-500 '><a href='/australia/sydney'>Sydney</a></span>, and <span className='text-red-500 '><a href='/australia/adelaide'>Adelaide</a></span>.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>A CRICOS Registered Institute ensures that the institute and programme meet the highest standards for overseas students. Australian universities are well-known around the world, with seven of the top 100 universities in the world based in the country.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why to choose Australia</p>
                    <p className='text-lg font-serif py-2 font-normal  px-8 mb-6'>The education system in Australia is highly regarded globally and is known for its quality and diversity.</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Quality education:</span> Australia is known for its high-quality education system, with many universities ranked among the best in the world.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Diverse study options:</span> Australia offers a wide range of <span className='text-red-500 '><a href='/australia/top-courses'>study options</a></span>, from traditional academic subjects to cutting-edge industries and practical skills training.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Multicultural society:</span> Australia is a culturally diverse country with a vibrant, inclusive, and friendly society that welcomes international students.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Safe and stable environment:</span> Australia is known for its low crime rates, stable political system, and high standard of living, making it a safe and secure destination for international students.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Work opportunities:</span> International students are allowed to work while they study in Australia, providing them with valuable work experience and the opportunity to support themselves financially.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Beautiful landscapes:</span> Australia is home to unique landscapes and diverse wildlife, making it a great place to explore and experience the natural beauty of the world.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Career prospects:</span> Upon graduation, international students can apply for post-study work visas, providing them with the opportunity to stay and work in Australia, thereby improving their career prospects.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>AUSTRALIA ADMISSION PROCESS</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are the general steps that Indian students can follow to apply to top universities in Australia:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Research and select the universities and courses you're interested in:</span> Research the universities and courses that you're interested in and make sure they're a good fit for your academic ,career goals and also consider the tution <span className='text-red-500 '><a href='/australia/fee'>fee</a></span> and livig expenses</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Check the entry requirements:</span> Make sure you meet the entry requirements for the courses you're interested in, such as English language proficiency (<span className='text-red-500 '><a href='/australia/exams-required'>exams required</a></span>), academic qualifications, and any relevant experience.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Prepare your application:</span> This typically includes preparing your personal statement, academic transcripts, test scores (such as the <span className='text-red-500 '><a href='/ielts'>IELTS</a></span> or <span className='text-red-500 '><a href='/toefl'>TOEFL</a></span> for English language proficiency), and other required documents.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submit your application:</span> Submit your application and all required documents to the universities you're interested in</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Universities will review your application and may request additional information or an interview. Once a decision has been made, you will receive an offer of admission.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for a student visa:</span> If you receive an offer of admission, you can then apply for a student visa through the Department of Home Affairs. You will need to provide evidence of your enrolment, financial support, and health insurance with <span className='text-red-500 '><a href='/australia/medical'>medical</a></span>.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Arrange for accommodation:</span> If you haven't already, you'll need to arrange for <span className='text-red-500 '><a href='/australia/accommodation'>accommodation</a></span> in Australia. This can include on-campus housing, off-campus housing, or homestay arrangements.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8 '>VISA PROCESS</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Subclass 500 is a type of student visa that allows you to study in Australia. With this visa, you can study full-time at an Australian educational institution, such as a university, college, or school. After that you can apply for <span className='text-red-500 '><a href='/australia/post-study-visa'>post study work visa</a></span> . To be eligible for a Subclass 500 visa, you must meet certain criteria, including:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Enrolment:</span> You must be enrolled in a course of study at an approved Australian educational institution.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Genuine Temporary Entrant (<span className='text-red-500 '><a href='/australia/gte'>GTE</a></span>) requirement:</span> You must demonstrate that you are a genuine temporary entrant and have a genuine intention to study in Australia.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Health and character requirements:</span> You must meet Australia's health and character requirements, which may include a medical examination and police check.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>English language proficiency:</span> You must demonstrate that you have a minimum level of English language proficiency, as determined by your educational institution or an approved English language test.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Financial capacity:</span> You must demonstrate that you have sufficient <span className='text-red-500 '><a href='/australia/funds'>funds</a></span> to support yourself and any dependents while in Australia.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Insurance:</span> You must have adequate overseas student health cover (<span className='text-red-500 '><a href='/australia/ohsc'>OSHC</a></span>) for the duration of your stay in Australia.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Once you have met these requirements, you can apply for a Subclass 500 visa. You will need to provide supporting documentation, including proof of enrolment, financial capacity, English language proficiency, and health and character requirements, among others. If your visa application is approved, you will be able to study in Australia for the duration of your course.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The visa application fee for a Subclass 500 (Student visa) in Australia can vary depending on several factors, including the length of the course you plan to study and the number of family members included in the application. As of my knowledge cut-off of 2021, the visa application fee for a single applicant for a Subclass 500 visa is AUD 575. However, this fee is subject to change and it is advisable to check the current fee on the official website of the Department of Home Affairs, Australia <a className='text-blue-500' href='https://www.homeaffairs.gov.au/' target="_blank">(https://www.homeaffairs.gov.au/)</a>.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In addition to the visa application fee, there may be additional costs involved in the visa application process, such as the cost of biometrics, medical examinations, and English language tests. It is important to consider these additional costs when planning and budgeting for your study in Australia.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process for applying for a Subclass 500 Student Visa in Australia involves the following steps:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Choose a course and university:</span> Select a course of study and an accredited university in Australia that matches your educational and career goals.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Meet the eligibility criteria:</span> : Make sure you meet the eligibility criteria for the Subclass 500 visa, including English language proficiency, financial requirements and health insurance.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Apply for admission:</span> Submit an application to the university of your choice and await an offer of a place.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Obtain a COE:</span> After receiving an offer, the university will provide you with a Confirmation of Enrollment (<span className='text-red-500 '><a href='/australia/coe'>COE</a></span>), which you will need to include in your visa application.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Lodge a visa application:</span> Submit your visa application, along with all required supporting documents, such as proof of identity , <span className='text-red-500 '><a href='/australia/biometrics'>biometrics</a></span>, financial capacity, health insurance, and police certificates.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Pay the visa fee:</span> Pay the visa application fee.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> Wait for a decision on your visa application. This can take several weeks or months.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Attend a visa interview:</span> If required, attend a visa interview with a visa officer.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Obtain the visa:</span> If your visa is approved, you will receive a visa grant notice, which will allow you to travel to Australia to start your studies.</p>
                    </div>


                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6'>
                        <button onClick={Sydney} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Sydney</button>
                        <button onClick={Melbourne} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Melbourne</button>
                        <button onClick={Brisbane} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Brisbane</button>
                        <button onClick={Adelaide} className='uppercase font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>Adelaide</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {Australia_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Australia