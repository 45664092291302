import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_Fee = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA Fee - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA Fee"
                />
                <meta
                    name="Keyword"
                    content="USA Fee"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Fee payment</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For Indian students studying in the United States, the common fee payment methods include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>International Wire Transfer:</span> Indian students can use this method to directly transfer funds from their bank in India to the university in the US.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Credit/debit cards:</span> Indian students can use their Indian credit/debit cards to pay their fees.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Personal Check or Draft:</span> Indian students can also send a personal check or draft to the university in the US.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Student Loans:</span> Indian students can apply for student loans in India to cover their education expenses in the US.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Sponsorship:</span> Indian students can also seek sponsorship from their parents, relatives, or organizations.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that different universities may have different accepted payment methods and that the exchange rate and transaction fees associated with international payments should be considered.</p>
                </div>
            </div>
        </div>
    )
}

export default USA_Fee
