import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_DLI = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada DLI - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada DLI"
                />
                <meta
                    name="Keyword"
                    content="Canada DLI"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>DLI</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A Designated Learning Institution (DLI) is a school in Canada that is authorized by the government to host international students. In order to be eligible to apply for a student visa to study in Canada, a student must be enrolled in a DLI. The DLI number is a unique identifier assigned by the government to each institution and is required on the student visa application.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>LOA</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of obtaining a Letter of Acceptance (LOA) for a Canada student visa typically involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Research:</span> Research and choose a Canadian educational institution that suits your needs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Application:</span> Apply to the educational institution and wait for acceptance.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>LOA:</span> Upon acceptance, the educational institution will provide you with a Letter of Acceptance.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Student Visa Application:</span> Use the LOA to apply for a Canada student visa through Immigration, Refugees and Citizenship Canada (IRCC).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Documentation:</span> Provide the required documentation to IRCC, including proof of financial support, language proficiency, and other relevant information.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Payment:</span> Pay the visa processing fee.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Processing time:</span> Wait for IRCC to process your application and make a decision on your student visa.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Canada_DLI