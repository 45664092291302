import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_OPT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>USA OPT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA OPT"
                />
                <meta
                    name="Keyword"
                    content="USA OPT"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>OPT</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>OPT stands for Optional Practical Training. It's a work authorization program for international students in the US on a student visa (F-1) that allows them to work in their field of study for up to 12 months (or 36 months for STEM students) after completing their academic program. To use OPT properly, students should follow the rules and regulations set by the US Citizenship and Immigration Services (USCIS), such as:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Maintaining valid F-1 status</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Working only in the field related to their degree</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Not working more than 20 hours per week while classes are in session</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Reporting any changes in employment to their designated school official (DSO)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Keeping track of their OPT employment dates and hours</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Additionally, students should make sure their employer is aware of the rules and regulations of the OPT program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>After OPT, Indian students who wish to stay in the US for a longer period may consider the following options:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>H1B Visa:</span> This is a common way for highly skilled workers to obtain a long-term visa to work in the US. To apply, the student needs a job offer from a US employer who sponsors their H1B visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Green Card:</span> This allows a foreign national to live and work permanently in the US. The process can be through employment-based sponsorship, family sponsorship, or self-sponsorship (e.g. through an investment or entrepreneurial activity).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>F-1 Visa Extension:</span> If the student is pursuing additional academic studies in the US, they may be eligible for an extension of their F-1 visa.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The eligibility and requirements for each option may vary and can be complex. It's recommended to seek guidance from an immigration lawyer or accredited representative</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here is an overview of the process of obtaining an H1B visa and a green card after OPT:</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>H1B Visa</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>A US employer must offer a job and agree to sponsor the student's H1B visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The employer must file a Labor Condition Application (LCA) with the US Department of Labor.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The employer must then file a Form I-129 (Petition for Nonimmigrant Worker) with the US Citizenship and Immigration Services (USCIS).</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>If the petition is approved, the student can apply for an H1B visa at a US consulate or embassy in their home country.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>After entering the US, the student can start working for the sponsoring employer.</p>
                    </div>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Green Card</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The student must first determine the eligibility category they fit in, such as employment-based sponsorship, family sponsorship, or self-sponsorship.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>If the student is sponsored by an employer, the employer must file a Form I-140 (Immigrant Petition for Alien Worker) with USCIS.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>If the I-140 is approved, the student must then file a Form I-485 (Application to Register Permanent Residence or Adjust Status) with USCIS if they are already in the US, or apply for an immigrant visa at a US consulate or embassy if they are outside the US.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>The student must attend an interview with USCIS if required.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>If the application is approved, the student will receive a green card and can live and work permanently in the US.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>CPT</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>CPT, or Curricular Practical Training, is a form of work authorization in the USA that allows international students on F-1 visas to engage in paid off-campus practical training that is directly related to their field of study. This type of training can include internships, co-op programs, or other work experiences.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To use the benefits of CPT, Indian students can follow the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Review eligibility requirements:</span> Ensure that you meet the eligibility criteria for CPT, including maintaining full-time enrollment and having a valid F-1 visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Seek advice from an international student advisor:</span> Consult with an international student advisor at your institution to determine if CPT is a good option for you and to receive guidance on the application process.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Identify suitable training opportunities:</span> Research and identify practical training opportunities that are related to your field of study and meet the requirements for CPT.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Secure employer sponsorship:</span> Secure an offer of employment from a US employer who is willing to sponsor you for CPT.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply for CPT:</span> Submit a formal application for CPT to your institution's international student office, including the necessary documents and information.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain the EAD card:</span> If your application is approved, you will receive an Employment Authorization Document (EAD) card, which will allow you to legally work in the USA during your CPT.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>By following these steps, Indian students can take advantage of the benefits of CPT, including gaining practical experience in their field of study, building their professional network, and earning an income while studying in the USA.</p>

                </div>
            </div>
        </div>
    )
}

export default USA_OPT