import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Ennis = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Ennis- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Ennis"
                />
                <meta
                    name="Keyword"
                    content="Ireland Ennis"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Ennis</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'> Ennis is a town in the west of Ireland, with a population of over 25,000 people. It is home to several universities and colleges, including the University of Limerick and the Limerick Institute of Technology. The average tuition fees for international students in Ennis range from €10,000 to €15,000 per year. Some of the top courses for international students in Ennis include engineering, health sciences, and arts. The highest paying jobs in Ennis are in the pharmaceutical, medical device, and technology sectors, with roles such as research scientist, software engineer, and sales manager. The average wage for these jobs is between €45,000 and €60,000 per year. The cost of living in Ennis is relatively lower compared to other cities in Ireland, with an average monthly rent of €500 and a monthly grocery bill of €200. Ennis is known for its beautiful parks, scenic walks, and the Ennis Book Club Festival. There are also several part-time and full-time job opportunities for international students in Ennis, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Ennis