import React from 'react'
import { Helmet } from "react-helmet";
const Letter_Of_Recommendation = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Letter Of Recommendation - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Letter Of Recommendation"
                />
                <meta
                    name="Keyword"
                    content="Letter Of Recommendation"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-LOR bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        Letter OF Recommendation
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Letter Of Recommendation (LOR)</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A Letter of Recommendation is an official document that is written on the applicant’s behalf. It must be written by someone who can vouch for the applicant’s academic and/or professional achievements. The letter is typically written to officials such as admissions officers or hiring managers at companies who will be considering the applicant for admission, a scholarship, or employment.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To have the greatest impact, the LOR must be concise, precise, and to the point.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Letters of recommendation can be extremely useful in completing out student visa and Permanent Residence applications. Its significance stems from the information it gives the admissions officer about the applicant’s personal characteristics. The applicant is an ideal prospect because of qualities such as integrity, intellectual curiosity, and leadership potential. LOR allows an admissions officer or potential employer to evaluate an applicant’s past performance, work ethic, and overall character.</p>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >Importance Of LOR</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 '>The majority of institutions and colleges require professional and academic references to write letters of recommendation. Professional references are often limited to coworkers, mentors, supervisors, or other business contacts who are familiar with the applicant’s professional abilities. As a result, approaching the professor, mentor, and supervisor would be the best option.</p>
                    <p className='font-semibold text-red-500 text-2xl mb-6 mt-8  px-8' >What Characteristics Distinguish A Strong Letter Of Recommendation (LOR)</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The most important piece of advice for writing a LOR is to conduct extensive research. The LOR must be specific and provide the applicant’s information in a clear and concise manner. Because this is a professional letter, the tone must be formal and upbeat. Because the applicant’s professional personality must come off as genuine, the letter should provide sincere and unprejudiced compliments. The importance of originality and consistency in the writing format cannot be overstated. Furthermore, the letter must be straightforward and error-free in grammar and punctuation.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Recommendation letters must be written on the officer’s or professor’s official letterhead. It should also give their email address in case any communication is required.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>For the admissions procedure, two or three letters of recommendation from varied professional contacts are usually sufficient.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Statements of Purpose and Letters of Recommendation are essential documents for studying abroad in nations such as the United States, Canada, the United Kingdom, Europe, Australia, and New Zealand.</p>
                </div>
            </div>
        </div>
    )
}

export default Letter_Of_Recommendation