import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const MBA = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Masters of Business Administration - Future Dream Makers</title>
        <meta
          name="description"
          content="Masters of Business Administration"
        />
        <meta
          name="Keyword"
          content="MBA"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-MBA bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
            Masters of Business Administration (MBA)
          </p>
        </div>
      </div>
      <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
        <div className='md:text-justify'>
          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>MBA</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Master of Business Administration is the full form of the term. MBA is a two-year postgraduate professional degree programme that focuses on the principles of modern business management. MBA programmes provide students with a thorough understanding of business while also developing leadership and soft skills.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>To be eligible for MBA admission and to sit for an MBA Entrance test, aspirants must have a 50-60% average in their undergraduate degree. After passing the MBA admission exams, students will be required to participate in the GD/PI rounds. CAT, MAT, GMAT, XAT, and SNAP are some of the MBA entrance exams.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Marketing Management, Supply Chain Management, Operations Management, Strategic Management, and other MBA subjects are available. The MBA Syllabus includes topics such as applied statistics, accounting, entrepreneurship, business law, and ethics in a market-oriented curriculum. If candidates do not wish to pursue a regular MBA, they can opt for a Distance MBA or Online MBA programme. The course curriculum is consistent throughout all MBA programmes.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>An MBA programme is a fantastic way to advance your career. IIM Ahmedabad, IIM Calcutta, and IIM Bangalore, for example, offer an average MBA placement of INR 20-25 LPA. Senior Managers, Business Development Management, Human Resources Manager, Business Analyst (IT), Management Consultant, Financial Analyst, Area Sales Manager, and other MBA jobs are common.</p>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>MBA Eligibility Criteria</p>
          <div className='flex px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>To enrol in MBA courses, students must have graduated from a recognised university with a bachelor’s degree in any field.</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>To pursue Master of Business Administration, they must have a minimum of 50% in their undergraduate degree. IIMs and IITs, for example, require a 60 percent graduation rate.</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Reserved category students, such as OBC, SC, and ST, receive a 5% reduction in the percentage required in several MBA programmes.</p>
          </div>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Type Of MBA</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Due to the growing demand for MBA courses, a variety of MBA types have evolved, each concentrating on a specific type of student. An Executive MBA, a Distance MBA, or an Online MBA can be pursued by those who are actively employed. MBA specialisations such as MBA HR, MBA Finance, MBA IT, and so on are offered in many MBA specialisations. The following are the numerous sorts of MBAs:</p>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Full Time MBA</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Executive MBA</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>1 Year MBA</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Online MBA</p>
          </div>
          <div className='flex  px-0 md:ml-0 ml-4 '>
            <div><BsDot className="text-5xl" /></div><p className='text-lg font-normal font-serif '>Distance MBA</p>
          </div>
          <p className=' font-semibold text-red-500 text-2xl mb-4 mt-8  px-8' >Full Time MBA:</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>A full-time MBA programme is a two-year planned and thorough MBA curriculum that focuses on management expertise. The full-time MBA programme is a residential programme that includes both live and virtual classes. The cost of a full-time MBA varies between INR 2 and 25 LPA.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-4 mt-8  px-8' >Part Time MBA:</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Executive MBA programme is a part-time MBA programme for professionals with significant job experience. The Executive MBA is a one-year programme designed for managers, entrepreneurs, technocrats, and other business executives. It emphasises teamwork and attaining a single goal. The Executive MBA is the greatest option for working people who wish to advance their professions.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Admission to the Executive MBA programme is similar to that of the MBA programme. They are based on entrance tests such as the CAT, MAT, and XAT, among others. To be eligible for an Executive MBA programme, candidates must have at least 5 years of work experience.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-4 mt-8  px-8' >One Year MBA:</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The One-Year MBA programme is designed for working professionals who do not want to compromise their jobs to get an MBA. The MBA-PGPX programme at IIM Ahmedabad is one of India’s most popular one-year MBA programmes. To apply for this course, students must have a minimum of 5 years of work experience.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>One-year MBA programmes are available in a variety of specialisations, including finance. Candidates who want to study MBA for working professionals but don’t want to commit to a full-time 2-year residential MBA programme might consider a one-year MBA programme. The typical cost of a one-year MBA programme is between INR 15 and 20 LPA.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-4 mt-8  px-8' >Online MBA:</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>An online MBA can be completed in one to four years. It is a part-time MBA programme that has been approved by the UGC. When compared to a full-time MBA programme, the fees for an online MBA programme are lower. The average cost of an online MBA programme is INR 50,000 – 6 LPA.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Candidates can also pursue an online MBA from one of the world’s top business schools. Online MBA courses are flexible courses offered by Indian and foreign business schools for students who want to study MBA while working or who do not want to enrol in a traditional full-time 2-year MBA programme. Upgrad MBA is one of the most popular online MBA programmes. Students can take mba courses online from the greatest business schools in the world.</p>
          <p className=' font-semibold text-red-500 text-2xl mb-4 mt-8  px-8' >Distance MBA:</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>MBA Distance Education is a two-year correspondence MBA programme for postgraduate management. Distance MBA programmes are ideal for individuals who are unable to attend a traditional MBA programme. IGNOU MBA is one of India’s most popular distance MBA programmes. The IGNOU MBA programme comprises of 28 courses: 20 core courses, 7 specialisation courses, and one project course. Students can also choose from five specialisations in the NMIMS Distance MBA, including Marketing, Leadership and Strategy, Operations and Supply Chain, and Applied Finance.</p>
          <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>MBA admissions are comparable to traditional MBA admissions in that they are based on an entrance exam. A Distance MBA programme has a cheaper average course charge than a normal or executive MBA programme.</p>
        </div>
      </div>
    </div>
  )
}

export default MBA