import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Italy = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const Italy_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university_logo-1.png' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university-logo-2.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university-logo-3.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university-logo-4.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university-logo-5.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Italy+logo/Italy_university-logo-6.jpg" }
    ];
    const Milan = () => {
        history.push('italy/milan')
        window.scrollTo(0, 0)
    }
    const Naples = () => {
        history.push('italy/naples')
        window.scrollTo(0, 0)
    }
    const Palermo = () => {
        history.push('italy/palermo')
        window.scrollTo(0, 0)
    }
    const Rome = () => {
        history.push('italy/rome')
        window.scrollTo(0, 0)
    }
    const Turin = () => {
        history.push('italy/turin')
        window.scrollTo(0, 0)
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Italy - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Italy"
                />
                <meta
                    name="Keyword"
                    content="Italy"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Italy bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        ITALY
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>About Italy</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Italy is a country located in Southern Europe. It is a peninsula that is surrounded by the Mediterranean Sea and is bordered by France, Switzerland, Austria, and Slovenia.Italy, the world’s favourite boot-shaped country, is a land of rich beauty, culture, and history. Studying abroad in Italy will provide you with access to an astonishing array of cultural, historic, and touristic treasures, from the Alps in the north to the seaside beaches in the south, and everything in between.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Italy’s education system is highly regarded, with a wide choice of quality academic institutions located throughout the country and welcoming international students with variety of <span className='text-red-500 '><a href='italy/scholarships'>scholarships</a></span>. Italy has played a significant role in academia, promoting the Bologna Process, which has led to the reform of European higher education. Students studying in Italy will learn essential classroom knowledge as well as important cultural competencies in one of Europe’s newest republics.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>With high <span className='text-red-500 '><a href='italy/cost-of-living'>cost of living</a></span> , Italy is known for its rich cultural heritage, which includes the Roman Empire, the Renaissance, and its rich cuisine. It is also famous for its art, architecture, fashion, and design. The country is a founding member of the European Union and is considered one of the world's most industrialized nations. The capital of Italy is Rome, and its largest city is Milan.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Italy Admission Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The application process for international students to study in Italy varies depending on the level of education and the institution. Here is a general outline of the steps involved:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Research:</span> Research the universities and programs that you are interested in and ensure that you meet the eligibility criteria for admission.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare required documents:</span> International students typically need to provide a <span className='text-red-500 '><a href='italy/admission-documents'>variety of documents</a></span>, including academic transcripts, language proficiency scores, a statement of purpose, letters of recommendation, and a resume.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply to the university:</span> Submit your application, along with the required documents, to the university of your choice.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for a decision:</span> The university will review your application and let you know if you have been accepted. This process can take several weeks or months.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain a student visa:</span> If you are accepted, you will need to obtain a student visa to study in Italy. You will typically need to provide proof of enrolment, <span className='text-red-500 '><a href='italy/financial-support'>financial support</a></span>, and <span className='text-red-500 '><a href='italy/health-insurance'>health insurance</a></span>.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Arrive in Italy:</span> Once you have your visa, you can <span className='text-red-500 '><a href='italy/travel'>travel</a></span> to Italy and start your studies.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>VISA Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The visa process for studying in Italy will depend on the country of origin of the student and the length of their intended stay. Generally, students from within the European Union (EU) do not need a visa, while students from outside the EU will need to obtain a student visa. Here are the steps to obtain a student visa for Italy:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Apply to a university in Italy:</span> Before applying for a student visa, you must first be accepted into a university in Italy.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Obtain a student visa application:</span> You can obtain the student visa application from the Italian embassy or consulate in your home country.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Gather required documents:</span> The following documents will be required to apply for a student visa:</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Passport</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Proof of acceptance into an Italian university</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Proof of financial support (e.g. scholarship, bank statement)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Health insurance policy</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Criminal record check</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '>Evidence of <span className='text-red-500 '><a href='italy/italian-language-proficiency'>Italian language proficiency</a></span> (if required)</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit the visa application:</span> Submit your visa application and all required documents to the Italian embassy or consulate in your home country.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend a visa interview:</span> You may be required to attend a visa interview with an embassy official.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for the visa decision:</span> The processing time for a student visa can vary, but it typically takes between 4 to 8 weeks.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the visa process can be complex and may vary based on your country of origin and the specific university you are attending. It is recommended that you check with the Italian embassy or consulate in your home country for specific requirements and up-to-date information.</p>


                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6 '>
                        <button onClick={Rome} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>ROME</button>
                        <button onClick={Milan} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>MILAN</button>
                        <button onClick={Naples} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>NAPLES</button>
                        <button onClick={Turin} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>TURIN</button>
                        <button onClick={Palermo} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>PALERMO</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {Italy_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Italy