import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Biology_Courses = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biology Courses - Future Dream Makers</title>
        <meta
          name="description"
          content="Biology Courses"
        />
        <meta
          name="Keyword"
          content="Biology Courses"
        />
      </Helmet>
      <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Biology bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            BIOLOGY
          </p>
        </div>
      </div>
      <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
        <div className='md:text-justify  '>

          <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Biology Stream</p>
          <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Biology stream students have a wide range of options to pursue at the post-secondary level. Some of the most popular options include:</p>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Bachelor of Science in Biology:</span> A four-year degree program that focuses on the study of living organisms, their behavior, environment and evolution.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Biology:</span> A two-year degree program that focuses on advanced topics in biology, such as genetics, biochemistry and cell biology.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Doctor of Philosophy in Biology:</span> A four- to six-year degree program that focuses on the study of molecular and cellular biology, with the option to specialize in a specific field. </p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Medical School:</span> A four-year degree program that focuses on the study of medicine and health sciences. </p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Veterinary School:</span> A four-year degree program that focuses on the study of animal health and medical care. </p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Nursing School:</span> A two-year degree program that focuses on providing medical care to patients.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pharmacy School:</span> A four-year degree program that focuses on the study of medications and their therapeutic uses.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Biotechnology:</span> A two- to four-year degree program that focuses on the study of genetic engineering. </p>
          </div>

          <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Masters level medical courses include:</p>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Biomedical Informatics:</span> A Master of Science in Biomedical Informatics is a graduate degree program designed to prepare students for a career in the rapidly expanding field of health informatics. The program provides a comprehensive education in the technologies, methods, and theories of health informatics, with a focus on developing the skills necessary to effectively design, develop, and manage information systems in the health and medical industry. The curriculum includes topics such as data mining, data management, health analytics, data security, information systems design, and user interface development. Students also receive training in areas such as health policy, clinical informatics, and research methodology. Graduates of the program may pursue a variety of career opportunities, including positions in clinical informatics, health data management, health analytics, and medical research.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Nursing:</span> The Master of Science in Nursing (MSN) is a graduate-level degree program designed for registered nurses (RNs) who want to expand their knowledge and skills in a particular area of nursing. MSN programs typically include courses in advanced nursing practice, healthcare systems and policies, healthcare research and evidence-based practice, and nursing leadership. Graduates from MSN programs often pursue careers in administration, research, education, and advanced practice nursing.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Services Management:</span> The Master of Science in Health Services Management is a program that provides students with the knowledge, skills, and experience necessary for a career in health services management.This program is designed to provide students with the skills necessary to be effective health services managers and leaders. Course topics include health services administration, health policy and law, health economics, health services management, leadership, and public health. Students also gain a thorough understanding of the healthcare system, including its structures, processes, and policies.This program is ideal for individuals who have a passion for helping others and improving the health of communities. It is also a great option for individuals who are interested in pursuing a career in the health services field.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Physician Assistant Studies:</span> The Physician Assistant (PA) Studies Master of Science Program is designed to train students in the field of medicine. The program focuses on developing a comprehensive understanding of the medical profession, which includes a detailed knowledge of anatomy, physiology, pharmacology, clinical medicine, and medical ethics. Upon completion of the program, students will be well-prepared to practice as a Physician Assistant in a variety of healthcare settings.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Science:</span> The Master of Science in Health Science (MSHS) is a professional degree program that focuses on the application of scientific principles to the prevention, diagnosis, treatment, and management of health conditions. The program prepares students for advanced positions in a variety of health care settings, including hospitals, clinics, and public health agencies. The MSHS curriculum covers a range of topics, including epidemiology, biostatistics, health services research, health policy, and health economics. Students also gain practical experience through hands-on clinical rotations, research projects, and internships. Graduates of this program are prepared to work as health professionals, administrators, educators, and researchers, and to pursue further study in a doctoral program.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Clinical Research:</span> The Master of Science in Clinical Research (MSCR) is a graduate-level program designed to provide students with an in-depth understanding of clinical research and a comprehensive overview of the clinical research process. The program is designed to provide students with a broad understanding of the principles and practices of clinical research, including regulatory and ethical considerations, research design and methodology, data management and analysis, and dissemination of results. Students complete coursework in topics such as clinical research design and methods, biostatistics, clinical trial management, and regulatory affairs. Students may also pursue specialized concentrations in areas such as medical devices and pharmaceuticals. Additionally, students may be able to complete a thesis or research project in order to gain further insight into the field of clinical research. The MSCR program typically takes two to three years to complete and may include courses such as clinical research design and methods, biostatistics, clinical trial management, and regulatory affairs. Upon successful completion of the program, graduates are prepared for careers in clinical research and may be qualified to pursue advanced research positions in the medical and pharmaceutical industry.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Economics:</span> The Master of Science in Health Economics is a degree program designed to prepare students for a career in health economics, health services research, and health policy. This program focuses on quantitative analysis, economic theory and methodology, and health care policy. Students will gain a strong understanding of the economic principles of health care, including the development and evaluation of health care programs and policies. Graduates of this program will be prepared to analyze health care data, develop health care policies, and conduct research in the health economics field. This program is ideal for those interested in health care, economics, and policy.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Informatics:</span> The Master of Science in Health Informatics focuses on the use of technology to improve the delivery of healthcare services. It combines knowledge of healthcare and technology to provide a comprehensive approach to health information management. Students in this program will learn the fundamentals of health informatics, such as data analytics, health information privacy and security, and healthcare information systems. They will also gain an understanding of how these technologies can be used to improve patient outcomes, reduce costs, and improve the overall quality of healthcare. Graduates will be prepared to become leaders in the healthcare industry and work in a variety of settings, including hospitals, clinics, and insurance companies.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Care Quality and Safety:</span> The Master of Science in Health Care Quality and Safety is a specialized program designed to provide students with the knowledge and skills to become health care quality and safety professionals. The program emphasizes quality improvement methodologies, risk management, patient safety, informatics, and leadership skills in the health care delivery system. The program focuses on the development of effective health care quality and safety systems, patient safety initiatives, and performance measurement strategies. The program also provides students with the opportunity to develop professional competencies in the areas of health care quality and safety, including leadership and communication. The program culminates in a capstone project in which students develop a real-world health care quality and safety program.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Care Law and Compliance:</span> The Master of Science in Health Care Law and Compliance is a multidisciplinary program designed to provide students with knowledge, skills, and competencies necessary to understand and navigate the legal and ethical complexities of the health care industry. The program focuses on legal and regulatory aspects of the industry, including topics such as health care fraud and abuse, medical malpractice, privacy and confidentiality, and health care compliance. Students who complete the program will gain an understanding of the legal landscape of health care and will be prepared to practice in a variety of legal and compliance roles within the health care industry.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Pharmaceutical Sciences:</span> The Master of Science in Pharmaceutical Sciences program provides students with the knowledge and skills necessary to pursue careers in the pharmaceutical sciences. The program focuses on the development, production, and regulation of drugs and other pharmaceutical products. Students will gain a comprehensive understanding of the regulations governing the production, sale, and use of pharmaceutical products. The program includes courses in pharmacology, medicinal chemistry, pharmacokinetics, pharmacy administration, pharmaceutical technology, drug formulation, and biopharmaceutics. Students can choose from a variety of electives in areas such as biotechnology, nanotechnology, and drug delivery systems.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Genetic Counseling:</span> A Master of Science in Genetic Counseling (MSGC) is a professional degree that prepares students to work as genetic counselors. Genetic counselors work with families who may have a family history of a genetic disorder, or who may be at risk for a genetic disorder due to their own personal medical history. They provide education, counseling, and support to families, individuals, and health care providers.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Care Ethics and Law:</span> The Master of Science in Health Care Ethics and Law (MSHCE) is an interdisciplinary program that combines the study of health care ethics and law to prepare students to work in a variety of health care settings. This program is designed to educate students on the ethical and legal issues related to health care, health care policies, and their implications for patient care. Students in this program learn about the legal, ethical, and social issues associated with health care, as well as the legal and ethical implications of health care policy and practice.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Population Health:</span> The Master of Science in Population Health (MSPH) degree is a professional master's program designed to prepare students to work with populations to improve health outcomes. The program combines coursework in public health, epidemiology, biostatistics, health policy, and health systems with practicum experience in a public health setting.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Education:</span> The Master of Science in Health Education is a two-year program designed to provide students with the skills and knowledge needed to effectively promote health and wellness. The program focuses on health promotion, health behavior, and health education. It also covers topics such as health policy, program planning, and evaluation. Graduates of the program will be prepared to work in a variety of health-related settings including public health departments, hospitals, and schools. They will also be able to create and implement health promotion initiatives.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Public Health:</span> The Master of Science in Public Health (MSPH) is a graduate degree that focuses on the prevention and treatment of disease in diverse populations. The degree program typically focuses on the social, economic, and environmental determinants of health and how to effectively use public health resources. The degree requires a minimum of 45 credit hours and can be completed in as little as two years. The curriculum typically includes coursework in biostatistics, epidemiology, health policy and management, environmental health sciences, and health promotion. Additionally, many programs offer concentrations in specific areas such as global health, health informatics, or health communication. This degree prepares students to take on leadership roles in public health and may even lead to a career in public health research.</p>
          </div>
          <div className='flex p-2 px-0 md:ml-0 ml-4 '>
            <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Master of Science in Health Promotion:</span> This program is designed for those who are interested in careers in health promotion and public health. It provides students with the knowledge and skills to design, implement, and evaluate health promotion programs in various settings. It also prepares students to work in a variety of health-related fields, including community health, health education, health communication, health policy, and health services administration. The program focuses on topics such as health promotion theory and research, health behavior theory, health communication, health policy, and program planning and evaluation. Students also learn about critical issues related to health disparities and inequities, and the role of health promotion in addressing these issues. Coursework typically includes courses in leadership, public health, and health promotion.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Biology_Courses
