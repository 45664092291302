import React from 'react'
import { Helmet } from "react-helmet";
const UK_England = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK England - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK England"
                />
                <meta
                    name="Keyword"
                    content="UK England"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>England</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>England is one of the four countries that make up the United Kingdom. Some of the top cities in England include London, Manchester, Birmingham, and Bristol. The population of these cities can range from hundreds of thousands to millions of residents.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>England has a number of highly ranked universities, including the University of Oxford, University of Cambridge, Imperial College London, and the University of Manchester. The average tuition fees for international students in England can range from £10,000 to £38,000 per year, depending on the institution and program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living in England varies depending on the city, with London being one of the more expensive cities. Accommodation options in England include university dorms, private apartments, and shared houses. Living criteria also vary depending on the city and individual preferences.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The English economy is diverse, with top companies in industries such as finance, technology, and healthcare. Some of the top companies in England include HSBC, BP, and AstraZeneca. Job opportunities in England vary by city and industry, with some popular job roles including software developer, financial analyst, and nurse. Part-time and full-time job opportunities are available, with many students taking on part-time work while studying. Some top courses in England include computer science, business, and law. The top job roles in demand can change depending on the job market, but some in-demand positions currently include data scientists, cybersecurity professionals, and digital marketing specialists.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>England is also known for its rich history and cultural heritage, making it a popular tourist destination. Some of the top tourist attractions in England include Buckingham Palace, the Tower of London, Stonehenge, and the Lake District National Park.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_England
