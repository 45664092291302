import React from 'react'
import { Helmet } from "react-helmet";
const UK_Scotland = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK Scotland - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK Scotland"
                />
                <meta
                    name="Keyword"
                    content="UK Scotland"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Scotland</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Scotland is one of the four countries that make up the United Kingdom. It is known for its stunning landscapes, rich history and cultural heritage, and vibrant cities. Some of the top cities in Scotland include Edinburgh, Glasgow, and Aberdeen. The population of these cities can range from hundreds of thousands to millions of residents.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Scotland has a number of highly ranked universities, including the University of Edinburgh, University of Glasgow, and the University of Aberdeen. The average tuition fees for international students in Scotland can range from £10,000 to £20,000 per year, depending on the institution and program.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The cost of living in Scotland varies depending on the city, with Edinburgh and Glasgow being two of the more expensive cities. Accommodation options in Scotland include university dorms, private apartments, and shared houses. Living criteria also vary depending on the city and individual preferences.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Scotland has a strong economy, with top companies in industries such as finance, technology, and energy. Some of the top companies in Scotland include Royal Bank of Scotland, Standard Life Aberdeen, and BP. Job opportunities in Scotland vary by city and industry, with some popular job roles including software developer, financial analyst, and nurse. Part-time and full-time job opportunities are available, with many students taking on part-time work while studying. Some top courses in Scotland include computer science, business, and law. The top job roles in demand can change depending on the job market, but some in-demand positions currently include data scientists, cybersecurity professionals, and digital marketing specialists.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Scotland is also a popular tourist destination, known for its stunning landscapes, rich history, and vibrant culture. Some of the top tourist attractions in Scotland include Edinburgh Castle, Loch Ness, the Scottish Highlands, and the Royal Botanic Garden Edinburgh.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Scotland
