import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Germany = () => {
    SwiperCore.use([Autoplay]);
    const history = useHistory();
    const Germany_University_Logo = [
        { image: 'https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Germany+logo/Germany_university_logo-1.gif' },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Germany+logo/Germany_university_logo-2.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Germany+logo/Germany_university_logo-3.png" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Germany+logo/Germany_university_logo-4.jpg" },
        { image: "https://s3.ap-south-1.amazonaws.com/futuredreammakers.com/FDM+IMAGES/Germany+logo/Germany_university_logo-5.png" }

    ];
    const Hamburg = () => {
        history.push('germany/hamburg')
        window.scrollTo(0, 0)
    }
    const Berlin = () => {
        history.push('germany/berlin')
        window.scrollTo(0, 0)
    }
    const Munich = () => {
        history.push('germany/munich')
        window.scrollTo(0, 0)
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Germany - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Germany"
                />
                <meta
                    name="Keyword"
                    content="Germany"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-Germany bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        GERMANY
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Germany</p>
                    <p className='text-lg font-serif py-2 font-normal  px-8 mb-6'>Germany is a country located in central-western Europe. It is the most populous member state of the European Union and the second most populous country in Europe after Russia. Germany is bordered by Denmark to the north, Poland and the Czech Republic to the east, Austria and Switzerland to the south, and France, Luxembourg, Belgium, and the Netherlands to the west. Berlin is the capital and largest city of Germany. The country is known for its rich cultural heritage, including its contributions to classical music, philosophy, and art. Germany has a strong economy and is a leading producer of goods such as machinery, vehicles, chemicals, and pharmaceuticals.</p>
                    <p className='text-lg font-serif py-2 font-normal  px-8 mb-6'>Germany is well-known for studying and pursuing jobs in subjects such as economics, engineering, and automotive technology, among others. Germany’s universities frequently appear in the QS University rankings of the world’s best universities. In reality, 45 German universities are listed among the top 1000 universities in the world by QS.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Students Choose To Study In Germany</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>The country offers a high standard of education and is home to many top-ranked universities, including the Technical University of Munich, the Humboldt University of Berlin, and the University of Heidelberg.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>International students in Germany can benefit from the country's low tuition fees, affordable <span className='text-red-500 '><a href='germany/cost-of-living'>cost of living</a></span> , and strong job market. Additionally, Germany is known for its high standard of living, cultural diversity, and strong support for students.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>A multiracial and Diversified society</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>It is well-known for its machineries and industrial sector.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>The country offers many part-time and full-time job opportunities, particularly in fields such as engineering, IT, and finance. Post-study work visas are also available for international students who wish to stay in Germany and work after graduation.</p></div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Germany Admission Process</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The application process for universities in Germany generally involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Research and selection of universities:</span> Research and select universities in Germany that offer the program you want to study and meet your admission requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check admission requirements:</span> Each university has its own admission requirements, such as language proficiency, academic transcripts, and standardized test scores.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Submit the application:</span> Submit your application, including all required documents, to the university or universities of your choice. Some universities use a centralized application platform, while others have their own individual application portals.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for a decision:</span> After submitting your application, you will receive a decision from the university, usually within several weeks.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Enroll:</span> If you are accepted, you will need to enroll in the university by paying a tuition fee and completing any other necessary enrollment procedures.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The exact application process may vary depending on the university and program you are applying to, so be sure to check the specific requirements and procedures of each institution you are interested in.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>VISA Process</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Determine visa type:</span> Depending on your nationality and the purpose of your stay in Germany, you may need a student visa, a language course visa, or another type of visa.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Gather required documents:</span> Prepare the required documents, including proof of financial means, academic transcripts, language proficiency test scores, and a health insurance policy.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Book an appointment:</span> Book an appointment at the German embassy or consulate in your home country to submit your visa application.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Submit the application:</span> Submit your visa application, including all required documents, at your appointment. You may also need to provide biometric data, such as fingerprints, at this time.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for a decision:</span> After submitting your application, you will need to wait for a decision from the German embassy or consulate, which can take several weeks.</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Receive your visa:</span> If your visa application is approved, you will receive your visa, which will be valid for the duration of your stay in Germany.</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: The exact visa process may vary depending on your nationality, the type of visa you need, and the German embassy or consulate you are applying to, so be sure to check the specific requirements and procedures of the relevant authorities.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Top Cities</p>
                    <div className='grid gap-3 gap-y-8 gap-x-8 content-center py-8 md:grid-cols-3 grid-cols-1 px-8 mb-6'>
                        <button onClick={Hamburg} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>HAMBURG</button>
                        <button onClick={Berlin} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>BERLIN</button>
                        <button onClick={Munich} className='font-bold text-white bg-blue-900 text-center rounded-2xl py-3 transition duration-300 hover:bg-red-500 transform hover:-translate-y-1 hover:scale-110 '>MUNICH</button>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 mb-6 mt-8  px-8'>Top University Lists</p>
                </div>
            </div>

            <div className='px-8 sm:px-12 md:px-8 lg:px-8 2xl:px-64 m-auto mb-6'>

                <Swiper
                    className=" "
                    breakpoints={{
                        300: {
                            width: 350,
                            slidesPerView: 1
                        },
                        350: {
                            width: 350,
                            slidesPerView: 2
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        450: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        550: {
                            width: 500,
                            slidesPerView: 2,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        650: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        700: {
                            width: 600,
                            slidesPerView: 2,
                        },
                        800: {
                            width: 900,
                            slidesPerView: 5,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]} // for pagination add {,pagination} module
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={1}
                    autoplay={{
                        delay: 900,
                        disableOnInteraction: false,
                    }}
                >
                    {Germany_University_Logo.map((slideContent, index) => (
                        <SwiperSlide>
                            <img
                                src={slideContent.image}
                                alt=""
                                className="w-auto h-auto align-middle p-1 "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Germany