import React from 'react'
import { Helmet } from "react-helmet";
const BBA = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bachelor of Business Adminstration - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Bachelor of Business Adminstration"
                />
                <meta
                    name="Keyword"
                    content="BBA"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-BBA bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold py-2 md:text-6xl text-4xl mb-2">
                        Bachelor of Business Adminstration <br>
                        </br>(BBA)
                    </p>
                </div>
            </div>
            <div className='flex justify-center md:flex-row flex-col 2xl:px-64 '>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6 '>Bachelor of Business Administration is the full form of the phrase BBA. The BBA Course is a three-year undergraduate business management course that teaches students managerial and entrepreneurial skills through BBA Subjects. BBA programmes are available in a variety of specialisations, including finance, marketing, and human resource management. Bachelor of Business Administration degrees are available to students from all disciplines, including the arts, sciences, and commerce.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA Admissions 2022 are made on the basis of entrance exams as well as merit in the Top BBA Colleges in India. Some of the best BBA Colleges can be found in cities such as Delhi, Mumbai, Kolkata, Pune, and Bangalore. SP Jain, Whistling Woods, and other BBA colleges in Mumbai</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The BBA Syllabus is intended to provide students with adequate business administration skills. BBA Subjects include Principles of Management, Macroeconomics, Human Behavior & Ethics at Workplace, Management Accounting, Banking & Insurance.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-6  px-8'>BBA Merit</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Merit and Entrance Based</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-6  px-8'>BBA Eligibility</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Merit and Entrance Based</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-6  px-8'>BBA Specializations</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA Finance, BBA Marketing, BBA International Business</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>Why Choose BBA After 12th ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>After completing their 10+2, students can pursue a career in management through the BBA programme.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>After completing the BBA course, students find it easy to find work. This allows them to gain more work experience.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Many students who cannot afford an MBA degree may choose to pursue a Bachelor of Business Administration degree immediately after graduating from high school. This will allow the student to save money and obtain a less expensive version of the MBA.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Students who are serious about management but do not want to pay the high fees associated with an MBA can pursue a Bachelor of Business Administration. This will help them in their MBA programme.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Merit and Entrance BasedBecause most Bachelor of Business Administration and MBA Colleges are the same, students have more time to develop stronger bonds with their peers and professors.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA (Full Time)</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Full-time BBA is a three-year undergraduate programme that prepares students for a successful career in management.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Full-time BBA is the most popular BBA option, with thousands of students choosing it each year.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Admission to the Bachelor of Business Administration programme is based on both merit and an entrance exam. DU, JAT, IPU CAT, and NPAT are popular BBA entrance exams.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA International Business, BBA Banking and Insurance, BBA HRl, BBA LLB, and other popular regular BBA specializations are listed below. Shailesh J Mehta School of Management, Gargi College, SP Jain Institute of Management and Research, and others are among the best colleges for BBA. Top BBA Colleges charge an average of INR 3,00,000 – 6,00,000 for BBA Courses.</p>

                    <p className=' uppercase font-bold text-black text-3xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA (Part Time)</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA part-time is a three-year undergraduate course, similar to a regular BBA. The only difference is that, unlike traditional Bachelor of Business Administration programmes, students are not required to be physically present for the course.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA Part Time is an excellent option for candidates who are unable to pursue a full-time BBA. The course curriculum of a part-time BBA is nearly identical to that of a regular BBA degree.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>In India, the average BBA Course Fees to pursue part-time BBA ranges from INR 45,000 to INR 60,000 in BBA Part-Time Colleges.</p>

                    <p className='leadinig-5 uppercase font-semibold text-black text-3xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA (Online)</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>BBA online was created with the goal of giving students the opportunity to understand the fundamentals of business administration and apply their knowledge in the real world through the use of a virtual education system.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Human Resource and Management, Organizational Behavior, Business and Communication, Management Skills, Retail Management, and many other topics are covered in this course.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Online BBA courses are less expensive than traditional BBA courses. The average fee for an online BBA programme is around INR 27,000. Online BBA offers popular BBA specialisations as well.</p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA - MBA Integrated Course</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Combined MBA or BBA MBA integrated course is a management course offered by various colleges such as IIMS for students interested in pursuing MBA after high school. It aims to teach management skills at both the undergraduate and postgraduate levels.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Human Resource and Management, Organizational Behavior, Business and Communication, Management Skills, Retail Management, and many other topics are covered in this course.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The eligibility requirements for a 5-Year MBA vary by college. Some colleges use entrance exams such as the IPMAT, while others choose students based on merit. To pursue an integrated MBA course, students must have passed the 10+2 examination in any stream.</p>
                    <p className=' text-lg font-serif py-1  px-8 font-bold '>1. BBA – MBA in IIM Rohtak</p>
                    <p className=' text-lg font-serif py-1  px-8 font-bold '>2. BBA – MBA in Mumbai University</p>
                    <p className=' text-lg font-serif py-1  px-8 font-bold '>3. BBA – MBA in Lucknow University </p>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>BBA - LLB Integrated Course</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Bachelor of Business Administration and Bachelor of Legislative Law (BBA LLB) is an integrated dual degree programme that offers an independent study of management studies and legislative law. LLB BBA The duration is five years. LLB BBA or BBA LLB entrance exams such as CLAT and SLAT are used to determine admission. Students must have a grade point average of 50-60% in class 12 to be eligible for a BBA LLB.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Symbiosis Law School, New Law College, Bharati Vidyapeeth Deemed University, M.S. Ramaiah College of Law, Amity University, Gwalior, and others are among the best colleges for BBA LLB.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The BBA LLB Integrated Course is a boon for students who want to study Law after completing their Bachelor of Business Administration. After completing the course, Law after BBA enables students to enter the legal field in Corporate.</p>
                </div>
            </div>
        </div>
    )
}

export default BBA