import React from 'react'
import { Helmet } from "react-helmet";
import { BsCheckLg } from 'react-icons/bs'
const Ireland_Travel = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Plan Your Travel - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Plan Your Travel"
                />
                <meta
                    name="Keyword"
                    content="Ireland Plan Your Travel"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Plan your travel</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are a few steps international students can follow to plan their travel to Ireland:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Book your flight:</span> Book a flight to Ireland as early as possible to get the best deal. Consider the time of day and the duration of the flight when choosing your flight.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Arrange transportation from the airport:</span> Once you arrive in Ireland, you will need to arrange transportation to your accommodation. Options include taking a taxi, using public transportation, or renting a car.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Plan your route:</span> If you plan on using public transportation, research your route beforehand to make your journey as smooth as possible. Consider purchasing an all-inclusive travel pass to save money.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pack appropriately:</span> Be sure to pack appropriately for the climate in Ireland, which can be cool and rainy, especially during the winter months.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Familiarize yourself with local customs:</span> Research local customs in Ireland to ensure you have a smooth and respectful trip.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check for visa requirements:</span> If you are an international student, make sure you have the correct visa for your stay in Ireland and that it is valid for the duration of your trip.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>By following these steps, international students can plan their travel to Ireland with confidence and ease.</p>
                    </div>
                    </div>
    </div>
  )
}

export default Ireland_Travel
