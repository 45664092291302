import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Career_Counselling = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career Counselling - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Career-Counselling"
                />
                <meta
                    name="Keyword"
                    content="Career-Counselling"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-CareerCounselling bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            CAREER COUNSELLING
          </p>
        </div>
      </div>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify'>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Career Counselling</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Career counseling is a process that helps individuals make decisions about their educational and career goals. A career counselor can assist with identifying skills and interests, exploring different career options, and creating a plan for achieving goals. This can include assistance with resume building, job search strategies, and interview preparation. Career counseling can be helpful for people at any stage of their career, whether they are just starting out, looking to make a change, or seeking advancement</p>
                    <p className='text-lg font-serif py-1 font-bold  px-8 mb-6'>The process of FDM for career counseling typically involves several steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Assessment:</span>The counselor will assess the individual's interests, skills, values, and personality traits to help identify potential career options. This may include administering and interpreting tests, such as aptitude and personality tests.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Exploration:</span>The counselor will help the individual explore different career options and gather information about industries, job duties, and requirements.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Decision-making:</span>The counselor will assist the individual in making informed decisions about their career goals by weighing the pros and cons of different options and considering their values, skills, and interests.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Action-planning:</span>The counselor will help the individual create a plan to achieve their career goals, which may include identifying specific steps to take, such as pursuing additional education or training, building a network of contacts, or improving their resume or interviewing skills.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Implementation and follow-up:</span>The counselor will help the individual implement their plan and provide ongoing support and guidance as needed.</p>
                    </div>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Selection of university</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>When selecting a university for a master's or undergrade  course, there are several factors to consider. These include the university's reputation and ranking, the specific program's reputation and ranking, the curriculum and course offerings, the location and campus culture, and the availability of financial aid or scholarships. It is also important to research the faculty and their areas of expertise, as well as the job prospects for graduates of the program. Finally, you should consider your own personal preferences, such as the size of the university and the type of campus atmosphere. It's recommended to visit university campus, talk to current students or alumni, and research the university's website to gather more information.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8  px-8'>Process of selection</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of selecting a university for an undergraduate or graduate program can vary depending on the individual, but generally it involves the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Research:</span>Begin by researching universities that offer the program you're interested in. Look at the universities' websites, talk to current students or alumni, and gather information about the program, the campus, and the surrounding community.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Narrow down your list:</span>Based on your research, narrow down your list of potential universities to a smaller group that best fits your needs and preferences.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Visit the campus:</span>If possible, visit the campus of each university on your short list. This will give you a better sense of the university's atmosphere and culture, and help you decide if it's the right fit for you.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Application:</span>Once you have identified the university you would like to apply, Prepare the required documents and submit the application to the university.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Wait for the decision:</span>Universities will notify you of their decision. If you are accepted, you will typically have to make a decision about whether to attend or not.</p></div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Make your final decision:</span>After considering all of the information you've gathered, make your final decision about which university to attend.</p></div>

                </div>
            </div>
        </div>
    )
}

export default Career_Counselling