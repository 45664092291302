import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_Scholarship = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Canada Scholarship - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada Scholarship"
                />
                <meta
                    name="Keyword"
                    content="Canada Scholarship"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Scholarship in Canada for students</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>There are several scholarships available for Indian students looking to study in Canada, including:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Mitacs Globalink Research Award:</span> This scholarship provides funding for undergraduate and graduate students in India to participate in 12- to 24-week research internships in Canada.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>IDRC Research Awards:</span> The International Development Research Centre (IDRC) offers research awards to support postdoctoral and doctoral research in Canada for students from developing countries, including India. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Vanier Canada Graduate Scholarships:</span> This scholarship is awarded to outstanding doctoral students in Canada and is open to international students, including those from India.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Pierre Elliot Trudeau Foundation Scholarship:</span> : This scholarship is awarded to doctoral students who are pursuing research in areas related to the Foundation's four themes, including human rights and dignity, responsible citizenship, Canada in the world, and people and their natural environment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University-specific scholarships:</span> Many Canadian universities offer scholarships and awards specifically for international students, including those from India. Contact the university you're interested in attending to learn more about their specific scholarship opportunities.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that eligibility requirements and application procedures for scholarships may vary. Be sure to check the specific requirements and deadlines for each scholarship before applying.</p>
                </div>
            </div>
        </div>
    )
}

export default Canada_Scholarship