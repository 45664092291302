import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_IHS = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UK IHS - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UK IHS"
                />
                <meta
                    name="Keyword"
                    content="UK IHS"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>IHS and NHS</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>IHS stands for the Immigration Health Surcharge. It's a fee that must be paid by international students and other non-UK residents applying for a Tier 4 (Student) visa, to access the National Health Service (NHS) while in the UK.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The National Health Service (NHS) is the UK's publicly funded healthcare system, providing free or low-cost healthcare to UK residents, including international students.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students with a valid Tier 4 (Student) visa and IHS payment can access most NHS services, including:</p>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>General practitioner (GP) services</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Hospital treatment</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Prescription medication</p>
                    </div>
                    <div className='flex p-2   px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'>Dental treatment (for some conditions)</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Note: There may be some exclusions and limitations on NHS services for international students, and some treatments may not be covered. It's recommended to check the latest information on the NHS website.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>IHS fee</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The Immigration Health Surcharge (IHS) fee is a charge that is paid by international students and certain other non-European Economic Area (EEA) nationals who apply for a visa to study in the United Kingdom. The IHS fee covers the cost of accessing the National Health Service (NHS) during the period of their visa.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As of 2021, the IHS fee for Indian students is £624 per year for most visa categories, including Tier 4 (Student) visas. This fee is payable for the entire duration of the visa, and is required to be paid at the time of making the visa application.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that the IHS fee is a non-refundable charge, even if the visa application is rejected. The fee is subject to change, so it is advisable to check the UK government's official website for the most up-to-date information on the IHS fee.</p>

                </div>
            </div>
        </div>
    )
}

export default UK_IHS