import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const CMAT = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CMAT - Future Dream Makers</title>
                <meta
                    name="description"
                    content="CMAT"
                />
                <meta
                    name="Keyword"
                    content="CMAT"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-CMAT bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        CMAT
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64 '>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is CMAT ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The National Testing Agency (NTA) administers the Common Management Admission Test (CMAT) for admission to MBA/PGDM programmes at many B-Schools across India. The CMAT exam has 100 questions covering Quantitative Techniques and Data Interpretation, Logical Reasoning, Language Comprehension, General Awareness, and Innovation and Entrepreneurship. CMAT has a total of 400 points. The CMAT exam is three hours long and is administered using a Computer-Based Test (CBT).</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Candidates will be eligible to apply for the following types of programmes across all AICTE affiliated colleges:</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGDM – Post Graduate Diploma in Management</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>MBA – Masters of Business Administration</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>PGCM – Post Graduate Certificate in Management</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Executive PGDM</p>
                    </div>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%]">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST TYPE</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">CONTENT</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Online (Computer-Based-Test)</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">100 Questions</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">3 Hours or 180 Minutes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>CMAT Eligibility Criteria</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Graduation:</span> It is mandatory to have a bachelor’s degree or its equivalent in any discipline from a recognized university.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Minimum Marks:</span> No minimum aggregate marks are required in graduation to apply for CMAT 2022 exam.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Age:</span> There is no age limit for applying for CMAT 2022 exam</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Final Year Students:</span>  Final year students whose results will be released before the beginning of admission for the academic session 2022-23 can also apply for CMAT 2022 exam.</p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Number of Attempts:</span> There is no limit on the number of times a student can take the exam.</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>CMAT Exam Pattern</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>CMAT 2022 exam pattern has been revised and will consist of 5 sections :</p>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Language Comprehensions</span></p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Quantitative Techniques & Data Interpretation</span></p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Logical Reasoning</span> </p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>General Awareness</span></p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Number of Attempts:</span></p>
                    </div>
                    <div className='flex  px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Innovation & Entrepreneurship </span>(Optional)</p>
                    </div>

                    {/* table */}
                    <div className='flex justify-center mb-6'>
                        <table className=" md:w-[90%] w-auto lg:w-[55%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">SECTION</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Language Comprehension</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">20 Questions</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Quantitative Techniques and Data Interpretation</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">20 Questions</td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Logical Reasoning</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">20 Questions	</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">General Awareness</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">20 Questions	</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Innovation & Entrepreneurship (Optional)</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">20 Questions	</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className='  uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>CMAT Marking Scheme</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>For the 2022 session, the CMAT marking scheme has not been effected. For your convenience, the CMAT 2022 marking scheme is provided below:</p>

                    {/* table */}
                    <div className='flex justify-center mb-6'>
                        <table className=" md:w-[90%] w-auto lg:w-[55%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">PARTICULARS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">MARKS ALLOTMENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">For Every Correct Response</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">+4</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">For Every Wrong Response</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">-1</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">For Unattempted Question</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CMAT