import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Swords = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Swords- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Swords"
                />
                <meta
                    name="Keyword"
                    content="Ireland Swords"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Swords</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Swords is a town in the north of Ireland, with a population of over 50,000 people. It is home to several universities and colleges, including Dublin Institute of Technology and Dublin City University. The average tuition fees for international students in Swords range from €10,000 to €15,000 per year. Some of the top courses for international students in Swords include business, engineering, and computer science. The highest paying jobs in Swords are in the technology, finance, and marketing sectors, with roles such as software engineer, financial analyst, and marketing manager. The average wage for these jobs is between €45,000 and €60,000 per year. The cost of living in Swords is relatively lower compared to other cities in Ireland, with an average monthly rent of €500 and a monthly grocery bill of €200. Swords is known for its historical landmarks, such as Swords Castle and the Battle of Clontarf Visitor Centre. There are also several part-time and full-time job opportunities for international students in Swords, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Swords