import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_Exams = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Exams - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Exams"
                />
                <meta
                    name="Keyword"
                    content="Australia Exams"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Exams Required</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The exams required for admission to universities in Australia vary depending on the course of study and the university you wish to attend. Here are some common exams that international students may need to take:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>English language proficiency exams:</span> International students whose first language is not English may be required to take exams such as <span className='text-red-500 '><a href='/ielts'>IELTS</a></span> , <span className='text-red-500 '><a href='/toefl'>TOEFL</a></span> , or <span className='text-red-500 '><a href='/pte'>PTE</a></span> to demonstrate their English language proficiency.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Standardized tests:</span> Some courses may require standardized tests such as the <span className='text-red-500 '><a href='/gre'>GRE</a></span> (Graduate Record Examination) or <span className='text-red-500 '><a href='/gmat'>GMAT</a></span> (Graduate Management Admission Test) for admission to graduate programs.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Academic exams:</span> If you have completed prior academic studies, you may need to provide transcripts or other records to demonstrate your academic achievements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Subject-specific exams:</span> Some courses may require subject-specific exams, such as physics or math exams, to assess your understanding and knowledge in a particular field.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to check with the specific university and course you wish to attend for their requirements and the exams they accept for admission.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Exams