import React from 'react'
import { Helmet } from "react-helmet";
const Ireland_Waterford = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ireland Waterford- Future Dream Makers</title>
                <meta
                    name="description"
                    content="Ireland Waterford"
                />
                <meta
                    name="Keyword"
                    content="Ireland Waterford"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Waterford</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Waterford is a coastal city in the southeast of Ireland, with a population of over 50,000 people. It is home to several universities and colleges, including Waterford Institute of Technology and Institute of Technology, Carlow. The average tuition fees for international students in Waterford range from €10,000 to €15,000 per year. Some of the top courses for international students in Waterford include engineering, business, and tourism. The highest paying jobs in Waterford are in the engineering, pharmaceutical, and medical device sectors, with roles such as product engineer, research scientist, and sales manager. The average wage for these jobs is between €45,000 and €60,000 per year. The cost of living in Waterford is relatively lower compared to other cities in Ireland, with an average monthly rent of €500 and a monthly grocery bill of €200. Waterford is known for its rich history, including the Waterford Crystal Visitor Centre and Reginald's Tower, a 13th-century tower museum. There are also several part-time and full-time job opportunities for international students in Waterford, including roles in hospitality, retail, and customer service.</p>
                </div>
            </div>
        </div>
    )
}

export default Ireland_Waterford