import React from 'react'
import { Helmet } from "react-helmet";
const New_Zealand_PCC = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Police clearance certificate - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand Police clearance certificate"
                />
                <meta
                    name="Keyword"
                    content="New Zealand Police clearance certificate"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Police clearance certificate</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A police clearance certificate, also known as a police check or a criminal record check, is a document that verifies the criminal history of an individual. This certificate is often required for visa applications, employment purposes, or other situations where a person's criminal background is relevant.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The process of obtaining a police clearance certificate typically involves filling out an application form and submitting it to the relevant law enforcement agency, along with a fee and any required supporting documentation. The processing time can vary depending on the agency and the country in which the certificate is being obtained, but it usually takes several weeks to several months to receive the certificate.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To obtain a police clearance certificate, an individual may be required to provide their personal information, such as their name, date of birth, and current address. They may also be asked to provide fingerprints, photos, or other forms of identification.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to note that a police clearance certificate may not be available in all countries, and the process for obtaining one can vary widely. In some cases, a police clearance certificate may only be available from the individual's home country. Additionally, some countries may have specific requirements for obtaining a police clearance certificate, such as a minimum residency period or a certain type of visa.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>When applying for a visa in New Zealand, it is important to check with the immigration authorities to determine if a police clearance certificate is required and what the specific requirements are for obtaining one.</p>
                </div>
            </div>
        </div>
    )
}

export default New_Zealand_PCC
