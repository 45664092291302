import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_Postworkvisa = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Post Study Work Visa - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Post Study Work Visa"
                />
                <meta
                    name="Keyword"
                    content="Australia Post Study Work Visa"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Post Study Work Visa</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The length of the post-study work visa that international students can apply for in Australia is dependent on the level and field of their study.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For Bachelor's and Master's degree holders in most fields, the post-study work visa (also known as the Graduate Temporary (Subclass 485) visa) is usually available for 2 years.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>However, if an international student has completed a Master's degree by research or a Doctorate degree in a STEM field (Science, Technology, Engineering, Mathematics), they may be eligible for a longer post-study work visa of up to 4 years.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that these visa arrangements are subject to change and students are encouraged to check the latest immigration rules and regulations on the Department of Home Affairs website.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Subclass 485</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Subclass 485 is a type of visa in Australia for international students who have completed their studies in Australia and want to temporarily stay in the country to gain work experience. There are two streams under Subclass 485 visa:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Graduate Work stream:</span> This stream is for international students who have completed a higher education degree in Australia and want to stay and work in the country for up to 18 months.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Post-Study Work stream:</span> This stream is for international students who have completed a higher education degree in Australia and want to stay and work in the country for up to five years.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Eligibility for this visa depends on various factors, including the type of degree you have completed, your English language proficiency, and your work experience. To apply for a Subclass 485 visa, you must have held a valid student visa in the past and have met the requirements of your course of study.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students can apply for a Subclass 485 visa after they have completed their studies in Australia and held a valid student visa.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For the Graduate Work stream, the student must have completed a higher education degree in Australia, such as a Bachelor's, Master's or PhD degree. For the Post-Study Work stream, the student must have completed a higher education degree in Australia, including a Bachelor's, Master's or PhD degree, or a vocational education and training (VET) course.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the eligibility criteria for a Subclass 485 visa may change over time, so it's best to check the official website of the Department of Home Affairs, Australia <a className='text-blue-500' href='https://www.homeaffairs.gov.au/' target="_blank">(www.homeaffairs.gov.au)</a> for the most up-to-date information on eligibility requirements and application procedures.</p>

                </div>
            </div>
        </div>
    )
}

export default Australia_Postworkvisa