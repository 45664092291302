import React from 'react'
import { BiArrowFromBottom } from 'react-icons/bi'

const TopScroll = () => {

    // Get the button:
    let mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return (
        <div className='fixed bottom-5 right-10 mx-10 px-5 z-20 '>
            <button onClick={topFunction} id="myBtn" title="Go to top"><BiArrowFromBottom className='text-4xl bg-transparent text-red-500' /></button>
        </div>
    )
}

export default TopScroll
