import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Canada_BritishColumbia = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Canada British Columbia - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Canada British Columbia"
                />
                <meta
                    name="Keyword"
                    content="Canada British Columbia"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>British Columbia</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>British Columbia, Canada's westernmost province, is defined by its Pacific coastline and mountain ranges. Nature areas like Glacier National Park offer hiking and biking trails, as well as campgrounds. Whistler Blackcomb is a major ski resort that hosted the 2010 Winter Olympics. The scenic Sea-to-Sky Highway links Whistler with Vancouver, a city known for its film industry, at the province's southern U.S. border.British Columbia is known for its breath taking mountains, lush forests, and rugged coastline. It’s the only province in Canada where you can go skiing or to the beach in the same day. population of British Columbia is 50.7 lakhs (2019). Gross domestic product of British Columbia, Canada from 2000 to 2021 (in million chained 2012 Canadian dollars) </p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of British Columbia:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>The University of British Columbia:</span> Established in 1908, The University of British Columbia is a non-profit public higher-education institution located in the urban setting of the metropolis of Vancouver (population range of 1,000,000-5,000,000 inhabitants), British Columbia. This institution also has a branch campus in Kelowna. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, The University of British Columbia (UBC) is a very large (university Rank enrolment range: 40,000-44,999 students) coeducational Canadian higher education institution. The University of British Columbia (UBC) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Simon Fraser University:</span> Founded in 1965, Simon Fraser University is a non-profit public higher-education institution located in the urban setting of the small city of Burnaby (population range of 50,000-249,999 inhabitants), British Columbia. This institution also has branch campuses in the following locations: Vancouver, Surrey. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, Simon Fraser University (SFU) is a very large (university Rank enrolment range: 30,000-34,999 students) coeducational Canadian higher education institution. Simon Fraser University (SFU) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Victoria:</span> Founded in 1963, University of Victoria is a non-profit public higher-education institution located in the suburban setting of the small city of Victoria (population range of 50,000-249,999 inhabitants), British Columbia. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, University of Victoria (UVic) is a large (university Rank enrolment range: 15,000-19,999 students) coeducational Canadian higher education institution. University of Victoria (UVic) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>British Columbia Institute of Technology:</span> Founded in 1960, British Columbia Institute of Technology is a non-profit public higher-education institution located in the suburban setting of the small city of Burnaby (population range of 50,000-249,999 inhabitants), British Columbia. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, British Columbia Institute of Technology (BCIT) is a large (university rank  enrolment range: 15,000-19,999 students) coeducational Canadian higher education institution. British Columbia Institute of Technology (BCIT) offers courses and programs leading to officially recognized higher education degrees such as bachelor degrees in several areas of study. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Vancouver Island University:</span> Established in 1969, Vancouver Island University is a non-profit public higher-education institution located in the urban setting of the small city of Nanaimo (population range of 50,000-249,999 inhabitants), British Columbia. This institution also has branch campuses in the following locations: Duncan, Parksville, Powell river. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, Vancouver Island University (VIU) is a large (university Rank enrolment range: 15,000-19,999 students) coeducational Canadian higher education institution. Vancouver Island University (VIU) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details. </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Northern British Columbia:</span> Established in 1990, University of Northern British Columbia is a non-profit public higher-education institution located in the suburban setting of the small city of Prince George (population range of 50,000-249,999 inhabitants), British Columbia. This institution also has branch campuses in the following locations: Fort St. John, Quesnel, Terrace. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, University of Northern British Columbia (UNBC) is a small (university Rank enrolment range: 3,000-3,999 students) coeducational Canadian higher education institution. University of Northern British Columbia (UNBC) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees, master degrees, doctorate degrees in several areas of study.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Kwantlen Polytechnic University:</span> Established in 1981, Kwantlen Polytechnic University is a non-profit public higher-education institution located in the urban setting of the medium city of Surrey (population range of 250,000-499,999 inhabitants), British Columbia. This institution also has branch campuses in the following locations: Richmond, Langley, Cloverdale. Officially recognized by the Ministry of Advanced Education, Skills & Training of British Columbia, Kwantlen Polytechnic University (KPU) is a large (university Rank enrolment range: 20,000-24,999 students) coeducational Canadian higher education institution. Kwantlen Polytechnic University (KPU) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, associate or foundation), bachelor degrees in several areas of study. See the university Rank degree levels and areas of study matrix below for further details.</p>
                    </div>
                    </div>
                    </div>
    </div>
  )
}

export default Canada_BritishColumbia
