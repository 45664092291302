import React from 'react'
import { BsDot } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UPSC = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>UPSC - Future Dream Makers</title>
                <meta
                    name="description"
                    content="UPSC"
                />
                <meta
                    name="Keyword"
                    content="UPSC"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-UPSC bg-cover md:bg-top bg-center' >
                <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
                    <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
                        UPSC
                    </p>
                </div>
            </div>

            <div className='flex justify-center md:flex-row flex-col 2xl:px-64'>
                <div className='md:text-justify'>
                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>What Is UPSC ?</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>UPSC is India’s central agency that administers exams such as the Civil Services Exam (CSE) to recruit candidates for top government positions such as IAS, IPS, and IFS. The UPSC recruits candidates for both civil and military services.</p>
                    <p className=' text-lg font-serif font-bold py-1  px-8 mb-6'>Exams Conducted For Selection Into Civil Services By UPSC :</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Civil Services Examination (CSE)</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Engineering Services Examination (ESE).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Indian Forestry Services Examination (IFoS).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Central Armed Police Forces Examination (CAPF).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Indian Economic Service and Indian Statistical Service (IES/ISS).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Combined Geo-Scientist and Geologist Examination.</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Combined Medical Services (CMS).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Special Class Railway Apprentices Exam (SCRA).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Limited Departmental Competitive Examination for selection of Assistant Commandant. (Executive) in CISF.</p>
                    </div>
                    <p className=' text-lg font-serif font-bold py-1  px-8 mb-6'>Exams Conducted For Selection Into Defense Services By UPSC :</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>National Defence Academy & Naval Academy Examination – NDA & NA (I).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>National Defence Academy & Naval Academy Examination – NDA & NA (II).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Combined Defense Services Exam – CDS (I).</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '>Combined Defense Services Exam – CDS (II).</p>
                    </div>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8  px-8'>UPSC Exam Pattern For Civil Services</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>There are 3 Levels in UPSC Civil Services Exam :</p>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Preliminary Exam</span>(Objective Test)</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Main Exam</span>(Written Test)</p>
                    </div>
                    <div className='flex px-0 md:ml-0 ml-4 '>
                        <div><BsDot className="text-5xl" /></div><p className='pr-8 pt-2 text-lg font-normal font-serif '><span className='font-bold'>Personality Test</span>(Interview)</p>
                    </div>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Prelims-</span> Civil Services Preliminaries The preliminary exam consists of two compulsory papers worth 200 points each (General Studies Paper-I and General Studies Paper-II). The questions will be multiple-choice and objective in nature. Preliminary exam scores will not be used to determine final rankings, but will only be used to qualify for the main exam.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Mains-</span>The written examination (main) will consist of nine papers, but only seven of them will be used to determine the final merit ranking. For the remaining two papers, the candidate must achieve the minimum marks set by the commission each year.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'><span className='font-bold'>Interview-</span>The candidate will be interviewed by a Board of Directors, who will have a record of his or her career in front of them. He or she will be quizzed on topics of general interest.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>The Preliminary Exam marks are not used to determine the final ranking. Prelims is a screening test used to determine who will take the Main Exam. The final rank of a candidate in the Civil Service Exam is determined solely by the marks obtained in the Main Exam and the Interview. The main exam is worth 1750 points, while the interview is worth 275 points. Total points are calculated based on the year 2025.</p>
                    <p className=' text-lg font-serif py-1 font-normal  px-8 mb-6'>Having said that, passing the Preliminary Exam without adequate preparation is difficult. The Union Public Service Commission selects only the top three candidates from a pool of 100 to take the Main Exam.</p>
                    {/* table */}
                    <div className='flex justify-center mb-6 md:px-0 px-8'>
                        <table className="md:w-[90%] w-auto lg:w-[90%] ">
                            <thead>
                                <tr>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">PAPER</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">MARKS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NO. OF QUESTIONS</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NEGATIVE MARKING</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">NATURE OF PAPER</th>
                                    <th className="text-center text-white bg-purple-800 rounded-t-xl text-lg font-semibold font-serif px-3 py-4 border-2 border-white">TEST DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">General Studies Paper-I</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">200</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">100</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">⅓ of the total marks</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Written & Objective Type</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 Hours</td>
                                </tr>
                                <tr>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">General Studies Paper-II (CSAT)</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">200</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">80</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">⅓ of the total marks</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">Written & Objective Type</td>
                                    <td className="text-center text-white text-base font-serif bg-purple-500 px-3 py-3 border-2 border-white">2 Hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UPSC