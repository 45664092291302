import React, { useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { BsCheckLg } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContectThankyou from './ContactThankyou';

function Contactform() {
    const [username, setUsername] = useState("");
    const [errorsname, setErrorsName] = useState("");
    const [errorsNa, setErrorsNa] = useState("");
  
    const [email, setEmail] = useState('');
  
  
    const [contactNumber, setContactNumber] = useState("");
    const [errorscon, setErrorsCon] = useState(false);
    const [errorscontact, setErrorsContact] = useState("");
  
    const [message, setMessage] = useState("");
    const [service, setService] = useState("");
   
  
    // --------kundan2-----------
    // Form Validation
    const onchangeFullName = (e) => {
      const username = e.target.value.replace(/[^a-z]/gi, " ");
      setUsername(username);
      if (username.length <= 3) {
        setErrorsName("Enter Full Name");
        setErrorsNa(true);
        return username;
      } else {
        setErrorsName(false);
      }
    };
    const onchangeContact = (e) => {
      const contactNumber = e.target.value.replace(/([^0-9])+/i, "");
      setContactNumber(contactNumber);
      if (contactNumber.length < 10) {
        setErrorsContact("Enter Full Contact Number");
        setErrorsCon(true);
      } else {
        setErrorsCon(false);
      }
    };
    // ---------------
  
    // connect with firebase
    const submitData = async (event) => {
      console.log( username, contactNumber, email, service, message);
      event.preventDefault();
      if (username && contactNumber && email && service && message) {
        const res = fetch(
          "https://fdm-website-80b63-default-rtdb.firebaseio.com/fdmEnquiry.json",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username, contactNumber, email, service, message
            }),
          }
        );
  
        if (res) {
        
          console.log(res.data);
          console.log(res);
          document.getElementById('ContectThankyou').style.display = 'block';
        } else {
          toast.error('Please Fill The Form');
        }
      } else {
        toast.error('Please fill the form');
  
      }
    };
  return (
    <div>
        
        <div className="grid md:grid-cols-2 grid-cols-1 lg:px-28 xl:px-64 px-8 gap-8 mb-6 mt-8 ">
        {/* Form */}
        <div className="">
          <form
            className="bg-white shadow-md rounded md:px-0 px-5"
            method="POST"
          >
            <div className="mb-4 px-4">
              <label
                className="block mb-5 text-lg font-serif font-bold pt-3"
                for="username"
              >
                Your Name
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline md:w-[400px] w-60"
                type="text"
                placeholder="Fullname"
                name="username"
                required
                value={username}
                onChange={onchangeFullName}
              ></input>
              {errorsNa && (
                <div className="text-left text-red-500">{errorsname}</div>
              )}
            </div>
            <div className="mb-6 px-4">
              <label
                className="block mb-5 text-lg font-serif font-bold"
                for="phone-number"
              >
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline md:w-[400px] w-60"
                type="text"
                placeholder="Phone-Number"
                name="contact_number"
                required
                value={contactNumber}
                onChange={onchangeContact}
                maxLength="10"
              ></input>

              {errorscon && (
                <div className="text-left text-red-500">{errorscontact}</div>
              )}
            </div>
            <div className="mb-6 px-4">
              <label
                className="block mb-5 text-lg font-serif font-bold"
                for="email"
              >
                Your Email
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline md:w-[400px] w-60"
                placeholder="Email"
                type="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              ></input>
            </div>
            <div className="mb-6 px-4">
              <label
                className="block mb-5 text-lg font-serif font-bold"
                for="email"
              >
                Choose Service
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 md:w-[400px] w-60 w-auto"
                name="service"
                onChange={(e) => setService(e.target.value)}
                value={service}
              >
                <option selected>Choose a service</option>
                <option value="Education Loan">Education Loan</option>
                <option value="Student Visa">Student Visa</option>
                <option value="ILETS Training">IELTS Training</option>
                <option value="Career Counselling">Career Counselling</option>
              </select>
            </div>
            <div className="mb-6 px-4">
              <label
                className="block mb-5 text-lg font-serif font-bold"
                for="message"
              >
                Message
              </label>
              <textarea  
              className="shadow appearance-none border rounded py-0  px-3 h-20 leading-tight focus:outline-none focus:shadow-outline md:w-[400px] w-60"
                type="message"
                placeholder="Mesasge"
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              >
              </textarea>
            </div>
            <div class="md:w-2/3 px-4 py-3">
              <button
                class="shadow bg-white hover:bg-red-500 focus:shadow-outline focus:outline-none text-black font-bold py-2 px-4 rounded-full border-2 border-black mb-5 hover:shadow-2xl shadow-black "
                type="button"
                onClick={submitData}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>

        <div className="">
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>{" "}
            <p className="p-2 text-lg font-serif font-semibold text-left">
              {" "}
              Accommodation & Travel Support
            </p>
          </div>
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>{" "}
            <p className="p-2 text-lg font-serif font-semibold text-left">
              Local Reference In Each Country
            </p>
          </div>
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>{" "}
            <p className="p-2 text-lg font-serif font-semibold text-left">
              1000+ Students Counselled
            </p>
          </div>
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>
            <p className="p-2 text-lg font-serif font-semibold text-left">
              More Than 95% VISA Approval Ratio
            </p>
          </div>
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>{" "}
            <p className="p-2 text-lg font-serif font-semibold text-left">
              Professionally Trained Experts & Faculties
            </p>
          </div>
          <div className="flex items-center p-2">
            <div className="px-2">
              {" "}
              <BsCheckLg className="text-2xl text-red-500" />
            </div>
            <p className="p-2 text-lg font-serif font-semibold text-left">
              Education Loan Availability
            </p>
          </div>
          <div className="flex md:px-11 px-5 flex-col mt-5">
            <div className="flex items-center">
              <button>
                {" "}
                <a href="tel:8770262128">
                  <BsTelephoneFill
                    size={40}
                    className="hover:text-red-500 mb-6"
                  />
                </a>
              </button>
              <p className="px-3 mb-7 font-poppins text-sm font-medium ">
                For More Details Call Us At<br></br>
                <span className="text-2xl font-semibold text-red-500 font-sans ">
                  (+91) 87702-62128
                </span>{" "}
              </p>
            </div>
            <div className="md:px-4 px-8 ">
              <button className="text-white bg-red-500 hover:bg-red-400 p-2 px-12 text-lg font-semibold m-4 font-serif border-blue-900 rounded-full hover:shadow-2xl shadow-black">
                <a href="mailto:info@futuredreammakers.com">MAIL US</a>
              </button>
            </div>
          </div>
        </div>
        </div>
        <ContectThankyou />
      <ToastContainer />
    </div>
  )
}

export default Contactform