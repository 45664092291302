import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Australia_Medical = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Medical - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Medical"
                />
                <meta
                    name="Keyword"
                    content="Australia Medical"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Medical Examination</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>For student visas in Australia, medical requirements typically include:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Medical examination:</span> All international students must undergo a medical examination with a Panel Physician authorized by the Australian government. This examination is designed to assess the student's overall health and to identify any medical conditions that may affect their ability to study and live in Australia.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Health insurance:</span> All international students must have adequate health insurance coverage for the duration of their stay in Australia. This is typically in the form of Overseas Student Health Cover (OSHC), which provides students with access to medical services and hospital care while they are in Australia.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Chest x-ray:</span> Depending on the student's personal circumstances, they may be required to undergo a chest x-ray to assess for any potential tuberculosis (TB) exposure.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Additional medical tests:</span> In some cases, students may be required to undergo additional medical tests, such as a blood test, to assess their health status.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the specific medical requirements will depend on the individual circumstances of the student, including their age, the length of their stay, and the type of course they are pursuing. To ensure that you have a clear understanding of the medical requirements for your visa, it's a good idea to seek professional advice.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>To apply for a medical examination for an Australian Subclass 500 student visa, you need to follow these steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Find a Panel Physician:</span> You must have a medical examination with a Panel Physician authorized by the Australian government. You can search for authorized Panel Physicians in your area on the Australian Department of Home Affairs website.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Book an appointment:</span> Contact the Panel Physician to schedule an appointment for your medical examination. Be sure to inform them that the medical examination is for an Australian student visa.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Prepare required documents:</span> Before your appointment, gather any required documents, such as your passport, proof of identity, and any previous medical test results. You should also bring a list of any current or past medical conditions, and any prescription medications you are taking.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Attend the medical examination:</span> On the day of your appointment, attend the medical examination as scheduled. The Panel Physician will complete a physical examination and may also request additional tests, such as a chest x-ray or blood test, depending on your individual circumstances.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Receive the medical results:</span> After the medical examination, the Panel Physician will send the results to the Australian Department of Home Affairs. You will receive a copy of the results and a form to attach to your student visa application.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It's important to note that the medical examination must be completed before you submit your student visa application. The medical examination results are valid for 12 months, so you should ensure that you apply for your student visa within this time frame.</p>

                </div>
            </div>
        </div>
    )
}

export default Australia_Medical