import React from 'react'
import { Helmet } from "react-helmet";
const Australia_Funds = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Australia Funds - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Australia Funds"
                />
                <meta
                    name="Keyword"
                    content="Australia Funds"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Funds to Maintain before Visa</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The exact amount in INR that you would need to maintain before applying for a Subclass 500 visa would depend on the exchange rate at the time of your application.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>As a rough estimate, based on the minimum amount required by the Australian government of AUD20,290 per year, or AUD620 per week, this could be around INR 1,000,000 to 1,500,000.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to keep in mind that this estimate is subject to change based on various factors such as fluctuations in the exchange rate, and that the exact amount you would need would depend on your individual circumstances.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is recommended to consult with a financial advisor or check the current exchange rate for a more accurate estimate.</p>
                </div>
            </div>
        </div>
    )
}

export default Australia_Funds