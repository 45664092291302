import React from 'react'
import { Helmet } from "react-helmet";
const New_Zealand_LOA = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand LOA - Future Dream Makers</title>
                <meta
                    name="description"
                    content="New Zealand LOA"
                />
                <meta
                    name="Keyword"
                    content="New Zealand LOA"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>letter of acceptance</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>A letter of acceptance is a formal document issued by an organization, school, or company to an individual to inform them that their application has been approved and they have been accepted into a program, job, school, or other opportunity. This letter typically includes details such as the start date, responsibilities, compensation, and any conditions of acceptance. It is usually seen as a binding agreement between the recipient and the issuer, and signifies the beginning of a new relationship. A letter of acceptance serves as proof of the individual's acceptance and is often required for official purposes, such as obtaining visas or enrolling in a program.</p>
                    </div>
                    </div>
    </div>
  )
}

export default New_Zealand_LOA
