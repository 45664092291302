import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const Currency_Exchange = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Currency Exchange - Future Dream Makers</title>
                <meta
                    name="description"
                    content="Currency Exchange"
                />
                <meta
                    name="Keyword"
                    content="Currency Exchange"
                />
            </Helmet>
            <div className='mx-auto w-full md:h-[30rem] h-96 py-12 px-4 bg-CurrencyExchange bg-cover md:bg-top bg-center' >
        <div className="flex flex-col justify-center text-center items-center md:h-[29rem] lg:h-[25rem] h-[22rem] mx-auto">
          <p className="text-white font-heading font-semibold uppercase py-2 md:text-6xl text-4xl mb-2">
            CURRENCY EXCHANGE
          </p>
        </div>
      </div>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64'>
                <div className='md:text-justify'>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Currency exchange</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Currency exchange for international students refers to the process of converting one currency to another in order to pay for expenses while studying abroad. International students may need to exchange currency in order to pay for tuition fees, living expenses, and other costs associated with studying in a foreign country.</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Here are a few things to keep in mind when exchanging currency as an international student:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Exchange rates:</span>Exchange rates fluctuate constantly, and the rate you receive can vary depending on the institution or service you use. It's important to research different options and compare exchange rates before making a decision.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Fees and charges:</span>Some institutions or services charge fees for currency exchange, so it's important to factor in these costs when comparing options.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Location:</span>Currency exchange rates may vary depending on the location. It's important to check the exchange rate in the country you are studying in, as well as in your home country.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Timing:</span>Exchange rates can fluctuate throughout the day, so it's best to exchange currency at a time when the rate is favorable.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Documents:</span>Some institutions or services may require you to present identification or other documents in order to exchange currency. Make sure you have the necessary documents on hand.</p></div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4'>
                        <div className='md:ml-4  p-2'> <BsCheckLg className="text-2xl text-red-500" /></div> <p className='pr-8 ml-2 text-lg font-normal font-serif'><span className='text-lg font-bold font-serif'>Credit card:</span>Some students prefer to use their credit card abroad as it is more convenient and also provides protection in case of fraud. It's important to check with the credit card issuer if they charge any foreign transaction fee and if they have any policies regarding usage of the card in foreign countries.</p></div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>International students have multiple options for currency exchange, such as banks, currency exchange offices, and online money transfer services. It's important to research different options and compare exchange rates before making a decision.</p>


                </div>
            </div>
        </div>
    )
}

export default Currency_Exchange