import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const USA_California = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>USA California - Future Dream Makers</title>
                <meta
                    name="description"
                    content="USA California"
                />
                <meta
                    name="Keyword"
                    content="USA California"
                />
            </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>California</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>Cultural events abound, international cuisine is plentiful and the state is known to have a laid-back vibe that is often appealing to visitors. The moderate climate of California means that no matter what time of year you plan to visit, you'll be met with weather that isn't too extreme.California population 3.90 carore .The economy of the State of California is the largest in the United States, with a $3.63 trillion gross state product (GSP) as of 2022.It is the largest sub-national economy in the world.</p>
                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>Top universities of California:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Stanford University   :</span> As the second-best university in the world, Stanford University is heads and shoulders above the other universities in the state. Located in the heart of California’s Silicon Valley , Stanford has produced so many future business leaders that it’s been nicknamed the ‘billionaire factory’.  The school earns particularly high scores in the research and employability indicators, ranking second in the US for both.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'> University of California, Berkeley (UCB):</span> The University of California, Berkeley is the highest ranked public university in the USA, the second best in California and the fourth best school in the country. Included amongst its faculty, alumni and researchers are 14 Nobel Prize winners, 13 faculty MacArthur Fellows, two Turing Award winners and three Pulitzer Prize winners. The public University of California system has 10 campuses, many of which are included in our ranking above. UCB benefits from a stronger reputation with academics and employers around the world than any of the other public institutions in the state. It also produces more research and hires more international faculty members.  </p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of California, Los Angeles (UCLA):</span> Up one place to rank fifth in the USA and third in California is UCLA. UCLA earns a high score in the research indicator, a reflection of the high quality of research output at the school.  UCLA describes itself as a truly international university, with students coming from nearly 90 countries to attend the school. It earns a high score in the diversity and internationalisation indicator, ranking in joint third in the USA.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>University of Southern California:</span> In fourth place we have the University of Southern California (USC), rising one place to rank joint 14th in the USA this year. The university performs particularly well in the learning experience indicator, placing eighth in the US.  USC is one of the world’s leading private research universities and a global centre for arts, technology and international business. The school has a total of 19,500 undergraduate students and 26,500 graduate students. Its diverse curriculum provides opportunities for interdisciplinary study and collaborations with leading researchers.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>California Institute of Technology (Caltech):</span> Despite falling two places in the USA rankings this year, California Institute of Technology (Caltech) performs well, placing 21st in the USA and fifth in California.  Based in Pasadena, close to Los Angeles, Caltech is an ideal study destination for anyone interested in science and engineering.Although Stanford is the best university overall, Caltech is the best school for learning experience, ranking joint first in the US. Caltech has a 3 to 1 student faculty ratio, and the school is associated with 76 Nobel laureates.  </p>
                    </div>
                    </div>
                    </div>
    </div>
  )
}

export default USA_California
