import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const UK_Fee = () => {
    return (
        <div>
               <Helmet>
        <meta charSet="utf-8" />
        <title>UK Fee - Future Dream Makers</title>
        <meta
          name="description"
          content="UK Fee"
        />
        <meta
          name="Keyword"
          content="UK Fee"
        />
      </Helmet>
            <div className='flex justify-center md:flex-row flex-col mb-6 2xl:px-64 lg:mt-0 mt-12'>
                <div className='md:text-justify  '>

                    <p className=' uppercase font-semibold text-black md:text-4xl text-2xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Fee Payment</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The fee payment process for an Indian student studying at a university in the UK may involve the following steps:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Contact University:</span> Contact the university's finance or international office to understand their fee payment requirements.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Choose Payment Method:</span> Choose a payment method, such as wire transfer or credit card, that is acceptable to the university and your bank.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Currency Conversion:</span> Consider <span className='text-red-500'><a href='currency-exchange'>currency exchange</a></span> rates and transaction fees when making the payment.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Transfer Funds:</span> Transfer the funds to the university's designated bank account, following the instructions provided by the university.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Confirm Payment:</span> Check with the university to confirm that the payment has been received and processed.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Keep Records:</span> Keep records of the payment, including the date, amount, and confirmation from the university.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>It is important to carefully review the fee payment instructions and requirements provided by the university, as well as to consider the exchange rate and any other fees that may be involved in the process. Additionally, you should contact your bank to understand any charges they may apply for international wire transfers.
                        duration of the visa, and is required to be paid at the time of making the visa application.</p>

                    <p className=' uppercase font-semibold text-black text-4xl underline  decoration-red-500 decoration-4 underline-offset-8 mb-6 mt-8 px-8'>Tution fee refundable process after visa rejection</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>If your UK visa has been rejected, you may be eligible for a refund of your tuition fees from the university. Here is the process you can follow to claim your refund:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Check the university's policy:</span> Before taking any action, review the university's policy on tuition fee refunds for international students. This information should be available on the university's website or in the enrollment agreement you signed when you first enrolled in the course.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Contact the university:</span> Contact the international student services or the finance department of the university to request a tuition fee refund. Explain your situation and provide a copy of your visa rejection letter.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Provide necessary documents:</span> The university may require you to provide additional documents, such as a letter from the embassy or proof of financial hardship, to support your refund request.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Wait for a response:</span> The university may take several weeks to process your request. Be patient and keep in touch with the university to follow up on the status of your refund.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Appeal the decision:</span> If your request is denied, you may have the option to appeal the decision. You can contact the university's ombudsman or legal services for further advice on the appeal process.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In conclusion, follow these steps to request a tuition fee refund from a UK university after a visa rejection. It is important to be informed about the university's policy and to provide all the necessary documents to support your claim.</p>

                    <p className=' font-semibold text-red-500 text-2xl mb-6 mt-8 px-8'>IHS refund</p>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>The time it takes to receive a refund of the Immigration Health Surcharge (IHS) fee after a visa rejection can vary, depending on several factors. Here are some things to consider:</p>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Refund process:</span> The process of obtaining a refund of the IHS fee can take several weeks or months, as the refund request needs to be reviewed and processed by the UK government.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Refund amount:</span> The amount of the refund you receive may be less than the full amount you paid, as the IHS fee is non-refundable after a certain period of time has elapsed.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Method of payment:</span> The method of payment you used to pay the IHS fee (e.g. credit card, debit card, or bank transfer) can affect the amount of time it takes to receive a refund.</p>
                    </div>
                    <div className='flex p-2 px-0 md:ml-0 ml-4 '>
                        <div className='md:ml-4 p-2'> <BsCheckLg className="text-2xl text-red-500" /></div><p className='pr-8 ml-2 text-lg font-normal font-serif '><span className='text-lg font-bold font-serif'>Processing time:</span> The processing time for a refund request can vary depending on the volume of requests being processed by the UK government. During busy periods, it may take longer to receive a refund.</p>
                    </div>
                    <p className='text-lg font-serif py-1 font-normal  px-8 mb-6'>In conclusion, it is difficult to estimate the exact time it will take to receive a refund of the IHS fee after a visa rejection, as it can depend on several factors. If you have not received your refund within a reasonable period of time, you can contact the UK government's customer service team for further information.</p>
                </div>
            </div>
        </div>
    )
}

export default UK_Fee